export let participants = [
	{
		id: 'clv1uoyl00010ykbvwnh0emrk',
		name: 'United States',
		image: 'https://cdn.cube.community/1713239716510-united-states.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwm9pqai0028903x1h8sfqqt',
					teamId: 'clv1uoyl00010ykbvwnh0emrk',
					user: {
						id: 'clgikpzti0016t74nje916hp4',
						discordId: '754833098595762227',
						discordAvatar: 'https://cdn.discordapp.com/avatars/754833098595762227/b054e244cac022ea6b032410d126bca6.png',
						discordUsername: 'Bytesy',
						discordDiscriminator: '0',
						twitchUser: 'Bytesy_',
						playableAccounts: [
							{id: '2169974796454690', type: 'OCULUS', name: 'JOY', avatar: 'https://cdn.assets.beatleader.xyz/2169974796454690R40.png'},
						],
						bio: null,
						controllers: null,
						countryCode: 'US',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9789380857649351,
				averageNormalizedAccuracy: 0.9978149463571409,
				averageWeightedScore: 1.1362152040707356,
				scores: [
					{
						id: 'clx9eheyg007b7piorrbpponj',
						score: 1992101,
						accuracy: 0.9766373902748124,
						normalizedAccuracy: 1,
						weightedScore: 1,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 1, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-10T20:03:05.848Z',
						updatedAt: '2024-06-10T20:06:26.907Z',
					},
					{
						id: 'clx93yoge005t7pioiw2y14lj',
						score: 1825017,
						accuracy: 0.9718729281326635,
						normalizedAccuracy: 1,
						weightedScore: 1,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 1, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-10T15:08:35.534Z',
						updatedAt: '2024-06-10T15:12:01.601Z',
					},
					{
						id: 'clx93utdk005p7pioxlr1jq6u',
						score: 1028989,
						accuracy: 0.97842869707847,
						normalizedAccuracy: 0.9980998071677718,
						weightedScore: 1.094514401505283,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 4, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-10T15:05:35.288Z',
						updatedAt: '2024-06-10T15:07:53.575Z',
					},
					{
						id: 'clx96lwoe00697pio9ulx92a1',
						score: 2109423,
						accuracy: 0.9785440755215884,
						normalizedAccuracy: 0.9979382916205605,
						weightedScore: 1.104024442640951,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 3, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-10T16:22:38.511Z',
						updatedAt: '2024-06-10T16:27:27.195Z',
					},
					{
						id: 'clx93njkf005l7pioq1kqmcnq',
						score: 999470,
						accuracy: 0.9830385112838897,
						normalizedAccuracy: 0.9982630999122062,
						weightedScore: 1.1121501354314458,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 4, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-10T14:59:55.983Z',
						updatedAt: '2024-06-10T15:02:29.982Z',
					},
					{
						id: 'clx93czb6005d7pio0p9obwl2',
						score: 502679,
						accuracy: 0.9914088770992141,
						normalizedAccuracy: 0.9982663160904931,
						weightedScore: 1.2506459948320414,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 10, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-10T14:51:43.170Z',
						updatedAt: '2024-06-10T14:54:37.826Z',
					},
					{
						id: 'clx93hhb3005f7pioy1p9a5mv',
						score: 664967,
						accuracy: 0.9726361209639083,
						normalizedAccuracy: 0.9921371097089537,
						weightedScore: 1.3921714540854293,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 4, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-10T14:55:13.120Z',
						updatedAt: '2024-06-10T14:56:45.640Z',
					},
				],
			},
			{
				player: {
					id: 'clwm9pmrg0026903xfykuhth3',
					teamId: 'clv1uoyl00010ykbvwnh0emrk',
					user: {
						id: 'clgijbzb4000it74nu9tgw02l',
						discordId: '798005132964266045',
						discordAvatar: 'https://cdn.discordapp.com/avatars/798005132964266045/d1fbe5be7e4f96bbb74eac10e968aefd.png',
						discordUsername: 'oermergeesh',
						discordDiscriminator: '0',
						twitchUser: 'oermergeesh',
						playableAccounts: [
							{
								id: '1922350521131465',
								type: 'OCULUS',
								name: 'oermergeesh',
								avatar: 'https://cdn.assets.beatleader.xyz/1922350521131465R43.png',
							},
						],
						bio: "Hi, I'm oermergeesh!\nI play on rift cv1, and have around 3.5k hours on Beat Saber.\nI have been playing since may of 2018.\nI used to use a Z offset of 27, then 50, until i was accused of cheating. Now my offset is 0.\nI map a little too much lol\n#1 paqqer 😎\nI'm currently #1 global.",
						controllers: 'Rift Touch',
						countryCode: 'US',
						motto: '"Just don\'t bad cut"',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9784481820599072,
				averageNormalizedAccuracy: 0.9973029683677129,
				averageWeightedScore: 1.1434859376562194,
				scores: [
					{
						id: 'clx7xgr0e002i7pio4duw7wnn',
						score: 670237,
						accuracy: 0.9803444619153838,
						normalizedAccuracy: 1,
						weightedScore: 1,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 1, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T19:18:55.166Z',
						updatedAt: '2024-06-09T19:20:29.373Z',
					},
					{
						id: 'clx7zspvu003g7piold6t5ze5',
						score: 1030948,
						accuracy: 0.98029143984596,
						normalizedAccuracy: 1,
						weightedScore: 1,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 1, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T20:24:12.811Z',
						updatedAt: '2024-06-09T20:26:32.493Z',
					},
					{
						id: 'clx7yhvco00307pioozke8cfp',
						score: 2112548,
						accuracy: 0.9799937374604242,
						normalizedAccuracy: 0.9994166850775932,
						weightedScore: 1.0294314221606913,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 2, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T19:47:47.064Z',
						updatedAt: '2024-06-09T19:52:36.798Z',
					},
					{
						id: 'clx807efz003m7piolzfvkn26',
						score: 1000360,
						accuracy: 0.9839138795040892,
						normalizedAccuracy: 0.9991520252015313,
						weightedScore: 1.054752998839159,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 2, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T20:35:37.824Z',
						updatedAt: '2024-06-09T20:38:14.116Z',
					},
					{
						id: 'clx7z7wf7003a7pioxnkwf5nq',
						score: 1812998,
						accuracy: 0.9654724722885664,
						normalizedAccuracy: 0.9934143079215153,
						weightedScore: 1.2275550001893294,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 6, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-09T20:08:01.507Z',
						updatedAt: '2024-06-09T20:11:28.577Z',
					},
					{
						id: 'clx7x2pc1002c7piof3n1r8xe',
						score: 502383,
						accuracy: 0.9908250909700513,
						normalizedAccuracy: 0.9976784919928826,
						weightedScore: 1.335630203847258,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 16, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-09T19:07:59.809Z',
						updatedAt: '2024-06-09T19:10:55.809Z',
					},
					{
						id: 'clx7y5c9d002u7pioghpr7x5a',
						score: 1975087,
						accuracy: 0.9682961924348757,
						normalizedAccuracy: 0.9914592683804686,
						weightedScore: 1.357031938557099,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 11, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-09T19:38:02.449Z',
						updatedAt: '2024-06-09T19:41:26.020Z',
					},
				],
			},
			{
				player: {
					id: 'clwm9r0ss002i903xct3tnnmd',
					teamId: 'clv1uoyl00010ykbvwnh0emrk',
					user: {
						id: 'cluywi32m000givs9m1wr4gmg',
						discordId: '728730355259015258',
						discordAvatar: 'https://cdn.discordapp.com/avatars/728730355259015258/01435928e84235cc8f18297ec43d79ae.png',
						discordUsername: 'Zenith',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199085118735',
								type: 'STEAM',
								name: 'Pausing=Cheating | Zenith',
								avatar: 'https://avatars.steamstatic.com/488a48a5a212c220eb7cf9acfa22f9a4ba10f607_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'US',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9752646073939298,
				averageNormalizedAccuracy: 0.994057683669685,
				averageWeightedScore: 1.3045252681942061,
				scores: [
					{
						id: 'clxsmrz8l00e684783nt362f1',
						score: 1816469,
						accuracy: 0.9673208774998868,
						normalizedAccuracy: 0.9953162080134048,
						weightedScore: 1.1618387670869779,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 4, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T07:02:52.966Z',
						updatedAt: '2024-06-24T07:06:25.178Z',
					},
					{
						id: 'clxsl7a0e00e08478fqqxh003',
						score: 502745,
						accuracy: 0.9915390456280139,
						normalizedAccuracy: 0.9983973849771225,
						weightedScore: 1.231696813092162,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 8, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T06:18:47.535Z',
						updatedAt: '2024-06-24T06:21:42.773Z',
					},
					{
						id: 'clxv6j89h002d90r9agqh50pa',
						score: 1025801,
						accuracy: 0.9753973423348468,
						normalizedAccuracy: 0.9950075076531503,
						weightedScore: 1.2483234428523182,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 6, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T01:51:29.429Z',
						updatedAt: '2024-06-26T01:53:57.299Z',
					},
					{
						id: 'clxry6525007u8478dcevw4ta',
						score: 997195,
						accuracy: 0.9808009127434926,
						normalizedAccuracy: 0.9959908470658974,
						weightedScore: 1.2588675351476848,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 9, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T19:34:03.293Z',
						updatedAt: '2024-06-23T19:36:37.735Z',
					},
					{
						id: 'clxrxi3dt007c84784p0iy0n0',
						score: 2100648,
						accuracy: 0.9744734247973372,
						normalizedAccuracy: 0.9937869627932128,
						weightedScore: 1.3134816441495203,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 11, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-23T19:15:21.377Z',
						updatedAt: '2024-06-23T19:20:13.708Z',
					},
					{
						id: 'clxrwbml9006e84781ei281rx',
						score: 1971175,
						accuracy: 0.966378315042738,
						normalizedAccuracy: 0.9894955125267243,
						weightedScore: 1.439123683216519,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 16, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T18:42:20.061Z',
						updatedAt: '2024-06-23T18:45:43.046Z',
					},
					{
						id: 'clxrvtekx00688478lf71k0jo',
						score: 663809,
						accuracy: 0.9709423337111932,
						normalizedAccuracy: 0.9904093626582836,
						weightedScore: 1.4783449918142582,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 5, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-23T18:28:09.873Z',
						updatedAt: '2024-06-23T18:29:42.932Z',
					},
				],
			},
			{
				player: {
					id: 'clwm9qrzl002e903x9mry8qdc',
					teamId: 'clv1uoyl00010ykbvwnh0emrk',
					user: {
						id: 'clkosglk0007qs32zk6jm12l2',
						discordId: '943693187854250066',
						discordAvatar: 'https://cdn.discordapp.com/avatars/943693187854250066/ccec27499ecc2a26b1df2435ce86d983.png',
						discordUsername: 'Ayyserr',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199071469065',
								type: 'STEAM',
								name: 'Ayyserr',
								avatar: 'https://avatars.steamstatic.com/2415d8cbbe1aa39a81ca7f3925db2246cd742a1c_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'US',
						motto: 'I LOVE SALTYBUTTER',
						pronouns: 'Legit/Boss',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9751008222053777,
				averageNormalizedAccuracy: 0.9938970719298631,
				averageWeightedScore: 1.3495015913688309,
				scores: [
					{
						id: 'clxasn67d00927pioeunnfmuk',
						score: 1815093,
						accuracy: 0.9665881187644282,
						normalizedAccuracy: 0.9945622424339061,
						weightedScore: 1.1878904918777689,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 5, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-11T19:27:15.241Z',
						updatedAt: '2024-06-11T19:30:43.020Z',
					},
					{
						id: 'clxkzdt4c00fr1320al4stb8i',
						score: 1982180,
						accuracy: 0.9717735708455182,
						normalizedAccuracy: 0.9950198308218309,
						weightedScore: 1.2081881898686364,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 4, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-18T22:33:37.452Z',
						updatedAt: '2024-06-18T22:36:59.363Z',
					},
					{
						id: 'clxkyarg600fj1320uow6pvxf',
						score: 997321,
						accuracy: 0.9809248412780376,
						normalizedAccuracy: 0.9961166949158468,
						weightedScore: 1.25074164839417,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 7, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-18T22:03:15.702Z',
						updatedAt: '2024-06-18T22:05:50.455Z',
					},
					{
						id: 'clxkykl0b00fl1320f5a6qlwo',
						score: 1025180,
						accuracy: 0.9748068557301448,
						normalizedAccuracy: 0.994405149435277,
						weightedScore: 1.2782843633907464,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 12, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-18T22:10:53.915Z',
						updatedAt: '2024-06-18T22:13:12.639Z',
					},
					{
						id: 'clxasa3eo008y7pio77ewuyne',
						score: 2099616,
						accuracy: 0.9739946884386561,
						normalizedAccuracy: 0.9932987381379622,
						weightedScore: 1.338115243232921,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 13, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-11T19:17:05.088Z',
						updatedAt: '2024-06-11T19:21:55.119Z',
					},
					{
						id: 'clxkxs1pk00ff1320tedn3306',
						score: 501536,
						accuracy: 0.9891545948504541,
						normalizedAccuracy: 0.9959964412811388,
						weightedScore: 1.578811369509044,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 37, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-18T21:48:42.536Z',
						updatedAt: '2024-06-18T21:51:37.457Z',
					},
					{
						id: 'clxky0ug900fh13205mqy0mcz',
						score: 662114,
						accuracy: 0.9684630855304055,
						normalizedAccuracy: 0.9878804064830798,
						weightedScore: 1.604479833308528,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 14, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-18T21:55:33.033Z',
						updatedAt: '2024-06-18T21:57:05.620Z',
					},
				],
			},
			{
				player: {
					id: 'clwmftg68002u903xlqw3jgc1',
					teamId: 'clv1uoyl00010ykbvwnh0emrk',
					user: {
						id: 'clwmflicb002s903xmxh2ws5v',
						discordId: '518889156680876032',
						discordAvatar: 'https://cdn.discordapp.com/avatars/518889156680876032/14915d5ae1eee434fa45244f4c1695ed.png',
						discordUsername: 'JustCallMeJack',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561197974131273',
								type: 'STEAM',
								name: 'JustCallMeJack',
								avatar: 'https://avatars.steamstatic.com/4006023a1c778762d5e3482998ccd8d59295c241_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'US',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9652080496969307,
				averageNormalizedAccuracy: 0.9837915930639909,
				averageWeightedScore: 1.851609817927289,
				scores: [
					{
						id: 'clxuy2e7q00edqiy3zfjlrtcb',
						score: 1793808,
						accuracy: 0.9552532570753022,
						normalizedAccuracy: 0.9828993373760354,
						weightedScore: 1.5908781097353175,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 26, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T21:54:27.062Z',
						updatedAt: '2024-06-25T21:57:54.404Z',
					},
					{
						id: 'clxuxgu0b00dxqiy3j1k5aqvi',
						score: 988960,
						accuracy: 0.9727012978071534,
						normalizedAccuracy: 0.9877657911584894,
						weightedScore: 1.7899522765381142,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 59, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T21:37:41.100Z',
						updatedAt: '2024-06-25T21:40:17.487Z',
					},
					{
						id: 'clxuye1yo00evqiy3gmo1pgc3',
						score: 1013567,
						accuracy: 0.9637644709629876,
						normalizedAccuracy: 0.983140759766739,
						weightedScore: 1.8385680513340088,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 67, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T22:03:31.056Z',
						updatedAt: '2024-06-25T22:05:51.917Z',
					},
					{
						id: 'clxuxccmg00dvqiy3andvptrj',
						score: 500624,
						accuracy: 0.9873559024524934,
						normalizedAccuracy: 0.9941853075749872,
						weightedScore: 1.8406546080964685,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 63, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-25T21:34:11.944Z',
						updatedAt: '2024-06-25T21:37:08.521Z',
					},
					{
						id: 'clxuxkwyw00e1qiy3grf1mjiz',
						score: 1948838,
						accuracy: 0.955427490066209,
						normalizedAccuracy: 0.9782827276327857,
						weightedScore: 1.9078566332312084,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 49, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T21:40:51.561Z',
						updatedAt: '2024-06-25T21:44:14.263Z',
					},
					{
						id: 'clxuxybby00e9qiy393p7hmcx',
						score: 657073,
						accuracy: 0.9610896990529125,
						normalizedAccuracy: 0.9803591863773561,
						weightedScore: 1.9796100610209852,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 34, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T21:51:16.702Z',
						updatedAt: '2024-06-25T21:52:51.431Z',
					},
					{
						id: 'clxuy7dt100ejqiy3g483u8md',
						score: 2071311,
						accuracy: 0.9608642304614564,
						normalizedAccuracy: 0.979908041561543,
						weightedScore: 2.0137489855349213,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 72, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T21:58:19.814Z',
						updatedAt: '2024-06-25T22:03:10.006Z',
					},
				],
			},
			{
				player: {
					id: 'clwm9puao002a903xx1tuqcxq',
					teamId: 'clv1uoyl00010ykbvwnh0emrk',
					user: {
						id: 'clgzcoyho002wo14m5twh4vuw',
						discordId: '640568178338037771',
						discordAvatar: 'https://cdn.discordapp.com/avatars/640568178338037771/214a624b2afb8f04b2348fd70ecd2ee3.png',
						discordUsername: 'alex',
						discordDiscriminator: '0',
						twitchUser: 'thinkingswag',
						playableAccounts: [
							{
								id: '76561199081029968',
								type: 'STEAM',
								name: 'thinking',
								avatar: 'https://cdn.assets.beatleader.xyz/76561199081029968R45.png',
							},
							{
								id: '3706376622721150',
								type: 'OCULUS',
								name: 'thinking',
								avatar: 'https://cdn.assets.beatleader.xyz/76561199081029968R37.png',
							},
						],
						bio: 'thinking swag',
						controllers: 'Valve Index Knuckles',
						countryCode: null,
						motto: '👽',
						pronouns: 'They/them',
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Content', name: 'Content Cubes', color: '#206694'},
						],
					},
				},
				averageAccuracy: 0.9733471325082494,
				averageNormalizedAccuracy: 0.7090647310504158,
				averageWeightedScore: 8.131623734023407,
				scores: [
					{
						id: 'clxjmjx5d00b6132038kolyui',
						score: 1977406,
						accuracy: 0.9694330936803685,
						normalizedAccuracy: 0.9926233659839536,
						weightedScore: 1.3083686574054645,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 6, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-17T23:46:41.426Z',
						updatedAt: '2024-06-17T23:50:02.907Z',
					},
					{
						id: 'clx9fls16007l7pio48fqpkbv',
						score: 1807602,
						accuracy: 0.9625989503870148,
						normalizedAccuracy: 0.9904576231344694,
						weightedScore: 1.3297171418834488,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 13, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-10T20:34:29.035Z',
						updatedAt: '2024-06-10T20:37:55.928Z',
					},
					{
						id: 'clxt7s69m00lh8478fw2g1g1g',
						score: 1023230,
						accuracy: 0.9729526707395345,
						normalizedAccuracy: 0.9925136864322934,
						weightedScore: 1.372364548656342,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 21, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T16:50:54.010Z',
						updatedAt: '2024-06-24T16:53:12.841Z',
					},
					{
						id: 'clxjj54e100aw1320pc0llb3a',
						score: 2094816,
						accuracy: 0.9717680077006042,
						normalizedAccuracy: 0.9910279257879601,
						weightedScore: 1.4526901226906002,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 19, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-17T22:11:12.121Z',
						updatedAt: '2024-06-17T22:16:01.556Z',
					},
					{
						id: 'clxdu8zsh001h1320chwxp5xd',
						score: 501956,
						accuracy: 0.9899829400337254,
						normalizedAccuracy: 0.9968305160142349,
						weightedScore: 1.4582256675279932,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 30, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-13T22:35:31.505Z',
						updatedAt: '2024-06-13T22:38:26.938Z',
					},
					{
						id: '4k8qwl',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 180, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '4lwu4w',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 184, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clwm9pzs2002c903xlm0fsjsc',
					teamId: 'clv1uoyl00010ykbvwnh0emrk',
					user: {
						id: 'cli2bejzx0004ui2zkem2dur4',
						discordId: '407556958678286356',
						discordAvatar: 'https://cdn.discordapp.com/avatars/407556958678286356/d94d87e7e78ddba36ac7f5e1711120f7.png',
						discordUsername: 'pandita',
						discordDiscriminator: '0',
						twitchUser: 'Pandita_vr',
						playableAccounts: [
							{
								id: '76561198186151129',
								type: 'STEAM',
								name: 'Pandita',
								avatar: 'https://avatars.steamstatic.com/32de0f6217e2345facbd473ea3d4290a3827b461_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'US',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9927223958898301,
				averageNormalizedAccuracy: 0.14279841718715955,
				averageWeightedScore: 21.579918789221114,
				scores: [
					{
						id: 'clxts88uo0027qiy3n80tm8yx',
						score: 503345,
						accuracy: 0.9927223958898301,
						normalizedAccuracy: 0.9995889203101169,
						weightedScore: 1.0594315245478036,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 3, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T02:23:16.177Z',
						updatedAt: '2024-06-25T02:26:10.912Z',
					},
					{
						id: '1ijseq',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 178, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'yakew',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 181, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'klvb1',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 183, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '9oksw',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 172, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'wgz3lg',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 183, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'ffsoit7',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 185, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clxxvljx400id90r9co12zu0u',
			user: {
				id: 'clxxv3is800ib90r9sklm1fiy',
				discordId: '366662353430904843',
				discordAvatar: 'https://cdn.discordapp.com/avatars/366662353430904843/dc704e953f28058e1e362cb81ee2134d.png',
				discordUsername: 'Astrella',
				discordDiscriminator: '0',
				twitchUser: null,
				playableAccounts: [
					{
						id: '2538637699496776',
						type: 'STEAM',
						name: 'Astrella_',
						avatar: 'https://avatars.steamstatic.com/ae9dfa806520bf892fd6bd507cad75244aeb27fd_full.jpg',
					},
				],
				bio: null,
				controllers: null,
				countryCode: 'US',
				motto: null,
				pronouns: null,
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9771744450587879,
		averageNormalizedAccuracy: 0.996008179455608,
		averageWeightedScore: 1.210212897794916,
	},
	{
		id: 'clv7k0gbj000wd63spv3udxio',
		name: 'United Kingdom',
		image: 'https://cdn.cube.community/1713584574297-united-kingdom.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwru07yj004v903x5pvhygnp',
					teamId: 'clv7k0gbj000wd63spv3udxio',
					user: {
						id: 'clgtnhrcn002co14myazkl81y',
						discordId: '607258000796942341',
						discordAvatar: 'https://cdn.discordapp.com/avatars/607258000796942341/53b8e0486e0875e4a1014aa76c925fdf.png',
						discordUsername: 'Matty',
						discordDiscriminator: '0',
						twitchUser: 'OlbmaPhlee1',
						playableAccounts: [
							{
								id: '76561198988695829',
								type: 'STEAM',
								name: 'OlbmaPhlee',
								avatar: 'https://avatars.akamai.steamstatic.com/6c6159caf5be7f92b6b105a58f0c9c723a4db648_full.jpg',
							},
						],
						bio: "Don't really like americans tbh ",
						controllers: 'Quest/Rift S Touch',
						countryCode: 'GB',
						motto: 'Not fond of americans. ',
						pronouns: 'He/him',
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Content', name: 'Content Cubes', color: '#206694'},
						],
					},
				},
				averageAccuracy: 0.9770184857312356,
				averageNormalizedAccuracy: 0.9958403373988503,
				averageWeightedScore: 1.1927015746800085,
				scores: [
					{
						id: 'clxs9sj7300ck8478s8bba16g',
						score: 1030034,
						accuracy: 0.9794223500606176,
						normalizedAccuracy: 0.9991134373411656,
						weightedScore: 1.0440970714526945,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 3, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T00:59:23.824Z',
						updatedAt: '2024-06-24T01:01:41.882Z',
					},
					{
						id: 'clxs9b5uy00c68478c0y5el5t',
						score: 503218,
						accuracy: 0.9924719200844123,
						normalizedAccuracy: 0.9993367119979665,
						weightedScore: 1.0958943439563595,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 4, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T00:45:53.387Z',
						updatedAt: '2024-06-24T00:48:48.129Z',
					},
					{
						id: 'clxsa0bcs00cm8478niaywb01',
						score: 999623,
						accuracy: 0.9831889959329803,
						normalizedAccuracy: 0.9984159151585733,
						weightedScore: 1.1022829872307494,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 3, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T01:05:26.908Z',
						updatedAt: '2024-06-24T01:08:00.747Z',
					},
					{
						id: 'clxvxj5n900ep90r9wg1q9xhg',
						score: 2105418,
						accuracy: 0.9766861887807763,
						normalizedAccuracy: 0.9960435825660274,
						weightedScore: 1.1996228576884518,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 4, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T14:27:15.670Z',
						updatedAt: '2024-06-26T14:32:09.805Z',
					},
					{
						id: 'clxtp7xtc000zqiy3tqr2hdmg',
						score: 666560,
						accuracy: 0.9749661754488609,
						normalizedAccuracy: 0.9945138809107823,
						weightedScore: 1.2736270278315225,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 2, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T00:59:03.024Z',
						updatedAt: '2024-06-25T01:00:35.474Z',
					},
					{
						id: 'clxvzj3qh00gx90r9pw5qx1kr',
						score: 1810395,
						accuracy: 0.9640863015121137,
						normalizedAccuracy: 0.9919880198376234,
						weightedScore: 1.276837441781211,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 9, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T15:23:12.425Z',
						updatedAt: '2024-06-26T15:26:44.464Z',
					},
					{
						id: 'clxvz1rvu00gd90r9qkvljxkd',
						score: 1975110,
						accuracy: 0.9683074682988888,
						normalizedAccuracy: 0.9914708139798133,
						weightedScore: 1.3565492928190708,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 10, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T15:09:43.915Z',
						updatedAt: '2024-06-26T15:13:08.014Z',
					},
				],
			},
			{
				player: {
					id: 'clwkideq60014903xibo170xl',
					teamId: 'clv7k0gbj000wd63spv3udxio',
					user: {
						id: 'cli33s8ms000oui2z1pmducn7',
						discordId: '386558897655250947',
						discordAvatar: 'https://cdn.discordapp.com/avatars/386558897655250947/c66f9b8e5742143f1cf457f7073a3a76.png',
						discordUsername: 'sarah :3',
						discordDiscriminator: '0',
						twitchUser: 'Aquaflee',
						playableAccounts: [
							{
								id: '76561198960449289',
								type: 'STEAM',
								name: 'squirting_sarah54',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198960449289R41.png',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'GB',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9766591541212805,
				averageNormalizedAccuracy: 0.9954880002022793,
				averageWeightedScore: 1.2480696603065335,
				scores: [
					{
						id: 'clxq2vg4d00yf1320w2xu4rf0',
						score: 1823408,
						accuracy: 0.9710160903380755,
						normalizedAccuracy: 0.9991183643768797,
						weightedScore: 1.0304630997008595,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 2, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-22T12:10:10.141Z',
						updatedAt: '2024-06-22T12:13:35.555Z',
					},
					{
						id: 'clxt49alg00jf8478w5uwj4xl',
						score: 1985005,
						accuracy: 0.9731585410992987,
						normalizedAccuracy: 0.9964379316108972,
						weightedScore: 1.1489067024803794,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 3, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T15:12:14.309Z',
						updatedAt: '2024-06-24T15:15:34.832Z',
					},
					{
						id: 'clxdp3z83001d1320xdv819ch',
						score: 1027680,
						accuracy: 0.9771840159745169,
						normalizedAccuracy: 0.9968301020032048,
						weightedScore: 1.1576687412553675,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 5, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-13T20:11:39.411Z',
						updatedAt: '2024-06-13T20:13:57.679Z',
					},
					{
						id: 'clxt3k27s00iz8478i8366ega',
						score: 998331,
						accuracy: 0.9819182366739941,
						normalizedAccuracy: 0.9971254753003619,
						weightedScore: 1.185605572036631,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 6, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T14:52:37.048Z',
						updatedAt: '2024-06-24T14:55:16.039Z',
					},
					{
						id: 'clxt40v3u00jd8478v6n7rezy',
						score: 2105118,
						accuracy: 0.9765470212346481,
						normalizedAccuracy: 0.9959016567941523,
						weightedScore: 1.2067837876545566,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 5, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T15:05:40.987Z',
						updatedAt: '2024-06-24T15:10:28.965Z',
					},
					{
						id: 'clxq28c9l00yb1320nahad9r2',
						score: 502705,
						accuracy: 0.9914601556105594,
						normalizedAccuracy: 0.9983179492882562,
						weightedScore: 1.2431811656617857,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 9, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-22T11:52:12.057Z',
						updatedAt: '2024-06-22T11:55:06.891Z',
					},
					{
						id: 'clxnrmsrn00qk13201l5al55g',
						score: 659972,
						accuracy: 0.9653300179178703,
						normalizedAccuracy: 0.984684522042203,
						weightedScore: 1.7638785533561543,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 21, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-20T21:19:58.499Z',
						updatedAt: '2024-06-20T21:21:30.919Z',
					},
				],
			},
			{
				player: {
					id: 'clwkidoqt0016903xjf8hciqq',
					teamId: 'clv7k0gbj000wd63spv3udxio',
					user: {
						id: 'clgievxy6000arr4md2hcd0pg',
						discordId: '304260782252621825',
						discordAvatar: 'https://cdn.discordapp.com/avatars/304260782252621825/6e7df925ebeecf0b2cc58bb49c121637.png',
						discordUsername: 'Crusader.Pepe',
						discordDiscriminator: '0',
						twitchUser: 'CrusaderPepe',
						playableAccounts: [
							{id: '76561198167372371', type: 'STEAM', name: 'thog', avatar: 'https://cdn.assets.beatleader.xyz/76561198167372371R26.png'},
						],
						bio: 'john accuracy',
						controllers: 'Valve Index Knuckles',
						countryCode: 'GB',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9736462466364232,
				averageNormalizedAccuracy: 0.9923936834824146,
				averageWeightedScore: 1.3476391574854918,
				scores: [
					{
						id: 'clxdv4fvz001j1320jzz447v7',
						score: 503552,
						accuracy: 0.9931306517301567,
						normalizedAccuracy: 1,
						weightedScore: 1,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 1, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-13T22:59:58.703Z',
						updatedAt: '2024-06-13T23:02:53.693Z',
					},
					{
						id: 'clxtryh81001xqiy37p7f2hv6',
						score: 1025676,
						accuracy: 0.9752784843226282,
						normalizedAccuracy: 0.9948862600247539,
						weightedScore: 1.2543542239590872,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 7, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T02:15:40.465Z',
						updatedAt: '2024-06-25T02:17:58.585Z',
					},
					{
						id: 'clxcch1jh00b27pioswdm5rx6',
						score: 997226,
						accuracy: 0.9808314030972298,
						normalizedAccuracy: 0.9960218096321547,
						weightedScore: 1.2568683090416612,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 8, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-12T21:30:07.758Z',
						updatedAt: '2024-06-12T21:32:42.032Z',
					},
					{
						id: 'clxts3nm90023qiy38smnhi24',
						score: 2101254,
						accuracy: 0.9747545432405164,
						normalizedAccuracy: 0.9940736528524005,
						weightedScore: 1.2990165656179882,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 9, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T02:19:42.033Z',
						updatedAt: '2024-06-25T02:24:31.625Z',
					},
					{
						id: 'clxtsi8q6002cqiy3j8kikg3q',
						score: 1801688,
						accuracy: 0.9594495789033648,
						normalizedAccuracy: 0.9872171053749088,
						weightedScore: 1.4416865462531712,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 20, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T02:31:02.575Z',
						updatedAt: '2024-06-25T02:34:28.626Z',
					},
					{
						id: 'clxtsb70l0029qiy3u5fbaz2d',
						score: 1970520,
						accuracy: 0.9660571980458438,
						normalizedAccuracy: 0.989166713936693,
						weightedScore: 1.4528685944516724,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 19, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T02:25:33.765Z',
						updatedAt: '2024-06-25T02:28:54.608Z',
					},
					{
						id: 'clxtrtdix001rqiy3rjzfw41u',
						score: 660445,
						accuracy: 0.9660218671152229,
						normalizedAccuracy: 0.9853902425559914,
						weightedScore: 1.7286798630748623,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 19, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T02:11:42.394Z',
						updatedAt: '2024-06-25T02:13:15.601Z',
					},
				],
			},
			{
				player: {
					id: 'clwkie1i40018903xuup2vlsw',
					teamId: 'clv7k0gbj000wd63spv3udxio',
					user: {
						id: 'cliivd30g0006p92zim72gmf4',
						discordId: '398872001810530304',
						discordAvatar: 'https://cdn.discordapp.com/avatars/398872001810530304/8286c3b86d83edb092479999de9686d8.png',
						discordUsername: 'Zejammydodger',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198180136111',
								type: 'STEAM',
								name: 'zejammydodger',
								avatar: 'https://avatars.steamstatic.com/8ad2716a2f24f6a6a8f9b2f6f4a05b5c46955fcc_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'GB',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9523790412371246,
				averageNormalizedAccuracy: 0.970620238330055,
				averageWeightedScore: 2.3346431712904354,
				scores: [
					{
						id: 'clxt5u7r300kd84785fo2ygo2',
						score: 993660,
						accuracy: 0.9773240288576445,
						normalizedAccuracy: 0.9924601157200944,
						weightedScore: 1.4868438023990713,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 25, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T15:56:30.016Z',
						updatedAt: '2024-06-24T15:59:03.913Z',
					},
					{
						id: 'clxtdeops00ph8478nyzfi542',
						score: 1019864,
						accuracy: 0.969752062186512,
						normalizedAccuracy: 0.9892487302948354,
						weightedScore: 1.5347614222994161,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 31, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T19:28:22.432Z',
						updatedAt: '2024-06-24T19:30:40.513Z',
					},
					{
						id: 'clxtdmu2200pn847898ud3aro',
						score: 2089734,
						accuracy: 0.9694105094691918,
						normalizedAccuracy: 0.9886237032123952,
						weightedScore: 1.5739962763164177,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 32, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T19:34:42.602Z',
						updatedAt: '2024-06-24T19:39:30.396Z',
					},
					{
						id: 'clxusxkqy00bwqiy35w4z3hn8',
						score: 501503,
						accuracy: 0.9890895105860542,
						normalizedAccuracy: 0.9959309068378241,
						weightedScore: 1.5882859603789836,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 39, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T19:30:44.171Z',
						updatedAt: '2024-06-25T19:33:38.762Z',
					},
					{
						id: 'clxt6u6o900kt84784emfusju',
						score: 1944724,
						accuracy: 0.9534105811727389,
						normalizedAccuracy: 0.9762175712978408,
						weightedScore: 1.994187266546355,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 52, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T16:24:28.234Z',
						updatedAt: '2024-06-24T16:27:48.605Z',
					},
					{
						id: 'clxtdvvsl00pz8478ly9d8ei7',
						score: 1679213,
						accuracy: 0.8942281936378862,
						normalizedAccuracy: 0.9201081414584084,
						weightedScore: 3.760498314968382,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 90, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T19:41:44.757Z',
						updatedAt: '2024-06-24T19:45:09.947Z',
					},
					{
						id: 'clxt6ffxt00kj8478hc745nki',
						score: 624495,
						accuracy: 0.9134384027498446,
						normalizedAccuracy: 0.9317524994889868,
						weightedScore: 4.403929156124423,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 116, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-24T16:13:00.401Z',
						updatedAt: '2024-06-24T16:14:32.816Z',
					},
				],
			},
			{
				player: {
					id: 'clwkietap001g903xpimtwnyz',
					teamId: 'clv7k0gbj000wd63spv3udxio',
					user: {
						id: 'clhz8vft10004mu2znv67hsnj',
						discordId: '318466834925879307',
						discordAvatar: 'https://cdn.discordapp.com/avatars/318466834925879307/19da8a99b59399e1e5037add3b6a93b2.png',
						discordUsername: 'sckuffles',
						discordDiscriminator: '0',
						twitchUser: 'sckuffles',
						playableAccounts: [
							{
								id: '76561198354105842',
								type: 'STEAM',
								name: 'sckuffles',
								avatar: 'https://avatars.akamai.steamstatic.com/2e5436392162567daed49c761997ca8bd37e37ca_full.jpg',
							},
						],
						bio: 'AlienPls3',
						controllers: 'Valve Index Knuckles',
						countryCode: 'GB',
						motto: 'NAAAAAAAILS',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9662913378093007,
				averageNormalizedAccuracy: 0.8428707548242199,
				averageWeightedScore: 5.207987034413514,
				scores: [
					{
						id: 'clxrl1ckk000v8478c0i7bebd',
						score: 1016751,
						accuracy: 0.9667920222502199,
						normalizedAccuracy: 0.9862291793572517,
						weightedScore: 1.6849519949823901,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 50, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-23T13:26:24.740Z',
						updatedAt: '2024-06-23T13:28:42.885Z',
					},
					{
						id: 'clxrk7i7h000j8478phhuw3c2',
						score: 989773,
						accuracy: 0.9735009319229085,
						normalizedAccuracy: 0.988577809428401,
						weightedScore: 1.7375209596285308,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 55, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-23T13:03:12.366Z',
						updatedAt: '2024-06-23T13:05:46.242Z',
					},
					{
						id: 'clxrkgmzd000n84787acl17fs',
						score: 500923,
						accuracy: 0.9879456053329652,
						normalizedAccuracy: 0.9947790893492628,
						weightedScore: 1.75480907263853,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 56, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-23T13:10:18.457Z',
						updatedAt: '2024-06-23T13:13:13.132Z',
					},
					{
						id: 'clxrlmc2k00158478s1fplcfx',
						score: 2075989,
						accuracy: 0.9630343163974161,
						normalizedAccuracy: 0.9821211374309827,
						weightedScore: 1.9020862175967919,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 62, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-23T13:42:43.868Z',
						updatedAt: '2024-06-23T13:47:31.626Z',
					},
					{
						id: 'clxrm6sgk001b8478fay77ift',
						score: 1943323,
						accuracy: 0.9527237339778551,
						normalizedAccuracy: 0.9755142937029799,
						weightedScore: 2.0235866873714694,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 57, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-23T13:58:38.229Z',
						updatedAt: '2024-06-23T14:01:58.621Z',
					},
					{
						id: 'clxrke3hk000l8478i6hr7f8u',
						score: 652056,
						accuracy: 0.9537514169744397,
						normalizedAccuracy: 0.9728737745006617,
						weightedScore: 2.3529543086768863,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 61, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-23T13:08:19.880Z',
						updatedAt: '2024-06-23T13:09:52.402Z',
					},
					{
						id: '8l0lu6',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 167, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwkieg7v001c903xjpbrjdax',
					teamId: 'clv7k0gbj000wd63spv3udxio',
					user: {
						id: 'cli26ryxh000krq304wi7xlh5',
						discordId: '211619743348686848',
						discordAvatar: 'https://cdn.discordapp.com/avatars/211619743348686848/a_4b7905037307f0a0af6f7382f669b12d.png',
						discordUsername: 'HarveyRacoonMan',
						discordDiscriminator: '0',
						twitchUser: 'harbgy',
						playableAccounts: [
							{
								id: '76561198145281261',
								type: 'STEAM',
								name: 'OBCT | ちんチン MAN',
								avatar: 'https://avatars.steamstatic.com/ace2952c36acc7e898e786ec8365c623b4587c9b_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'GB',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9687960463583177,
				averageNormalizedAccuracy: 0.707606334786807,
				averageWeightedScore: 8.161631563069232,
				scores: [
					{
						id: 'clxrm35vx00198478l8iirw2p',
						score: 1809170,
						accuracy: 0.9634339545274212,
						normalizedAccuracy: 0.9913167932134331,
						weightedScore: 1.3000302927032452,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 11, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-23T13:55:49.005Z',
						updatedAt: '2024-06-23T13:59:14.911Z',
					},
					{
						id: 'clxrkzu1q000t8478u6touzw8',
						score: 1023406,
						accuracy: 0.9731200228207384,
						normalizedAccuracy: 0.9926844030930755,
						weightedScore: 1.3638732088580112,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 18, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T13:25:14.079Z',
						updatedAt: '2024-06-23T13:27:32.808Z',
					},
					{
						id: 'clxrmncgg001l8478c8t7vct4',
						score: 1974246,
						accuracy: 0.9678838880159627,
						normalizedAccuracy: 0.99103710103052,
						weightedScore: 1.37467998489109,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 13, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-23T14:11:30.640Z',
						updatedAt: '2024-06-23T14:14:51.425Z',
					},
					{
						id: 'clxrldcnw00118478n5ffhkly',
						score: 2092480,
						accuracy: 0.9706843564080856,
						normalizedAccuracy: 0.989922797110959,
						weightedScore: 1.5084498973600038,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 25, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T13:35:44.732Z',
						updatedAt: '2024-06-23T13:40:33.093Z',
					},
					{
						id: 'clxrn3jlq001x84781cd7c1nm',
						score: 662384,
						accuracy: 0.9688580100193805,
						normalizedAccuracy: 0.988283249059661,
						weightedScore: 1.5843875576722726,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 11, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T14:24:06.399Z',
						updatedAt: '2024-06-23T14:25:39.508Z',
					},
					{
						id: 'aad60p',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 185, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: '67wyp7f',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 179, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clwkie8lg001a903xzbd9m7ch',
					teamId: 'clv7k0gbj000wd63spv3udxio',
					user: {
						id: 'cll62rmm7000dqi2zkjdh04jg',
						discordId: '796862543788245022',
						discordAvatar: 'https://cdn.discordapp.com/avatars/796862543788245022/6230a1599eed4197bf1718b40e19c736.png',
						discordUsername: 'Floatyoats',
						discordDiscriminator: '0',
						twitchUser: 'floatyoat',
						playableAccounts: [
							{
								id: '76561198108275916',
								type: 'STEAM',
								name: 'floatyoats',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198108275916R36.png',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'GB',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9732994068884248,
				averageNormalizedAccuracy: 0.7066359193164417,
				averageWeightedScore: 8.315141941756186,
				scores: [
					{
						id: 'clx9jlhqs007v7piox9xcgqfp',
						score: 995153,
						accuracy: 0.9787924836360239,
						normalizedAccuracy: 0.993951312862749,
						weightedScore: 1.3905584934863924,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 18, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-10T22:26:14.164Z',
						updatedAt: '2024-06-10T22:28:48.490Z',
					},
					{
						id: 'clx96p3gb006b7piojy3mddkf',
						score: 1022096,
						accuracy: 0.9718743908526873,
						normalizedAccuracy: 0.9914137279474814,
						weightedScore: 1.42707579485695,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 23, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-10T16:25:07.260Z',
						updatedAt: '2024-06-10T16:27:26.215Z',
					},
					{
						id: 'clxpo6y3b00xd1320zqzl48a0',
						score: 2091454,
						accuracy: 0.970208403400327,
						normalizedAccuracy: 0.989437410971146,
						weightedScore: 1.5329402778440826,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 28, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-22T05:19:12.407Z',
						updatedAt: '2024-06-22T05:24:00.081Z',
					},
					{
						id: 'clxpntayq00wz1320awldixam',
						score: 501205,
						accuracy: 0.9885017799560188,
						normalizedAccuracy: 0.9953391109557702,
						weightedScore: 1.6738443870226816,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 49, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-22T05:08:35.907Z',
						updatedAt: '2024-06-22T05:11:31.353Z',
					},
					{
						id: 'clxpo31g200x71320hgvc9we2',
						score: 654359,
						accuracy: 0.9571199765970673,
						normalizedAccuracy: 0.9763098724779443,
						weightedScore: 2.181574639083197,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 50, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-22T05:16:10.131Z',
						updatedAt: '2024-06-22T05:17:42.453Z',
					},
					{
						id: 'evcdf5',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 172, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'gwqvtu',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 165, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwkienek001e903xjxcpxnx6',
					teamId: 'clv7k0gbj000wd63spv3udxio',
					user: {
						id: 'clq4p1b340002p32rmoz4m2ie',
						discordId: '842675585477246986',
						discordAvatar: 'https://cdn.discordapp.com/avatars/842675585477246986/11ae94ea14acfaab10d210f2dd7011a9.png',
						discordUsername: '40 mcnuggets please',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199157065937',
								type: 'STEAM',
								name: '40 mcnuggets please',
								avatar: 'https://avatars.steamstatic.com/3b4834809d829fd901b21b5bfd0c0d93cd04b2ff_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'GB',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.959563334261585,
				averageNormalizedAccuracy: 0.28086929030389424,
				averageWeightedScore: 18.343775890668002,
				scores: [
					{
						id: 'clxkhjuqm00ch1320y55s5b1e',
						score: 1791059,
						accuracy: 0.9537893371888372,
						normalizedAccuracy: 0.9813930500373421,
						weightedScore: 1.642924760498315,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 33, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-18T14:14:26.398Z',
						updatedAt: '2024-06-18T14:17:52.109Z',
					},
					{
						id: 'clxkje12b00cx1320d1qg28ds',
						score: 659977,
						accuracy: 0.9653373313343329,
						normalizedAccuracy: 0.9846919820899175,
						weightedScore: 1.7635064741777051,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 20, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-18T15:05:53.892Z',
						updatedAt: '2024-06-18T15:07:26.769Z',
					},
					{
						id: 'x69iqb',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 186, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'qy23sl',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 173, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '1kacke',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 179, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'ks665d',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 179, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'khgd27',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 180, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clv7k0gb6000ud63srg7p6hfc',
			user: {
				id: 'clh8r607l004kpf4mgco4cdnx',
				discordId: '259474708062404608',
				discordAvatar: 'https://cdn.discordapp.com/avatars/259474708062404608/da0431d2b472072c4925f5b557d5882d.png',
				discordUsername: 'Ghilo',
				discordDiscriminator: '0',
				twitchUser: 'ghilovr',
				playableAccounts: [
					{
						id: '76561198161493499',
						type: 'STEAM',
						name: 'Ghilo',
						avatar: 'https://avatars.akamai.steamstatic.com/6faffdbb5c5dd6114b478bc24d350bc31b53adbb_full.jpg',
					},
				],
				bio: 'Ex-top 50 player, streamer and admin of BBSD. Love tournaments, both playing and casting them.',
				controllers: 'Rift Touch',
				countryCode: 'GB',
				motto: 'Bugs be buggin whether you like it or not',
				pronouns: 'She/her',
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9751120266018823,
		averageNormalizedAccuracy: 0.9938995775300267,
		averageWeightedScore: 1.3081702165527065,
	},
	{
		id: 'cluzrpp90000vivs9r63se9ux',
		name: 'Canada',
		image: 'https://cdn.cube.community/1713113780310-canada.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwdywp79001m13xttbdhkaky',
					teamId: 'cluzrpp90000vivs9r63se9ux',
					user: {
						id: 'clhzj6xrh002co42zn8l3sid2',
						discordId: '740263292563488768',
						discordAvatar: 'https://cdn.discordapp.com/avatars/740263292563488768/55db02cc7125b3c817c77c5f82e97af6.png',
						discordUsername: 'Bizzy',
						discordDiscriminator: '0',
						twitchUser: 'bizzy825',
						playableAccounts: [
							{id: '3225556157461414', type: 'OCULUS', name: 'Bizzy825', avatar: 'https://cdn.assets.beatleader.xyz/3225556157461414.png'},
						],
						bio: '#2 global (#1 sometimes)\nthe canadian carrier\n700pp guy (663pp)',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'CA',
						motto: 'just gotta beat the danish',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9795033141286424,
				averageNormalizedAccuracy: 0.998384243730692,
				averageWeightedScore: 1.0836118749275354,
				scores: [
					{
						id: 'clxvy7el700fh90r978lqp2lf',
						score: 2113781,
						accuracy: 0.9805657160750113,
						normalizedAccuracy: 1,
						weightedScore: 1,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 1, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T14:46:07.004Z',
						updatedAt: '2024-06-26T14:50:56.922Z',
					},
					{
						id: 'clxje33dw00a013208de6qbhr',
						score: 1001209,
						accuracy: 0.9847489217725715,
						normalizedAccuracy: 1,
						weightedScore: 1,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 1, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T19:49:39.429Z',
						updatedAt: '2024-06-17T19:52:14.248Z',
					},
					{
						id: 'clxtec8v400qd84784elj14ej',
						score: 1030352,
						accuracy: 0.9797247248437018,
						normalizedAccuracy: 0.999421891307806,
						weightedScore: 1.0287547643170742,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 2, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T19:54:28.193Z',
						updatedAt: '2024-06-24T19:56:47.900Z',
					},
					{
						id: 'clxmbprvu00kb1320m8l8xqnz',
						score: 1989920,
						accuracy: 0.9755681442133982,
						normalizedAccuracy: 0.9989051759925827,
						weightedScore: 1.045767406723465,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 2, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-19T21:06:37.291Z',
						updatedAt: '2024-06-19T21:10:05.569Z',
					},
					{
						id: 'clxmck2f900kl13201r3s4nvc',
						score: 1819417,
						accuracy: 0.9688907704883549,
						normalizedAccuracy: 0.9969315354322726,
						weightedScore: 1.1060244613578705,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 3, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-19T21:30:10.629Z',
						updatedAt: '2024-06-19T21:33:37.766Z',
					},
					{
						id: 'clxjdrxm7009u1320qv2r79hk',
						score: 503142,
						accuracy: 0.9923220290512489,
						normalizedAccuracy: 0.9991857841891205,
						weightedScore: 1.117714613838645,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 5, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T19:40:58.735Z',
						updatedAt: '2024-06-17T19:43:57.188Z',
					},
					{
						id: 'clxteuzy400qv8478hcgu2vsb',
						score: 666380,
						accuracy: 0.9747028924562109,
						normalizedAccuracy: 0.9942453191930616,
						weightedScore: 1.2870218782556928,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 3, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T20:09:03.101Z',
						updatedAt: '2024-06-24T20:10:37.168Z',
					},
				],
			},
			{
				player: {
					id: 'clvejcung001gd63sr5q3rec3',
					teamId: 'cluzrpp90000vivs9r63se9ux',
					user: {
						id: 'clvegim9c001cd63sbrlu3sum',
						discordId: '354025521740382208',
						discordAvatar: 'https://cdn.discordapp.com/avatars/354025521740382208/da9b8b90a43ea3daefffeeeb35382b24.png',
						discordUsername: 'sploobers',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198333403325',
								type: 'STEAM',
								name: 'sploob',
								avatar: 'https://avatars.steamstatic.com/8a1f9acf5e3d4cb87f80e1fa274b8d5b59b00503_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'CA',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.970430132521288,
				averageNormalizedAccuracy: 0.9891284896469392,
				averageWeightedScore: 1.6100959957586445,
				scores: [
					{
						id: 'clxswqtp500gl8478chlf9ywq',
						score: 1804127,
						accuracy: 0.9607484150630913,
						normalizedAccuracy: 0.9885535312821744,
						weightedScore: 1.3955091067439132,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 16, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T11:41:55.290Z',
						updatedAt: '2024-06-24T11:45:22.152Z',
					},
					{
						id: 'clxvwyo0y00cz90r9kry5y3gq',
						score: 2093834,
						accuracy: 0.9713124659329444,
						normalizedAccuracy: 0.9905633554280221,
						weightedScore: 1.4761302334463169,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 21, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T14:11:19.715Z',
						updatedAt: '2024-06-26T14:16:09.200Z',
					},
					{
						id: 'clxsx7azd00gr8478jd8xtfew',
						score: 993770,
						accuracy: 0.9774322204354219,
						normalizedAccuracy: 0.9925699828906852,
						weightedScore: 1.4797497742809236,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 24, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T11:54:44.185Z',
						updatedAt: '2024-06-24T11:57:20.202Z',
					},
					{
						id: 'clxvxc76x00e790r9oi4utzyr',
						score: 1966220,
						accuracy: 0.9639491017303549,
						normalizedAccuracy: 0.9870081888418308,
						weightedScore: 1.5431023628656566,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 25, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T14:21:51.081Z',
						updatedAt: '2024-06-26T14:25:13.694Z',
					},
					{
						id: 'clxsxvd9100h78478vt1zkxob',
						score: 1019501,
						accuracy: 0.9694068985190292,
						normalizedAccuracy: 0.9888966271819724,
						weightedScore: 1.5522748106334732,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 37, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T12:13:26.869Z',
						updatedAt: '2024-06-24T12:15:46.224Z',
					},
					{
						id: 'clxvx5z8300dh90r9e15yx3hd',
						score: 658903,
						accuracy: 0.9637664094781877,
						normalizedAccuracy: 0.9830895638408503,
						weightedScore: 1.8434290817085877,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 27, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T14:17:00.820Z',
						updatedAt: '2024-06-26T14:18:33.739Z',
					},
					{
						id: 'clxvxwfo000fb90r9q3r3mouw',
						score: 500137,
						accuracy: 0.9863954164899859,
						normalizedAccuracy: 0.9932181780630401,
						weightedScore: 1.9804766006316394,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 71, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-26T14:37:35.184Z',
						updatedAt: '2024-06-26T14:40:30.175Z',
					},
				],
			},
			{
				player: {
					id: 'cluzugiau001divs90wzh4gfz',
					teamId: 'cluzrpp90000vivs9r63se9ux',
					user: {
						id: 'clgigwciw000at74nwpmgfpbb',
						discordId: '693932079678750720',
						discordAvatar: 'https://cdn.discordapp.com/avatars/693932079678750720/485e19eccb474590fe2faadb110445e1.png',
						discordUsername: 'RaccoonVR',
						discordDiscriminator: '0',
						twitchUser: 'raccoonvr',
						playableAccounts: [
							{
								id: '76561198356790410',
								type: 'STEAM',
								name: 'raccoonvr',
								avatar: 'https://avatars.steamstatic.com/46f82359b2b2123cf49103e121e428b175437e5c_full.jpg',
							},
						],
						bio: 'Beat Saber video game player.',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'CA',
						motto: 'Sometimes I wonder.',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9670489467501101,
				averageNormalizedAccuracy: 0.9856652101692646,
				averageWeightedScore: 1.7561010239548094,
				scores: [
					{
						id: 'clxvxm1xx00ev90r9f2kkmjd7',
						score: 1793741,
						accuracy: 0.9552175776892006,
						normalizedAccuracy: 0.982862625389243,
						weightedScore: 1.5921466166837064,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 29, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T14:29:30.837Z',
						updatedAt: '2024-06-26T14:33:02.669Z',
					},
					{
						id: 'clxvxawyk00e390r9ng1laypw',
						score: 2088410,
						accuracy: 0.9687963166989458,
						normalizedAccuracy: 0.9879973374725196,
						weightedScore: 1.605599847233494,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 35, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T14:20:51.165Z',
						updatedAt: '2024-06-26T14:25:43.634Z',
					},
					{
						id: 'clxvyaza700fn90r99qt7wmzm',
						score: 991764,
						accuracy: 0.9754591994806804,
						normalizedAccuracy: 0.9905664052160937,
						weightedScore: 1.6091190506900555,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 37, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T14:48:53.791Z',
						updatedAt: '2024-06-26T14:51:31.177Z',
					},
					{
						id: 'clxvw36jw00bx90r92uwas9vc',
						score: 1017563,
						accuracy: 0.9675641238975919,
						normalizedAccuracy: 0.9870168039513147,
						weightedScore: 1.645776040912819,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 44, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T13:46:50.732Z',
						updatedAt: '2024-06-26T13:49:13.728Z',
					},
					{
						id: 'clxvwk7l800cf90r9klxulbc7',
						score: 661429,
						accuracy: 0.967461147475043,
						normalizedAccuracy: 0.9868583799461982,
						weightedScore: 1.6554546807560648,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 17, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T14:00:05.228Z',
						updatedAt: '2024-06-26T14:01:40.931Z',
					},
					{
						id: 'clxs7w19a00bu84788aptuuey',
						score: 500149,
						accuracy: 0.9864190834952222,
						normalizedAccuracy: 0.9932420087697,
						weightedScore: 1.9770312948607522,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 70, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T00:06:07.966Z',
						updatedAt: '2024-06-24T00:09:04.768Z',
					},
					{
						id: 'clxsayndq00cw84789ldh0s9j',
						score: 1934555,
						accuracy: 0.9484251785140863,
						normalizedAccuracy: 0.9711129104397819,
						weightedScore: 2.207579636546775,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 67, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T01:32:08.799Z',
						updatedAt: '2024-06-24T01:35:44.377Z',
					},
				],
			},
			{
				player: {
					id: 'cluzrpp8x000tivs9w6g1jx9e',
					teamId: 'cluzrpp90000vivs9r63se9ux',
					user: {
						id: 'clgikif5y000cs74m8au7vtqa',
						discordId: '284813145942261763',
						discordAvatar: 'https://cdn.discordapp.com/avatars/284813145942261763/19b047be9008d1d9cba8c4ba4e4d5f45.png',
						discordUsername: 'EJ',
						discordDiscriminator: '0',
						twitchUser: 'ej_ca',
						playableAccounts: [
							{
								id: '76561198321316302',
								type: 'STEAM',
								name: 'EJ',
								avatar: 'https://avatars.steamstatic.com/1b0fdb87c2862ce0048d587bcf6b6b55ac86073f_full.jpg',
							},
						],
						bio: 'World Cup 2021 - #2 [Canada]\nWorld Cup 2022, 2023 - #3 [Canada]\nCanadian Tournament 3rd Edition - #4\nCanadian Tournament 4th Edition - #3\nCanadian Tournament 5th Edition - #5\nCanadian Halloween 2021 Team Tournament - #1 [YNY]\nCVRE Season 8, 9 - #1\nCVRE Season 10 - #2 [Team Athlings]\nSUPA Team Tournament - #2 [Team Sploob]\nBSTS Modifiers Tournament - #3\nBSTS Mystery Acc Royale - #1\nHelium 2v2 -  #1 [WcDonalds]\nSSS S1 - #2\nSSS S2 - #3\ntheres more but character limit :(',
						controllers: 'Rift Touch',
						countryCode: 'CA',
						motto: '🐡',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9668631175135938,
				averageNormalizedAccuracy: 0.9854780642229288,
				averageWeightedScore: 1.757266174752896,
				scores: [
					{
						id: 'clxtjl1vh001196lnvkjwd3qw',
						score: 1962814,
						accuracy: 0.9622792933465049,
						normalizedAccuracy: 0.9852984361736679,
						weightedScore: 1.6145759012884542,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 30, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T22:21:17.117Z',
						updatedAt: '2024-06-24T22:24:40.374Z',
					},
					{
						id: 'clxtxc81r0030qiy3jcxv0mxr',
						score: 1017187,
						accuracy: 0.9672065989968384,
						normalizedAccuracy: 0.9866520910850983,
						weightedScore: 1.6639166304819801,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 48, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T04:46:19.840Z',
						updatedAt: '2024-06-25T04:48:38.610Z',
					},
					{
						id: 'clxsc4wct00cy8478lkr8bacg',
						score: 2084815,
						accuracy: 0.9671286256045091,
						normalizedAccuracy: 0.9862965936395492,
						weightedScore: 1.6914116579939849,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 45, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T02:04:59.981Z',
						updatedAt: '2024-06-24T02:09:51.086Z',
					},
					{
						id: 'clxsdm75000da8478w8pwvq9c',
						score: 1788056,
						accuracy: 0.9521901551520767,
						normalizedAccuracy: 0.9797475859128983,
						weightedScore: 1.699780377901473,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 38, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T02:46:26.725Z',
						updatedAt: '2024-06-24T02:49:53.226Z',
					},
					{
						id: 'clxqsrxyx012f1320ol0p3o5p',
						score: 501034,
						accuracy: 0.9881645251314012,
						normalizedAccuracy: 0.9949995233858668,
						weightedScore: 1.7229399942578236,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 52, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T00:15:16.666Z',
						updatedAt: '2024-06-23T00:18:11.990Z',
					},
					{
						id: 'clxsdie7b00d8847850tjly3q',
						score: 988934,
						accuracy: 0.9726757252524061,
						normalizedAccuracy: 0.9877398225545315,
						weightedScore: 1.7916290468205855,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 60, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T02:43:29.255Z',
						updatedAt: '2024-06-24T02:46:03.368Z',
					},
					{
						id: 'clxtxjit40036qiy33mqd40os',
						score: 655232,
						accuracy: 0.9583968991114199,
						normalizedAccuracy: 0.97761239680889,
						weightedScore: 2.116609614525971,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 47, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-25T04:52:00.377Z',
						updatedAt: '2024-06-25T04:53:33.062Z',
					},
				],
			},
			{
				player: {
					id: 'cluzugaqn001bivs97zy20lke',
					teamId: 'cluzrpp90000vivs9r63se9ux',
					user: {
						id: 'clhz8eygs0002mu2zu6zisg55',
						discordId: '531604157300473898',
						discordAvatar: 'https://cdn.discordapp.com/avatars/531604157300473898/0b9ebfd226874bf7b24c85bc80b2511a.png',
						discordUsername: 'Stud3nt_4thlete',
						discordDiscriminator: '0',
						twitchUser: 'Stud3nt_4thlete',
						playableAccounts: [
							{
								id: '76561198965353139',
								type: 'STEAM',
								name: 'Stud3nt_4thlete',
								avatar: 'https://avatars.akamai.steamstatic.com/93d349accd492552c00137ab82bcd67b08a329f0_full.jpg',
							},
						],
						bio: 'Me: What should I set as my bio on the cube community website?\nMy gf: mmm I don\'t know... how about "I have a girlfriend losers" lol',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'CA',
						motto: '3 cool 5 school',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9634033995310515,
				averageNormalizedAccuracy: 0.9819031121108012,
				averageWeightedScore: 1.8128700685744081,
				scores: [
					{
						id: 'clxs6kpea00bi8478ez0euhk3',
						score: 502336,
						accuracy: 0.9907323951995425,
						normalizedAccuracy: 0.9975851550584647,
						weightedScore: 1.3491243181165662,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 17, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T23:29:19.763Z',
						updatedAt: '2024-06-23T23:32:14.909Z',
					},
					{
						id: 'clxs7ooca00bs8478ssq7qzic',
						score: 1023382,
						accuracy: 0.9730972020823924,
						normalizedAccuracy: 0.9926611235484234,
						weightedScore: 1.3650311188305109,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 19, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T00:00:24.634Z',
						updatedAt: '2024-06-24T00:02:43.617Z',
					},
					{
						id: 'clxs7h3hw00bq8478dom7pdfx',
						score: 995266,
						accuracy: 0.9789036258931952,
						normalizedAccuracy: 0.9940641764107194,
						weightedScore: 1.3832709918741133,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 16, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T23:54:31.028Z',
						updatedAt: '2024-06-23T23:57:05.289Z',
					},
					{
						id: 'clxs8dm3i00by8478d70bmux8',
						score: 2093200,
						accuracy: 0.9710183585187934,
						normalizedAccuracy: 0.9902634189634593,
						weightedScore: 1.491263665441352,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 24, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T00:19:48.126Z',
						updatedAt: '2024-06-24T00:24:37.634Z',
					},
					{
						id: 'clxs7y9ne00bw84782cd7zheo',
						score: 662795,
						accuracy: 0.9694591728525981,
						normalizedAccuracy: 0.9888964649817901,
						weightedScore: 1.5538026492037507,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 8, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T00:07:52.154Z',
						updatedAt: '2024-06-24T00:09:24.831Z',
					},
					{
						id: 'clxnzwk0d00r31320hcfxcq54',
						score: 1769409,
						accuracy: 0.9422601027246803,
						normalizedAccuracy: 0.9695301468424677,
						weightedScore: 2.0528229012836534,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 57, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-21T01:11:30.637Z',
						updatedAt: '2024-06-21T01:14:56.898Z',
					},
					{
						id: 'clxs9e0v400cc8478sk5efy4t',
						score: 1873215,
						accuracy: 0.9183529394461589,
						normalizedAccuracy: 0.9403212989702832,
						weightedScore: 3.494774835270911,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 98, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-24T00:48:06.881Z',
						updatedAt: '2024-06-24T00:51:28.386Z',
					},
				],
			},
			{
				player: {
					id: 'clvejcqew001ed63s0jkflgnp',
					teamId: 'cluzrpp90000vivs9r63se9ux',
					user: {
						id: 'clvefcag8001bd63sttauccf4',
						discordId: '517825076997914635',
						discordAvatar: 'https://cdn.discordapp.com/avatars/517825076997914635/fc6749ea08ec8ad4f3a792b68f12406e.png',
						discordUsername: 'Parv',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198355355471',
								type: 'STEAM',
								name: 'ParvTubeHD',
								avatar: 'https://avatars.steamstatic.com/0a25510be86af2f091f39a7318ac649e9a51c061_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'CA',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9609486784919297,
				averageNormalizedAccuracy: 0.97938640566701,
				averageWeightedScore: 1.9259131979666553,
				scores: [
					{
						id: 'clxjra25500b81320ty0m5v24',
						score: 502330,
						accuracy: 0.9907205616969242,
						normalizedAccuracy: 0.9975732397051347,
						weightedScore: 1.3508469710020097,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 18, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-18T01:58:59.418Z',
						updatedAt: '2024-06-18T02:01:54.594Z',
					},
					{
						id: 'clxjsa03m00bc1320iamadngm',
						score: 992165,
						accuracy: 0.9758536069596692,
						normalizedAccuracy: 0.99096692099252,
						weightedScore: 1.583258093641171,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 31, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-18T02:26:56.387Z',
						updatedAt: '2024-06-18T02:29:30.641Z',
					},
					{
						id: 'clxl4tj6c00gb1320474f0z6q',
						score: 1016722,
						accuracy: 0.9667644471913852,
						normalizedAccuracy: 0.9862010499074638,
						weightedScore: 1.6863511361991605,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 51, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-19T01:05:49.141Z',
						updatedAt: '2024-06-19T01:08:07.741Z',
					},
					{
						id: 'clxtuf47y002gqiy34vvo7xn1',
						score: 2082601,
						accuracy: 0.9661015691140826,
						normalizedAccuracy: 0.9852491814431107,
						weightedScore: 1.7442593211438393,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 50, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T03:24:35.998Z',
						updatedAt: '2024-06-25T03:29:25.676Z',
					},
					{
						id: 'clxtv8i9n002mqiy3xaconimf',
						score: 1944331,
						accuracy: 0.9532179109746023,
						normalizedAccuracy: 0.976020292143822,
						weightedScore: 2.002434213287447,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 53, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T03:47:27.227Z',
						updatedAt: '2024-06-25T03:50:49.506Z',
					},
					{
						id: 'clxjriebr00ba1320a8k8vl3o',
						score: 655390,
						accuracy: 0.9586280030716349,
						normalizedAccuracy: 0.9778481343166671,
						weightedScore: 2.104851912486977,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 46, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-18T02:05:28.455Z',
						updatedAt: '2024-06-18T02:07:01.088Z',
					},
					{
						id: 'clxtvejwj002oqiy3dfe4zxfo',
						score: 1718885,
						accuracy: 0.9153546504352086,
						normalizedAccuracy: 0.9418460211603509,
						weightedScore: 3.009390738005983,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 83, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-25T03:52:09.284Z',
						updatedAt: '2024-06-25T03:55:36.299Z',
					},
				],
			},
			{
				player: {
					id: 'clvn1ssyx0030d63s2jx2wtdi',
					teamId: 'cluzrpp90000vivs9r63se9ux',
					user: {
						id: 'clhciz5sc004io14mnr5y3w2g',
						discordId: '956042444493951017',
						discordAvatar: 'https://cdn.discordapp.com/avatars/956042444493951017/825b9f28d6bc1772a1cf45a737fef6f5.png',
						discordUsername: 'sharkzy',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199070667798',
								type: 'STEAM',
								name: 'SillySteve7839',
								avatar: 'https://cdn.assets.beatleader.xyz/76561199070667798R27.png',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: null,
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9631390851633551,
				averageNormalizedAccuracy: 0.981686889397727,
				averageWeightedScore: 2.0346856195270315,
				scores: [
					{
						id: 'clxb7tt2r009v7pio6jswymdb',
						score: 1016464,
						accuracy: 0.966519124254166,
						normalizedAccuracy: 0.9859507948024536,
						weightedScore: 1.6987986684035317,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 55, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-12T02:32:19.060Z',
						updatedAt: '2024-06-12T02:34:38.112Z',
					},
					{
						id: 'clxqt8jus012j1320xhcnk4cr',
						score: 1954881,
						accuracy: 0.9583901007719065,
						normalizedAccuracy: 0.9813162083649373,
						weightedScore: 1.7810467117136022,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 42, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T00:28:11.524Z',
						updatedAt: '2024-06-23T00:31:35.348Z',
					},
					{
						id: 'clxb8n6lo00a17pioxx3e37ew',
						score: 1777836,
						accuracy: 0.9467477174512138,
						normalizedAccuracy: 0.974147638076796,
						weightedScore: 1.8932750198795865,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 47, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-12T02:55:09.613Z',
						updatedAt: '2024-06-12T02:58:37.655Z',
					},
					{
						id: 'clxb88noe009x7pioqbb7z4xq',
						score: 2074449,
						accuracy: 0.9623199229939579,
						normalizedAccuracy: 0.981392585135357,
						weightedScore: 1.938845658089464,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 63, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-12T02:43:51.903Z',
						updatedAt: '2024-06-12T02:48:42.545Z',
					},
					{
						id: 'clxb7jloz009t7pio8qspzyrz',
						score: 656030,
						accuracy: 0.959564120378835,
						normalizedAccuracy: 0.9788030204241186,
						weightedScore: 2.057225777645483,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 40, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-12T02:24:22.931Z',
						updatedAt: '2024-06-12T02:25:55.845Z',
					},
					{
						id: 'clxl3wjfj00g71320s80345gl',
						score: 983251,
						accuracy: 0.9670861549205038,
						normalizedAccuracy: 0.9820636850048292,
						weightedScore: 2.1581323358699858,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 97, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-19T00:40:09.824Z',
						updatedAt: '2024-06-19T00:42:44.693Z',
					},
					{
						id: 'clxb8iwvn009z7pioarf7iwtk',
						score: 497577,
						accuracy: 0.9813464553729032,
						normalizedAccuracy: 0.9881342939755974,
						weightedScore: 2.715475165087568,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 117, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-12T02:51:50.388Z',
						updatedAt: '2024-06-12T02:54:46.068Z',
					},
				],
			},
			{
				player: {
					id: 'clve5tjik001ad63s4crsd2c3',
					teamId: 'cluzrpp90000vivs9r63se9ux',
					user: {
						id: 'clve4zy0r0018d63str822759',
						discordId: '133040995024502785',
						discordAvatar: 'https://cdn.discordapp.com/avatars/133040995024502785/3885313a26bcf1538c780f8ce0bd16d1.png',
						discordUsername: 'Whiisper 🍀',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198110018904',
								type: 'STEAM',
								name: 'Whiisper',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198110018904R3.gif',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'CA',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.49023346000898677,
				averageNormalizedAccuracy: 0.2832596692679992,
				averageWeightedScore: 18.33607655275037,
				scores: [
					{
						id: 'clxtlkkbl0005ljn25gs2mmpq',
						score: 502022,
						accuracy: 0.9901131085625253,
						normalizedAccuracy: 0.9969615849008643,
						weightedScore: 1.4392764857881137,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 25, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T23:16:53.601Z',
						updatedAt: '2024-06-24T23:19:48.860Z',
					},
					{
						id: 'clxl0s8xw00fz13203b9m0ov6',
						score: 987048,
						accuracy: 0.9708207314734217,
						normalizedAccuracy: 0.98585609997513,
						weightedScore: 1.9132593834644653,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 73, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-18T23:12:50.757Z',
						updatedAt: '2024-06-18T23:15:25.514Z',
					},
					{
						id: 'clxl0xac000g11320y8re8rrm',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 134, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-18T23:16:45.840Z',
						updatedAt: '2024-06-18T23:16:45.840Z',
					},
					{
						id: 'fprm4',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 146, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'clxqdbm3i010513208522j95b',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 146, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-22T17:02:40.542Z',
						updatedAt: '2024-06-22T17:02:40.542Z',
					},
					{
						id: 'ja877p',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 124, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'ef4cmh',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 152, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
				],
			},
		],
		captain: {
			id: 'cluzrpp8x000tivs9w6g1jx9e',
			user: {
				id: 'clgikif5y000cs74m8au7vtqa',
				discordId: '284813145942261763',
				discordAvatar: 'https://cdn.discordapp.com/avatars/284813145942261763/19b047be9008d1d9cba8c4ba4e4d5f45.png',
				discordUsername: 'EJ',
				discordDiscriminator: '0',
				twitchUser: 'ej_ca',
				playableAccounts: [
					{
						id: '76561198321316302',
						type: 'STEAM',
						name: 'EJ',
						avatar: 'https://avatars.steamstatic.com/1b0fdb87c2862ce0048d587bcf6b6b55ac86073f_full.jpg',
					},
				],
				bio: 'World Cup 2021 - #2 [Canada]\nWorld Cup 2022, 2023 - #3 [Canada]\nCanadian Tournament 3rd Edition - #4\nCanadian Tournament 4th Edition - #3\nCanadian Tournament 5th Edition - #5\nCanadian Halloween 2021 Team Tournament - #1 [YNY]\nCVRE Season 8, 9 - #1\nCVRE Season 10 - #2 [Team Athlings]\nSUPA Team Tournament - #2 [Team Sploob]\nBSTS Modifiers Tournament - #3\nBSTS Mystery Acc Royale - #1\nHelium 2v2 -  #1 [WcDonalds]\nSSS S1 - #2\nSSS S2 - #3\ntheres more but character limit :(',
				controllers: 'Rift Touch',
				countryCode: 'CA',
				motto: '🐡',
				pronouns: 'He/him',
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9726762663163012,
		averageNormalizedAccuracy: 0.9914085863340191,
		averageWeightedScore: 1.4280618915913859,
	},
	{
		id: 'clvjqmtwq0027d63sfy3856h7',
		name: 'Denmark',
		image: 'https://cdn.cube.community/1714321290307-denmark.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clvjtcep9002bd63s8va5xuoc',
					teamId: 'clvjqmtwq0027d63sfy3856h7',
					user: {
						id: 'clgj4ppfc000gpf4mwbaozosy',
						discordId: '301424951343185921',
						discordAvatar: 'https://cdn.discordapp.com/avatars/301424951343185921/8254116fd7071c70ff2a65c68b6f152f.png',
						discordUsername: 'A-tach',
						discordDiscriminator: '0',
						twitchUser: 'A_tach',
						playableAccounts: [
							{
								id: '76561198010829104',
								type: 'STEAM',
								name: 'A-tach',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198010829104R45.png',
							},
							{id: '3117609721598571', type: 'OCULUS', name: 'A-tach', avatar: 'https://cdn.assets.beatleader.xyz/3117609721598571.png'},
						],
						bio: null,
						controllers: 'Quest 3 Touch',
						countryCode: 'DK',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9693532781706222,
				averageNormalizedAccuracy: 0.9880329529147318,
				averageWeightedScore: 1.671174978380949,
				scores: [
					{
						id: 'clxq7fw4g00zd1320xijmydgw',
						score: 1806481,
						accuracy: 0.9620019863300023,
						normalizedAccuracy: 0.9898433822808226,
						weightedScore: 1.3509409670945511,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 14, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-22T14:18:02.465Z',
						updatedAt: '2024-06-22T14:21:28.321Z',
					},
					{
						id: 'clxq8mfap00zj1320ef21mm5q',
						score: 1019740,
						accuracy: 0.9696341550383911,
						normalizedAccuracy: 0.9891284526474662,
						weightedScore: 1.540743957157331,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 33, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-22T14:51:06.866Z',
						updatedAt: '2024-06-22T14:53:25.873Z',
					},
					{
						id: 'clxhv658u007013203mg30pzn',
						score: 1964280,
						accuracy: 0.9629980071135994,
						normalizedAccuracy: 0.9860343426362418,
						weightedScore: 1.5838124816384773,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 29, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-16T18:12:22.927Z',
						updatedAt: '2024-06-16T18:15:43.805Z',
					},
					{
						id: 'clxq8bywe00zh13204ci9lrlp',
						score: 2086908,
						accuracy: 0.9680995511846637,
						normalizedAccuracy: 0.9872867624413314,
						weightedScore: 1.641452236597126,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 40, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-22T14:42:59.055Z',
						updatedAt: '2024-06-22T14:47:47.406Z',
					},
					{
						id: 'clxrmd0ow001f8478f836az31',
						score: 990591,
						accuracy: 0.9743054838376536,
						normalizedAccuracy: 0.9893948216606123,
						weightedScore: 1.6847671868953953,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 48, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-23T14:03:28.832Z',
						updatedAt: '2024-06-23T14:06:03.424Z',
					},
					{
						id: 'clxq7ag9s00zb13207nlnmcbu',
						score: 657611,
						accuracy: 0.9618766226642776,
						normalizedAccuracy: 0.9811618875114325,
						weightedScore: 1.939574341419854,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 31, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-22T14:13:48.641Z',
						updatedAt: '2024-06-22T14:15:21.618Z',
					},
					{
						id: 'clxrmxxd7001t8478xdhfnai3',
						score: 500219,
						accuracy: 0.9865571410257674,
						normalizedAccuracy: 0.9933810212252161,
						weightedScore: 1.9569336778639104,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 69, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-23T14:19:44.299Z',
						updatedAt: '2024-06-23T14:22:39.394Z',
					},
				],
			},
			{
				player: {
					id: 'clvmwcnoo002wd63svvw11qrt',
					teamId: 'clvjqmtwq0027d63sfy3856h7',
					user: {
						id: 'clvl8t4cm002od63s00qaigvp',
						discordId: '557601615855747082',
						discordAvatar: 'https://cdn.discordapp.com/avatars/557601615855747082/42168bce729661f10ac9dcf8ef19e852.png',
						discordUsername: 'Mælk',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198931503563',
								type: 'STEAM',
								name: 'Mælk',
								avatar: 'https://avatars.steamstatic.com/5b633a95bc08f79014c7c7195ea700709c3de5a2_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'DK',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9670440154648458,
				averageNormalizedAccuracy: 0.985648711951845,
				averageWeightedScore: 1.733708110629351,
				scores: [
					{
						id: 'clxamfeg8008e7pio3qfua3v4',
						score: 1021396,
						accuracy: 0.9712087859842632,
						normalizedAccuracy: 0.9907347412284616,
						weightedScore: 1.460848169054856,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 26, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-11T16:33:14.985Z',
						updatedAt: '2024-06-11T16:35:33.240Z',
					},
					{
						id: 'clxtbu69x00nr84786p24tptk',
						score: 1969196,
						accuracy: 0.965408100482656,
						normalizedAccuracy: 0.9885020890005075,
						weightedScore: 1.4806522012842573,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 21, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T18:44:25.797Z',
						updatedAt: '2024-06-24T18:47:46.280Z',
					},
					{
						id: 'clxtd9lum00pb84782u49r9yp',
						score: 2091563,
						accuracy: 0.9702589676087536,
						normalizedAccuracy: 0.9894889773349274,
						weightedScore: 1.5303384732897312,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 26, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T19:24:25.438Z',
						updatedAt: '2024-06-24T19:29:51.181Z',
					},
					{
						id: 'clxtc4grk00o784783zeqf4zm',
						score: 991877,
						accuracy: 0.9755703417378518,
						normalizedAccuracy: 0.9906792687640642,
						weightedScore: 1.6018315490777764,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 36, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T18:52:25.952Z',
						updatedAt: '2024-06-24T18:54:59.811Z',
					},
					{
						id: 'clxbk934o00a37pio8uokc5y1',
						score: 500452,
						accuracy: 0.9870166753774394,
						normalizedAccuracy: 0.9938437341128622,
						weightedScore: 1.8900373241458512,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 64, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-12T08:20:07.321Z',
						updatedAt: '2024-06-12T08:23:01.989Z',
					},
					{
						id: 'clxan9xy1008g7piopswtwqyi',
						score: 656394,
						accuracy: 0.960096537097305,
						normalizedAccuracy: 0.9793461118977317,
						weightedScore: 2.030138413454383,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 38, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-11T16:56:59.929Z',
						updatedAt: '2024-06-11T16:58:32.375Z',
					},
					{
						id: 'clxna2myw00nd13208xhghtdx',
						score: 1764693,
						accuracy: 0.9397486999656519,
						normalizedAccuracy: 0.9669460613243602,
						weightedScore: 2.1421106440986026,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 61, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-20T13:08:24.392Z',
						updatedAt: '2024-06-20T13:11:49.784Z',
					},
				],
			},
			{
				player: {
					id: 'clvjt94720029d63sx9l6nl3n',
					teamId: 'clvjqmtwq0027d63sfy3856h7',
					user: {
						id: 'cli0e80ii0002ln30mkafggqe',
						discordId: '317722835281903617',
						discordAvatar: 'https://cdn.discordapp.com/avatars/317722835281903617/277cd2161348ceefa4bc40069e1560e5.png',
						discordUsername: 'gustav',
						discordDiscriminator: '0',
						twitchUser: 'gustavsnedker',
						playableAccounts: [
							{
								id: '76561198075605706',
								type: 'STEAM',
								name: 'gustav',
								avatar: 'https://avatars.akamai.steamstatic.com/70bf0e9eb2a231446863d58ba5067de40c7551c7_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'DK',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.974609791889702,
				averageNormalizedAccuracy: 0.8508233964504829,
				averageWeightedScore: 4.739334034986195,
				scores: [
					{
						id: 'clxgmf6l6004w1320904c8l74',
						score: 503130,
						accuracy: 0.9922983620460126,
						normalizedAccuracy: 0.9991619534824606,
						weightedScore: 1.121159919609532,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 6, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-15T21:19:41.851Z',
						updatedAt: '2024-06-15T21:22:36.865Z',
					},
					{
						id: 'clxuzvs9300g9qiy34d2zrw4y',
						score: 1025456,
						accuracy: 0.9750692942211234,
						normalizedAccuracy: 0.9946728641987763,
						weightedScore: 1.2649683987070006,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 9, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T22:45:17.895Z',
						updatedAt: '2024-06-25T22:47:36.752Z',
					},
					{
						id: 'clxpaabtb00w913203spr0g2l',
						score: 995709,
						accuracy: 0.979339342883699,
						normalizedAccuracy: 0.9945066414704622,
						weightedScore: 1.3547014059073907,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 13, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-21T22:49:55.535Z',
						updatedAt: '2024-06-21T22:52:29.685Z',
					},
					{
						id: 'clxuzl0g700g1qiy35cobs3vt',
						score: 2097422,
						accuracy: 0.9729769097846382,
						normalizedAccuracy: 0.9922607876596488,
						weightedScore: 1.3904855110517018,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 15, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T22:36:55.303Z',
						updatedAt: '2024-06-25T22:41:43.689Z',
					},
					{
						id: 'clxvy9d7w00fl90r9gpss4z1j',
						score: 1801813,
						accuracy: 0.9595161449222109,
						normalizedAccuracy: 0.9872855978875813,
						weightedScore: 1.4393199288121474,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 19, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T14:47:38.540Z',
						updatedAt: '2024-06-26T14:51:04.545Z',
					},
					{
						id: 'clxi3gdah007u1320slie5ea7',
						score: 662111,
						accuracy: 0.968458697480528,
						normalizedAccuracy: 0.9878759304544512,
						weightedScore: 1.6047030808155975,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 15, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-16T22:04:16.841Z',
						updatedAt: '2024-06-16T22:05:49.875Z',
					},
					{
						id: 'uc5p1',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 137, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
				],
			},
			{
				player: {
					id: 'clvr3nb0u0035d63sijwcqtj0',
					teamId: 'clvjqmtwq0027d63sfy3856h7',
					user: {
						id: 'cli0fqyc3000cln30mbwo5l9k',
						discordId: '266939447860264960',
						discordAvatar: 'https://cdn.discordapp.com/avatars/266939447860264960/882c942e2651bde5e7872adb3a45a072.png',
						discordUsername: 'Rac',
						discordDiscriminator: '0',
						twitchUser: 'raacc_',
						playableAccounts: [
							{
								id: '76561198318835649',
								type: 'STEAM',
								name: 'Rac',
								avatar: 'https://avatars.akamai.steamstatic.com/194f29140ac363c4c9a6d6838a881e7327005263_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'DK',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8346590945903686,
				averageNormalizedAccuracy: 0.8500820866118367,
				averageWeightedScore: 4.754100261113478,
				scores: [
					{
						id: 'clx81go9m003s7pio91hhd9q1',
						score: 503512,
						accuracy: 0.9930517617127023,
						normalizedAccuracy: 0.9999205643111337,
						weightedScore: 1.011484352569624,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 2, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T21:10:50.074Z',
						updatedAt: '2024-06-09T21:13:44.864Z',
					},
					{
						id: 'clx8185gc003q7pioq96xtfi1',
						score: 999357,
						accuracy: 0.9829273690267184,
						normalizedAccuracy: 0.9981502363642356,
						weightedScore: 1.1194376370437251,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 5, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T21:04:12.445Z',
						updatedAt: '2024-06-09T21:06:46.558Z',
					},
					{
						id: 'clx81se5e003w7pio61g65ipg',
						score: 2103668,
						accuracy: 0.9758743780950282,
						normalizedAccuracy: 0.9952156822300892,
						weightedScore: 1.2413949491573972,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 7, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T21:19:56.834Z',
						updatedAt: '2024-06-09T21:24:45.528Z',
					},
					{
						id: 'clxjfl6b700ac13201v6zk8kx',
						score: 1025420,
						accuracy: 0.9750350631136044,
						normalizedAccuracy: 0.9946379448817981,
						weightedScore: 1.26670526366575,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 10, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-17T20:31:42.643Z',
						updatedAt: '2024-06-17T20:34:01.270Z',
					},
					{
						id: 'clxti440t000396ln30aoaedh',
						score: 1798435,
						accuracy: 0.9577172648289121,
						normalizedAccuracy: 0.98543465622512,
						weightedScore: 1.503275398538377,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 23, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T21:40:07.133Z',
						updatedAt: '2024-06-24T21:43:32.955Z',
					},
					{
						id: 'clxqlvjke010v1320ck97qhuu',
						score: 654966,
						accuracy: 0.9580078253556149,
						normalizedAccuracy: 0.9772155222704805,
						weightedScore: 2.1364042268194674,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 48, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-22T21:02:07.310Z',
						updatedAt: '2024-06-22T21:03:39.715Z',
					},
					{
						id: 'clxqm71dk01131320dfcw9d18',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 139, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-22T21:11:03.609Z',
						updatedAt: '2024-06-22T21:11:03.609Z',
					},
				],
			},
			{
				player: {
					id: 'clvl4cldd002ld63sdc09fk93',
					teamId: 'clvjqmtwq0027d63sfy3856h7',
					user: {
						id: 'clgikfe9z000ot74nnll5pt30',
						discordId: '169481403858157568',
						discordAvatar: 'https://cdn.discordapp.com/avatars/169481403858157568/9192ffd60af239912deb9e2210d4cae1.png',
						discordUsername: 'Luck',
						discordDiscriminator: '0',
						twitchUser: 'luackci',
						playableAccounts: [
							{
								id: '76561198366737508',
								type: 'STEAM',
								name: 'Luck',
								avatar: 'https://avatars.akamai.steamstatic.com/b66e84cfe89f8abe95f5bba933b0b9842eab463f_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'DK',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9716708196322607,
				averageNormalizedAccuracy: 0.8487816504035113,
				averageWeightedScore: 4.844430183410261,
				scores: [
					{
						id: 'clxthksn000rt8478furua5np',
						score: 1979798,
						accuracy: 0.9706057835377288,
						normalizedAccuracy: 0.9938241083157933,
						weightedScore: 1.2581735006505226,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 5, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T21:25:05.917Z',
						updatedAt: '2024-06-24T21:28:27.036Z',
					},
					{
						id: 'clxtke1z1001b96ln3ub9p1ao',
						score: 1023283,
						accuracy: 0.9730030665367152,
						normalizedAccuracy: 0.9925650954267334,
						weightedScore: 1.369807497467072,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 20, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T22:43:50.269Z',
						updatedAt: '2024-06-24T22:46:08.838Z',
					},
					{
						id: 'clxtjwu14001796lns9gsvxxa',
						score: 2095622,
						accuracy: 0.9721419045078687,
						normalizedAccuracy: 0.9914092330283979,
						weightedScore: 1.4334510908483316,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 17, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T22:30:26.825Z',
						updatedAt: '2024-06-24T22:35:15.348Z',
					},
					{
						id: 'clxth7x0u00rr8478mq8vbw2t',
						score: 501965,
						accuracy: 0.9900006902876527,
						normalizedAccuracy: 0.9968483890442298,
						weightedScore: 1.4556416881998278,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 28, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T21:15:05.070Z',
						updatedAt: '2024-06-24T21:18:00.331Z',
					},
					{
						id: 'clxo352h200r513205fcj9yrk',
						score: 992949,
						accuracy: 0.9766247178412829,
						normalizedAccuracy: 0.9917499742810941,
						weightedScore: 1.5326970205081905,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 27, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-21T02:42:06.663Z',
						updatedAt: '2024-06-21T02:44:42.307Z',
					},
					{
						id: 'clxtkv2ak001j96lnczsaoq7v',
						score: 1779528,
						accuracy: 0.9476487550823155,
						normalizedAccuracy: 0.9750747527283308,
						weightedScore: 1.861240486197887,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 45, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T22:57:03.836Z',
						updatedAt: '2024-06-24T23:00:29.774Z',
					},
					{
						id: 'm3jujpp',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 149, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
				],
			},
			{
				player: {
					id: 'clvl5plo9002nd63sqvejdz0i',
					teamId: 'clvjqmtwq0027d63sfy3856h7',
					user: {
						id: 'cli0e6c780000ln30mc86v1bm',
						discordId: '325313216391413761',
						discordAvatar: 'https://cdn.discordapp.com/avatars/325313216391413761/befe8026c19f9dd5b637efb574097777.png',
						discordUsername: 'Kassi',
						discordDiscriminator: '0',
						twitchUser: 'Kassi_BS',
						playableAccounts: [
							{id: '3361764630549475', type: 'OCULUS', name: 'Kassi', avatar: 'https://cdn.assets.beatleader.xyz/3361764630549475.png'},
						],
						bio: null,
						controllers: 'Quest Touch',
						countryCode: 'DK',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.6568078222655751,
				averageNormalizedAccuracy: 0.6681333591647748,
				averageWeightedScore: 11.776874114347686,
				scores: [
					{
						id: 'clxq5zr1700yv13205cucbza3',
						score: 995203,
						accuracy: 0.9788416616259227,
						normalizedAccuracy: 0.9940012524857448,
						weightedScore: 1.3873339352508707,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 17, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-22T13:37:29.756Z',
						updatedAt: '2024-06-22T13:40:03.850Z',
					},
					{
						id: 'clxta0wiu00mb8478hwsw99e3',
						score: 502059,
						accuracy: 0.9901860818286706,
						normalizedAccuracy: 0.9970350629130655,
						weightedScore: 1.4286534596612115,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 24, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T17:53:40.519Z',
						updatedAt: '2024-06-24T17:56:35.299Z',
					},
					{
						id: 'clxq6ejjd00yx1320qb5xlz2r',
						score: 1020416,
						accuracy: 0.9702769391684694,
						normalizedAccuracy: 0.9897841598218339,
						weightedScore: 1.5081294929319244,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 29, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-22T13:48:59.882Z',
						updatedAt: '2024-06-22T13:51:18.069Z',
					},
					{
						id: 'clxapr2rc008i7pioedf51nua',
						score: 656249,
						accuracy: 0.9598844480198925,
						normalizedAccuracy: 0.9791297705140122,
						weightedScore: 2.040928709629409,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 39, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-11T18:06:18.552Z',
						updatedAt: '2024-06-11T18:07:51.254Z',
					},
					{
						id: 'clxq6mgj000z11320s2hyab57',
						score: 676862,
						accuracy: 0.3604480691860574,
						normalizedAccuracy: 0.37087983290018667,
						weightedScore: 22.737949183990306,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 106, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-22T13:55:09.228Z',
						updatedAt: '2024-06-22T13:58:34.854Z',
					},
					{
						id: 'clxq72nva00z913201j0ns7uz',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 149, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-22T14:07:45.239Z',
						updatedAt: '2024-06-22T14:07:45.239Z',
					},
					{
						id: 'clxq6sul700z31320t6vscvp1',
						score: 689473,
						accuracy: 0.33801755603001343,
						normalizedAccuracy: 0.34610343551858064,
						weightedScore: 28.335124018970077,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 115, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-22T14:00:07.387Z',
						updatedAt: '2024-06-22T14:03:28.091Z',
					},
				],
			},
			{
				player: {
					id: 'clvjxs6jf002fd63s6dwutxtq',
					teamId: 'clvjqmtwq0027d63sfy3856h7',
					user: {
						id: 'clgigy7y9000ct74nwbnbngds',
						discordId: '430267703639539713',
						discordAvatar: 'https://cdn.discordapp.com/avatars/430267703639539713/9b064ef495a439d740511f0d813cc254.png',
						discordUsername: 'Sensei WU',
						discordDiscriminator: '0',
						twitchUser: 'tmbs_senseiwu',
						playableAccounts: [
							{
								id: '76561198400393482',
								type: 'STEAM',
								name: 'Sensei WU',
								avatar: 'https://avatars.akamai.steamstatic.com/f2b57d50be438a445034c7675a7e8555b1a6f1bc_full.jpg',
							},
						],
						bio: 'Jeg spiller et spil der hedder Beat Saber ret tit',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'DK',
						motto: 'Born to play challenge, forced to play acc :pensive:',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.7318617979809444,
				averageNormalizedAccuracy: 0.42410249352520457,
				averageWeightedScore: 14.970549462983698,
				scores: [
					{
						id: 'clx6ci2kj00097piosqdupjn2',
						score: 503112,
						accuracy: 0.9922628615381581,
						normalizedAccuracy: 0.9991262074224707,
						weightedScore: 1.1263278782658628,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 7, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-08T16:44:18.692Z',
						updatedAt: '2024-06-08T16:47:14.029Z',
					},
					{
						id: 'clxnn6xqd00p913203uqv5y1i',
						score: 990744,
						accuracy: 0.9744559684867441,
						normalizedAccuracy: 0.9895476369069794,
						weightedScore: 1.6749000386946988,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 46, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-20T19:15:39.973Z',
						updatedAt: '2024-06-20T19:18:14.822Z',
					},
					{
						id: 'clxnnb3ks00pb1320iaty3vaf',
						score: 1010374,
						accuracy: 0.9607283618988756,
						normalizedAccuracy: 0.9800436103469816,
						weightedScore: 1.9926183239253148,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 80, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-20T19:18:54.173Z',
						updatedAt: '2024-06-20T19:21:15.060Z',
					},
					{
						id: 'fgc395',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 138, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'of8si',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 148, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'clx6d0erl000f7piow5s6rihk',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 148, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-08T16:58:34.305Z',
						updatedAt: '2024-06-08T16:58:34.305Z',
					},
					{
						id: 'u35vu',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 128, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clvjtnzmo002dd63skep9ae0t',
					teamId: 'clvjqmtwq0027d63sfy3856h7',
					user: {
						id: 'clkot1tvx007ss32zm5nf369c',
						discordId: '850432289451147285',
						discordAvatar: 'https://cdn.discordapp.com/avatars/850432289451147285/ba3e9df973ebbf4b94fb897cb85acd15.png',
						discordUsername: 'WalkingKat',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561197997028786',
								type: 'STEAM',
								name: 'uwu',
								avatar: 'https://avatars.steamstatic.com/ce6dd78a66c79bba9b1be7ca077241fc1d2f0146_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'DK',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.960205075687527,
				averageNormalizedAccuracy: 0.42147307935327966,
				averageWeightedScore: 15.001331513674412,
				scores: [
					{
						id: 'clxjk5ddm00b01320lz4ilens',
						score: 1966992,
						accuracy: 0.9643275785572287,
						normalizedAccuracy: 0.9873957193937456,
						weightedScore: 1.5269022537457506,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 22, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-17T22:39:23.387Z',
						updatedAt: '2024-06-17T22:42:44.632Z',
					},
					{
						id: 'clxf8one6003i132017pgo203',
						score: 2087057,
						accuracy: 0.9681686710659074,
						normalizedAccuracy: 0.9873572522413627,
						weightedScore: 1.6378956413806274,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 39, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-14T22:07:22.735Z',
						updatedAt: '2024-06-14T22:12:11.847Z',
					},
					{
						id: 'clxgo4l9n00581320q5are711',
						score: 1780411,
						accuracy: 0.9481189774394448,
						normalizedAccuracy: 0.9755585838378492,
						weightedScore: 1.8445227005944942,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 44, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-15T22:07:26.891Z',
						updatedAt: '2024-06-15T22:10:52.759Z',
					},
					{
						id: '1qhvqn',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 170, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'we88fm',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 147, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'ocsyx',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 154, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: '8m62to',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 155, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clvjqmtwl0025d63sa057drh6',
			user: {
				id: 'clgimc3h20006o14mqzdvcnzm',
				discordId: '454664934568099842',
				discordAvatar: 'https://cdn.discordapp.com/avatars/454664934568099842/bc471ddea4686fa0e25135fb1bb852d6.png',
				discordUsername: 'mabe',
				discordDiscriminator: '0',
				twitchUser: 'mabe45',
				playableAccounts: [
					{
						id: '76561198843522732',
						type: 'STEAM',
						name: 'moneyguy$$$$$$$$$$$',
						avatar: 'https://avatars.steamstatic.com/71db1777a66261febcb6075581783fbd09024ebd_full.jpg',
					},
				],
				bio: '𝓲 𝓱𝓪𝓽𝓮 𝓱𝓮𝓵𝓵𝓸𝓲𝓪𝓶𝓭𝓪𝓪𝓷 ',
				controllers: 'Quest/Rift S Touch',
				countryCode: 'DK',
				motto: '!bsr fa3',
				pronouns: 'He/him',
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9718610956010337,
		averageNormalizedAccuracy: 0.9905724785240998,
		averageWeightedScore: 1.45152639070486,
	},
	{
		id: 'clv3ptvk5001gykbvfjgzcnd0',
		name: 'Germany',
		image: 'https://cdn.cube.community/1713352480795-germany.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clv5bo9zn000fd63sz9qgrrpu',
					teamId: 'clv3ptvk5001gykbvfjgzcnd0',
					user: {
						id: 'cli0ky9pk0016ln30z494fzjb',
						discordId: '528918530402549761',
						discordAvatar: 'https://cdn.discordapp.com/avatars/528918530402549761/68e89d686c389e03f967a87e6028d7e0.png',
						discordUsername: 'nailik lp',
						discordDiscriminator: '0',
						twitchUser: 'nailiklp',
						playableAccounts: [
							{id: '2769016623220259', type: 'OCULUS', name: 'NailikLP', avatar: 'https://cdn.assets.beatleader.xyz/2769016623220259.png'},
						],
						bio: null,
						controllers: 'Rift Touch',
						countryCode: 'DE',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9743685783690267,
				averageNormalizedAccuracy: 0.9931487859566025,
				averageWeightedScore: 1.3938131594095027,
				scores: [
					{
						id: 'clx7rvk2m00247pio6uu4ikmn',
						score: 2105080,
						accuracy: 0.9765293933454718,
						normalizedAccuracy: 0.9958836795297148,
						weightedScore: 1.2076908387835967,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 6, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T16:42:28.318Z',
						updatedAt: '2024-06-09T16:47:16.756Z',
					},
					{
						id: 'clxqlio3j010n13205jlhlhn6',
						score: 1811625,
						accuracy: 0.96474131113756,
						normalizedAccuracy: 0.9926619861623207,
						weightedScore: 1.253549926161536,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 8, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-22T20:52:06.655Z',
						updatedAt: '2024-06-22T20:55:32.601Z',
					},
					{
						id: 'clxgmza5i005413206i1n06fk',
						score: 1025580,
						accuracy: 0.9751872013692443,
						normalizedAccuracy: 0.9947931418461455,
						weightedScore: 1.2589858638490858,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 8, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-15T21:35:19.590Z',
						updatedAt: '2024-06-15T21:37:38.462Z',
					},
					{
						id: 'clxqludkj010t1320py2nm348',
						score: 1975075,
						accuracy: 0.9682903093753906,
						normalizedAccuracy: 0.9914532445895062,
						weightedScore: 1.357283753724766,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 12, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-22T21:01:12.884Z',
						updatedAt: '2024-06-22T21:04:34.681Z',
					},
					{
						id: 'clxgmrycf0052132098y0nsg1',
						score: 994876,
						accuracy: 0.9785200375719842,
						normalizedAccuracy: 0.9936746473513522,
						weightedScore: 1.4084225461111828,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 19, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-15T21:29:37.696Z',
						updatedAt: '2024-06-15T21:32:11.702Z',
					},
					{
						id: 'clxgmlqh4005013203vs8rm5c',
						score: 662424,
						accuracy: 0.9689165173510805,
						normalizedAccuracy: 0.9883429294413767,
						weightedScore: 1.5814109242446792,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 10, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-15T21:24:47.561Z',
						updatedAt: '2024-06-15T21:26:20.543Z',
					},
					{
						id: 'clx7rdblg00227pioma94bz4e',
						score: 501151,
						accuracy: 0.9883952784324553,
						normalizedAccuracy: 0.9952318727758007,
						weightedScore: 1.6893482629916738,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 50, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-09T16:28:17.524Z',
						updatedAt: '2024-06-09T16:31:12.508Z',
					},
				],
			},
			{
				player: {
					id: 'clv5bosi1000nd63syq8ybcta',
					teamId: 'clv3ptvk5001gykbvfjgzcnd0',
					user: {
						id: 'clgzy4har003gpf4mo472cqop',
						discordId: '733489965568360539',
						discordAvatar: 'https://cdn.discordapp.com/avatars/733489965568360539/09993848f06eb8ddec958e9942dd10e3.png',
						discordUsername: 'olliemine',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199006338762',
								type: 'STEAM',
								name: 'olliemine',
								avatar: 'https://avatars.akamai.steamstatic.com/24eb872dbd47f15d59a12184c4088b0dc33259a6_full.jpg',
							},
						],
						bio: 'viva mexico',
						controllers: 'Quest 2 Touch',
						countryCode: 'MX',
						motto: '3 beers no dinner',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9692319774941301,
				averageNormalizedAccuracy: 0.9879037914782397,
				averageWeightedScore: 1.6855124556639267,
				scores: [
					{
						id: 'clxghr4yo00461320fkun5r3q',
						score: 2093444,
						accuracy: 0.9711315481229778,
						normalizedAccuracy: 0.9903788519245844,
						weightedScore: 1.4854394424022532,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 22, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-15T19:09:01.537Z',
						updatedAt: '2024-06-15T19:13:52.809Z',
					},
					{
						id: 'clxtxlchi0038qiy3vm9agkyz',
						score: 1966411,
						accuracy: 0.9640427404271591,
						normalizedAccuracy: 0.9871040675146491,
						weightedScore: 1.5390943047802912,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 24, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T04:53:25.494Z',
						updatedAt: '2024-06-25T04:56:48.786Z',
					},
					{
						id: 'clxghlm6o004413207b5oc20h',
						score: 1019770,
						accuracy: 0.9696626809613236,
						normalizedAccuracy: 0.9891575520782814,
						weightedScore: 1.5392965696917065,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 32, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-15T19:04:43.920Z',
						updatedAt: '2024-06-15T19:07:03.579Z',
					},
					{
						id: 'clxkz95vb00fp1320g0sakqge',
						score: 1793769,
						accuracy: 0.9552324884774221,
						normalizedAccuracy: 0.9828779677120816,
						weightedScore: 1.5916164943769169,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 27, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-18T22:30:00.696Z',
						updatedAt: '2024-06-18T22:33:29.775Z',
					},
					{
						id: 'clxgihiz8004c1320fe3tay05',
						score: 991616,
						accuracy: 0.9753136326305799,
						normalizedAccuracy: 0.9904185839320262,
						weightedScore: 1.6186637430671997,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 40, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-15T19:29:32.756Z',
						updatedAt: '2024-06-15T19:32:08.851Z',
					},
					{
						id: 'clxgi2h6z00481320gewrphts',
						score: 659383,
						accuracy: 0.9644684974585878,
						normalizedAccuracy: 0.983805728421439,
						weightedScore: 1.807709480577467,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 24, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-15T19:17:50.604Z',
						updatedAt: '2024-06-15T19:19:23.454Z',
					},
					{
						id: 'clxghaond00421320xob9hmmk',
						score: 499314,
						accuracy: 0.9847722543808612,
						normalizedAccuracy: 0.9915837887646162,
						weightedScore: 2.216767154751651,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 92, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-15T18:56:13.898Z',
						updatedAt: '2024-06-15T18:59:09.604Z',
					},
				],
			},
			{
				player: {
					id: 'clv5bp4yy000rd63sm3z4ykvo',
					teamId: 'clv3ptvk5001gykbvfjgzcnd0',
					user: {
						id: 'clilnvckz0004p930ob0qbo9j',
						discordId: '263272950700965888',
						discordAvatar: 'https://cdn.discordapp.com/avatars/263272950700965888/ffc07386556984430d48affa78700a6d.png',
						discordUsername: 'qlulezz',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{id: '76561198256479099', type: 'STEAM', name: 'qlulezz', avatar: 'https://cdn.assets.beatleader.xyz/76561198256479099.gif'},
						],
						bio: 'Imagine playing in tournaments',
						controllers: 'Valve Index Knuckles',
						countryCode: 'DE',
						motto: '​',
						pronouns: 'undefined',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9567728320722587,
				averageNormalizedAccuracy: 0.9751510443781332,
				averageWeightedScore: 2.2047540425659906,
				scores: [
					{
						id: 'clxgkb5gf004i1320dp95wbp6',
						score: 501018,
						accuracy: 0.9881329691244194,
						normalizedAccuracy: 0.9949677491103203,
						weightedScore: 1.7275337352856732,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 54, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-15T20:20:34.527Z',
						updatedAt: '2024-06-15T20:23:29.238Z',
					},
					{
						id: 'clxm5r7fx00j51320958jue7i',
						score: 987080,
						accuracy: 0.970852205386957,
						normalizedAccuracy: 0.9858880613338474,
						weightedScore: 1.9111956661937315,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 72, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-19T18:19:46.413Z',
						updatedAt: '2024-06-19T18:22:20.659Z',
					},
					{
						id: 'clxs1tlwt009e84789wq76thf',
						score: 2073176,
						accuracy: 0.9617293887065537,
						normalizedAccuracy: 0.9807903467767002,
						weightedScore: 1.9692318709123025,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 67, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T21:16:17.070Z',
						updatedAt: '2024-06-23T21:21:05.357Z',
					},
					{
						id: 'clxm5xx4200j713206simff8r',
						score: 1010215,
						accuracy: 0.9605771745073335,
						normalizedAccuracy: 0.9798893833636614,
						weightedScore: 2.000289477493125,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 84, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-19T18:24:59.619Z',
						updatedAt: '2024-06-19T18:27:17.549Z',
					},
					{
						id: 'clxtizzju000l96ln3cvico47',
						score: 1770864,
						accuracy: 0.9430349311840497,
						normalizedAccuracy: 0.9703273996899755,
						weightedScore: 2.0252754742701353,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 55, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T22:04:54.330Z',
						updatedAt: '2024-06-24T22:08:19.477Z',
					},
					{
						id: 'clxs1ms35009c8478un07vjjw',
						score: 652571,
						accuracy: 0.9545046988700772,
						normalizedAccuracy: 0.9736421594152517,
						weightedScore: 2.3146301532966214,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 59, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T21:10:58.481Z',
						updatedAt: '2024-06-23T21:12:30.962Z',
					},
					{
						id: 'clxna3fpe00nf13203oxz2cx8',
						score: 1873675,
						accuracy: 0.9185784567264206,
						normalizedAccuracy: 0.9405522109571753,
						weightedScore: 3.485121920510345,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 97, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-20T13:09:01.634Z',
						updatedAt: '2024-06-20T13:12:21.997Z',
					},
				],
			},
			{
				player: {
					id: 'clw3imy60000lpj8v6615y1q7',
					teamId: 'clv3ptvk5001gykbvfjgzcnd0',
					user: {
						id: 'clw3ilj38000jpj8v16lgbdka',
						discordId: '525022908104572928',
						discordAvatar: 'https://cdn.discordapp.com/avatars/525022908104572928/44191ccb24305bbd4a07f9fd217d8d84.png',
						discordUsername: 'schonk',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198442753567',
								type: 'STEAM',
								name: 'schonk',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198442753567R20.png',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'DE',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9531254935337401,
				averageNormalizedAccuracy: 0.8325541439154736,
				averageWeightedScore: 5.741010191624957,
				scores: [
					{
						id: 'clxjg05e200ag13203lkzjo4j',
						score: 1956381,
						accuracy: 0.9591254832075421,
						normalizedAccuracy: 0.9820691822352381,
						weightedScore: 1.7495698157552357,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 39, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-17T20:43:21.290Z',
						updatedAt: '2024-06-17T20:46:41.965Z',
					},
					{
						id: 'clxksf3tq00el1320f2dj5pjp',
						score: 2072323,
						accuracy: 0.961333688983729,
						normalizedAccuracy: 0.9803868044986686,
						weightedScore: 1.9895927817825942,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 70, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-18T19:18:40.670Z',
						updatedAt: '2024-06-18T19:23:28.966Z',
					},
					{
						id: 'clxksn4hw00en1320yqw08nc8',
						score: 984618,
						accuracy: 0.9684306811643381,
						normalizedAccuracy: 0.9834290342975343,
						weightedScore: 2.0699729137108216,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 90, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-18T19:24:54.789Z',
						updatedAt: '2024-06-18T19:27:28.989Z',
					},
					{
						id: 'clxkrvy9f00eb1320uxmbq855',
						score: 1748605,
						accuracy: 0.9311813870760743,
						normalizedAccuracy: 0.9581308009733608,
						weightedScore: 2.446703775228142,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 72, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-18T19:03:46.995Z',
						updatedAt: '2024-06-18T19:07:12.717Z',
					},
					{
						id: 'clxksafd600ej13200h3wj8l7',
						score: 497523,
						accuracy: 0.9812399538493398,
						normalizedAccuracy: 0.9880270557956279,
						weightedScore: 2.7309790410565604,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 119, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-18T19:15:02.346Z',
						updatedAt: '2024-06-18T19:17:57.025Z',
					},
					{
						id: 'clxvxk01800er90r9arlbbu61',
						score: 627232,
						accuracy: 0.9174417669214173,
						normalizedAccuracy: 0.935836129607885,
						weightedScore: 4.200253013841346,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 113, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-26T14:27:55.053Z',
						updatedAt: '2024-06-26T14:29:27.536Z',
					},
					{
						id: 'uot6zr',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 163, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
				],
			},
			{
				player: {
					id: 'clv5boit5000jd63s3ag8no25',
					teamId: 'clv3ptvk5001gykbvfjgzcnd0',
					user: {
						id: 'clgioqo6u0000pf4mbzaun0gn',
						discordId: '102181965930717184',
						discordAvatar: 'https://cdn.discordapp.com/avatars/102181965930717184/f2f3de2c07d52cf0554aeaead641ea8e.png',
						discordUsername: 'Kakifrucht',
						discordDiscriminator: '0',
						twitchUser: 'kakifrucht',
						playableAccounts: [
							{
								id: '76561198014365525',
								type: 'STEAM',
								name: 'Kakifrucht',
								avatar: 'https://avatars.akamai.steamstatic.com/cccb15cef59202125522113cca0e432e5e3d2ebd_full.jpg',
							},
						],
						bio: null,
						controllers: 'Valve Index Knuckles',
						countryCode: 'DE',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9746777487842632,
				averageNormalizedAccuracy: 0.7076372932031124,
				averageWeightedScore: 8.245540523959496,
				scores: [
					{
						id: 'clxji35rz00as13203wmd0aq5',
						score: 995725,
						accuracy: 0.9793550798404665,
						normalizedAccuracy: 0.9945226221498209,
						weightedScore: 1.3536695472720237,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 12, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T21:41:40.992Z',
						updatedAt: '2024-06-17T21:44:14.757Z',
					},
					{
						id: 'clxtp04j4000rqiy3sbi2wqms',
						score: 1021559,
						accuracy: 0.9713637768321962,
						normalizedAccuracy: 0.9908928481358905,
						weightedScore: 1.4529840304916293,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 25, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T00:52:58.480Z',
						updatedAt: '2024-06-25T00:55:16.594Z',
					},
					{
						id: 'clxtos84z000pqiy3eojukktn',
						score: 501894,
						accuracy: 0.9898606605066711,
						normalizedAccuracy: 0.9967073906964922,
						weightedScore: 1.47602641401091,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 31, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T00:46:49.908Z',
						updatedAt: '2024-06-25T00:49:44.561Z',
					},
					{
						id: 'clxqoilzg011r1320gp0wcoql',
						score: 2089840,
						accuracy: 0.969459682002157,
						normalizedAccuracy: 0.9886738503184578,
						weightedScore: 1.5714660810617271,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 30, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-22T22:16:02.764Z',
						updatedAt: '2024-06-22T22:20:50.687Z',
					},
					{
						id: 'clxtp3wgx000vqiy34zcc0rbz',
						score: 658618,
						accuracy: 0.9633495447398253,
						normalizedAccuracy: 0.9826643411211258,
						weightedScore: 1.8646375948801905,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 28, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T00:55:54.658Z',
						updatedAt: '2024-06-25T00:57:27.087Z',
					},
					{
						id: 'djgg5u',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 131, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'n3fktt',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 120, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clv5bomy4000ld63s2gx9g30x',
					teamId: 'clv3ptvk5001gykbvfjgzcnd0',
					user: {
						id: 'clgikl43v000is74mt50naa2x',
						discordId: '203478823746928640',
						discordAvatar: 'https://cdn.discordapp.com/avatars/203478823746928640/f930f0fb1db5c46882880ae9c9a9621f.png',
						discordUsername: 'Taddus',
						discordDiscriminator: '0',
						twitchUser: 'LicensedTaddus',
						playableAccounts: [
							{
								id: '76561198253282811',
								type: 'STEAM',
								name: 'Taddus',
								avatar: 'https://avatars.steamstatic.com/e30c2073fe02641dd71e7a2f80187021a7bdd5aa_full.jpg',
							},
						],
						bio: 'https://www.youtube.com/watch?v=mQLlzKfxPME',
						controllers: 'Rift Touch',
						countryCode: 'DE',
						motto: 'Paulaner Spezi',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.6504744077996886,
				averageNormalizedAccuracy: 0.566209727161446,
				averageWeightedScore: 11.604035770481877,
				scores: [
					{
						id: 'clxlxyvo600ij13204f7r12ex',
						score: 502073,
						accuracy: 0.9902136933347796,
						normalizedAccuracy: 0.9970628654041688,
						weightedScore: 1.4246339362618432,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 23, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-19T14:41:47.479Z',
						updatedAt: '2024-06-19T14:44:42.991Z',
					},
					{
						id: 'clxvvktm500az90r97cfns846',
						score: 992899,
						accuracy: 0.9765755398513841,
						normalizedAccuracy: 0.9917000346580983,
						weightedScore: 1.5359215787437122,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 28, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T13:32:34.157Z',
						updatedAt: '2024-06-26T13:35:08.789Z',
					},
					{
						id: 'clxkzerca00ft1320hi0ld4c6',
						score: 1018615,
						accuracy: 0.9685644329284238,
						normalizedAccuracy: 0.9880372239918987,
						weightedScore: 1.5950209871182515,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 41, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-18T22:34:21.802Z',
						updatedAt: '2024-06-18T22:36:40.908Z',
					},
					{
						id: 'clxlyq9l400ip1320gt60tby3',
						score: 2085600,
						accuracy: 0.9674927806835446,
						normalizedAccuracy: 0.9866679660759559,
						weightedScore: 1.6726738912493435,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 43, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-19T15:03:05.225Z',
						updatedAt: '2024-06-19T15:07:54.565Z',
					},
					{
						id: 'clxlz2xjr00it1320yr4socf3',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 132, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-19T15:12:56.151Z',
						updatedAt: '2024-06-19T15:12:56.151Z',
					},
					{
						id: 'xtzj3t',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 144, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'clxvvqba900bd90r9y5bw7qre',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 121, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-26T13:36:50.337Z',
						updatedAt: '2024-06-26T13:36:50.337Z',
					},
				],
			},
			{
				player: {
					id: 'clv5bog0x000hd63s9ekocoqp',
					teamId: 'clv3ptvk5001gykbvfjgzcnd0',
					user: {
						id: 'clhf9obup005cpf4m59ytyklw',
						discordId: '466064825869533185',
						discordAvatar: 'https://cdn.discordapp.com/avatars/466064825869533185/4a181b5e25844bf6a162e73982d82ea3.png',
						discordUsername: 'theMJ',
						discordDiscriminator: '0',
						twitchUser: 'the__MJ',
						playableAccounts: [
							{
								id: '76561198158199188',
								type: 'STEAM',
								name: 'theMJ',
								avatar: 'https://avatars.akamai.steamstatic.com/65fe1be2271046a9c403eed505932adb0046eb14_full.jpg',
							},
						],
						bio: null,
						controllers: 'Valve Index Knuckles',
						countryCode: 'DE',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9657556040440654,
				averageNormalizedAccuracy: 0.5610424220153326,
				averageWeightedScore: 11.838572534313068,
				scores: [
					{
						id: 'clxqoet93011n1320wneh3cko',
						score: 502318,
						accuracy: 0.990696894691688,
						normalizedAccuracy: 0.9975494089984749,
						weightedScore: 1.3542922767728969,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 19, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-22T22:13:05.560Z',
						updatedAt: '2024-06-22T22:16:00.612Z',
					},
					{
						id: 'clxp6kat000vx1320yaevvgf0',
						score: 1015360,
						accuracy: 0.9654693702902513,
						normalizedAccuracy: 0.9848799357484568,
						weightedScore: 1.752062527138515,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 59, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-21T21:05:42.324Z',
						updatedAt: '2024-06-21T21:08:00.936Z',
					},
					{
						id: 'clxmdsu1b00kz1320t1lphs0x',
						score: 2063214,
						accuracy: 0.9571080983914552,
						normalizedAccuracy: 0.9760774649786331,
						weightedScore: 2.2070224853200937,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 85, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-19T22:04:59.279Z',
						updatedAt: '2024-06-19T22:09:49.484Z',
					},
					{
						id: 'clxmeidqw00l913201jd89y16',
						score: 649319,
						accuracy: 0.9497480528028669,
						normalizedAccuracy: 0.9687901443817635,
						weightedScore: 2.556630450959964,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 71, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-19T22:24:51.225Z',
						updatedAt: '2024-06-19T22:26:24.126Z',
					},
					{
						id: 'hsubr9',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 130, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'f9vlz',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 119, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'tomgns',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 152, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clv5bp253000pd63str7hdn13',
					teamId: 'clv3ptvk5001gykbvfjgzcnd0',
					user: {
						id: 'cli1j4pgi001iln309tkzqad9',
						discordId: '312254980864081931',
						discordAvatar: 'https://cdn.discordapp.com/avatars/312254980864081931/7042a27e16bbfccca18fd089700436a6.png',
						discordUsername: 'shqrks',
						discordDiscriminator: '0',
						twitchUser: 'shqrks_',
						playableAccounts: [
							{
								id: '76561198338930975',
								type: 'STEAM',
								name: 'shqrks',
								avatar: 'https://avatars.akamai.steamstatic.com/8bf905184f09f17775b5e189f13f6b2b779e3633_full.jpg',
							},
						],
						bio: 'hai',
						controllers: 'Valve Index Knuckles',
						countryCode: 'DE',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.47644704229359625,
				averageNormalizedAccuracy: 0.27824084753016315,
				averageWeightedScore: 18.48694496816506,
				scores: [
					{
						id: 'clxmfaia400lh1320q29kmxjr',
						score: 1942596,
						accuracy: 0.9523673186240504,
						normalizedAccuracy: 0.9751493523671741,
						weightedScore: 2.0388424896126245,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 58, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-19T22:46:43.468Z',
						updatedAt: '2024-06-19T22:50:04.141Z',
					},
					{
						id: 'clxnrixxx00qc1320hdik892y',
						score: 651830,
						accuracy: 0.9534208505503345,
						normalizedAccuracy: 0.9725365803439678,
						weightedScore: 2.369772287542789,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 63, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-20T21:16:58.582Z',
						updatedAt: '2024-06-20T21:18:31.179Z',
					},
					{
						id: 'clxmfk31h00lj1320lfpcybf6',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 167, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-19T22:54:10.278Z',
						updatedAt: '2024-06-19T22:54:10.278Z',
					},
					{
						id: '2fjfh',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 144, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'clxnrqxid00qm1320cfsqw7wv',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 122, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-20T21:23:11.269Z',
						updatedAt: '2024-06-20T21:23:11.269Z',
					},
					{
						id: 'twvtmd',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 150, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'h24e5xk',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 153, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clv3ptvk1001eykbv9gbw27w5',
			user: {
				id: 'clhw27ymi0004r62zxmtxw5qt',
				discordId: '124467312458858496',
				discordAvatar: 'https://cdn.discordapp.com/avatars/124467312458858496/975b8e22fe5504225b59edc268070340.png',
				discordUsername: 'Ixsen',
				discordDiscriminator: '0',
				twitchUser: 'ixsen',
				playableAccounts: [
					{
						id: '76561197999550197',
						type: 'STEAM',
						name: 'ACC | Ixsen',
						avatar: 'https://avatars.akamai.steamstatic.com/47036e45f048a54fb03f93b312991bbbb7d0391e_full.jpg',
					},
				],
				bio: null,
				controllers: null,
				countryCode: 'DE',
				motto: null,
				pronouns: null,
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9688420550369344,
		averageNormalizedAccuracy: 0.9874850606968096,
		averageWeightedScore: 1.614816435599559,
	},
	{
		id: 'clvuy5ipr003id63sbx4ro27x',
		name: 'Australia',
		image: 'https://cdn.cube.community/1714999047596-australia.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwbidc4n000u13xtg9m2ld64',
					teamId: 'clvuy5ipr003id63sbx4ro27x',
					user: {
						id: 'clippg8fa001ap92zj09pvqz6',
						discordId: '515796013856849942',
						discordAvatar: 'https://cdn.discordapp.com/avatars/515796013856849942/d8b68bc7a42ac26af4a5aec72ac113ff.png',
						discordUsername: 'jujulipz06',
						discordDiscriminator: '0',
						twitchUser: 'jujulipz06',
						playableAccounts: [
							{
								id: '76561198351485033',
								type: 'STEAM',
								name: 'JujuLipz',
								avatar: 'https://avatars.akamai.steamstatic.com/bd46b76f1465849bbf428b60fa81b1836408029f_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'AU',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9687422866821054,
				averageNormalizedAccuracy: 0.9873985801974575,
				averageWeightedScore: 1.6677655907113933,
				scores: [
					{
						id: 'clxvt5o9u009b90r99rjp0y22',
						score: 1971980,
						accuracy: 0.9667729702831958,
						normalizedAccuracy: 0.9898996085037857,
						weightedScore: 1.422231082385529,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 14, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T12:24:48.162Z',
						updatedAt: '2024-06-26T12:28:12.826Z',
					},
					{
						id: 'clxvsutgu008r90r9blx4hj2d',
						score: 1020879,
						accuracy: 0.970717189245727,
						normalizedAccuracy: 0.9902332610374142,
						weightedScore: 1.4857914797124523,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 28, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T12:16:21.679Z',
						updatedAt: '2024-06-26T12:18:40.837Z',
					},
					{
						id: 'clxudtnr8006kqiy36fpfrqpt',
						score: 2091209,
						accuracy: 0.9700947499043223,
						normalizedAccuracy: 0.9893215049241146,
						weightedScore: 1.538788370649735,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 29, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T12:27:47.204Z',
						updatedAt: '2024-06-25T12:32:39.185Z',
					},
					{
						id: 'clxu5d3c40046qiy39ujytgz9',
						score: 1791179,
						accuracy: 0.9538532405669294,
						normalizedAccuracy: 0.9814588028495077,
						weightedScore: 1.640652807754932,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 32, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T08:30:57.317Z',
						updatedAt: '2024-06-25T08:34:25.856Z',
					},
					{
						id: 'clxst047x00f984785x7brolb',
						score: 990359,
						accuracy: 0.974077297964523,
						normalizedAccuracy: 0.9891631018099118,
						weightedScore: 1.6997291371082162,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 52, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T09:57:10.365Z',
						updatedAt: '2024-06-24T09:59:45.200Z',
					},
					{
						id: 'clxvsqaa4008l90r9hqzagsn0',
						score: 501019,
						accuracy: 0.9881349413748558,
						normalizedAccuracy: 0.9949697350025419,
						weightedScore: 1.7272466264714326,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 53, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T12:12:50.188Z',
						updatedAt: '2024-06-26T12:15:46.008Z',
					},
					{
						id: 'clxvsyhrc008v90r95ox3e0cb',
						score: 654650,
						accuracy: 0.9575456174351848,
						normalizedAccuracy: 0.9767440472549263,
						weightedScore: 2.159919630897455,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 49, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T12:19:13.128Z',
						updatedAt: '2024-06-26T12:20:46.044Z',
					},
				],
			},
			{
				player: {
					id: 'clwbtc7nn000z13xtd1cae8lr',
					teamId: 'clvuy5ipr003id63sbx4ro27x',
					user: {
						id: 'clgj6jg8o000so14m4m10e6hn',
						discordId: '705156508337438810',
						discordAvatar: 'https://cdn.discordapp.com/avatars/705156508337438810/2faf8c78c437c8a7bcf1e3fee138b4ff.png',
						discordUsername: 'blobby56789',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199080950125',
								type: 'STEAM',
								name: 'charlie1epicdude',
								avatar: 'https://cdn.scoresaber.com/avatars/76561199080950125.jpg',
							},
						],
						bio: 'hi ausbiscuit 🤑🤑\n',
						controllers: 'Quest 2 Touch',
						countryCode: 'AU',
						motto: null,
						pronouns: 'sig/ma',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9694084710123005,
				averageNormalizedAccuracy: 0.9880982400233346,
				averageWeightedScore: 1.7364049979377363,
				scores: [
					{
						id: 'clxvw2yqx00bv90r96wt4eelp',
						score: 1804399,
						accuracy: 0.9608932627201006,
						normalizedAccuracy: 0.9887025709897497,
						weightedScore: 1.390359347192245,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 15, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T13:46:40.618Z',
						updatedAt: '2024-06-26T13:50:05.793Z',
					},
					{
						id: 'clxvza3rd00gp90r9s8kut5s7',
						score: 1970700,
						accuracy: 0.9661454439381201,
						normalizedAccuracy: 0.989257070801129,
						weightedScore: 1.4490913669366685,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 17, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T15:16:12.554Z',
						updatedAt: '2024-06-26T15:19:32.753Z',
					},
					{
						id: 'clxvysudp00g790r96vrfadsh',
						score: 2093262,
						accuracy: 0.9710471198116599,
						normalizedAccuracy: 0.9902927502896468,
						weightedScore: 1.4897837399150236,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 23, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T15:02:47.245Z',
						updatedAt: '2024-06-26T15:07:34.896Z',
					},
					{
						id: 'clxfcvtnd003m1320dfefbbb5',
						score: 992099,
						accuracy: 0.9757886920130027,
						normalizedAccuracy: 0.9909010006901656,
						weightedScore: 1.5875145105120598,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 32, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-15T00:04:55.897Z',
						updatedAt: '2024-06-15T00:07:29.704Z',
					},
					{
						id: 'clxhtwatk006u1320bxmja7jd',
						score: 1016561,
						accuracy: 0.9666113580716477,
						normalizedAccuracy: 0.9860448829620893,
						weightedScore: 1.6941187822646788,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 53, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-16T17:36:43.977Z',
						updatedAt: '2024-06-16T17:39:01.953Z',
					},
					{
						id: 'clxvvpznv00bb90r9tvqbx10a',
						score: 659069,
						accuracy: 0.9640092149047428,
						normalizedAccuracy: 0.9833372374249706,
						weightedScore: 1.831076052984075,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 25, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T13:36:35.275Z',
						updatedAt: '2024-06-26T13:38:07.568Z',
					},
					{
						id: 'clx7irjbr001g7piol143ukvp',
						score: 497586,
						accuracy: 0.9813642056268305,
						normalizedAccuracy: 0.9881521670055923,
						weightedScore: 2.712891185759403,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 115, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-09T12:27:24.183Z',
						updatedAt: '2024-06-09T12:30:18.901Z',
					},
				],
			},
			{
				player: {
					id: 'clwbtdmh4001313xtghvbh7q1',
					teamId: 'clvuy5ipr003id63sbx4ro27x',
					user: {
						id: 'clgiuumiv000qo14mabyp8137',
						discordId: '892301257887326310',
						discordAvatar: 'https://cdn.discordapp.com/avatars/892301257887326310/1b3825a68334d4c90809eef5ea7d8589.png',
						discordUsername: 'Rotech',
						discordDiscriminator: '0',
						twitchUser: 'itsrotech',
						playableAccounts: [
							{
								id: '76561198320958554',
								type: 'STEAM',
								name: 'Rotech',
								avatar: 'https://avatars.akamai.steamstatic.com/68373605cf37040a5d95c24bc8ccde32fca98525_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'AU',
						motto: "wait i'm goated",
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9667893777398735,
				averageNormalizedAccuracy: 0.9854083818952774,
				averageWeightedScore: 1.7961853343910297,
				scores: [
					{
						id: 'clxvqnq4e007h90r90ss3evnj',
						score: 1793566,
						accuracy: 0.955124385262816,
						normalizedAccuracy: 0.9827667358715014,
						weightedScore: 1.5954598811011398,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 30, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T11:14:51.518Z',
						updatedAt: '2024-06-26T11:18:21.382Z',
					},
					{
						id: 'clxu9btqh0050qiy3nqj2ga3n',
						score: 1017488,
						accuracy: 0.9674928090902608,
						normalizedAccuracy: 0.9869440553742769,
						weightedScore: 1.6493945095768805,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 45, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T10:21:56.681Z',
						updatedAt: '2024-06-25T10:24:18.818Z',
					},
					{
						id: 'clxsqront00ei8478vk2mqlow',
						score: 2086024,
						accuracy: 0.9676894708154058,
						normalizedAccuracy: 0.986868554500206,
						weightedScore: 1.662553110230582,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 42, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T08:54:37.722Z',
						updatedAt: '2024-06-24T08:59:33.334Z',
					},
					{
						id: 'clx8st1gn00547pio8pomams7',
						score: 989375,
						accuracy: 0.9731094751233138,
						normalizedAccuracy: 0.9881802900293545,
						weightedScore: 1.7631884431832838,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 57, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-10T09:56:16.679Z',
						updatedAt: '2024-06-10T09:58:53.648Z',
					},
					{
						id: 'clxsrqdk100eu8478kfxd3aap',
						score: 1952384,
						accuracy: 0.9571659341440516,
						normalizedAccuracy: 0.9800627578621767,
						weightedScore: 1.8334452511856298,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 45, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T09:21:36.289Z',
						updatedAt: '2024-06-24T09:25:02.007Z',
					},
					{
						id: 'clx8tqnp700567pioeu9bnxn9',
						score: 657292,
						accuracy: 0.9614100266939701,
						normalizedAccuracy: 0.9806859364672497,
						weightedScore: 1.9633129930049114,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 32, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-10T10:22:25.148Z',
						updatedAt: '2024-06-10T10:23:58.626Z',
					},
					{
						id: 'clx8sfq1100527pio2oee5d6m',
						score: 499700,
						accuracy: 0.9855335430492964,
						normalizedAccuracy: 0.9923503431621759,
						weightedScore: 2.1059431524547803,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 80, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-10T09:45:55.333Z',
						updatedAt: '2024-06-10T09:48:51.546Z',
					},
				],
			},
			{
				player: {
					id: 'clwc3rmr9001513xtx8ysuifs',
					teamId: 'clvuy5ipr003id63sbx4ro27x',
					user: {
						id: 'clk61bdju0004mr2z79go8mhf',
						discordId: '532063399069351947',
						discordAvatar: 'https://cdn.discordapp.com/avatars/532063399069351947/a8c61d0e84785e21d63b1d9457784787.png',
						discordUsername: 'Speecil',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199077754911',
								type: 'STEAM',
								name: 'Speecil',
								avatar: 'https://cdn.assets.beatleader.xyz/76561199077754911R24.png',
							},
						],
						bio: 'fortnite',
						controllers: 'Quest 2 Touch',
						countryCode: 'AU',
						motto: 'Modder, mediocre player',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9569621605022914,
				averageNormalizedAccuracy: 0.9753637189355508,
				averageWeightedScore: 2.238949394742008,
				scores: [
					{
						id: 'clxvmf3z0005190r9ktyar03k',
						score: 500899,
						accuracy: 0.9878982713224925,
						normalizedAccuracy: 0.994731427935943,
						weightedScore: 1.7616996841803043,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 57, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T09:16:11.100Z',
						updatedAt: '2024-06-26T09:19:07.080Z',
					},
					{
						id: 'clxpmllam00wp1320l2blmk6u',
						score: 986080,
						accuracy: 0.9698686455889802,
						normalizedAccuracy: 0.9848892688739315,
						weightedScore: 1.9756868309041662,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 82, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-22T04:34:36.431Z',
						updatedAt: '2024-06-22T04:37:12.192Z',
					},
					{
						id: 'clxvtj7rm009n90r9nxlt17yl',
						score: 1010234,
						accuracy: 0.9605952409251908,
						normalizedAccuracy: 0.9799078130031776,
						weightedScore: 1.999372798764896,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 83, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T12:35:19.955Z',
						updatedAt: '2024-06-26T12:37:39.288Z',
					},
					{
						id: 'clxson05300ea8478j6wsyd7a',
						score: 1770737,
						accuracy: 0.9429673001089021,
						normalizedAccuracy: 0.9702578112971002,
						weightedScore: 2.0276799575902156,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 56, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T07:55:00.087Z',
						updatedAt: '2024-06-24T07:58:32.428Z',
					},
					{
						id: 'clxvnozyq005j90r9doq4bxxk',
						score: 1941675,
						accuracy: 0.9519157938085702,
						normalizedAccuracy: 0.9746870264108095,
						weightedScore: 2.0581693037310616,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 59, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T09:51:52.082Z',
						updatedAt: '2024-06-26T09:55:16.183Z',
					},
					{
						id: 'clxsnvhd200e88478ywvwwyty',
						score: 2065640,
						accuracy: 0.9582334999478122,
						normalizedAccuracy: 0.9772251713871967,
						weightedScore: 2.149114431660858,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 82, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T07:33:36.038Z',
						updatedAt: '2024-06-24T07:38:27.543Z',
					},
					{
						id: 'clxvo5n4y005z90r9m05excd4',
						score: 633942,
						accuracy: 0.9272563718140929,
						normalizedAccuracy: 0.9458475136406972,
						weightedScore: 3.7009227563625537,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 108, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T10:04:48.611Z',
						updatedAt: '2024-06-26T10:06:21.680Z',
					},
				],
			},
			{
				player: {
					id: 'clwbicf51000s13xt2swvtk3o',
					teamId: 'clvuy5ipr003id63sbx4ro27x',
					user: {
						id: 'clgit0qdi000ko14m663trzun',
						discordId: '692208192628719636',
						discordAvatar: 'https://cdn.discordapp.com/avatars/692208192628719636/c80fbac4df4fc4fcff2568eae4f6a494.png',
						discordUsername: 'SpirLV',
						discordDiscriminator: '0',
						twitchUser: 'spirlv',
						playableAccounts: [
							{
								id: '76561198364485489',
								type: 'STEAM',
								name: '#3 william god follower',
								avatar: 'https://avatars.akamai.steamstatic.com/dca68fe59cea061337af8f6f041906d44c9dc978_full.jpg',
							},
						],
						bio: 'William',
						controllers: 'Valve Index Knuckles',
						countryCode: 'AU',
						motto: 'William',
						pronouns: 'Will/iam',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9747517756929641,
				averageNormalizedAccuracy: 0.8502660406349104,
				averageWeightedScore: 4.816650210955629,
				scores: [
					{
						id: 'clxvqs38p007l90r9x6wl99u5',
						score: 1025003,
						accuracy: 0.9746385527848432,
						normalizedAccuracy: 0.9942334627934678,
						weightedScore: 1.2868239494379312,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 13, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T11:18:15.145Z',
						updatedAt: '2024-06-26T11:20:34.401Z',
					},
					{
						id: 'clxvqvawi007n90r9vb0q65k4',
						score: 996065,
						accuracy: 0.9796894901717788,
						normalizedAccuracy: 0.9948622115861923,
						weightedScore: 1.331742551270476,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 11, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T11:20:45.043Z',
						updatedAt: '2024-06-26T11:23:21.483Z',
					},
					{
						id: 'clxvql16w007f90r9p6niowa8',
						score: 2099143,
						accuracy: 0.9737752676075939,
						normalizedAccuracy: 0.9930749685043058,
						weightedScore: 1.3494056428128134,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 14, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T11:12:45.897Z',
						updatedAt: '2024-06-26T11:17:37.954Z',
					},
					{
						id: 'clxvqet2g007b90r92ucn6nff',
						score: 501645,
						accuracy: 0.9893695701480174,
						normalizedAccuracy: 0.9962129035332994,
						weightedScore: 1.5475165087568188,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 34, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T11:07:55.432Z',
						updatedAt: '2024-06-26T11:10:51.279Z',
					},
					{
						id: 'clxvq0lfx006z90r9yh01tjad',
						score: 662567,
						accuracy: 0.9691256810619081,
						normalizedAccuracy: 0.9885562868060104,
						weightedScore: 1.5707694597410329,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 9, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T10:56:52.365Z',
						updatedAt: '2024-06-26T10:58:25.681Z',
					},
					{
						id: 'clxvq2xc0007190r96jled0tk',
						score: 1962065,
						accuracy: 0.9619120923836442,
						normalizedAccuracy: 0.9849224512210977,
						weightedScore: 1.630293364670332,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 31, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T10:58:41.088Z',
						updatedAt: '2024-06-26T11:02:05.389Z',
					},
					{
						id: '3buyam',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 152, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwbtd0rj001113xt0z7pkcxc',
					teamId: 'clvuy5ipr003id63sbx4ro27x',
					user: {
						id: 'cliimy5rw0002p92zrwewuvfy',
						discordId: '537745391412838411',
						discordAvatar: 'https://cdn.discordapp.com/avatars/537745391412838411/08db8a5f76b9c87272f914b0a7cde4a2.png',
						discordUsername: 'Purp1e',
						discordDiscriminator: '0',
						twitchUser: 'purp1evr',
						playableAccounts: [
							{
								id: '76561198946533547',
								type: 'STEAM',
								name: '#1 william god follower',
								avatar: 'https://avatars.steamstatic.com/e331a12d9e7f2ae7ec42dd9d434e6bf997e2cb53_full.jpg',
							},
						],
						bio: 'william',
						controllers: 'Quest 3 Touch',
						countryCode: 'AU',
						motto: 'william',
						pronouns: 'will/iam',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8222009746524236,
				averageNormalizedAccuracy: 0.8373803413301548,
				averageWeightedScore: 5.513031517105304,
				scores: [
					{
						id: 'clxsxpkhz00gz8478tam6uifu',
						score: 1010350,
						accuracy: 0.9607055411605296,
						normalizedAccuracy: 0.9800203308023295,
						weightedScore: 1.9937762338978144,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 81, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T12:08:56.328Z',
						updatedAt: '2024-06-24T12:11:16.376Z',
					},
					{
						id: 'clxsyjmcp00hl84788htuvhoo',
						score: 1764801,
						accuracy: 0.939806213005935,
						normalizedAccuracy: 0.9670052388553093,
						weightedScore: 2.140065886629558,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 60, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T12:32:18.409Z',
						updatedAt: '2024-06-24T12:35:47.557Z',
					},
					{
						id: 'clxsxugph00h584780g0sv1mt',
						score: 983081,
						accuracy: 0.9669189497548477,
						normalizedAccuracy: 0.9818938902866434,
						weightedScore: 2.1690958338707595,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 98, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-24T12:12:44.694Z',
						updatedAt: '2024-06-24T12:15:21.488Z',
					},
					{
						id: 'clxsy60nx00hh84783hug4mrk',
						score: 2061495,
						accuracy: 0.9563106683521403,
						normalizedAccuracy: 0.9752642303057886,
						weightedScore: 2.248054614025875,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 89, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T12:21:43.774Z',
						updatedAt: '2024-06-24T12:26:36.249Z',
					},
					{
						id: 'clxsxgxv400gv8478fil1oiz2',
						score: 498663,
						accuracy: 0.9834883193467907,
						normalizedAccuracy: 0.9902909729283172,
						weightedScore: 2.4036749928222796,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 104, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-24T12:02:13.745Z',
						updatedAt: '2024-06-24T12:05:10.330Z',
					},
					{
						id: 'clxsy3opc00hd8478im50ewon',
						score: 648245,
						accuracy: 0.9481771309467217,
						normalizedAccuracy: 0.9671877261326963,
						weightedScore: 2.636553058490847,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 77, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T12:19:54.961Z',
						updatedAt: '2024-06-24T12:21:29.008Z',
					},
					{
						id: 'clxsyd72u00hj84780qwrpzc8',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 161, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-24T12:27:18.679Z',
						updatedAt: '2024-06-24T12:27:18.679Z',
					},
				],
			},
			{
				player: {
					id: 'clwbt7x5s000x13xtqto5q8v0',
					teamId: 'clvuy5ipr003id63sbx4ro27x',
					user: {
						id: 'clwbsp59u000v13xt1zp4hux8',
						discordId: '467191596249645058',
						discordAvatar: 'https://cdn.discordapp.com/avatars/467191596249645058/08a44d53793322d89996d5986377a8cd.png',
						discordUsername: 'enka',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{id: '76561198295957545', type: 'STEAM', name: 'Enka', avatar: 'https://cdn.assets.beatleader.xyz/76561198295957545R9.png'},
						],
						bio: null,
						controllers: null,
						countryCode: 'AU',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9732217911085949,
				averageNormalizedAccuracy: 0.4229768015061146,
				averageWeightedScore: 15.078262930791583,
				scores: [
					{
						id: 'clxsgvyr200di8478jg5t1mlz',
						score: 501455,
						accuracy: 0.988994842565109,
						normalizedAccuracy: 0.9958355840111845,
						weightedScore: 1.6020671834625324,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 43, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T04:18:01.263Z',
						updatedAt: '2024-06-24T04:20:56.601Z',
					},
					{
						id: 'clxskk34y00dw8478rrk7jesc',
						score: 987369,
						accuracy: 0.9711364541685723,
						normalizedAccuracy: 0.9861767123547631,
						weightedScore: 1.892557719592416,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 71, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T06:00:45.539Z',
						updatedAt: '2024-06-24T06:03:21.626Z',
					},
					{
						id: 'clxshq3v600do8478pv560ha1',
						score: 1009118,
						accuracy: 0.9595340765921031,
						normalizedAccuracy: 0.9788253141768547,
						weightedScore: 2.053215612486129,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 91, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-24T04:41:27.570Z',
						updatedAt: '2024-06-24T04:43:47.613Z',
					},
					{
						id: 'shlxr',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 160, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '0nzfio',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 166, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'jpw7e',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 170, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'nz68if',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 153, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clweydr39000g903xu7501z97',
					teamId: 'clvuy5ipr003id63sbx4ro27x',
					user: {
						id: 'clwdlasr8001713xtwr6d3ztc',
						discordId: '262841347818389504',
						discordAvatar: 'https://cdn.discordapp.com/avatars/262841347818389504/bd70f042c265b6aae7c2739f9aa0cc8f.png',
						discordUsername: 'Strydr',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198202320173',
								type: 'STEAM',
								name: 'Strydr',
								avatar: 'https://avatars.steamstatic.com/14582e0ff662b4b38779dda25ebc00e91e2ae72b_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'AU',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.653497153066385,
				averageNormalizedAccuracy: 0.283196777880263,
				averageWeightedScore: 18.352212387114605,
				scores: [
					{
						id: 'clxe4cvpg001n1320sxiffncn',
						score: 501490,
						accuracy: 0.9890638713303815,
						normalizedAccuracy: 0.9959050902389426,
						weightedScore: 1.5920183749641115,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 41, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-14T03:18:28.996Z',
						updatedAt: '2024-06-14T03:21:26.894Z',
					},
					{
						id: 'clxu9ypk50056qiy3ojqjqfl3',
						score: 987665,
						accuracy: 0.9714275878687735,
						normalizedAccuracy: 0.9864723549228982,
						weightedScore: 1.8734683348381271,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 69, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T10:39:44.358Z',
						updatedAt: '2024-06-25T10:42:21.613Z',
					},
					{
						id: 'clxvnp0xz005l90r9s1r2p4t7',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 167, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-26T09:51:53.352Z',
						updatedAt: '2024-06-26T09:51:53.352Z',
					},
					{
						id: '08u4bi',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 171, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'hpo7b',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 176, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 't41yts',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 160, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'j52gqo',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 177, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
				],
			},
		],
		captain: {
			id: 'clvuy5ipn003gd63szvkblym9',
			user: {
				id: 'clk3yvgik0001ng30jxm5rzc4',
				discordId: '300150025558687745',
				discordAvatar: 'https://cdn.discordapp.com/avatars/300150025558687745/ae842ee52412ef2d9f7f7af9b0085e81.png',
				discordUsername: 'Grashnak',
				discordDiscriminator: '0',
				twitchUser: null,
				playableAccounts: [
					{
						id: '76561197977946759',
						type: 'STEAM',
						name: 'Grashnak',
						avatar: 'https://avatars.steamstatic.com/7c8deb686b57d1b236b43840a77fc389d174acba_full.jpg',
					},
				],
				bio: null,
				controllers: null,
				countryCode: 'AU',
				motto: null,
				pronouns: null,
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9691864238402145,
		averageNormalizedAccuracy: 0.9878462383798686,
		averageWeightedScore: 1.6257847898490427,
	},
	{
		id: 'clv14mkvm000vykbv98ftumeo',
		name: 'Finland',
		image: 'https://cdn.cube.community/1713195936098-finland.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clv14mkvi000tykbvt7a5125q',
					teamId: 'clv14mkvm000vykbv98ftumeo',
					user: {
						id: 'clgnbdkno001so14mdt9huz5w',
						discordId: '386142373622841344',
						discordAvatar: 'https://cdn.discordapp.com/avatars/386142373622841344/139d5ed9b32fb951298ffc2195cd2c74.png',
						discordUsername: 'Tseska',
						discordDiscriminator: '0',
						twitchUser: 'Tseska_',
						playableAccounts: [
							{
								id: '76561198362923485',
								type: 'STEAM',
								name: 'Tseska',
								avatar: 'https://avatars.akamai.steamstatic.com/4e754e272acda0035e103168fb6f684b0b63dca5_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest/Rift S Touch',
						countryCode: 'FI',
						motto: 'TseskaHappySabering',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9727486609174975,
				averageNormalizedAccuracy: 0.9914881308973414,
				averageWeightedScore: 1.4422920271712882,
				scores: [
					{
						id: 'clxq4qgdv00yt13206owrdebo',
						score: 1808107,
						accuracy: 0.9628678771031534,
						normalizedAccuracy: 0.9907343328856663,
						weightedScore: 1.3201560074217122,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 12, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-22T13:02:16.435Z',
						updatedAt: '2024-06-22T13:05:41.924Z',
					},
					{
						id: 'clxvt5kto009990r96ndpty5e',
						score: 1023557,
						accuracy: 0.9732636032994985,
						normalizedAccuracy: 0.9928308702281783,
						weightedScore: 1.3565880252810345,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 17, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T12:24:43.692Z',
						updatedAt: '2024-06-26T12:27:01.962Z',
					},
					{
						id: 'clxalssck008c7pio9h142vcz',
						score: 994814,
						accuracy: 0.9784590568645097,
						normalizedAccuracy: 0.9936127222188375,
						weightedScore: 1.4124209983232296,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 20, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-11T16:15:39.908Z',
						updatedAt: '2024-06-11T16:18:13.756Z',
					},
					{
						id: 'clxnex2k300nx13208bhxab25',
						score: 502083,
						accuracy: 0.9902334158391433,
						normalizedAccuracy: 0.9970827243263853,
						weightedScore: 1.4217628481194373,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 22, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-20T15:24:02.739Z',
						updatedAt: '2024-06-20T15:26:57.364Z',
					},
					{
						id: 'clxq3ksvi00yl1320rp275qmz',
						score: 2095929,
						accuracy: 0.97228431929674,
						normalizedAccuracy: 0.9915544704016168,
						weightedScore: 1.4261230725163507,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 16, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-22T12:29:53.071Z',
						updatedAt: '2024-06-22T12:34:41.139Z',
					},
					{
						id: 'clxvtisis009l90r9f5murni5',
						score: 1965506,
						accuracy: 0.9635990596909924,
						normalizedAccuracy: 0.9866497732795676,
						weightedScore: 1.5580853653418392,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 28, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T12:35:00.196Z',
						updatedAt: '2024-06-26T12:38:20.710Z',
					},
					{
						id: 'clxvt96u9009f90r94yd0iz7x',
						score: 662162,
						accuracy: 0.9685332943284455,
						normalizedAccuracy: 0.9879520229411387,
						weightedScore: 1.600907873195416,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 13, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T12:27:32.193Z',
						updatedAt: '2024-06-26T12:29:04.616Z',
					},
				],
			},
			{
				player: {
					id: 'clwhrbnux000o903xk591jm5t',
					teamId: 'clv14mkvm000vykbv98ftumeo',
					user: {
						id: 'clieucwt10008p92zs198pyn1',
						discordId: '572826457060147210',
						discordAvatar: 'https://cdn.discordapp.com/avatars/572826457060147210/e281d2c27c8a28debecf4d71b24df20f.png',
						discordUsername: 'Finnishguy123',
						discordDiscriminator: '0',
						twitchUser: 'finnishguy123_',
						playableAccounts: [
							{
								id: '76561198941171879',
								type: 'STEAM',
								name: 'ACC | Finnishguy123',
								avatar: 'https://avatars.steamstatic.com/7c5a471b412bfb9aa3811d049342e76772be30a6_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'FI',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.969038534919571,
				averageNormalizedAccuracy: 0.9876978921001796,
				averageWeightedScore: 1.652592571186259,
				scores: [
					{
						id: 'clxtjbn3g000p96lnconcs6yn',
						score: 1970309,
						accuracy: 0.9659537542498977,
						normalizedAccuracy: 0.9890607956122707,
						weightedScore: 1.4572963444831493,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 20, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T22:13:58.061Z',
						updatedAt: '2024-06-24T22:17:19.152Z',
					},
					{
						id: 'clxuuv1cn00cyqiy3jgt7d3in',
						score: 1019625,
						accuracy: 0.96952480566715,
						normalizedAccuracy: 0.9890169048293416,
						weightedScore: 1.5462922757755584,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 34, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T20:24:44.952Z',
						updatedAt: '2024-06-25T20:27:03.305Z',
					},
					{
						id: 'clxq24hi200y51320svc5m0zn',
						score: 2089774,
						accuracy: 0.9694290651420089,
						normalizedAccuracy: 0.9886426266486452,
						weightedScore: 1.5730414856542703,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 31, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-22T11:49:12.218Z',
						updatedAt: '2024-06-22T11:54:00.798Z',
					},
					{
						id: 'clxthz3n2000196lnjh4x4ahj',
						score: 991656,
						accuracy: 0.975352975022499,
						normalizedAccuracy: 0.9904585356304229,
						weightedScore: 1.6160840964787824,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 39, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T21:36:13.359Z',
						updatedAt: '2024-06-24T21:38:47.627Z',
					},
					{
						id: 'clxq2vi4600yh13203b8wipwm',
						score: 1787123,
						accuracy: 0.9516933063874089,
						normalizedAccuracy: 0.979236357798311,
						weightedScore: 1.7174448104812754,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 39, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-22T12:10:12.726Z',
						updatedAt: '2024-06-22T12:13:38.622Z',
					},
					{
						id: 'clxti7501000596lneqsv42kf',
						score: 500711,
						accuracy: 0.9875274882404568,
						normalizedAccuracy: 0.9943580801982714,
						weightedScore: 1.8156761412575366,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 62, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T21:42:28.370Z',
						updatedAt: '2024-06-24T21:45:23.043Z',
					},
					{
						id: 'clxq1kgal00xt132079vitoox',
						score: 658918,
						accuracy: 0.9637883497275752,
						normalizedAccuracy: 0.9831119439839937,
						weightedScore: 1.8423128441732401,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 26, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-22T11:33:37.534Z',
						updatedAt: '2024-06-22T11:35:10.168Z',
					},
				],
			},
			{
				player: {
					id: 'clwhrbysn000s903x56di6ash',
					teamId: 'clv14mkvm000vykbv98ftumeo',
					user: {
						id: 'clieudt31000ap92zpuo6jwq1',
						discordId: '210767873826619392',
						discordAvatar: 'https://cdn.discordapp.com/avatars/210767873826619392/a93950468e2dc8c9d51632ce759b1b2c.png',
						discordUsername: 'General Dumbass',
						discordDiscriminator: '0',
						twitchUser: 'generaldum',
						playableAccounts: [
							{
								id: '76561198148209170',
								type: 'STEAM',
								name: 'General Dumbass',
								avatar: 'https://avatars.steamstatic.com/afec1731b2043e6769ee7b673c9f13d507c8278c_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'FI',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9608799177334062,
				averageNormalizedAccuracy: 0.9793267781412027,
				averageWeightedScore: 1.945585916386903,
				scores: [
					{
						id: 'clxrp9t99002r84783qc38enx',
						score: 502291,
						accuracy: 0.9906436439299062,
						normalizedAccuracy: 0.9974957899084901,
						weightedScore: 1.362044214757393,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 20, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T15:24:58.077Z',
						updatedAt: '2024-06-23T15:27:52.745Z',
					},
					{
						id: 'clxp41qc100ux1320efv6oktt',
						score: 1022176,
						accuracy: 0.9719504599805073,
						normalizedAccuracy: 0.991491326429655,
						weightedScore: 1.4232160949486177,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 22, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-21T19:55:16.753Z',
						updatedAt: '2024-06-21T19:57:34.816Z',
					},
					{
						id: 'clxp3vae800ut1320v5m7rxlc',
						score: 990576,
						accuracy: 0.9742907304406839,
						normalizedAccuracy: 0.9893798397737136,
						weightedScore: 1.6857345543660518,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 49, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-21T19:50:16.160Z',
						updatedAt: '2024-06-21T19:52:50.101Z',
					},
					{
						id: 'clxrpt7ku003984789eng317m',
						score: 2079865,
						accuracy: 0.964832361093393,
						normalizedAccuracy: 0.9839548184036094,
						weightedScore: 1.8095670024347161,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 54, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T15:40:03.102Z',
						updatedAt: '2024-06-23T15:44:51.244Z',
					},
					{
						id: 'clxp45amw00uz1320ypztkjeo',
						score: 656857,
						accuracy: 0.9607737594617326,
						normalizedAccuracy: 0.9800369123160911,
						weightedScore: 1.9956838815299895,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 36, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-21T19:58:03.032Z',
						updatedAt: '2024-06-21T19:59:35.475Z',
					},
					{
						id: 'clxp4nye100v91320m975ehp8',
						score: 1750254,
						accuracy: 0.932059525996693,
						normalizedAccuracy: 0.9590343542005362,
						weightedScore: 2.4154833579461545,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 70, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-21T20:12:33.625Z',
						updatedAt: '2024-06-21T20:15:59.241Z',
					},
					{
						id: 'clxp4yjlp00vd1320kmuw77pb',
						score: 1900254,
						accuracy: 0.9316089432309272,
						normalizedAccuracy: 0.9538944059563245,
						weightedScore: 2.9273723087253956,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 88, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-21T20:20:47.677Z',
						updatedAt: '2024-06-21T20:24:08.182Z',
					},
				],
			},
			{
				player: {
					id: 'clwhrbia9000m903x3i2diyvv',
					teamId: 'clv14mkvm000vykbv98ftumeo',
					user: {
						id: 'cli519u5b000mjq2zk7yq5ynk',
						discordId: '415046518970646530',
						discordAvatar: 'https://cdn.discordapp.com/avatars/415046518970646530/b35e3853f6852c35bdce5d4bec005c05.png',
						discordUsername: 'scoop',
						discordDiscriminator: '0',
						twitchUser: 'scooooop_',
						playableAccounts: [
							{
								id: '76561198844037204',
								type: 'STEAM',
								name: 'scoop',
								avatar: 'https://avatars.steamstatic.com/0d33f92010b0c489c0031ff106677c19b0257ace_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: null,
						motto: 'waow...',
						pronouns: 'She/her',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9634317472239792,
				averageNormalizedAccuracy: 0.9819762162321688,
				averageWeightedScore: 1.9553399172879438,
				scores: [
					{
						id: 'clxkoc46g00dj1320wnosthv5',
						score: 2081940,
						accuracy: 0.9657949366207801,
						normalizedAccuracy: 0.9849364716590792,
						weightedScore: 1.7600372368358237,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 52, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-18T17:24:22.697Z',
						updatedAt: '2024-06-18T17:29:13.446Z',
					},
					{
						id: 'clxuarn7c005cqiy3pxxb31ee',
						score: 1954226,
						accuracy: 0.9580689837750122,
						normalizedAccuracy: 0.980987409774906,
						weightedScore: 1.7947916229487557,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 43, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T11:02:14.328Z',
						updatedAt: '2024-06-25T11:05:36.860Z',
					},
					{
						id: 'clx96f3ow00637pioch316tul',
						score: 1013910,
						accuracy: 0.9640906173485154,
						normalizedAccuracy: 0.9834734632590586,
						weightedScore: 1.822019587977035,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 65, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-10T16:17:21.008Z',
						updatedAt: '2024-06-10T16:19:40.197Z',
					},
					{
						id: 'clxuaz97q005gqiy3oe7co4y7',
						score: 1781120,
						accuracy: 0.9484965398983404,
						normalizedAccuracy: 0.9759470733697275,
						weightedScore: 1.8310992464690068,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 42, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T11:08:09.447Z',
						updatedAt: '2024-06-25T11:11:36.747Z',
					},
					{
						id: 'clxvp958k006n90r9ximjd4p6',
						score: 988160,
						accuracy: 0.971914449968772,
						normalizedAccuracy: 0.9869667571905566,
						weightedScore: 1.841545208306462,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 67, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T10:35:31.653Z',
						updatedAt: '2024-06-26T10:38:06.836Z',
					},
					{
						id: 'clx6atyt100047piouwwt1su0',
						score: 499730,
						accuracy: 0.9855927105623872,
						normalizedAccuracy: 0.9924099199288257,
						weightedScore: 2.0973298880275624,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 79, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-08T15:57:34.454Z',
						updatedAt: '2024-06-08T16:00:29.880Z',
					},
					{
						id: 'clxqjofpu010j1320eznls47m',
						score: 649535,
						accuracy: 0.9500639923940469,
						normalizedAccuracy: 0.9691124184430283,
						weightedScore: 2.54055663045096,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 69, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-22T20:00:36.498Z',
						updatedAt: '2024-06-22T20:02:10.149Z',
					},
				],
			},
			{
				player: {
					id: 'clwhrctv6000y903x2q7auzs9',
					teamId: 'clv14mkvm000vykbv98ftumeo',
					user: {
						id: 'clgiedoc90002rr4msbbz5sew',
						discordId: '213140015184609280',
						discordAvatar: 'https://cdn.discordapp.com/avatars/213140015184609280/d2d378e25159fa56f018afad089297d0.png',
						discordUsername: 'riisi',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{id: '76561198060902645', type: 'STEAM', name: 'riisi', avatar: 'https://cdn.assets.beatleader.xyz/76561198060902645R45.gif'},
						],
						bio: null,
						controllers: 'Valve Index Knuckles',
						countryCode: 'FI',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8048059849028542,
				averageNormalizedAccuracy: 0.8191704929758779,
				averageWeightedScore: 8.203460292463253,
				scores: [
					{
						id: 'clxv25x6u001390r9nug09wte',
						score: 1014478,
						accuracy: 0.9646307081560368,
						normalizedAccuracy: 0.9840244124824918,
						weightedScore: 1.7946157186278766,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 63, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T23:49:10.086Z',
						updatedAt: '2024-06-25T23:51:29.301Z',
					},
					{
						id: 'clxp7fv6i00w11320g9z8pvjz',
						score: 988776,
						accuracy: 0.9725203228043257,
						normalizedAccuracy: 0.9875820133458648,
						weightedScore: 1.8018186508448342,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 61, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-21T21:30:15.067Z',
						updatedAt: '2024-06-21T21:32:50.028Z',
					},
					{
						id: 'clxuy57ww00efqiy3411qcjet',
						score: 499296,
						accuracy: 0.9847367538730067,
						normalizedAccuracy: 0.9915480427046264,
						weightedScore: 2.2219351134079814,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 94, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-25T21:56:38.864Z',
						updatedAt: '2024-06-25T21:59:34.506Z',
					},
					{
						id: 'clxt7g1om00lb8478nd5wlr5w',
						score: 2062269,
						accuracy: 0.9566697206211512,
						normalizedAccuracy: 0.9756303987972264,
						weightedScore: 2.2295794147133243,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 86, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-24T16:41:28.199Z',
						updatedAt: '2024-06-24T16:46:17.685Z',
					},
					{
						id: 'clxp7t9c900w31320j5sv9wm7',
						score: 643633,
						accuracy: 0.9414312356017114,
						normalizedAccuracy: 0.9603065781208736,
						weightedScore: 2.979758892692365,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 91, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-21T21:40:39.945Z',
						updatedAt: '2024-06-21T21:42:12.436Z',
					},
					{
						id: 'clxt8g6k800lp8478hgd5j1j5',
						score: 742611,
						accuracy: 0.3954612625709927,
						normalizedAccuracy: 0.40690634662581227,
						weightedScore: 21.493127342951265,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 103, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-24T17:09:34.137Z',
						updatedAt: '2024-06-24T17:13:01.333Z',
					},
					{
						id: 'clxv1z6o9000x90r9kcsipidh',
						score: 853009,
						accuracy: 0.41819189069275475,
						normalizedAccuracy: 0.4281956587542499,
						weightedScore: 24.90338691400512,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 108, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T23:43:55.785Z',
						updatedAt: '2024-06-25T23:47:17.401Z',
					},
				],
			},
			{
				player: {
					id: 'clwhrbtq7000q903x3z9ky2vx',
					teamId: 'clv14mkvm000vykbv98ftumeo',
					user: {
						id: 'clhqcs33x005mo14maf9uwk2w',
						discordId: '376097968530522112',
						discordAvatar: 'https://cdn.discordapp.com/avatars/376097968530522112/7c50b5995c7e7cdd2307213d895e18b3.png',
						discordUsername: 'Makko',
						discordDiscriminator: '0',
						twitchUser: 'makkoo_',
						playableAccounts: [
							{
								id: '76561198438681935',
								type: 'STEAM',
								name: 'Makko',
								avatar: 'https://avatars.steamstatic.com/f97ffb8b2f50dba07ffb2c843672daf06582d88a_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'FI',
						motto: '96ing 400bpm maps and low midspeed since 2021',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9645278891734664,
				averageNormalizedAccuracy: 0.7026320146983432,
				averageWeightedScore: 8.499760710521189,
				scores: [
					{
						id: 'clxp64gg000vt1320lunere1v',
						score: 1789387,
						accuracy: 0.9528989501207508,
						normalizedAccuracy: 0.980476894187835,
						weightedScore: 1.6745806353894506,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 37, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-21T20:53:23.136Z',
						updatedAt: '2024-06-21T20:56:50.310Z',
					},
					{
						id: 'clxp5hc0600vr1320vt1y1wz0',
						score: 1955016,
						accuracy: 0.9584562851911136,
						normalizedAccuracy: 0.9813839760132644,
						weightedScore: 1.7782137910773492,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 41, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-21T20:35:24.295Z',
						updatedAt: '2024-06-21T20:38:46.084Z',
					},
					{
						id: 'clxp56c8t00vj1320gx3gwia4',
						score: 1013608,
						accuracy: 0.9638034563909953,
						normalizedAccuracy: 0.983180528988853,
						weightedScore: 1.8365899551309885,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 66, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-21T20:26:51.390Z',
						updatedAt: '2024-06-21T20:29:10.411Z',
					},
					{
						id: 'clxp5aiwo00vn13201aqm6yz2',
						score: 2076966,
						accuracy: 0.9634875387059738,
						normalizedAccuracy: 0.9825833423613893,
						weightedScore: 1.8787654556738436,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 60, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-21T20:30:06.648Z',
						updatedAt: '2024-06-21T20:34:56.089Z',
					},
					{
						id: 'clxp51xpn00vf1320mzsxn1sx',
						score: 498919,
						accuracy: 0.9839932154584989,
						normalizedAccuracy: 0.9907993613370615,
						weightedScore: 2.330175136376687,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 100, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-21T20:23:25.931Z',
						updatedAt: '2024-06-21T20:26:21.517Z',
					},
					{
						id: 'qigeyo',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 172, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '8h07y9',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 177, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clwhrci32000w903xrunqgbaw',
					teamId: 'clv14mkvm000vykbv98ftumeo',
					user: {
						id: 'clhxsmqxg0004p42zi34i4fz7',
						discordId: '265185780442464258',
						discordAvatar: 'https://cdn.discordapp.com/avatars/265185780442464258/ea1bc8f1060558208e853f440ebc203f.png',
						discordUsername: 'Takanasi',
						discordDiscriminator: '0',
						twitchUser: 'takanas1',
						playableAccounts: [
							{
								id: '76561198093028672',
								type: 'STEAM',
								name: 'Takanasi',
								avatar: 'https://avatars.akamai.steamstatic.com/6ae3de7e558bf53f9801ece64352544fb58ccb21_full.jpg',
							},
						],
						bio: 'Apparently i like bad maps even tho thats not true..... I think...',
						controllers: 'Valve Index Knuckles',
						countryCode: 'FI',
						motto: 'Speed is overrated',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.790523289589909,
				averageNormalizedAccuracy: 0.8045639206950603,
				averageWeightedScore: 8.812917694567624,
				scores: [
					{
						id: 'clxrxbi05006y8478gl1zs4c0',
						score: 501446,
						accuracy: 0.9889770923111817,
						normalizedAccuracy: 0.9958177109811897,
						weightedScore: 1.6046511627906976,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 44, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T19:10:13.734Z',
						updatedAt: '2024-06-23T19:13:12.842Z',
					},
					{
						id: 'clxp20dbh00u51320779lim6s',
						score: 986392,
						accuracy: 0.970175516245949,
						normalizedAccuracy: 0.9852008921214251,
						weightedScore: 1.9555655875145106,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 80, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-21T18:58:13.997Z',
						updatedAt: '2024-06-21T19:00:52.346Z',
					},
					{
						id: 'clxp2xq3u00ud1320sms3e5e6',
						score: 1007667,
						accuracy: 0.9581543727862695,
						normalizedAccuracy: 0.9774178717064295,
						weightedScore: 2.123220919573503,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 99, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-21T19:24:10.218Z',
						updatedAt: '2024-06-21T19:26:32.861Z',
					},
					{
						id: 'clxp34dnu00uh1320xvywm1co',
						score: 644334,
						accuracy: 0.9424565765897539,
						normalizedAccuracy: 0.9613524768104417,
						weightedScore: 2.9275933918737906,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 89, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-21T19:29:20.682Z',
						updatedAt: '2024-06-21T19:30:57.651Z',
					},
					{
						id: 'clxp2bei200u71320m0t66ssa',
						score: 1982749,
						accuracy: 0.9197810430607583,
						normalizedAccuracy: 0.9380106075321899,
						weightedScore: 4.127703251062204,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 128, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-21T19:06:48.747Z',
						updatedAt: '2024-06-21T19:11:41.665Z',
					},
					{
						id: 'clxp2mbyp00ub13201haat4nh',
						score: 744394,
						accuracy: 0.39641076026381444,
						normalizedAccuracy: 0.40788332382657255,
						weightedScore: 21.4593699117725,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 102, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-21T19:15:18.674Z',
						updatedAt: '2024-06-21T19:18:48.790Z',
					},
					{
						id: 'clxp3as4e00un1320yv0vue91',
						score: 729636,
						accuracy: 0.35770766587163655,
						normalizedAccuracy: 0.3662645618871734,
						weightedScore: 27.49231963738616,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 113, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-21T19:34:19.359Z',
						updatedAt: '2024-06-21T19:37:45.026Z',
					},
				],
			},
			{
				player: {
					id: 'clwhrc8sj000u903xspcrpz5p',
					teamId: 'clv14mkvm000vykbv98ftumeo',
					user: {
						id: 'cli7cm1c4000yjq2zok1v6ewp',
						discordId: '542001929165733898',
						discordAvatar: 'https://cdn.discordapp.com/avatars/542001929165733898/78f61f5b5fc1648aa419947ce67fa0fe.png',
						discordUsername: 'Tanhis',
						discordDiscriminator: '0',
						twitchUser: 'tanhis',
						playableAccounts: [
							{
								id: '76561198993934070',
								type: 'STEAM',
								name: 'Goobie snoobert 3.1415926535',
								avatar: 'https://avatars.steamstatic.com/447111d7bad9e4ffc4bf6b18a602d3e799235d57_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'FI',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.7783485963109612,
				averageNormalizedAccuracy: 0.5645931320993622,
				averageWeightedScore: 11.69060181201316,
				scores: [
					{
						id: 'clxuqinf200b6qiy3xjtk04c7',
						score: 501972,
						accuracy: 0.9900144960407072,
						normalizedAccuracy: 0.9968622902897813,
						weightedScore: 1.4536319265001436,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 27, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T18:23:08.559Z',
						updatedAt: '2024-06-25T18:26:03.215Z',
					},
					{
						id: 'clxs12q0700988478ugfnh00z',
						score: 991692,
						accuracy: 0.9753883831752261,
						normalizedAccuracy: 0.9904944921589798,
						weightedScore: 1.6137624145492067,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 38, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T20:55:22.663Z',
						updatedAt: '2024-06-23T20:57:56.614Z',
					},
					{
						id: 'clxvvlny600b190r9ukbacpjf',
						score: 1017330,
						accuracy: 0.9673425725628164,
						normalizedAccuracy: 0.9867907983719838,
						weightedScore: 1.6570174168958363,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 47, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T13:33:13.470Z',
						updatedAt: '2024-06-26T13:35:31.711Z',
					},
					{
						id: 'clxvwci5z00c590r98661c83u',
						score: 2067287,
						accuracy: 0.9589975297760562,
						normalizedAccuracy: 0.9780043438747912,
						weightedScore: 2.109800926146942,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 80, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T13:54:05.687Z',
						updatedAt: '2024-06-26T13:58:54.943Z',
					},
					{
						id: 'clxvx21fc00d790r9qa8ukhe2',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 169, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-26T14:13:57.048Z',
						updatedAt: '2024-06-26T14:13:57.048Z',
					},
					{
						id: 'xii9xp',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 173, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'jxy9ul',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 162, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
		],
		captain: {
			id: 'clv14mkvi000tykbvt7a5125q',
			user: {
				id: 'clgnbdkno001so14mdt9huz5w',
				discordId: '386142373622841344',
				discordAvatar: 'https://cdn.discordapp.com/avatars/386142373622841344/139d5ed9b32fb951298ffc2195cd2c74.png',
				discordUsername: 'Tseska',
				discordDiscriminator: '0',
				twitchUser: 'Tseska_',
				playableAccounts: [
					{
						id: '76561198362923485',
						type: 'STEAM',
						name: 'Tseska',
						avatar: 'https://avatars.akamai.steamstatic.com/4e754e272acda0035e103168fb6f684b0b63dca5_full.jpg',
					},
				],
				bio: null,
				controllers: 'Quest/Rift S Touch',
				countryCode: 'FI',
				motto: 'TseskaHappySabering',
				pronouns: 'He/him',
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9686777167719721,
		averageNormalizedAccuracy: 0.987325439388061,
		averageWeightedScore: 1.6368965671139037,
	},
	{
		id: 'cluzjmq10000nivs9rx3wfcsw',
		name: 'Netherlands',
		image: 'https://cdn.cube.community/1713100204570-netherlands.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwewxyj50004903xxvd6qmbd',
					teamId: 'cluzjmq10000nivs9rx3wfcsw',
					user: {
						id: 'clhzbl59v000ao42zhre1jwa8',
						discordId: '397816897963294740',
						discordAvatar: 'https://cdn.discordapp.com/avatars/397816897963294740/a_b69f71a7a208363862a332c4678c5c6f.png',
						discordUsername: 'yabje',
						discordDiscriminator: '0',
						twitchUser: 'yabje0_0',
						playableAccounts: [
							{
								id: '76561198848329343',
								type: 'STEAM',
								name: 'yabje',
								avatar: 'https://avatars.akamai.steamstatic.com/d359d65f962942bb319da36cfe33981ac71eaef4_full.jpg',
							},
						],
						bio: 'i have quite the skillset',
						controllers: 'Valve Index Knuckles',
						countryCode: 'NL',
						motto: 'Your motto...',
						pronouns: 'He/him',
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Content', name: 'Content Cubes', color: '#206694'},
						],
					},
				},
				averageAccuracy: 0.9706499973761348,
				averageNormalizedAccuracy: 0.9893407680669933,
				averageWeightedScore: 1.5430227293984522,
				scores: [
					{
						id: 'clxcalkl800ax7piovjspx0iy',
						score: 1022079,
						accuracy: 0.9718582261630256,
						normalizedAccuracy: 0.9913972382700195,
						weightedScore: 1.4278959810874705,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 24, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-12T20:37:39.836Z',
						updatedAt: '2024-06-12T20:39:58.243Z',
					},
					{
						id: 'clxozgf7000tb1320mb9j27w6',
						score: 994409,
						accuracy: 0.9780607151463291,
						normalizedAccuracy: 0.9932082112725714,
						weightedScore: 1.4385399200309557,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 21, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-21T17:46:44.077Z',
						updatedAt: '2024-06-21T17:49:18.294Z',
					},
					{
						id: 'clxoz7vit00t71320obpt1m88',
						score: 501820,
						accuracy: 0.9897147139743805,
						normalizedAccuracy: 0.9965604346720894,
						weightedScore: 1.4972724662647143,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 32, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-21T17:40:05.333Z',
						updatedAt: '2024-06-21T17:43:00.528Z',
					},
					{
						id: 'clxtbskfw00nn8478i1tlse3d',
						score: 1798122,
						accuracy: 0.9575505835177212,
						normalizedAccuracy: 0.9852631509733882,
						weightedScore: 1.5092014086107008,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 24, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T18:43:10.844Z',
						updatedAt: '2024-06-24T18:46:37.222Z',
					},
					{
						id: 'clxtb4gpi00n98478a9qlqllr',
						score: 2089221,
						accuracy: 0.9691725329653125,
						normalizedAccuracy: 0.9883810101424887,
						weightedScore: 1.586241466558457,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 34, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T18:24:26.262Z',
						updatedAt: '2024-06-24T18:29:15.470Z',
					},
					{
						id: 'clxtce10q00on84789glzk7gv',
						score: 1961111,
						accuracy: 0.9614443891545799,
						normalizedAccuracy: 0.9844435598395864,
						weightedScore: 1.6503126704998532,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 32, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T18:59:52.106Z',
						updatedAt: '2024-06-24T19:03:13.580Z',
					},
					{
						id: 'clxcaj5qz00av7piocnhulzg3',
						score: 660942,
						accuracy: 0.9667488207115954,
						normalizedAccuracy: 0.9861317712988092,
						weightedScore: 1.6916951927370145,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 18, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-12T20:35:47.292Z',
						updatedAt: '2024-06-12T20:37:20.475Z',
					},
				],
			},
			{
				player: {
					id: 'clwewzcet000c903xx3djxk7a',
					teamId: 'cluzjmq10000nivs9rx3wfcsw',
					user: {
						id: 'clgigq5ab0004t74nfxsqt98z',
						discordId: '371002487219683348',
						discordAvatar: 'https://cdn.discordapp.com/avatars/371002487219683348/33e3eba558152925678abcc27cac6bd2.png',
						discordUsername: 'Mr_bjo',
						discordDiscriminator: '0',
						twitchUser: 'mr_bjo',
						playableAccounts: [
							{
								id: '76561198305748823',
								type: 'STEAM',
								name: 'King Charles',
								avatar: 'https://avatars.steamstatic.com/05088a37380e6b7732f4149965121a8fdd6fa54a_full.jpg',
							},
						],
						bio: '!bsr 305f8',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'NL',
						motto: 'ja',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9669242795646108,
				averageNormalizedAccuracy: 0.9855339283822675,
				averageWeightedScore: 1.7360719737934482,
				scores: [
					{
						id: 'clxteeqwx00qf847826cbwlhy',
						score: 1017930,
						accuracy: 0.9679130910214657,
						normalizedAccuracy: 0.9873727869882865,
						weightedScore: 1.6280696675833455,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 43, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T19:56:24.898Z',
						updatedAt: '2024-06-24T19:58:43.113Z',
					},
					{
						id: 'clxtenck200qp84787dpcgp9p',
						score: 991342,
						accuracy: 0.9750441372459342,
						normalizedAccuracy: 0.9901449147980093,
						weightedScore: 1.6363343221978588,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 41, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T20:03:06.194Z',
						updatedAt: '2024-06-24T20:05:40.098Z',
					},
					{
						id: 'clxun817300akqiy3ius0yiym',
						score: 501244,
						accuracy: 0.9885786977230369,
						normalizedAccuracy: 0.9954165607524148,
						weightedScore: 1.6626471432672982,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 47, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T16:50:54.351Z',
						updatedAt: '2024-06-25T16:53:49.145Z',
					},
					{
						id: 'clxjcfocr009i1320uj6tw7ew',
						score: 1960079,
						accuracy: 0.9609384460388625,
						normalizedAccuracy: 0.9839255138168195,
						weightedScore: 1.6719687749192094,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 34, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-17T19:03:27.243Z',
						updatedAt: '2024-06-17T19:06:47.715Z',
					},
					{
						id: 'clxdasxi1000l13204z1pmnvr',
						score: 2084819,
						accuracy: 0.9671304811717908,
						normalizedAccuracy: 0.9862984859831743,
						weightedScore: 1.6913161789277702,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 44, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-13T13:31:09.337Z',
						updatedAt: '2024-06-13T13:35:57.617Z',
					},
					{
						id: 'clxepp2tt002e13201740d9c3',
						score: 1782930,
						accuracy: 0.949460415851233,
						normalizedAccuracy: 0.9769388449532251,
						weightedScore: 1.7968306259229807,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 41, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-14T13:15:50.033Z',
						updatedAt: '2024-06-14T13:19:15.557Z',
					},
					{
						id: 'clxmbhrrt00k51320pdg0a616',
						score: 655921,
						accuracy: 0.9594046878999525,
						normalizedAccuracy: 0.9786403913839433,
						weightedScore: 2.065337103735675,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 41, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-19T21:00:23.897Z',
						updatedAt: '2024-06-19T21:01:56.321Z',
					},
				],
			},
			{
				player: {
					id: 'clwewz5ax000a903xsyawqxkh',
					teamId: 'cluzjmq10000nivs9rx3wfcsw',
					user: {
						id: 'clhzboefa000io42z5v6eih50',
						discordId: '579018678280912906',
						discordAvatar: 'https://cdn.discordapp.com/avatars/579018678280912906/291bb5f317631b5423730dfefd4c209b.png',
						discordUsername: 'BigOlDumplin',
						discordDiscriminator: '0',
						twitchUser: 'BigOlDumplin',
						playableAccounts: [
							{
								id: '76561198326089365',
								type: 'STEAM',
								name: 'BigOlDumplin',
								avatar: 'https://avatars.akamai.steamstatic.com/0c57c5b4f8448b7edf2249a83728209966b286aa_full.jpg',
							},
						],
						bio: 'Drunk driving is cute',
						controllers: 'HTC Vive Wands',
						countryCode: 'NL',
						motto: "if you read this you're gay, haha go fuck yourself you're gay now L",
						pronouns: 'Hp Printer Super-ink 3000 hyper edition/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.965989893233993,
				averageNormalizedAccuracy: 0.9845696058799644,
				averageWeightedScore: 1.740619686980699,
				scores: [
					{
						id: 'clxp081wp00tp1320uuhoay25',
						score: 1023943,
						accuracy: 0.9736306368412294,
						normalizedAccuracy: 0.9932052829046664,
						weightedScore: 1.3379649732233319,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 15, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-21T18:08:13.225Z',
						updatedAt: '2024-06-21T18:10:31.312Z',
					},
					{
						id: 'clxtd9e5r00p98478n0f2w4o5',
						score: 502088,
						accuracy: 0.9902432770913251,
						normalizedAccuracy: 0.9970926537874937,
						weightedScore: 1.4203273040482343,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 21, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T19:24:15.472Z',
						updatedAt: '2024-06-24T19:27:10.076Z',
					},
					{
						id: 'clxozwu2m00tj13206dyal7qy',
						score: 993849,
						accuracy: 0.977509921659462,
						normalizedAccuracy: 0.9926488874950186,
						weightedScore: 1.4746549722687992,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 23, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-21T17:59:29.854Z',
						updatedAt: '2024-06-21T18:02:03.710Z',
					},
					{
						id: 'clxtcrq6q00p58478t0m1r0x0',
						score: 1790437,
						accuracy: 0.9534581046790586,
						normalizedAccuracy: 0.9810522312942839,
						weightedScore: 1.65470104888485,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 34, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T19:10:31.250Z',
						updatedAt: '2024-06-24T19:13:56.561Z',
					},
					{
						id: 'clxp0jyz400tv1320pw853rrv',
						score: 2082260,
						accuracy: 0.9659433820033169,
						normalizedAccuracy: 0.9850878591490793,
						weightedScore: 1.7523989115386451,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 51, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-21T18:17:29.296Z',
						updatedAt: '2024-06-21T18:22:17.075Z',
					},
					{
						id: 'clxtceysn00op8478qvo55ovf',
						score: 657175,
						accuracy: 0.9612388927487476,
						normalizedAccuracy: 0.9805113713507312,
						weightedScore: 1.972019645780622,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 33, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T19:00:35.879Z',
						updatedAt: '2024-06-24T19:02:09.451Z',
					},
					{
						id: 'clxp1f2ns00tz1320fkaqwqo5',
						score: 1917176,
						accuracy: 0.9399050376148116,
						normalizedAccuracy: 0.9623889551784774,
						weightedScore: 2.5722709531204098,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 79, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-21T18:41:40.408Z',
						updatedAt: '2024-06-21T18:45:00.824Z',
					},
				],
			},
			{
				player: {
					id: 'clwewy2ra0006903xkg8gmn8a',
					teamId: 'cluzjmq10000nivs9rx3wfcsw',
					user: {
						id: 'clkptsjs70000ug2zfmfyitis',
						discordId: '851159321039732836',
						discordAvatar: 'https://cdn.discordapp.com/avatars/851159321039732836/8d843296445726623c5b752ec2d5a9ef.png',
						discordUsername: 'Tibytes',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198802040781',
								type: 'STEAM',
								name: 'Tibytes',
								avatar: 'https://avatars.akamai.steamstatic.com/c7f5076d4a19767719a39394c454bbfca7b86bea_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'NL',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8314549319829412,
				averageNormalizedAccuracy: 0.8473660414271489,
				averageWeightedScore: 5.000766332749444,
				scores: [
					{
						id: 'clxmc6je400kh1320lsel8vq8',
						score: 1971819,
						accuracy: 0.9666940392351042,
						normalizedAccuracy: 0.9898187893083734,
						weightedScore: 1.425609602551727,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 15, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-19T21:19:39.437Z',
						updatedAt: '2024-06-19T21:22:59.980Z',
					},
					{
						id: 'clxtdvc1j00px84780kfs72n0',
						score: 1019614,
						accuracy: 0.9695143461620748,
						normalizedAccuracy: 0.9890062350380426,
						weightedScore: 1.5468229845129542,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 35, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T19:41:19.159Z',
						updatedAt: '2024-06-24T19:43:37.278Z',
					},
					{
						id: 'clxmahe9700k31320w76daj6d',
						score: 2089421,
						accuracy: 0.969265311329398,
						normalizedAccuracy: 0.9884756273237388,
						weightedScore: 1.5814675132477205,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 33, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-19T20:32:06.764Z',
						updatedAt: '2024-06-19T20:36:54.666Z',
					},
					{
						id: 'clxteld7e00ql8478p6flt80n',
						score: 1793752,
						accuracy: 0.955223435498859,
						normalizedAccuracy: 0.9828686527303582,
						weightedScore: 1.5919383543488963,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 28, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T20:01:33.722Z',
						updatedAt: '2024-06-24T20:04:59.039Z',
					},
					{
						id: 'clxmac1m300k11320kaoy7lgz',
						score: 990815,
						accuracy: 0.9745258012324004,
						normalizedAccuracy: 0.9896185511716334,
						weightedScore: 1.670321166000258,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 45, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-19T20:27:57.100Z',
						updatedAt: '2024-06-19T20:30:30.945Z',
					},
					{
						id: 'clxjg1cke00ai1320kwt95yfm',
						score: 499410,
						accuracy: 0.9849615904227519,
						normalizedAccuracy: 0.9917744344178953,
						weightedScore: 2.1892047085845534,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 88, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-17T20:44:17.247Z',
						updatedAt: '2024-06-17T20:47:11.970Z',
					},
					{
						id: 'clxtfjfud00rf8478xf108trl',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 169, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-24T20:28:03.446Z',
						updatedAt: '2024-06-24T20:28:03.446Z',
					},
				],
			},
			{
				player: {
					id: 'clwewylhf0008903xf2u8wucc',
					teamId: 'cluzjmq10000nivs9rx3wfcsw',
					user: {
						id: 'clhv4zyno0000mx2zz8zjvvsf',
						discordId: '341971173116084224',
						discordAvatar: 'https://cdn.discordapp.com/avatars/341971173116084224/63715d813684844272573ba0bbd23e2c.png',
						discordUsername: 'Olaf',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198170634587',
								type: 'STEAM',
								name: 'Olaf',
								avatar: 'https://avatars.akamai.steamstatic.com/c9c8335b52731d09e6aa82462a53affd5474cb01_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'NL',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8737005900152073,
				averageNormalizedAccuracy: 0.7624531716908022,
				averageWeightedScore: 7.970676045754883,
				scores: [
					{
						id: 'clxlun7e100i5132088yfjrx5',
						score: 501473,
						accuracy: 0.9890303430729634,
						normalizedAccuracy: 0.9958713300711743,
						weightedScore: 1.5968992248062015,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 42, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-19T13:08:43.946Z',
						updatedAt: '2024-06-19T13:11:38.771Z',
					},
					{
						id: 'clxlvyyqs00ib1320nlrv9b8y',
						score: 1013456,
						accuracy: 0.9636589250481375,
						normalizedAccuracy: 0.9830330918727229,
						weightedScore: 1.8439233849568195,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 69, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-19T13:45:52.228Z',
						updatedAt: '2024-06-19T13:48:10.590Z',
					},
					{
						id: 'clxna3se500nh13209t96r4mc',
						score: 986641,
						accuracy: 0.9704204226356452,
						normalizedAccuracy: 0.9854495914439443,
						weightedScore: 1.9395072875016124,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 77, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-20T13:09:18.077Z',
						updatedAt: '2024-06-20T13:11:52.218Z',
					},
					{
						id: 'clxnlkc6n00p51320xxl57bty',
						score: 2056805,
						accuracy: 0.9541350157143355,
						normalizedAccuracy: 0.9730454574054739,
						weightedScore: 2.3600038191626487,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 94, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-20T18:30:05.999Z',
						updatedAt: '2024-06-20T18:34:53.972Z',
					},
					{
						id: 'clxn8qmqv00n51320k7z7wudf',
						score: 1905613,
						accuracy: 0.9342362195459749,
						normalizedAccuracy: 0.956584530603619,
						weightedScore: 2.8149158517648045,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 85, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-20T12:31:04.615Z',
						updatedAt: '2024-06-20T12:34:25.364Z',
					},
					{
						id: 'clxn9j2q800n71320ncm4bgvi',
						score: 808826,
						accuracy: 0.4307226140741865,
						normalizedAccuracy: 0.44318820043868085,
						weightedScore: 20.23948275209209,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 100, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-20T12:53:11.697Z',
						updatedAt: '2024-06-20T12:56:37.024Z',
					},
					{
						id: 'gk2pjq',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 170, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
				],
			},
			{
				player: {
					id: 'clwewzqaz000e903xs4e5ccl7',
					teamId: 'cluzjmq10000nivs9rx3wfcsw',
					user: {
						id: 'clgjjzb53000ypf4mu0ym742s',
						discordId: '438034916836507650',
						discordAvatar: 'https://cdn.discordapp.com/avatars/438034916836507650/3b888e4e26d98c6e42ec9d58de506fc7.png',
						discordUsername: 'DieEneSchrodinger',
						discordDiscriminator: '0',
						twitchUser: 'dieeneschrodinger',
						playableAccounts: [
							{
								id: '76561198085444341',
								type: 'STEAM',
								name: 'Die ene Schrödinger',
								avatar: 'https://avatars.akamai.steamstatic.com/e67ae257d883c8cc645337d440c54fa82ea4906c_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'NL',
						motto: null,
						pronouns: 'They/them',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9635090011377021,
				averageNormalizedAccuracy: 0.5591329091984076,
				averageWeightedScore: 11.978633021597105,
				scores: [
					{
						id: 'clx6gpoqp000o7pioce9ima4s',
						score: 501441,
						accuracy: 0.9889672310589999,
						normalizedAccuracy: 0.9958077815200813,
						weightedScore: 1.6060867068619007,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 45, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-08T18:42:12.482Z',
						updatedAt: '2024-06-08T18:45:10.365Z',
					},
					{
						id: 'clx9dab06006x7piob6zpdk9n',
						score: 1016589,
						accuracy: 0.9666379822663845,
						normalizedAccuracy: 0.98607204243085,
						weightedScore: 1.6927678872967626,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 52, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-10T19:29:34.518Z',
						updatedAt: '2024-06-10T19:31:57.531Z',
					},
					{
						id: 'clxszg9mb00hv8478lyk4c1yb',
						score: 990135,
						accuracy: 0.9738569805697762,
						normalizedAccuracy: 0.9889393722988906,
						weightedScore: 1.7141751580033535,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 54, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T12:57:41.556Z',
						updatedAt: '2024-06-24T13:00:15.636Z',
					},
					{
						id: 'clxt19z6p00id8478wy8nmal3',
						score: 632108,
						accuracy: 0.9245738106556478,
						normalizedAccuracy: 0.9431111681390314,
						weightedScore: 3.837401399017711,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 111, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T13:48:47.329Z',
						updatedAt: '2024-06-24T13:50:23.163Z',
					},
					{
						id: 'd6p05u',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 166, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '9z3q35',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 175, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '0pvniq',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 159, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwewxvo30002903xma3gxar9',
					teamId: 'cluzjmq10000nivs9rx3wfcsw',
					user: {
						id: 'clgidny1a0002na4mchh5t1of',
						discordId: '331490163739983872',
						discordAvatar: 'https://cdn.discordapp.com/avatars/331490163739983872/851fcc9d6044883ebac40847ff14e737.png',
						discordUsername: 'fqrb',
						discordDiscriminator: '0',
						twitchUser: 'fqrbi',
						playableAccounts: [
							{
								id: '76561199003586234',
								type: 'STEAM',
								name: 'fqrb',
								avatar: 'https://avatars.akamai.steamstatic.com/e7cd70b9ad1bf56fd390cdf3a99a10fc7f5c748c_full.jpg',
							},
						],
						bio: '1984',
						controllers: 'Valve Index Knuckles',
						countryCode: 'NL',
						motto: 'beest',
						pronouns: null,
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Content', name: 'Content Cubes', color: '#206694'},
						],
					},
				},
				averageAccuracy: 0.9764248378617176,
				averageNormalizedAccuracy: 0.4243630545381069,
				averageWeightedScore: 14.974739373935725,
				scores: [
					{
						id: 'clxnomjpi00pl1320ni9ja3hv',
						score: 502385,
						accuracy: 0.990829035470924,
						normalizedAccuracy: 0.9976824637773258,
						weightedScore: 1.335055986218777,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 15, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-20T19:55:47.911Z',
						updatedAt: '2024-06-20T19:58:42.494Z',
					},
					{
						id: 'clxnp2hqm00pn1320bc43wzhv',
						score: 992049,
						accuracy: 0.9757395140231038,
						normalizedAccuracy: 0.9908510610671698,
						weightedScore: 1.5907390687475815,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 34, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-20T20:08:11.854Z',
						updatedAt: '2024-06-20T20:10:45.563Z',
					},
					{
						id: 'clxt9cf7q00lx84787x4qmgnz',
						score: 658178,
						accuracy: 0.9627059640911252,
						normalizedAccuracy: 0.9820078569222529,
						weightedScore: 1.897380562583718,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 29, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T17:34:38.342Z',
						updatedAt: '2024-06-24T17:36:10.638Z',
					},
					{
						id: '71kim',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 165, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'j3uk8n',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 174, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '7lre0k',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 158, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'zgafvm',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 176, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
				],
			},
			{
				player: {
					id: 'cluzjmq0w000livs99d6262gm',
					teamId: 'cluzjmq10000nivs9rx3wfcsw',
					user: {
						id: 'clgidqxb80006na4mxeqiglpj',
						discordId: '273773715412746241',
						discordAvatar: 'https://cdn.discordapp.com/avatars/273773715412746241/a_ad88138aec1cf286ce51402e9894803a.png',
						discordUsername: 'Nolanimations',
						discordDiscriminator: '0',
						twitchUser: 'nolanimations',
						playableAccounts: [
							{
								id: '76561198082797048',
								type: 'STEAM',
								name: 'Nolanimations',
								avatar: 'https://avatars.akamai.steamstatic.com/fd4363144b3a9bab2a176f58c04200fd7d8eec16_full.jpg',
							},
						],
						bio: 'GRAAAAAAAAAAAAAAAAAAAAH',
						controllers: 'Valve Index Knuckles',
						countryCode: 'NL',
						motto: 'Mozes Kriebel',
						pronouns: '',
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Staff', name: 'Staff Cubes', color: '#f8db01'},
							{id: 'Content', name: 'Content Cubes', color: '#206694'},
						],
					},
				},
				averageAccuracy: 0.9521323397442368,
				averageNormalizedAccuracy: 0.4167855268282179,
				averageWeightedScore: 15.233653881326635,
				scores: [
					{
						id: 'clxnckag500nr1320dl7n59kq',
						score: 988974,
						accuracy: 0.9727150676443251,
						normalizedAccuracy: 0.9877797742529282,
						weightedScore: 1.7890494002321682,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 58, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-20T14:18:07.205Z',
						updatedAt: '2024-06-20T14:20:42.087Z',
					},
					{
						id: 'clxtbwpmb00nt8478o652epe4',
						score: 651949,
						accuracy: 0.9535949098621421,
						normalizedAccuracy: 0.9727141294795721,
						weightedScore: 2.360916803095699,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 62, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T18:46:24.179Z',
						updatedAt: '2024-06-24T18:47:57.104Z',
					},
					{
						id: 'clxndl7l800nv1320h23b7cce',
						score: 1746550,
						accuracy: 0.9300870417262432,
						normalizedAccuracy: 0.9570047840650251,
						weightedScore: 2.485610965958575,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 74, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-20T14:46:49.772Z',
						updatedAt: '2024-06-20T14:50:15.662Z',
					},
					{
						id: 'fl5i0h',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 169, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: '1hofli',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 136, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '1abfpi',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 147, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '53h88q',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 153, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
				],
			},
		],
		captain: {
			id: 'cluzjmq0w000livs99d6262gm',
			user: {
				id: 'clgidqxb80006na4mxeqiglpj',
				discordId: '273773715412746241',
				discordAvatar: 'https://cdn.discordapp.com/avatars/273773715412746241/a_ad88138aec1cf286ce51402e9894803a.png',
				discordUsername: 'Nolanimations',
				discordDiscriminator: '0',
				twitchUser: 'nolanimations',
				playableAccounts: [
					{
						id: '76561198082797048',
						type: 'STEAM',
						name: 'Nolanimations',
						avatar: 'https://avatars.akamai.steamstatic.com/fd4363144b3a9bab2a176f58c04200fd7d8eec16_full.jpg',
					},
				],
				bio: 'GRAAAAAAAAAAAAAAAAAAAAH',
				controllers: 'Valve Index Knuckles',
				countryCode: 'NL',
				motto: 'Mozes Kriebel',
				pronouns: '',
				groups: [
					{id: 'Player', name: 'Player', color: '#bebebe'},
					{id: 'Staff', name: 'Staff Cubes', color: '#f8db01'},
					{id: 'Content', name: 'Content Cubes', color: '#206694'},
				],
			},
		},
		averageAccuracy: 0.9684061600851317,
		averageNormalizedAccuracy: 0.9870455561353785,
		averageWeightedScore: 1.6443309601770881,
	},
	{
		id: 'clv03uyff000eykbvrg6xgkjp',
		name: 'Israel',
		image: 'https://cdn.cube.community/1713134180893-israel.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwb3p954000o13xt6mai3vq0',
					teamId: 'clv03uyff000eykbvrg6xgkjp',
					user: {
						id: 'clwb3n07n000m13xtma9efg16',
						discordId: '559136282387808257',
						discordAvatar: 'https://cdn.discordapp.com/avatars/559136282387808257/4d13d8a623c8e75331484b3bbb221c9b.png',
						discordUsername: 'Hikari',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198979511454',
								type: 'STEAM',
								name: 'Hikari',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198979511454R41.png',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'IL',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9736421412360734,
				averageNormalizedAccuracy: 0.9923968209906973,
				averageWeightedScore: 1.3810831389296132,
				scores: [
					{
						id: 'clxvnjimi005d90r9ai3nj02l',
						score: 502641,
						accuracy: 0.9913339315826324,
						normalizedAccuracy: 0.9981908521860702,
						weightedScore: 1.261556129773184,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 11, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T09:47:36.330Z',
						updatedAt: '2024-06-26T09:50:31.014Z',
					},
					{
						id: 'clxvnq21i005p90r9a1u942fx',
						score: 1976720,
						accuracy: 0.9690967787798045,
						normalizedAccuracy: 0.992279005933936,
						weightedScore: 1.3227640911570906,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 8, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T09:52:41.430Z',
						updatedAt: '2024-06-26T09:56:04.175Z',
					},
					{
						id: 'clxpnp49300wx132067b07afw',
						score: 2099689,
						accuracy: 0.9740285525415473,
						normalizedAccuracy: 0.9933332734091186,
						weightedScore: 1.3363727502745024,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 12, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-22T05:05:20.583Z',
						updatedAt: '2024-06-22T05:10:10.151Z',
					},
					{
						id: 'clxvo1rs4005v90r99j1456zq',
						score: 1023852,
						accuracy: 0.9735441082083344,
						normalizedAccuracy: 0.9931170146311938,
						weightedScore: 1.3423553818690597,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 16, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T10:01:48.004Z',
						updatedAt: '2024-06-26T10:04:07.446Z',
					},
					{
						id: 'clxpnjfh900wv1320kyya0fov',
						score: 995666,
						accuracy: 0.979297049812386,
						normalizedAccuracy: 0.9944636933946859,
						weightedScore: 1.3574745259899395,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 14, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-22T05:00:55.197Z',
						updatedAt: '2024-06-22T05:03:30.311Z',
					},
					{
						id: 'clxvnvmnc005r90r9tcuvgncv',
						score: 1803555,
						accuracy: 0.9604438089608512,
						normalizedAccuracy: 0.9882401095441851,
						weightedScore: 1.4063387481540384,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 18, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T09:57:01.417Z',
						updatedAt: '2024-06-26T10:00:28.867Z',
					},
					{
						id: 'clxvnnrqu005h90r9sq822hj2',
						score: 661627,
						accuracy: 0.9677507587669579,
						normalizedAccuracy: 0.9871537978356909,
						weightedScore: 1.6407203452894776,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 16, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T09:50:54.775Z',
						updatedAt: '2024-06-26T09:52:28.235Z',
					},
				],
			},
			{
				player: {
					id: 'clv03uyfc000cykbvf4dgme22',
					teamId: 'clv03uyff000eykbvrg6xgkjp',
					user: {
						id: 'clh7mhbt1004epf4mqln091sw',
						discordId: '273127950248050688',
						discordAvatar: 'https://cdn.discordapp.com/avatars/273127950248050688/c8ede809fe1bea62454c455721cd14d8.png',
						discordUsername: 'noam15A',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198329760372',
								type: 'STEAM',
								name: 'noam15A',
								avatar: 'https://avatars.akamai.steamstatic.com/3db4bee2d73485d823f60b5bc3ada453444f3f54_full.jpg',
							},
						],
						bio: "Tech (/acc/midspeed) player forced to play speed\n\nI have 5 headsets, but I'm using Quest 2 for BSWC.",
						controllers: 'Quest 2 Touch',
						countryCode: 'IL',
						motto: null,
						pronouns: 'He/Him',
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Editor', name: 'Editor Cubes', color: '#e91e63'},
						],
					},
				},
				averageAccuracy: 0.9676262263324303,
				averageNormalizedAccuracy: 0.9862542124345104,
				averageWeightedScore: 1.7095473403484083,
				scores: [
					{
						id: 'clxupzajm00b4qiy34qwv8yf4',
						score: 1019478,
						accuracy: 0.9693850286447809,
						normalizedAccuracy: 0.9888743176183474,
						weightedScore: 1.5533844743571188,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 38, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T18:08:05.411Z',
						updatedAt: '2024-06-25T18:10:23.771Z',
					},
					{
						id: 'clxusuv4t00bsqiy3c8nov98u',
						score: 1794254,
						accuracy: 0.9554907646305453,
						normalizedAccuracy: 0.9831437186612508,
						weightedScore: 1.5824340187057442,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 25, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T19:28:37.661Z',
						updatedAt: '2024-06-25T19:32:05.287Z',
					},
					{
						id: 'clxsy0x3v00h98478y2voj869',
						score: 2087513,
						accuracy: 0.9683802057360223,
						normalizedAccuracy: 0.987572979414613,
						weightedScore: 1.627011027832148,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 38, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T12:17:45.884Z',
						updatedAt: '2024-06-24T12:22:37.152Z',
					},
					{
						id: 'clxq25eyt00y71320zhzk7ffz',
						score: 990548,
						accuracy: 0.9742631907663406,
						normalizedAccuracy: 0.9893518735848359,
						weightedScore: 1.687540306977944,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 50, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-22T11:49:55.590Z',
						updatedAt: '2024-06-22T11:52:31.198Z',
					},
					{
						id: 'clxm69xx500jb13206kibrlg4',
						score: 501112,
						accuracy: 0.9883183606654373,
						normalizedAccuracy: 0.9951544229791561,
						weightedScore: 1.7005455067470572,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 51, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-19T18:34:20.538Z',
						updatedAt: '2024-06-19T18:37:15.984Z',
					},
					{
						id: 'clxp5ezu100vp1320yk4v00zt',
						score: 659403,
						accuracy: 0.9644977511244378,
						normalizedAccuracy: 0.9838355686122968,
						weightedScore: 1.8062211638636703,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 23, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-21T20:33:35.209Z',
						updatedAt: '2024-06-21T20:35:10.266Z',
					},
					{
						id: 'clxsz97sx00hr8478djprpqhl',
						score: 1943985,
						accuracy: 0.9530482827594491,
						normalizedAccuracy: 0.9758466061710727,
						weightedScore: 2.0096948839551767,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 54, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T12:52:12.609Z',
						updatedAt: '2024-06-24T12:55:37.336Z',
					},
				],
			},
			{
				player: {
					id: 'clv03y2z5000kykbv7l87iikh',
					teamId: 'clv03uyff000eykbvrg6xgkjp',
					user: {
						id: 'cli49yn640004jq2z90jq554r',
						discordId: '307971486210064384',
						discordAvatar: 'https://cdn.discordapp.com/avatars/307971486210064384/bddc704060d756576c6045309739b814.png',
						discordUsername: 'Shani132',
						discordDiscriminator: '0',
						twitchUser: 'Shani132_',
						playableAccounts: [
							{
								id: '76561198312915350',
								type: 'STEAM',
								name: 'Shani132',
								avatar: 'https://avatars.steamstatic.com/9cdfeb5bbe830217238df5f6f60262d44f185b8c_full.jpg',
							},
						],
						bio: null,
						controllers: 'Valve Index Knuckles',
						countryCode: 'NZ',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9656922599748076,
				averageNormalizedAccuracy: 0.9842639276714273,
				averageWeightedScore: 1.7732163905237601,
				scores: [
					{
						id: 'clxvnfdgc005790r90w8p0b9l',
						score: 993056,
						accuracy: 0.9767299587396665,
						normalizedAccuracy: 0.9918568450743052,
						weightedScore: 1.5257964658841738,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 26, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T09:44:23.005Z',
						updatedAt: '2024-06-26T09:46:59.043Z',
					},
					{
						id: 'clxvnbjsf005590r9xectp6fq',
						score: 1019583,
						accuracy: 0.9694848693750445,
						normalizedAccuracy: 0.9889761656262004,
						weightedScore: 1.5483186182274329,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 36, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T09:41:24.591Z',
						updatedAt: '2024-06-26T09:43:48.398Z',
					},
					{
						id: 'clx70ge04001a7pioovxlz2d1',
						score: 2086244,
						accuracy: 0.9677915270158999,
						normalizedAccuracy: 0.9869726333995811,
						weightedScore: 1.6573017615887717,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 41, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-09T03:54:50.981Z',
						updatedAt: '2024-06-09T03:59:46.656Z',
					},
					{
						id: 'clx704ocp00167pio67nimon2',
						score: 501219,
						accuracy: 0.9885293914621278,
						normalizedAccuracy: 0.9953669134468734,
						weightedScore: 1.6698248636233133,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 48, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-09T03:45:44.522Z',
						updatedAt: '2024-06-09T03:48:40.849Z',
					},
					{
						id: 'clxls0z9t00hy13206zpuzuoa',
						score: 656885,
						accuracy: 0.9608147145939225,
						normalizedAccuracy: 0.9800786885832922,
						weightedScore: 1.993600238130674,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 35, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-19T11:55:27.761Z',
						updatedAt: '2024-06-19T11:57:06.238Z',
					},
					{
						id: 'clxsmhlll00e484781aypnd4a',
						score: 1944729,
						accuracy: 0.9534130324475243,
						normalizedAccuracy: 0.9762200812107418,
						weightedScore: 1.9940823435598272,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 51, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T06:54:48.730Z',
						updatedAt: '2024-06-24T06:58:16.818Z',
					},
					{
						id: 'clxsmbd1400e284787es0fy36',
						score: 1770953,
						accuracy: 0.9430823261894682,
						normalizedAccuracy: 0.9703761663589983,
						weightedScore: 2.023590442652126,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 54, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T06:49:57.688Z',
						updatedAt: '2024-06-24T06:53:30.930Z',
					},
				],
			},
			{
				player: {
					id: 'clv03xyor000iykbvf4tutpse',
					teamId: 'clv03uyff000eykbvrg6xgkjp',
					user: {
						id: 'clhza82df0000kb2zzg0c9jfj',
						discordId: '742084474761773077',
						discordAvatar: 'https://cdn.discordapp.com/avatars/742084474761773077/fc31615f41830371946c365c447df858.png',
						discordUsername: 'ajmonkey21',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198835431545',
								type: 'STEAM',
								name: 'ajmonkey21',
								avatar: 'https://avatars.akamai.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest/Rift S Touch',
						countryCode: 'IL',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9620391978366265,
				averageNormalizedAccuracy: 0.8403502244688529,
				averageWeightedScore: 5.299302948918132,
				scores: [
					{
						id: 'clxteu0b200qt8478v65204js',
						score: 1956334,
						accuracy: 0.9591024412245588,
						normalizedAccuracy: 0.9820455890539687,
						weightedScore: 1.7505560918285978,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 40, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T20:08:16.911Z',
						updatedAt: '2024-06-24T20:11:37.604Z',
					},
					{
						id: 'clxtefknk00qh8478epxc20sm',
						score: 1011804,
						accuracy: 0.9620880975586564,
						normalizedAccuracy: 0.9814306832158363,
						weightedScore: 1.923626188063878,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 74, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T19:57:03.441Z',
						updatedAt: '2024-06-24T19:59:21.783Z',
					},
					{
						id: 'clxtde9aa00pf8478jpvmjlpy',
						score: 986207,
						accuracy: 0.9699935576833233,
						normalizedAccuracy: 0.9850161155163407,
						weightedScore: 1.967496452985941,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 81, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T19:28:02.434Z',
						updatedAt: '2024-06-24T19:30:36.314Z',
					},
					{
						id: 'clxte3n3b00q784787jcszu6v',
						score: 2072949,
						accuracy: 0.9616240852633166,
						normalizedAccuracy: 0.9806829562759812,
						weightedScore: 1.9746503079199886,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 68, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T19:47:46.727Z',
						updatedAt: '2024-06-24T19:52:34.803Z',
					},
					{
						id: 'clxtdmv1900pp8478dobzyd4l',
						score: 499596,
						accuracy: 0.9853284290039149,
						normalizedAccuracy: 0.9921438103711235,
						weightedScore: 2.1358024691358026,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 82, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T19:34:43.869Z',
						updatedAt: '2024-06-24T19:37:38.546Z',
					},
					{
						id: 'clxtfb5ej00r98478pbjullrf',
						score: 1754083,
						accuracy: 0.9340985762859889,
						normalizedAccuracy: 0.9611324168487198,
						weightedScore: 2.342989132492711,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 67, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T20:21:36.667Z',
						updatedAt: '2024-06-24T20:25:02.149Z',
					},
					{
						id: '1b5r5',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 142, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
				],
			},
			{
				player: {
					id: 'clwar4gu3000213xto8e53mmo',
					teamId: 'clv03uyff000eykbvrg6xgkjp',
					user: {
						id: 'clj37snfg0092pn2z3goly2vp',
						discordId: '721356501628878899',
						discordAvatar: 'https://cdn.discordapp.com/avatars/721356501628878899/629492191c8a5ba501dc8bcdcb0648fc.png',
						discordUsername: 'Nagha',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198821525771',
								type: 'STEAM',
								name: 'Nagha',
								avatar: 'https://avatars.steamstatic.com/56de4479fe68230bd99b4cd377d29a017cc08c3e_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'IL',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9612765147543638,
				averageNormalizedAccuracy: 0.5583577892275046,
				averageWeightedScore: 12.03068316562711,
				scores: [
					{
						id: 'clxrurivu00608478w43p8wgu',
						score: 1009533,
						accuracy: 0.9599286851926688,
						normalizedAccuracy: 0.9792278563031307,
						weightedScore: 2.0331934192116563,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 87, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T17:58:42.523Z',
						updatedAt: '2024-06-23T18:01:00.909Z',
					},
					{
						id: 'clxc97ayh00ap7pionc6nwu5q',
						score: 985055,
						accuracy: 0.9688604967960539,
						normalizedAccuracy: 0.9838655066025176,
						weightedScore: 2.0417902747323615,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 87, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-12T19:58:34.554Z',
						updatedAt: '2024-06-12T20:01:08.502Z',
					},
					{
						id: 'clxru5fqr005o8478zfj3216a',
						score: 499349,
						accuracy: 0.984841283146134,
						normalizedAccuracy: 0.9916532949923742,
						weightedScore: 2.20671834625323,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 90, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T17:41:32.020Z',
						updatedAt: '2024-06-23T17:44:26.385Z',
					},
					{
						id: 'clxrv98pp006484789jkv5bx5',
						score: 1899982,
						accuracy: 0.9314755938825986,
						normalizedAccuracy: 0.95375786669451,
						weightedScore: 2.9330801191925127,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 89, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T18:12:29.149Z',
						updatedAt: '2024-06-23T18:15:51.013Z',
					},
					{
						id: 'g8qtr',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 159, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'h209d',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 162, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '2c9pl',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 144, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwar7t8n000413xtaszz0f0k',
					teamId: 'clv03uyff000eykbvrg6xgkjp',
					user: {
						id: 'cli3p81190006ui30nswaw7uv',
						discordId: '540654797414465581',
						discordAvatar: 'https://cdn.discordapp.com/avatars/540654797414465581/29ecbc8db5a7a41cbf845182e278409f.png',
						discordUsername: 'mathslap',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198049144310',
								type: 'STEAM',
								name: 'mathslap',
								avatar: 'https://avatars.akamai.steamstatic.com/361cac18f4dbf284a7793a4759ba14d2398562b9_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'CA',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.4896644215882533,
				averageNormalizedAccuracy: 0.14087181803326312,
				averageWeightedScore: 21.858455354579384,
				scores: [
					{
						id: 'clxusfa7y00bgqiy3o638976a',
						score: 496554,
						accuracy: 0.9793288431765066,
						normalizedAccuracy: 0.9861027262328419,
						weightedScore: 3.009187482055699,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 130, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T19:16:30.718Z',
						updatedAt: '2024-06-25T19:19:26.191Z',
					},
					{
						id: '6obp7',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 154, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '686epq',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 160, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '1n96hw',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 163, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'clxusnafl00bmqiy3prqcp15u',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 145, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T19:22:44.241Z',
						updatedAt: '2024-06-25T19:22:44.241Z',
					},
					{
						id: 'f1py3a',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 167, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'gzpf9',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 168, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clwo3zzok002z903x45kbp2s9',
					teamId: 'clv03uyff000eykbvrg6xgkjp',
					user: {
						id: 'clwgh3ye5000j903xkfxnsxdd',
						discordId: '482473302397419520',
						discordAvatar: 'https://cdn.discordapp.com/avatars/482473302397419520/1a382ca9bb73ab490dbc6de516a49f35.png',
						discordUsername: 'danydance',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '3453457044725083',
								type: 'OCULUS',
								name: 'Nagha',
								avatar: 'https://avatars.steamstatic.com/8358d28bfb13cba40ca400e76d9343aedc81e332_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest/Rift S Touch',
						countryCode: 'IL',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9387676893260687,
				averageNormalizedAccuracy: 0.13679851839189508,
				averageWeightedScore: 21.87361001849765,
				scores: [
					{
						id: 'clxrxd7px0072847886v1cgbp',
						score: 641812,
						accuracy: 0.9387676893260687,
						normalizedAccuracy: 0.9575896287432655,
						weightedScore: 3.1152701294835543,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 95, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T19:11:33.717Z',
						updatedAt: '2024-06-23T19:13:10.594Z',
					},
					{
						id: '2ab1o7',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 183, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'hjbjm',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 164, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'bwxar9',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 173, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'ig45oo',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 157, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'wudw1d',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 175, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: '9zwjkp',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 176, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clwar7yb2000613xt2x9fpx0f',
					teamId: 'clv03uyff000eykbvrg6xgkjp',
					user: {
						id: 'cli3rb9r30000mo302fyadqd7',
						discordId: '289764902682492938',
						discordAvatar: 'https://cdn.discordapp.com/avatars/289764902682492938/c5bb35334ad614ed1161a6ceba15b868.png',
						discordUsername: 'TJ',
						discordDiscriminator: '7051',
						twitchUser: null,
						playableAccounts: [
							{id: '76561198118722215', type: 'STEAM', name: 'Talson', avatar: 'https://cdn.assets.beatleader.xyz/76561198118722215.png'},
						],
						bio: null,
						controllers: null,
						countryCode: 'IL',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'exdxeg',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 181, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'fe0y45',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 155, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '3fyrv',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 161, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'mkf36j',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 164, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'y0w56k',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 146, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '5ux9dm',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 168, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'sb2q5n',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 169, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clv03uyfc000cykbvf4dgme22',
			user: {
				id: 'clh7mhbt1004epf4mqln091sw',
				discordId: '273127950248050688',
				discordAvatar: 'https://cdn.discordapp.com/avatars/273127950248050688/c8ede809fe1bea62454c455721cd14d8.png',
				discordUsername: 'noam15A',
				discordDiscriminator: '0',
				twitchUser: null,
				playableAccounts: [
					{
						id: '76561198329760372',
						type: 'STEAM',
						name: 'noam15A',
						avatar: 'https://avatars.akamai.steamstatic.com/3db4bee2d73485d823f60b5bc3ada453444f3f54_full.jpg',
					},
				],
				bio: "Tech (/acc/midspeed) player forced to play speed\n\nI have 5 headsets, but I'm using Quest 2 for BSWC.",
				controllers: 'Quest 2 Touch',
				countryCode: 'IL',
				motto: null,
				pronouns: 'He/Him',
				groups: [
					{id: 'Player', name: 'Player', color: '#bebebe'},
					{id: 'Editor', name: 'Editor Cubes', color: '#e91e63'},
				],
			},
		},
		averageAccuracy: 0.9664188310410362,
		averageNormalizedAccuracy: 0.9850159259893455,
		averageWeightedScore: 1.7591899593043911,
	},
	{
		id: 'clvk05yf5002jd63st675amt8',
		name: 'Norway',
		image: 'https://cdn.cube.community/1714337298622-norway.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clw42zyix000opj8vm1hlkb6f',
					teamId: 'clvk05yf5002jd63st675amt8',
					user: {
						id: 'clgie4d6p000ana4mqxo4fnl2',
						discordId: '163015425599340544',
						discordAvatar: 'https://cdn.discordapp.com/avatars/163015425599340544/a_2bd164e9ad0894c036c3fd6621bf4a98.png',
						discordUsername: 'Latest',
						discordDiscriminator: '0',
						twitchUser: 'lateest',
						playableAccounts: [
							{
								id: '76561198842731461',
								type: 'STEAM',
								name: 'Latest',
								avatar: 'https://avatars.akamai.steamstatic.com/7864d75a9845966ec939a411596357c5f0460922_full.jpg',
							},
						],
						bio: 'Latest is well known for consistently setting new high scores on the hardest Beat Saber maps. She has a unique ability to adapt to new challenges and push the limits of what is possible in the game, earning her the nickname "The Innovator" among the Beat Saber community. She is always on the cutting edge, trying out the latest mods and custom levels, and setting new benchmarks for other players to strive for.',
						controllers: 'Quest 3 Touch',
						countryCode: 'NO',
						motto: 'Innovating',
						pronouns: 'They/them',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9678610886113248,
				averageNormalizedAccuracy: 0.9864921713865016,
				averageWeightedScore: 1.6776944758786845,
				scores: [
					{
						id: 'clxg2yt6p003u1320xnhiils1',
						score: 501960,
						accuracy: 0.989990829035471,
						normalizedAccuracy: 0.9968384595831215,
						weightedScore: 1.4570772322710308,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 29, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-15T12:15:05.281Z',
						updatedAt: '2024-06-15T12:18:00.423Z',
					},
					{
						id: 'clxardfn3008u7pio45r5jo1s',
						score: 1020218,
						accuracy: 0.970088668077115,
						normalizedAccuracy: 0.989592103578454,
						weightedScore: 1.5176822502050467,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 30, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-11T18:51:41.295Z',
						updatedAt: '2024-06-11T18:53:59.803Z',
					},
					{
						id: 'clxta2ogr00md847851g378am',
						score: 992254,
						accuracy: 0.9759411437816891,
						normalizedAccuracy: 0.9910558135214526,
						weightedScore: 1.5775183799819426,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 30, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T17:55:03.387Z',
						updatedAt: '2024-06-24T17:57:37.284Z',
					},
					{
						id: 'clxtaytwo00n18478hypq7lwj',
						score: 1791273,
						accuracy: 0.9539032982131018,
						normalizedAccuracy: 0.9815103092190374,
						weightedScore: 1.638873111439282,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 31, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T18:20:03.433Z',
						updatedAt: '2024-06-24T18:23:29.145Z',
					},
					{
						id: 'clxg446e3003y1320ktk6ff8n',
						score: 1960620,
						accuracy: 0.9612036739706484,
						normalizedAccuracy: 0.984197086392708,
						weightedScore: 1.6606161077768917,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 33, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-15T12:47:15.291Z',
						updatedAt: '2024-06-15T12:50:35.956Z',
					},
					{
						id: 'clxnlhr7o00p11320mngmfkhi',
						score: 2079706,
						accuracy: 0.9647586022939451,
						normalizedAccuracy: 0.9838795977445156,
						weightedScore: 1.8133622953167519,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 56, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-20T18:28:05.508Z',
						updatedAt: '2024-06-20T18:32:53.583Z',
					},
					{
						id: 'clxnl4egx00ox1320ikhni7hj',
						score: 655741,
						accuracy: 0.9591414049073025,
						normalizedAccuracy: 0.9783718296662225,
						weightedScore: 2.0787319541598452,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 43, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-20T18:17:42.465Z',
						updatedAt: '2024-06-20T18:19:15.265Z',
					},
				],
			},
			{
				player: {
					id: 'clw4306m3000qpj8vwp2sl7yu',
					teamId: 'clvk05yf5002jd63st675amt8',
					user: {
						id: 'clgiphd2w0002pf4mjcu0vz22',
						discordId: '275671472372973568',
						discordAvatar: 'https://cdn.discordapp.com/avatars/275671472372973568/59a5db2c6a06f3edab16579de55d16bf.png',
						discordUsername: 'Lubb',
						discordDiscriminator: '0',
						twitchUser: 'Lubb420',
						playableAccounts: [
							{
								id: '76561198381538767',
								type: 'STEAM',
								name: 'Lubb',
								avatar: 'https://avatars.steamstatic.com/57c24d0113982e7c8a43081a4d21b76836f6052e_full.jpg',
							},
						],
						bio: '8',
						controllers: 'Quest 2 Touch',
						countryCode: 'NO',
						motto: 'ø',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9663022351199938,
				averageNormalizedAccuracy: 0.9848933580931191,
				averageWeightedScore: 1.7509561412191537,
				scores: [
					{
						id: 'clx7ja65c001m7piodooz5glj',
						score: 501565,
						accuracy: 0.9892117901131086,
						normalizedAccuracy: 0.9960540321555669,
						weightedScore: 1.5704852138960665,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 36, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-09T12:41:53.569Z',
						updatedAt: '2024-06-09T12:44:49.819Z',
					},
					{
						id: 'clx9h11k1007n7pio796m0by1',
						score: 991023,
						accuracy: 0.9747303816703796,
						normalizedAccuracy: 0.989826300003296,
						weightedScore: 1.6569070037404876,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 43, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-10T21:14:20.834Z',
						updatedAt: '2024-06-10T21:16:56.023Z',
					},
					{
						id: 'clxbu7c4900ab7pioukgfvk3t',
						score: 1016952,
						accuracy: 0.9669831459338674,
						normalizedAccuracy: 0.9864241455437132,
						weightedScore: 1.6752544989627056,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 49, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-12T12:58:41.817Z',
						updatedAt: '2024-06-12T13:01:01.309Z',
					},
					{
						id: 'clxnvcxvr00qu1320rcbbj19s',
						score: 1958461,
						accuracy: 0.9601452135182902,
						normalizedAccuracy: 0.9831133060020552,
						weightedScore: 1.705921853359634,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 36, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-20T23:04:17.031Z',
						updatedAt: '2024-06-20T23:07:38.915Z',
					},
					{
						id: 'clxnuyqt300qs13200jqxo3if',
						score: 2081008,
						accuracy: 0.9653625894441417,
						normalizedAccuracy: 0.9844955555944537,
						weightedScore: 1.7822838592638564,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 53, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-20T22:53:14.679Z',
						updatedAt: '2024-06-20T22:58:03.905Z',
					},
					{
						id: 'clxnvsa0e00qy13201bfa2q60',
						score: 1776007,
						accuracy: 0.9457737234634566,
						normalizedAccuracy: 0.9731454556313722,
						weightedScore: 1.927903366276648,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 48, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-20T23:16:12.590Z',
						updatedAt: '2024-06-20T23:19:39.873Z',
					},
					{
						id: 'clx9hq3gg007p7piout77i8n4',
						score: 657633,
						accuracy: 0.9619088016967127,
						normalizedAccuracy: 0.9811947117213762,
						weightedScore: 1.9379371930346778,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 30, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-10T21:33:49.696Z',
						updatedAt: '2024-06-10T21:35:23.948Z',
					},
				],
			},
			{
				player: {
					id: 'clw4318cu000ypj8v4hnvvgju',
					teamId: 'clvk05yf5002jd63st675amt8',
					user: {
						id: 'clw417dsb000mpj8vjya6xhaq',
						discordId: '389139688075558925',
						discordAvatar: 'https://cdn.discordapp.com/avatars/389139688075558925/92da16b17ee0df318d8dc7f7ca81237d.png',
						discordUsername: 'sonson',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198360649756',
								type: 'STEAM',
								name: 'SonSon',
								avatar: 'https://avatars.akamai.steamstatic.com/3c1067d070e6bef492ca0b9eb3f20cacc9cef7d4_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'NO',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9527897076513602,
				averageNormalizedAccuracy: 0.8328985580563408,
				averageWeightedScore: 5.685472796340373,
				scores: [
					{
						id: 'clxmcsfcr00kt1320kvfu3609',
						score: 1012571,
						accuracy: 0.9628174103216298,
						normalizedAccuracy: 0.9821746586636766,
						weightedScore: 1.8866213151927438,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 72, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-19T21:36:40.635Z',
						updatedAt: '2024-06-19T21:38:59.427Z',
					},
					{
						id: 'clxquh4kq01311320x718otk9',
						score: 1760107,
						accuracy: 0.9373065258662236,
						normalizedAccuracy: 0.9644332080194321,
						weightedScore: 2.228937104774887,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 66, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T01:02:51.242Z',
						updatedAt: '2024-06-23T01:06:17.528Z',
					},
					{
						id: 'clxqtxqcm012v13208nmzskbi',
						score: 2056640,
						accuracy: 0.9540584735639649,
						normalizedAccuracy: 0.9729673982309426,
						weightedScore: 2.363942330644006,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 95, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T00:47:46.343Z',
						updatedAt: '2024-06-23T00:52:35.401Z',
					},
					{
						id: 'clxs3sqhz00ag8478x56qset2',
						score: 1923128,
						accuracy: 0.9428230351194139,
						normalizedAccuracy: 0.9653767554958308,
						weightedScore: 2.4473706299576112,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 77, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T22:11:35.592Z',
						updatedAt: '2024-06-23T22:14:57.074Z',
					},
					{
						id: 'clxmbo1nl00k91320cfz3h2ak',
						score: 497463,
						accuracy: 0.9811216188231582,
						normalizedAccuracy: 0.9879079022623284,
						weightedScore: 2.748205569910996,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 121, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-19T21:05:16.641Z',
						updatedAt: '2024-06-19T21:08:12.086Z',
					},
					{
						id: 'clxqtb9je012l1320w1bejm9r',
						score: 641705,
						accuracy: 0.9386111822137712,
						normalizedAccuracy: 0.9574299837221759,
						weightedScore: 3.1232326239023664,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 96, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T00:30:18.122Z',
						updatedAt: '2024-06-23T00:31:51.107Z',
					},
					{
						id: '521q1',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 164, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clw4u8rf60015pj8v1llcqdm1',
					teamId: 'clvk05yf5002jd63st675amt8',
					user: {
						id: 'clgjcuttz000yo14mf4sfqk1l',
						discordId: '685108587323850767',
						discordAvatar: 'https://cdn.discordapp.com/avatars/685108587323850767/7376a1e68bfed49b96764d515c07c237.png',
						discordUsername: 'Oysths',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{id: '3615798788460911', type: 'OCULUS', name: 'Oysths', avatar: 'https://cdn.assets.beatleader.xyz/3615798788460911.png'},
						],
						bio: null,
						controllers: null,
						countryCode: 'NO',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.6785006241908759,
				averageNormalizedAccuracy: 0.6938093865296667,
				averageWeightedScore: 8.793170752737355,
				scores: [
					{
						id: 'clxswhgu000gf8478ho76f0cu',
						score: 1780911,
						accuracy: 0.9483852415148296,
						normalizedAccuracy: 0.9758325538885391,
						weightedScore: 1.8350562308303988,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 43, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T11:34:38.712Z',
						updatedAt: '2024-06-24T11:38:04.349Z',
					},
					{
						id: 'clxvvb3e300ar90r908km5rbe',
						score: 2071131,
						accuracy: 0.9607807299337794,
						normalizedAccuracy: 0.9798228860984179,
						weightedScore: 2.0180455435145843,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 73, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T13:25:00.267Z',
						updatedAt: '2024-06-26T13:29:48.560Z',
					},
					{
						id: 'clxvw5be900bz90r9mzrzvg17',
						score: 1008643,
						accuracy: 0.9590824161456724,
						normalizedAccuracy: 0.9783645731889484,
						weightedScore: 2.0761325806918514,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 96, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T13:48:30.322Z',
						updatedAt: '2024-06-26T13:50:48.625Z',
					},
					{
						id: 'clxvvptij00b990r9xex6h2v6',
						score: 1934066,
						accuracy: 0.948185443840069,
						normalizedAccuracy: 0.9708674409580639,
						weightedScore: 2.217841104629202,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 68, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T13:36:27.308Z',
						updatedAt: '2024-06-26T13:39:48.279Z',
					},
					{
						id: 'clxsw9d7b00g98478pd2f4squ',
						score: 637917,
						accuracy: 0.9330705379017808,
						normalizedAccuracy: 0.9517782515736971,
						weightedScore: 3.4051198094954604,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 104, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T11:28:20.759Z',
						updatedAt: '2024-06-24T11:29:53.305Z',
					},
					{
						id: 'clxvuab9200ad90r9tg3s7jmu',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 180, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-26T12:56:24.182Z',
						updatedAt: '2024-06-26T12:56:24.182Z',
					},
					{
						id: 'clxvvn3q800b790r9po6qzinm',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 166, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-26T13:34:20.576Z',
						updatedAt: '2024-06-26T13:34:20.576Z',
					},
				],
			},
			{
				player: {
					id: 'clw430d44000spj8vvd5v9f0r',
					teamId: 'clvk05yf5002jd63st675amt8',
					user: {
						id: 'clhzih07g002ao42zgiw15bqi',
						discordId: '477810162715852811',
						discordAvatar: 'https://cdn.discordapp.com/avatars/477810162715852811/cf9e6e2abf4326379418c4fa9d126175.png',
						discordUsername: 'Cirzzo',
						discordDiscriminator: '0',
						twitchUser: 'Cirzzo',
						playableAccounts: [
							{
								id: '76561198445060816',
								type: 'STEAM',
								name: 'c1rzZo ◇',
								avatar: 'https://avatars.akamai.steamstatic.com/40ec6c130f775081e8bb902c9f65b553de61c28e_full.jpg',
							},
						],
						bio: 'Cirzzo is famous for his incredible hand-eye coordination and finger dexterity, which he puts on display in his Beat Saber gameplay. He is able to effortlessly navigate through complex, fast-paced maps with ease, earning him the nickname "The Juggler" among the Beat Saber Community. His mesmerizing playstyle has inspired countless players to strive for greater precision and control of their own gameplay.',
						controllers: 'Valve Index Knuckles',
						countryCode: 'NO',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.7197736229932642,
				averageNormalizedAccuracy: 0.7321699559352295,
				averageWeightedScore: 9.388228908435625,
				scores: [
					{
						id: 'clxnixajs00o5132037ygcqcf',
						score: 502422,
						accuracy: 0.9909020087370695,
						normalizedAccuracy: 0.9977559417895272,
						weightedScore: 1.3244329600918747,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 13, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-20T17:16:11.560Z',
						updatedAt: '2024-06-20T17:19:06.484Z',
					},
					{
						id: 'clxnjd8tw00of132063apvrs9',
						score: 1018875,
						accuracy: 0.9688116575938384,
						normalizedAccuracy: 0.9882894190589632,
						weightedScore: 1.582476962416172,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 40, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-20T17:28:35.828Z',
						updatedAt: '2024-06-20T17:30:54.111Z',
					},
					{
						id: 'clxnjkuv900oj1320iyj5qqqa',
						score: 990603,
						accuracy: 0.9743172865552293,
						normalizedAccuracy: 0.9894068071701313,
						weightedScore: 1.6839932929188701,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 47, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-20T17:34:30.982Z',
						updatedAt: '2024-06-20T17:37:04.918Z',
					},
					{
						id: 'clxnj6ntp00ob13203dezp7l8',
						score: 648813,
						accuracy: 0.9490079350568618,
						normalizedAccuracy: 0.9680351875530596,
						weightedScore: 2.594284863819021,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 75, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-20T17:23:28.669Z',
						updatedAt: '2024-06-20T17:25:01.153Z',
					},
					{
						id: 'clxnju7wr00op1320ul4t5t26',
						score: 1680747,
						accuracy: 0.7796847855080196,
						normalizedAccuracy: 0.795137717672739,
						weightedScore: 11.33642048980761,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 133, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-20T17:41:47.787Z',
						updatedAt: '2024-06-20T17:46:36.831Z',
					},
					{
						id: 'clxnjou6m00ol13205wh8n65s',
						score: 705487,
						accuracy: 0.3756916875018306,
						normalizedAccuracy: 0.3865646183021857,
						weightedScore: 22.195993789995835,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 105, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-20T17:37:36.719Z',
						updatedAt: '2024-06-20T17:41:02.503Z',
					},
					{
						id: 'clxnk0s2x00or1320bbf0lc2j',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 152, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-20T17:46:53.865Z',
						updatedAt: '2024-06-20T17:46:53.865Z',
					},
				],
			},
			{
				player: {
					id: 'clw430mck000upj8v7f6gfzvb',
					teamId: 'clvk05yf5002jd63st675amt8',
					user: {
						id: 'clhzkfcqz002go42zrmj3t638',
						discordId: '207426592656326656',
						discordAvatar: 'https://cdn.discordapp.com/avatars/207426592656326656/d9756d5b14076732929171128284fd70.png',
						discordUsername: 'B4Z1C',
						discordDiscriminator: '0',
						twitchUser: 'b4z1c',
						playableAccounts: [
							{
								id: '76561198181886967',
								type: 'STEAM',
								name: 'B4Z1C',
								avatar: 'https://avatars.akamai.steamstatic.com/0e1f9fdcdfbf5ef2af2cfe986f34ec4e734f115c_full.jpg',
							},
						],
						bio: 'my hands go brrrr',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'NO',
						motto: "It's pronounced Bazic",
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9565027265558376,
				averageNormalizedAccuracy: 0.5592436852584416,
				averageWeightedScore: 11.837480128474523,
				scores: [
					{
						id: 'clxno97ba00pj132077wsrjwr',
						score: 1965520,
						accuracy: 0.9636059232603915,
						normalizedAccuracy: 0.9866568010356904,
						weightedScore: 1.557791580979561,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 27, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-20T19:45:25.318Z',
						updatedAt: '2024-06-20T19:48:46.388Z',
					},
					{
						id: 'clxnr9hbw00q61320fpybenfb',
						score: 1790073,
						accuracy: 0.9532642644321786,
						normalizedAccuracy: 0.9808527810973816,
						weightedScore: 1.6615926388731115,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 36, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-20T21:09:37.149Z',
						updatedAt: '2024-06-20T21:13:03.038Z',
					},
					{
						id: 'clxno265200ph13208e4dud5w',
						score: 2079761,
						accuracy: 0.9647841163440686,
						normalizedAccuracy: 0.9839056174693595,
						weightedScore: 1.8120494581562991,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 55, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-20T19:39:57.207Z',
						updatedAt: '2024-06-20T19:44:45.605Z',
					},
					{
						id: 'clxqio694010b132000rs3gc9',
						score: 645633,
						accuracy: 0.9443566021867115,
						normalizedAccuracy: 0.9632905972066598,
						weightedScore: 2.8309272213126953,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 84, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-22T19:32:24.616Z',
						updatedAt: '2024-06-22T19:33:57.182Z',
					},
					{
						id: 'oidjlu',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 178, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'dqtwsi',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 164, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'ww5myd',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 163, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clw430upn000wpj8vas0pdvoj',
					teamId: 'clvk05yf5002jd63st675amt8',
					user: {
						id: 'clgjh3zn8000upf4mxijjtrb4',
						discordId: '106037564414164992',
						discordAvatar: 'https://cdn.discordapp.com/avatars/106037564414164992/384bfdb9f35c42c838ed331ecbcfd56c.png',
						discordUsername: 'Ole',
						discordDiscriminator: '0',
						twitchUser: 'WookieOJ',
						playableAccounts: [
							{
								id: '76561198103016268',
								type: 'STEAM',
								name: 'ACC | Wookie',
								avatar: 'https://avatars.akamai.steamstatic.com/855969827873088818f23de4dc51776b3e8b1e0e_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 3 Touch',
						countryCode: 'NO',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.6543218930180915,
				averageNormalizedAccuracy: 0.4744578171182198,
				averageWeightedScore: 15.470032304392117,
				scores: [
					{
						id: 'clxt6j4qn00kl8478c4krpkk3',
						score: 501518,
						accuracy: 0.9891190943425996,
						normalizedAccuracy: 0.9959606952211489,
						weightedScore: 1.5839793281653747,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 38, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T16:15:52.512Z',
						updatedAt: '2024-06-24T16:18:47.387Z',
					},
					{
						id: 'clxvvh05y00av90r92qd01jo1',
						score: 986701,
						accuracy: 0.9704794362235238,
						normalizedAccuracy: 0.9855095189915393,
						weightedScore: 1.9356378176189861,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 76, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T13:29:36.022Z',
						updatedAt: '2024-06-26T13:32:10.114Z',
					},
					{
						id: 'clxuk10780094qiy37mlp7ewj',
						score: 1008962,
						accuracy: 0.9593857417928543,
						normalizedAccuracy: 0.978673997136616,
						weightedScore: 2.0607420273073767,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 93, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T15:21:27.620Z',
						updatedAt: '2024-06-25T15:23:46.024Z',
					},
					{
						id: 'pogiye',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 157, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'clxvvqdto00bf90r91umyredb',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 159, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-26T13:36:53.628Z',
						updatedAt: '2024-06-26T13:36:53.628Z',
					},
					{
						id: 'phplu8',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 141, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'clxvzhbe300gv90r9yfso3sqt',
						score: 719269,
						accuracy: 0.35262519273148,
						normalizedAccuracy: 0.3610605084782348,
						weightedScore: 27.709866957653084,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 114, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T15:21:49.035Z',
						updatedAt: '2024-06-26T15:25:09.697Z',
					},
				],
			},
			{
				player: {
					id: 'clw439m690011pj8vstr6tjyi',
					teamId: 'clvk05yf5002jd63st675amt8',
					user: {
						id: 'clw4364j5000zpj8v1abrbr06',
						discordId: '224848109849542656',
						discordAvatar: 'https://cdn.discordapp.com/avatars/224848109849542656/1c3a95713d0e0d90bc2516f38b155ce4.png',
						discordUsername: 'BungiCraft',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198127781196',
								type: 'STEAM',
								name: 'BungiCraft',
								avatar: 'https://avatars.steamstatic.com/56692569a4039c3a44421737a83b2d14c9854523_full.jpg',
							},
						],
						bio: 'Meow :3c',
						controllers: 'Quest 3 Touch',
						countryCode: 'NO',
						motto: null,
						pronouns: 'She/her',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'ee6xa',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 179, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'huzc7',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 153, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'szh56c',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 158, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '1lwjr',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 160, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'g05ly9',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 142, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'yba14',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 165, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: '623kz',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 165, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clvk05yf1002hd63sj2fer0g4',
			user: {
				id: 'clgiki2ne0012t74ntexeytw0',
				discordId: '165492338037227523',
				discordAvatar: 'https://cdn.discordapp.com/avatars/165492338037227523/de7f21ad003cc5443781d745cd09cc05.png',
				discordUsername: 'slamsyk',
				discordDiscriminator: '0',
				twitchUser: 'slamsyk',
				playableAccounts: [
					{
						id: '76561198114515066',
						type: 'STEAM',
						name: 'Duh | slamsyk',
						avatar: 'https://avatars.akamai.steamstatic.com/9b59662ef2225f575847e4a7d1bc7f46ab08680b_full.jpg',
					},
				],
				bio: 'Slamsyk is a highly skilled Beat Saber player known for his impressive power and strength in the game. He is able to generate incredible impact with each hit, earning him the nickname "The Hammer" among the Beat Saber community. His powerhouse playstyle has inspired countless players to strive for greater power and force in their own gameplay.',
				controllers: 'Quest/Rift S Touch',
				countryCode: 'NO',
				motto: 'oldest man on the team',
				pronouns: 'He/him',
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.965284300587929,
		averageNormalizedAccuracy: 0.9838592718771251,
		averageWeightedScore: 1.80447445136442,
	},
	{
		id: 'clwpdrvka003j903xv12k1fff',
		name: 'Poland',
		image: 'https://cdn.cube.community/1716839270032-poland.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwr3hysi004f903xnnce3y27',
					teamId: 'clwpdrvka003j903xv12k1fff',
					user: {
						id: 'clgjgaxwn0012o14m4dmju06t',
						discordId: '442724808623390722',
						discordAvatar: 'https://cdn.discordapp.com/avatars/442724808623390722/cdd90350da4714cbd84ffbbb2775c569.png',
						discordUsername: 'Voytech',
						discordDiscriminator: '0',
						twitchUser: 'mr_voytech',
						playableAccounts: [
							{
								id: '76561198265369367',
								type: 'STEAM',
								name: 'Voytech',
								avatar: 'https://avatars.steamstatic.com/122a795be1ef2b9ffbc242c341f8ac68c9685b5b_full.jpg',
							},
						],
						bio: ':3',
						controllers: 'Quest 2 Touch',
						countryCode: 'PL',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9741594964523774,
				averageNormalizedAccuracy: 0.9929335226363013,
				averageWeightedScore: 1.3951704589824874,
				scores: [
					{
						id: 'clxrs1b80004n8478xrk89bdq',
						score: 1810094,
						accuracy: 0.9639260105387321,
						normalizedAccuracy: 0.9918230898671081,
						weightedScore: 1.2825362565791965,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 10, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T16:42:20.305Z',
						updatedAt: '2024-06-23T16:45:46.153Z',
					},
					{
						id: 'clxui9slw0088qiy3iwh05aet',
						score: 2101849,
						accuracy: 0.9750305588736706,
						normalizedAccuracy: 0.9943551389666195,
						weightedScore: 1.284814054518547,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 8, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T14:32:18.452Z',
						updatedAt: '2024-06-25T14:37:06.733Z',
					},
					{
						id: 'clxkk0c6600d11320sosuyt5j',
						score: 1024728,
						accuracy: 0.9743770651579623,
						normalizedAccuracy: 0.9939667180109957,
						weightedScore: 1.300091667872823,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 14, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-18T15:23:14.718Z',
						updatedAt: '2024-06-18T15:25:34.005Z',
					},
					{
						id: 'clxqf3lo2010713202pm7c1ze',
						score: 1976685,
						accuracy: 0.9690796198563063,
						normalizedAccuracy: 0.9922614365436291,
						weightedScore: 1.323498552062786,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 9, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-22T17:52:25.970Z',
						updatedAt: '2024-06-22T17:55:47.736Z',
					},
					{
						id: 'clxvwglu400cd90r9ytryw6zd',
						score: 995588,
						accuracy: 0.9792203321481437,
						normalizedAccuracy: 0.9943857875828124,
						weightedScore: 1.3625048368373534,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 15, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T13:57:17.069Z',
						updatedAt: '2024-06-26T13:59:52.334Z',
					},
					{
						id: 'clxjczlq8009m13209ua229pl',
						score: 662185,
						accuracy: 0.968566936044173,
						normalizedAccuracy: 0.9879863391606253,
						weightedScore: 1.5991963089745498,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 12, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T19:18:56.960Z',
						updatedAt: '2024-06-17T19:20:29.827Z',
					},
					{
						id: 'clxszha7q00hx8478visizml8',
						score: 501415,
						accuracy: 0.9889159525476545,
						normalizedAccuracy: 0.9957561483223183,
						weightedScore: 1.6135515360321562,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 46, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T12:58:28.982Z',
						updatedAt: '2024-06-24T13:01:24.140Z',
					},
				],
			},
			{
				player: {
					id: 'clwr3i1yw004h903xsr1p2a4c',
					teamId: 'clwpdrvka003j903xv12k1fff',
					user: {
						id: 'cliq37y08000ikd2zf06vqgo8',
						discordId: '403658957278674965',
						discordAvatar: 'https://cdn.discordapp.com/avatars/403658957278674965/b4c4ab53ef0ad8971d703709067926d8.png',
						discordUsername: 'Vixoner',
						discordDiscriminator: '0',
						twitchUser: 'vixoner__',
						playableAccounts: [
							{
								id: '76561198086560253',
								type: 'STEAM',
								name: 'Vixoner',
								avatar: 'https://avatars.steamstatic.com/28bebab81aeaa15f15a6ab97fb3af187d126827b_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'PL',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9650392266639785,
				averageNormalizedAccuracy: 0.9836066598372751,
				averageWeightedScore: 1.8321981915234835,
				scores: [
					{
						id: 'clxt2mare00ip84788nc2f7xa',
						score: 990449,
						accuracy: 0.9741658183463409,
						normalizedAccuracy: 0.9892529931313042,
						weightedScore: 1.693924932284277,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 51, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T14:26:21.819Z',
						updatedAt: '2024-06-24T14:28:57.939Z',
					},
					{
						id: 'clxt3zvuw00jb8478t4d577f4',
						score: 1015498,
						accuracy: 0.9656005895357406,
						normalizedAccuracy: 0.9850137931302064,
						weightedScore: 1.745404544796642,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 58, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T15:04:55.305Z',
						updatedAt: '2024-06-24T15:07:15.004Z',
					},
					{
						id: 'clxs22w8b009k8478mhtbfeg4',
						score: 500873,
						accuracy: 0.9878469928111472,
						normalizedAccuracy: 0.99467979473818,
						weightedScore: 1.7691645133505598,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 58, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T21:23:30.347Z',
						updatedAt: '2024-06-23T21:26:25.324Z',
					},
					{
						id: 'clxv232p7001190r98ks4aymi',
						score: 1953981,
						accuracy: 0.9579488713105251,
						normalizedAccuracy: 0.9808644240427569,
						weightedScore: 1.7999328492886222,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 44, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T23:46:57.259Z',
						updatedAt: '2024-06-25T23:50:20.834Z',
					},
					{
						id: 'clxt4kwyd00jn8478m6xze1al',
						score: 2079634,
						accuracy: 0.9647252020828743,
						normalizedAccuracy: 0.9838455355592656,
						weightedScore: 1.815080918508617,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 57, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T15:21:16.502Z',
						updatedAt: '2024-06-24T15:26:06.799Z',
					},
					{
						id: 'clxv3kmf4001t90r9monu2d0f',
						score: 1775873,
						accuracy: 0.9457023646912535,
						normalizedAccuracy: 0.9730720316577873,
						weightedScore: 1.9304403801734258,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 49, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T00:28:35.585Z',
						updatedAt: '2024-06-26T00:32:11.826Z',
					},
					{
						id: 'clxs2not6009u8478zyqi1xjx',
						score: 655839,
						accuracy: 0.9592847478699674,
						normalizedAccuracy: 0.978518046601426,
						weightedScore: 2.0714392022622414,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 42, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T21:39:40.507Z',
						updatedAt: '2024-06-23T21:41:13.028Z',
					},
				],
			},
			{
				player: {
					id: 'clwr4argc004o903xvlg4ffcg',
					teamId: 'clwpdrvka003j903xv12k1fff',
					user: {
						id: 'clwr463ai004m903x9lnz9ant',
						discordId: '273802901661417473',
						discordAvatar: 'https://cdn.discordapp.com/avatars/273802901661417473/315cf9e3a1fee7816b6b035dfcca706a.png',
						discordUsername: 'michos',
						discordDiscriminator: '0',
						twitchUser: 'michosu',
						playableAccounts: [
							{
								id: '2667687279923252',
								type: 'OCULUS',
								name: 'micholusu',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198175978233.png',
							},
						],
						bio: null,
						controllers: 'Rift Touch',
						countryCode: null,
						motto: 'we do a little editing',
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9593996359982119,
				averageNormalizedAccuracy: 0.9778400666776674,
				averageWeightedScore: 2.122809873244459,
				scores: [
					{
						id: 'clxme4ifd00l11320x4zaosyz',
						score: 988149,
						accuracy: 0.9719036308109942,
						normalizedAccuracy: 0.9869557704734976,
						weightedScore: 1.8422546111182767,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 68, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-19T22:14:04.105Z',
						updatedAt: '2024-06-19T22:16:38.238Z',
					},
					{
						id: 'clxrxzwk2007o8478gau7vw9y',
						score: 1948983,
						accuracy: 0.9554985770349871,
						normalizedAccuracy: 0.9783555151069148,
						weightedScore: 1.9048138666218994,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 48, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T19:29:12.338Z',
						updatedAt: '2024-06-23T19:32:33.165Z',
					},
					{
						id: 'clxmcd2n900kj1320i8mgn43t',
						score: 1010921,
						accuracy: 0.9612484845603442,
						normalizedAccuracy: 0.9805741899688442,
						weightedScore: 1.9662276258020939,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 79, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-19T21:24:44.325Z',
						updatedAt: '2024-06-19T21:27:02.563Z',
					},
					{
						id: 'clxmbw31l00kd1320jjlepxjy',
						score: 2068113,
						accuracy: 0.9593807044197293,
						normalizedAccuracy: 0.9783951128333541,
						weightedScore: 2.0900844989736,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 79, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-19T21:11:31.689Z',
						updatedAt: '2024-06-19T21:16:19.735Z',
					},
					{
						id: 'clxn870cn00mx1320ti6rjvrc',
						score: 499599,
						accuracy: 0.985334345755224,
						normalizedAccuracy: 0.9921497680477885,
						weightedScore: 2.134941142693081,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 81, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-20T12:15:49.127Z',
						updatedAt: '2024-06-20T12:18:43.847Z',
					},
					{
						id: 'clxna882a00nj13204zm5bh2a',
						score: 1747924,
						accuracy: 0.9308187354054004,
						normalizedAccuracy: 0.9577576537643211,
						weightedScore: 2.4595971070468403,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 73, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-20T13:12:45.010Z',
						updatedAt: '2024-06-20T13:16:10.238Z',
					},
					{
						id: 'clxrz4qms00868478vy5cy8j6',
						score: 650594,
						accuracy: 0.9516129740008045,
						normalizedAccuracy: 0.9706924565489521,
						weightedScore: 2.461750260455425,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 65, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T20:00:57.557Z',
						updatedAt: '2024-06-23T20:02:30.302Z',
					},
				],
			},
			{
				player: {
					id: 'clwr3ht57004d903xhwrjcsud',
					teamId: 'clwpdrvka003j903xv12k1fff',
					user: {
						id: 'clgidkzw30000m74mi9y0su53',
						discordId: '404182315611455503',
						discordAvatar: 'https://cdn.discordapp.com/avatars/404182315611455503/2c7c93580cdee9f9dde63c293192df3d.png',
						discordUsername: 'Slayx',
						discordDiscriminator: '0',
						twitchUser: 'slayx0',
						playableAccounts: [
							{
								id: '76561198347652574',
								type: 'STEAM',
								name: 'Setsuna',
								avatar: 'https://avatars.steamstatic.com/0a875b42366c0d3ca2b96e93cec8ddbd63fb0a77_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest/Rift S Touch',
						countryCode: 'PL',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9581898826910705,
				averageNormalizedAccuracy: 0.9765928906074679,
				averageWeightedScore: 2.1705832577122885,
				scores: [
					{
						id: 'clxme8x5q00l313208bl5hwkq',
						score: 1015910,
						accuracy: 0.9659923455440131,
						normalizedAccuracy: 0.9854134253134008,
						weightedScore: 1.7255270902687316,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 56, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-19T22:17:29.822Z',
						updatedAt: '2024-06-19T22:19:48.215Z',
					},
					{
						id: 'clxt50lob00jz8478wx17vlp4',
						score: 2076540,
						accuracy: 0.9632899207904717,
						normalizedAccuracy: 0.9823818077653267,
						weightedScore: 1.8889339762257125,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 61, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T15:33:28.380Z',
						updatedAt: '2024-06-24T15:38:17.075Z',
					},
					{
						id: 'clxmeij4j00lb1320ld5ho1g3',
						score: 986479,
						accuracy: 0.9702610859483729,
						normalizedAccuracy: 0.9852877870654378,
						weightedScore: 1.9499548561847027,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 79, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-19T22:24:58.196Z',
						updatedAt: '2024-06-19T22:27:32.669Z',
					},
					{
						id: 'clxuj57y6008eqiy3j4ju3koc',
						score: 1933232,
						accuracy: 0.9477765712058556,
						normalizedAccuracy: 0.9704487874861767,
						weightedScore: 2.235342258782054,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 69, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T14:56:44.671Z',
						updatedAt: '2024-06-25T15:00:05.872Z',
					},
					{
						id: 'clx994xzf006j7pio1tjxyhxb',
						score: 499159,
						accuracy: 0.9844665555632254,
						normalizedAccuracy: 0.9912759754702593,
						weightedScore: 2.2612690209589434,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 97, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-10T17:33:25.899Z',
						updatedAt: '2024-06-10T17:36:20.798Z',
					},
					{
						id: 'clxt5kq7m00k784783ufht3tm',
						score: 652076,
						accuracy: 0.9537806706402896,
						normalizedAccuracy: 0.9729036146915195,
						weightedScore: 2.3514659919630896,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 60, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T15:49:07.379Z',
						updatedAt: '2024-06-24T15:50:39.945Z',
					},
					{
						id: 'clxt58d5o00k184780w3fbgr3',
						score: 1730917,
						accuracy: 0.9217620291452657,
						normalizedAccuracy: 0.9484388364601535,
						weightedScore: 2.781589609602787,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 81, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T15:39:30.588Z',
						updatedAt: '2024-06-24T15:42:56.592Z',
					},
				],
			},
			{
				player: {
					id: 'clwr3hl440049903xtxw40uys',
					teamId: 'clwpdrvka003j903xv12k1fff',
					user: {
						id: 'clgobqwnh0024pf4mtmab6706',
						discordId: '272492529532796929',
						discordAvatar: 'https://cdn.discordapp.com/avatars/272492529532796929/40e4a680fbf6675c80a411144e185730.png',
						discordUsername: 'shreddy',
						discordDiscriminator: '0',
						twitchUser: 'shreddyfreddy',
						playableAccounts: [
							{
								id: '76561198087710981',
								type: 'STEAM',
								name: 'shreddy',
								avatar: 'https://avatars.steamstatic.com/55afc2042ffacb672abc54611bb216196fdd3f8b_full.jpg',
							},
						],
						bio: null,
						controllers: 'Rift Touch',
						countryCode: 'PL',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9597498736437123,
				averageNormalizedAccuracy: 0.8371510674249242,
				averageWeightedScore: 5.511625273293807,
				scores: [
					{
						id: 'clxosbbhy00sr1320aq8lu3dm',
						score: 1015125,
						accuracy: 0.9652459172272803,
						normalizedAccuracy: 0.9846519902070715,
						weightedScore: 1.7634003956192406,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 60, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-21T14:26:48.694Z',
						updatedAt: '2024-06-21T14:29:06.996Z',
					},
					{
						id: 'clxs43tbz00as8478ckb8zktu',
						score: 988306,
						accuracy: 0.9720580496992766,
						normalizedAccuracy: 0.9871125808897043,
						weightedScore: 1.8321294982587386,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 65, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T22:20:12.479Z',
						updatedAt: '2024-06-23T22:22:46.882Z',
					},
					{
						id: 'clxs3ej6a00a484784xdpp17x',
						score: 499982,
						accuracy: 0.98608971767235,
						normalizedAccuracy: 0.9929103647686833,
						weightedScore: 2.024978466838932,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 73, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T22:00:32.915Z',
						updatedAt: '2024-06-23T22:03:27.991Z',
					},
					{
						id: 'clxs3p6nl00ac8478pw35v2ak',
						score: 2061877,
						accuracy: 0.9564878750275436,
						normalizedAccuracy: 0.9754449491219762,
						weightedScore: 2.238936363202368,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 87, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-23T22:08:49.905Z',
						updatedAt: '2024-06-23T22:13:38.524Z',
					},
					{
						id: 'clxosh7g400st1320872s236o',
						score: 1925158,
						accuracy: 0.9438182526823075,
						normalizedAccuracy: 0.9663957801336378,
						weightedScore: 2.4047718974272883,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 74, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-21T14:31:23.380Z',
						updatedAt: '2024-06-21T14:34:44.684Z',
					},
					{
						id: 'clxs3kmbl00aa847813po65lg',
						score: 639099,
						accuracy: 0.9347994295535159,
						normalizedAccuracy: 0.9535418068533966,
						weightedScore: 3.317160291710076,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 99, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T22:05:16.930Z',
						updatedAt: '2024-06-23T22:06:49.511Z',
					},
					{
						id: 'b6q1xn',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 175, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwr3hp3v004b903x9qi9rrk6',
					teamId: 'clwpdrvka003j903xv12k1fff',
					user: {
						id: 'clhvutsip000glq2zri2dja8q',
						discordId: '425748779019534348',
						discordAvatar: 'https://cdn.discordapp.com/avatars/425748779019534348/a27b58064a4bcd78fd09a6ceb05f7056.png',
						discordUsername: 'Sonex',
						discordDiscriminator: '0',
						twitchUser: 'Sonex120',
						playableAccounts: [
							{
								id: '76561198866750478',
								type: 'STEAM',
								name: 'NYA | Sonex',
								avatar: 'https://avatars.akamai.steamstatic.com/0e1ffbb795ec336799d3fdd659771482a00c1b6c_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest/Rift S Touch',
						countryCode: 'PL',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9366798431032604,
				averageNormalizedAccuracy: 0.8180914441108368,
				averageWeightedScore: 6.16889254741869,
				scores: [
					{
						id: 'clxqnvptk011d1320v5wk0b65',
						score: 990182,
						accuracy: 0.9739032078802811,
						normalizedAccuracy: 0.9889863155445067,
						weightedScore: 1.7111440732619632,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 53, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-22T21:58:14.648Z',
						updatedAt: '2024-06-22T22:00:49.856Z',
					},
					{
						id: 'clxqmxumg01191320oj2jboaf',
						score: 500738,
						accuracy: 0.9875807390022385,
						normalizedAccuracy: 0.9944116992882562,
						weightedScore: 1.8079242032730405,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 60, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-22T21:31:54.569Z',
						updatedAt: '2024-06-22T21:34:52.498Z',
					},
					{
						id: 'clxqo6i91011h1320hh5vgsg9',
						score: 1012783,
						accuracy: 0.9630189935103526,
						normalizedAccuracy: 0.9823802946414368,
						weightedScore: 1.8763931104356637,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 71, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-22T22:06:38.054Z',
						updatedAt: '2024-06-22T22:08:56.201Z',
					},
					{
						id: 'clxqoimwh011t1320ptrvnafh',
						score: 1928204,
						accuracy: 0.9453115692816049,
						normalizedAccuracy: 0.9679248190729285,
						weightedScore: 2.340852814034499,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 72, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-22T22:16:03.953Z',
						updatedAt: '2024-06-22T22:19:28.030Z',
					},
					{
						id: 'clxqoaopk011j1320rvulpry9',
						score: 613756,
						accuracy: 0.8977306468716861,
						normalizedAccuracy: 0.9157298090078584,
						weightedScore: 5.203080815597559,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 120, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-22T22:09:53.048Z',
						updatedAt: '2024-06-22T22:11:26.709Z',
					},
					{
						id: 'clxqod4r7011l13201ugehm0x',
						score: 1600918,
						accuracy: 0.8525339020733984,
						normalizedAccuracy: 0.8772071712208708,
						weightedScore: 5.242852815328108,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 95, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-22T22:11:47.156Z',
						updatedAt: '2024-06-22T22:15:16.053Z',
					},
					{
						id: 'ec244',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 185, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
				],
			},
			{
				player: {
					id: 'clwr3ic5i004l903xbitji9my',
					teamId: 'clwpdrvka003j903xv12k1fff',
					user: {
						id: 'clw2galts000fpj8vqifkvuyu',
						discordId: '779753220523294771',
						discordAvatar: 'https://cdn.discordapp.com/avatars/779753220523294771/e49d1a3854e7a2022a813e1d3afe5d7e.png',
						discordUsername: 'Rimax',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198294651531',
								type: 'STEAM',
								name: 'Rimax_PL',
								avatar: 'https://avatars.steamstatic.com/88eb0568699e45fc815ded3cd096d883603cbd1c_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'PL',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9703857552328732,
				averageNormalizedAccuracy: 0.7050589518828129,
				averageWeightedScore: 8.450924722287912,
				scores: [
					{
						id: 'clxmldhwg00lx1320x96j2hzp',
						score: 1966711,
						accuracy: 0.9641898169142863,
						normalizedAccuracy: 0.9872546622887093,
						weightedScore: 1.5327989255886179,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 23, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-20T01:37:00.641Z',
						updatedAt: '2024-06-20T01:40:25.542Z',
					},
					{
						id: 'clxmmzoih00mb1320dqign4rn',
						score: 2084365,
						accuracy: 0.9669198742853167,
						normalizedAccuracy: 0.9860837049817365,
						weightedScore: 1.7021530529431421,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 47, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-20T02:22:15.258Z',
						updatedAt: '2024-06-20T02:27:07.088Z',
					},
					{
						id: 'clxmm7nrs00m513205b5fewrj',
						score: 1015011,
						accuracy: 0.965137518720137,
						normalizedAccuracy: 0.9845414123699741,
						weightedScore: 1.7689004679886138,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 61, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-20T02:00:27.929Z',
						updatedAt: '2024-06-20T02:02:47.885Z',
					},
					{
						id: 'clxmn71k300md1320fcclvdrc',
						score: 987499,
						accuracy: 0.9712643169423093,
						normalizedAccuracy: 0.9863065553745521,
						weightedScore: 1.8841738681800593,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 70, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-20T02:27:58.756Z',
						updatedAt: '2024-06-20T02:30:36.070Z',
					},
					{
						id: 'clxmmv58x00m91320smsrk0ct',
						score: 499134,
						accuracy: 0.9844172493023164,
						normalizedAccuracy: 0.9912263281647179,
						weightedScore: 2.2684467413149583,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 98, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-20T02:18:43.666Z',
						updatedAt: '2024-06-20T02:21:41.123Z',
					},
					{
						id: '80o7z',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 185, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'q80twf',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 177, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwr3i84b004j903xidgaw9ma',
					teamId: 'clwpdrvka003j903xv12k1fff',
					user: {
						id: 'clipz6voz000akd2zeki1jr7e',
						discordId: '269208399860858881',
						discordAvatar: 'https://cdn.discordapp.com/avatars/269208399860858881/06ac4e85de112b8ba763ec9da22f79f9.png',
						discordUsername: 'Woltix',
						discordDiscriminator: '0',
						twitchUser: 'woltixo',
						playableAccounts: [
							{
								id: '76561198999385463',
								type: 'STEAM',
								name: 'Woltix',
								avatar: 'https://avatars.akamai.steamstatic.com/38630f84adf0cd60c03816fe0220e9e5f5d3f181_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'PL',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.48012741578909834,
				averageNormalizedAccuracy: 0.13993722282792148,
				averageWeightedScore: 21.7166635651221,
				scores: [
					{
						id: 'clxv2nf8e001b90r9v48g2xcd',
						score: 1009876,
						accuracy: 0.9602548315781967,
						normalizedAccuracy: 0.9795605597954504,
						weightedScore: 2.0166449558546824,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 86, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-26T00:02:46.622Z',
						updatedAt: '2024-06-26T00:05:04.851Z',
					},
					{
						id: 'rpv4',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 189, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.616Z',
						updatedAt: '2024-07-08T16:23:01.616Z',
					},
					{
						id: 'clxv2046s000z90r91y7pk5z9',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 181, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-25T23:44:39.220Z',
						updatedAt: '2024-06-25T23:44:39.220Z',
					},
					{
						id: '1lzfhn',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 184, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '27h8r9',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 186, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '9swzog',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 176, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'lnon28',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 187, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clwpdrvk7003h903xx6asrtag',
			user: {
				id: 'clhsg7236005so14m0emxxmu2',
				discordId: '377862395596505098',
				discordAvatar: 'https://cdn.discordapp.com/avatars/377862395596505098/165bc34b50b73f9e5b4e3f15fc739a8b.png',
				discordUsername: 'Drakonno',
				discordDiscriminator: '0',
				twitchUser: null,
				playableAccounts: [
					{
						id: '76561198025451538',
						type: 'STEAM',
						name: 'Drakonno',
						avatar: 'https://avatars.akamai.steamstatic.com/ac083299806d2b0d6e5c30c33582e78e12f09b19_full.jpg',
					},
				],
				bio: null,
				controllers: null,
				countryCode: 'PL',
				motto: null,
				pronouns: null,
				groups: [
					{id: 'Player', name: 'Player', color: '#bebebe'},
					{id: 'Developer', name: 'Developer Cubes', color: '#007990'},
				],
			},
		},
		averageAccuracy: 0.9654690584663126,
		averageNormalizedAccuracy: 0.9840405680333676,
		averageWeightedScore: 1.8049923954110696,
	},
	{
		id: 'clv506pr50008d63sx8zkh2dh',
		name: 'Japan',
		image: 'https://cdn.cube.community/1713430341777-japan.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwrye7p70058903xae6vffq3',
					teamId: 'clv506pr50008d63sx8zkh2dh',
					user: {
						id: 'clhapvfnr0050pf4m1itq42en',
						discordId: '559354077289513017',
						discordAvatar: 'https://cdn.discordapp.com/avatars/559354077289513017/28da5184fb5bd4754ff54b8c4b94381e.png',
						discordUsername: 'Marsh',
						discordDiscriminator: '0',
						twitchUser: 'marsh_era',
						playableAccounts: [
							{id: '1477469542377599', type: 'OCULUS', name: 'Marsh', avatar: 'https://cdn.assets.beatleader.xyz/76561198273286768.png'},
						],
						bio: "I'm allergic to cats",
						controllers: 'Quest 2 Touch',
						countryCode: 'JP',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9750881663921268,
				averageNormalizedAccuracy: 0.9938778797577468,
				averageWeightedScore: 1.3211644880464597,
				scores: [
					{
						id: 'clxvqrtqe007j90r9cpvs30es',
						score: 1812348,
						accuracy: 0.9651263289905663,
						normalizedAccuracy: 0.9930581468556183,
						weightedScore: 1.2398614108826536,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 7, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T11:18:02.822Z',
						updatedAt: '2024-06-26T11:21:29.714Z',
					},
					{
						id: 'clxk8mavq00bx13208qb75fd5',
						score: 997019,
						accuracy: 0.9806278062190485,
						normalizedAccuracy: 0.9958150595929521,
						weightedScore: 1.2702179801367213,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 10, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-18T10:04:24.087Z',
						updatedAt: '2024-06-18T10:07:05.898Z',
					},
					{
						id: 'clxk8w69400bz1320qozs4hza',
						score: 1025236,
						accuracy: 0.9748601041196187,
						normalizedAccuracy: 0.9944594683727986,
						weightedScore: 1.2755825734549138,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 11, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-18T10:12:04.648Z',
						updatedAt: '2024-06-18T10:14:23.881Z',
					},
					{
						id: 'clxohx5w100rf13209lii6c6j',
						score: 1977265,
						accuracy: 0.9693639677314188,
						normalizedAccuracy: 0.9925525864401453,
						weightedScore: 1.3113274856255508,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 7, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-21T09:35:52.081Z',
						updatedAt: '2024-06-21T09:39:15.582Z',
					},
					{
						id: 'clxk949r000c113205mf8nny5',
						score: 2100672,
						accuracy: 0.9744845582010275,
						normalizedAccuracy: 0.9937983168549628,
						weightedScore: 1.312908769752232,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 10, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-18T10:18:22.429Z',
						updatedAt: '2024-06-18T10:23:18.063Z',
					},
					{
						id: 'clxvq3bod007390r9siy4cbpd',
						score: 502420,
						accuracy: 0.9908980642361968,
						normalizedAccuracy: 0.9977519700050839,
						weightedScore: 1.325007177720356,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 14, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T10:58:59.677Z',
						updatedAt: '2024-06-26T11:01:57.353Z',
					},
					{
						id: 'clxk9g06z00c31320hhh9q206',
						score: 663340,
						accuracy: 0.9702563352470106,
						normalizedAccuracy: 0.9897096101826667,
						weightedScore: 1.5132460187527905,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 6, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-18T10:27:29.915Z',
						updatedAt: '2024-06-18T10:29:03.222Z',
					},
				],
			},
			{
				player: {
					id: 'clwryeda5005a903xc1y5pitn',
					teamId: 'clv506pr50008d63sx8zkh2dh',
					user: {
						id: 'cligii21m001ap92z5wdohpe6',
						discordId: '224894241279574016',
						discordAvatar: 'https://cdn.discordapp.com/avatars/224894241279574016/d5861ca361eea4ea48a1e6bf719eb4bf.png',
						discordUsername: 'ejiejidayo',
						discordDiscriminator: '0',
						twitchUser: 'ejiejidayo',
						playableAccounts: [
							{
								id: '76561198005985428',
								type: 'STEAM',
								name: 'ejiejidayo',
								avatar: 'https://avatars.steamstatic.com/5232821e8a2b5074620e4f02a3581dab71f02e34_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'JP',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9661235128216275,
				averageNormalizedAccuracy: 0.9847448049928798,
				averageWeightedScore: 1.9024461668872001,
				scores: [
					{
						id: 'clxhs3ma5006c1320qdjofmkl',
						score: 1801505,
						accuracy: 0.959352126251774,
						normalizedAccuracy: 0.9871168323363563,
						weightedScore: 1.4451512741868302,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 21, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-16T16:46:26.189Z',
						updatedAt: '2024-06-16T16:49:54.527Z',
					},
					{
						id: 'clxhr796c00621320w8hwy939',
						score: 2084471,
						accuracy: 0.966969046818282,
						normalizedAccuracy: 0.986133852087799,
						weightedScore: 1.6996228576884518,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 46, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-16T16:21:16.213Z',
						updatedAt: '2024-06-16T16:26:07.744Z',
					},
					{
						id: 'clxhsn1xc006g1320x75td1qd',
						score: 1958141,
						accuracy: 0.9599883319320213,
						normalizedAccuracy: 0.982952671576391,
						weightedScore: 1.7126369244974189,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 37, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-16T17:01:32.928Z',
						updatedAt: '2024-06-16T17:04:57.231Z',
					},
					{
						id: 'clxht6m9z006k1320mct3fs6r',
						score: 1014420,
						accuracy: 0.9645755580383674,
						normalizedAccuracy: 0.9839681535829159,
						weightedScore: 1.7974140010614175,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 64, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-16T17:16:45.767Z',
						updatedAt: '2024-06-16T17:19:06.507Z',
					},
					{
						id: 'clxfxrny4003q1320thsvraaz',
						score: 988238,
						accuracy: 0.9719911676330142,
						normalizedAccuracy: 0.9870446630024301,
						weightedScore: 1.8365148974590482,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 66, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-15T09:49:33.820Z',
						updatedAt: '2024-06-15T09:52:09.818Z',
					},
					{
						id: 'clxhtu2av006s13204negsc6p',
						score: 655444,
						accuracy: 0.9587069879694299,
						normalizedAccuracy: 0.9779287028319833,
						weightedScore: 2.1008334573597263,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 45, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-16T17:34:59.623Z',
						updatedAt: '2024-06-16T17:36:32.906Z',
					},
					{
						id: 'clxfx1uz2003o1320rt1cd3eo',
						score: 497544,
						accuracy: 0.9812813711085033,
						normalizedAccuracy: 0.9880687595322827,
						weightedScore: 2.7249497559575078,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 118, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-15T09:29:29.871Z',
						updatedAt: '2024-06-15T09:32:26.481Z',
					},
				],
			},
			{
				player: {
					id: 'clwsttd0z005q903xb2u9qrnj',
					teamId: 'clv506pr50008d63sx8zkh2dh',
					user: {
						id: 'clwss2rfn005o903x5phna201',
						discordId: '970993622528979024',
						discordAvatar: 'https://cdn.discordapp.com/avatars/970993622528979024/3eb8c50aa20b2c39b35d179cfb860cdd.png',
						discordUsername: 'Hiroronron',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199551716563',
								type: 'STEAM',
								name: 'hiroron',
								avatar: 'https://avatars.steamstatic.com/5242ab04185405ebf40ec06dd32b9a2887f5e31a_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'JP',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9553442577350876,
				averageNormalizedAccuracy: 0.9737094886572251,
				averageWeightedScore: 2.386540832028376,
				scores: [
					{
						id: 'clxvyqw0100fz90r9h6xhpi5o',
						score: 1937092,
						accuracy: 0.9496689553402247,
						normalizedAccuracy: 0.9723864402457506,
						weightedScore: 2.154341713182524,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 62, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T15:01:16.033Z',
						updatedAt: '2024-06-26T15:04:38.804Z',
					},
					{
						id: 'clxvx9n8100dx90r9isawl43k',
						score: 1005558,
						accuracy: 0.9561490004041172,
						normalizedAccuracy: 0.9753721817201255,
						weightedScore: 2.224972258406909,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 101, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T14:19:51.889Z',
						updatedAt: '2024-06-26T14:22:15.501Z',
					},
					{
						id: 'clxub7xbo005kqiy33fnr06va',
						score: 2061421,
						accuracy: 0.9562763403574287,
						normalizedAccuracy: 0.975229221948726,
						weightedScore: 2.2498209767508475,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 90, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T11:14:53.940Z',
						updatedAt: '2024-06-25T11:19:45.200Z',
					},
					{
						id: 'clxvx4wlf00df90r9k6mokir3',
						score: 980748,
						accuracy: 0.9646243047461678,
						normalizedAccuracy: 0.9795637074776595,
						weightedScore: 2.319553721140204,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 105, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T14:16:10.756Z',
						updatedAt: '2024-06-26T14:18:46.686Z',
					},
					{
						id: 'clxvwpw7100cj90r974gvt002',
						score: 650047,
						accuracy: 0.950812886239807,
						normalizedAccuracy: 0.9698763273289897,
						weightedScore: 2.5024557225777646,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 66, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T14:04:30.398Z',
						updatedAt: '2024-06-26T14:06:03.542Z',
					},
					{
						id: 'clxu8bnya004wqiy34aafdt4b',
						score: 1743600,
						accuracy: 0.9285160836814736,
						normalizedAccuracy: 0.9553883607659545,
						weightedScore: 2.5414631375667387,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 76, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T09:53:49.571Z',
						updatedAt: '2024-06-25T09:57:17.339Z',
					},
					{
						id: 'clxmllmyr00lz1320ckwitcrk',
						score: 497585,
						accuracy: 0.9813622333763942,
						normalizedAccuracy: 0.9881501811133706,
						weightedScore: 2.7131782945736433,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 116, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-20T01:43:20.451Z',
						updatedAt: '2024-06-20T01:46:15.301Z',
					},
				],
			},
			{
				player: {
					id: 'clwryfu0y005e903xvowxpyfn',
					teamId: 'clv506pr50008d63sx8zkh2dh',
					user: {
						id: 'cli7ya23i000aob3080jqatcc',
						discordId: '335372379746336769',
						discordAvatar: 'https://cdn.discordapp.com/avatars/335372379746336769/11fe1d32b5ce691bdeb64d2decf78987.png',
						discordUsername: 'take_nyan',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198138892498',
								type: 'STEAM',
								name: 'take_nyan',
								avatar: 'https://avatars.steamstatic.com/79e5c90e59a21521c796ec63a7bb618241a5c3cb_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'JP',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9492907559533978,
				averageNormalizedAccuracy: 0.9675098684897494,
				averageWeightedScore: 2.631081906089531,
				scores: [
					{
						id: 'clxt4ae9m00jh84782gm47b28',
						score: 1005101,
						accuracy: 0.9557144555114461,
						normalizedAccuracy: 0.9749289003907083,
						weightedScore: 2.247020794133256,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 102, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T15:13:05.722Z',
						updatedAt: '2024-06-24T15:15:26.091Z',
					},
					{
						id: 'clxrru2wk004j8478usu2pp39',
						score: 498480,
						accuracy: 0.9831273975169367,
						normalizedAccuracy: 0.989927554651754,
						weightedScore: 2.456215905828309,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 107, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-23T16:36:42.932Z',
						updatedAt: '2024-06-23T16:39:39.311Z',
					},
					{
						id: 'clxt2md0c00ir8478h2jid129',
						score: 978005,
						accuracy: 0.9619264002203174,
						normalizedAccuracy: 0.97682401976011,
						weightedScore: 2.496452985940926,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 117, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T14:26:24.733Z',
						updatedAt: '2024-06-24T14:28:59.543Z',
					},
					{
						id: 'clxrrp0yr004f847857otf8nv',
						score: 649533,
						accuracy: 0.9500610670274618,
						normalizedAccuracy: 0.9691094344239426,
						weightedScore: 2.5407054621223395,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 70, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T16:32:47.139Z',
						updatedAt: '2024-06-23T16:34:20.075Z',
					},
					{
						id: 'clxt3olul00j18478dwe4ebda',
						score: 2047321,
						accuracy: 0.9497354656894013,
						normalizedAccuracy: 0.9685587106705945,
						weightedScore: 2.586384685157779,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 105, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T14:56:09.117Z',
						updatedAt: '2024-06-24T15:01:00.305Z',
					},
					{
						id: 'clxkhjuyo00cj1320u6hoplg0',
						score: 1731140,
						accuracy: 0.9218807829228872,
						normalizedAccuracy: 0.9485610271027612,
						weightedScore: 2.7773675640880002,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 80, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-18T14:14:26.688Z',
						updatedAt: '2024-06-18T14:17:58.158Z',
					},
					{
						id: 'clxrs8oxe004p84784kwpm5vr',
						score: 1881857,
						accuracy: 0.9225897227853345,
						normalizedAccuracy: 0.9446594324283759,
						weightedScore: 3.3134259453561086,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 95, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T16:48:04.659Z',
						updatedAt: '2024-06-23T16:51:32.398Z',
					},
				],
			},
			{
				player: {
					id: 'clwsi4u3m005n903xoq69937w',
					teamId: 'clv506pr50008d63sx8zkh2dh',
					user: {
						id: 'clwryt5jv005i903xj5u4qncw',
						discordId: '695255999333924875',
						discordAvatar: 'https://cdn.discordapp.com/avatars/695255999333924875/5b608f43bb59a4988ce445b9d1d39048.png',
						discordUsername: 'hyama24',
						discordDiscriminator: '0',
						twitchUser: 'hyama24',
						playableAccounts: [
							{
								id: '76561199039580793',
								type: 'STEAM',
								name: 'hyama24',
								avatar: 'https://avatars.steamstatic.com/0267948b538f82380692966ee6f11097125413da_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 3 Touch',
						countryCode: 'JP',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8593151029353281,
				averageNormalizedAccuracy: 0.8750450923615434,
				averageWeightedScore: 6.0698173134430435,
				scores: [
					{
						id: 'clxu7htqj004mqiy3ew5vygo5',
						score: 993743,
						accuracy: 0.9449145410892148,
						normalizedAccuracy: 0.9639118558840989,
						weightedScore: 2.7950016886187097,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 125, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-25T09:30:37.387Z',
						updatedAt: '2024-06-25T09:32:57.445Z',
					},
					{
						id: 'clxu75hbu004gqiy3mcr35ili',
						score: 971448,
						accuracy: 0.9554771986249834,
						normalizedAccuracy: 0.970274937600441,
						weightedScore: 2.9193215529472463,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 130, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-25T09:21:01.435Z',
						updatedAt: '2024-06-25T09:23:36.217Z',
					},
					{
						id: 'clxu7t6cp004qqiy3b6sh0oy7',
						score: 2032561,
						accuracy: 0.9428884224198917,
						normalizedAccuracy: 0.9615759626943378,
						weightedScore: 2.9387024394901418,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 115, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T09:39:26.953Z',
						updatedAt: '2024-06-25T09:44:17.815Z',
					},
					{
						id: 'clxvo7heh006190r9g9nvvhtt',
						score: 496112,
						accuracy: 0.9784571084836352,
						normalizedAccuracy: 0.9852249618708694,
						weightedScore: 3.136089577950043,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 136, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-26T10:06:14.489Z',
						updatedAt: '2024-06-26T10:09:09.909Z',
					},
					{
						id: 'clxu9m7ha0052qiy30fpzq6ps',
						score: 638771,
						accuracy: 0.9343196694335759,
						normalizedAccuracy: 0.9530524277233278,
						weightedScore: 3.3415686858163416,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 100, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T10:30:01.054Z',
						updatedAt: '2024-06-25T10:31:33.996Z',
					},
					{
						id: 'clxvpers2006t90r9pj7qbojj',
						score: 1753196,
						accuracy: 0.8595130297511221,
						normalizedAccuracy: 0.8800738516771991,
						weightedScore: 6.013325219289042,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 106, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T10:39:54.146Z',
						updatedAt: '2024-06-26T10:43:17.945Z',
					},
					{
						id: 'clxu8a1jb004uqiy3i39udtbq',
						score: 750450,
						accuracy: 0.39963575074487373,
						normalizedAccuracy: 0.4112016490805291,
						weightedScore: 21.344712029989775,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 101, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T09:52:33.863Z',
						updatedAt: '2024-06-25T09:56:01.741Z',
					},
				],
			},
			{
				player: {
					id: 'clwryyck5005k903xt74ufucr',
					teamId: 'clv506pr50008d63sx8zkh2dh',
					user: {
						id: 'clwrysj7y005h903xlz9gem2o',
						discordId: '199121271592714240',
						discordAvatar: 'https://cdn.discordapp.com/avatars/199121271592714240/2b172f1856cab393f2e4acfa801dbe81.png',
						discordUsername: 'KTNR',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{id: '76561198074458700', type: 'STEAM', name: '💪🌞✓', avatar: 'https://cdn.assets.beatleader.xyz/76561198074458700.gif'},
						],
						bio: null,
						controllers: null,
						countryCode: 'JP',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8538661635612529,
				averageNormalizedAccuracy: 0.8700755687899367,
				averageWeightedScore: 7.351301391175487,
				scores: [
					{
						id: 'clxtuue5v002iqiy37wlsdngw',
						score: 497325,
						accuracy: 0.9808494482629404,
						normalizedAccuracy: 0.9876338491357397,
						weightedScore: 2.7878265862761986,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 122, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T03:36:28.723Z',
						updatedAt: '2024-06-25T03:39:24.411Z',
					},
					{
						id: 'clxtvhwoi002qqiy3s7slg3al',
						score: 971500,
						accuracy: 0.9555283437344781,
						normalizedAccuracy: 0.9703268748083567,
						weightedScore: 2.9159680123823035,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 129, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-25T03:54:45.811Z',
						updatedAt: '2024-06-25T03:57:21.297Z',
					},
					{
						id: 'clxtwqoze002wqiy3lgs6m4xy',
						score: 1900450,
						accuracy: 0.931705033202517,
						normalizedAccuracy: 0.9539927945420438,
						weightedScore: 2.9232593276535024,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 87, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T04:29:35.355Z',
						updatedAt: '2024-06-25T04:33:01.480Z',
					},
					{
						id: 'clxtz3zp5003iqiy3k1i2kdke',
						score: 990813,
						accuracy: 0.9421285092828108,
						normalizedAccuracy: 0.9610698114744876,
						weightedScore: 2.936363197761374,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 128, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-25T05:35:55.001Z',
						updatedAt: '2024-06-25T05:38:16.670Z',
					},
					{
						id: 'clxtvmrql002sqiy39hvhyzoe',
						score: 2030073,
						accuracy: 0.9417342595706681,
						normalizedAccuracy: 0.9603989249595867,
						weightedScore: 2.998090418675705,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 118, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-25T03:58:32.686Z',
						updatedAt: '2024-06-25T04:03:24.949Z',
					},
					{
						id: 'clxtyszlg003eqiy39a7ypuq4',
						score: 1568340,
						accuracy: 0.8351851999776339,
						normalizedAccuracy: 0.8593563785981172,
						weightedScore: 5.859650119277519,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 97, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T05:27:21.652Z',
						updatedAt: '2024-06-25T05:30:51.631Z',
					},
					{
						id: 'clxtz7yx7003kqiy3xhb2a6ed',
						score: 266587,
						accuracy: 0.3899323508977219,
						normalizedAccuracy: 0.3977503480112259,
						weightedScore: 31.037952076201815,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 133, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-25T05:39:00.619Z',
						updatedAt: '2024-06-25T05:40:34.005Z',
					},
				],
			},
			{
				player: {
					id: 'clwryfjmm005c903xjsjq5hvw',
					teamId: 'clv506pr50008d63sx8zkh2dh',
					user: {
						id: 'clh38y0wc003mpf4mu8lcyzng',
						discordId: '739830794419437599',
						discordAvatar: 'https://cdn.discordapp.com/avatars/739830794419437599/21afb1a737bdc52dada0fae5c7059e1e.png',
						discordUsername: 'Yさん',
						discordDiscriminator: '0',
						twitchUser: 'ysan_jp',
						playableAccounts: [
							{
								id: '76561199116439465',
								type: 'STEAM',
								name: 'ACC | Yさん',
								avatar: 'https://avatars.akamai.steamstatic.com/f6c2c35f6940a6cfb88de1eb45a26504a5cda091_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'JP',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.6911784761493698,
				averageNormalizedAccuracy: 0.7025230758155011,
				averageWeightedScore: 8.537544184791534,
				scores: [
					{
						id: 'clxrpb3bf002t8478yn8p8wvt',
						score: 1021248,
						accuracy: 0.9710680580977964,
						normalizedAccuracy: 0.9905911840364402,
						weightedScore: 1.4679886138852705,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 27, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T15:25:57.771Z',
						updatedAt: '2024-06-23T15:28:17.158Z',
					},
					{
						id: 'clxrq1g8b003d8478alb1gt6r',
						score: 2091511,
						accuracy: 0.9702348452340914,
						normalizedAccuracy: 0.9894643768678023,
						weightedScore: 1.5315797011505228,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 27, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T15:46:27.563Z',
						updatedAt: '2024-06-23T15:51:21.021Z',
					},
					{
						id: 'clxrp2il2002p8478wl6ajpa6',
						score: 992097,
						accuracy: 0.9757867248934067,
						normalizedAccuracy: 0.9908990031052458,
						weightedScore: 1.5876434928414807,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 33, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T15:19:17.654Z',
						updatedAt: '2024-06-23T15:21:54.943Z',
					},
					{
						id: 'clxrop4tc002n8478dl9vphec',
						score: 500800,
						accuracy: 0.9877030185292929,
						normalizedAccuracy: 0.994534824605999,
						weightedScore: 1.7901234567901234,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 59, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T15:08:53.280Z',
						updatedAt: '2024-06-23T15:11:49.221Z',
					},
					{
						id: 'clxvy65bh00ff90r9y19eel9w',
						score: 638181,
						accuracy: 0.9334566862910009,
						normalizedAccuracy: 0.9521721420930208,
						weightedScore: 3.385474028873344,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 103, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T14:45:08.334Z',
						updatedAt: '2024-06-26T14:46:41.453Z',
					},
					{
						id: 'clxvzo9jw00h790r91qmzzhbe',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 184, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-26T15:27:13.244Z',
						updatedAt: '2024-06-26T15:27:13.244Z',
					},
					{
						id: 'clxvzrptm00hb90r9582o4gsr',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 181, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-26T15:29:54.298Z',
						updatedAt: '2024-06-26T15:29:54.298Z',
					},
				],
			},
			{
				player: {
					id: 'clwrygpaj005g903xyn76zae8',
					teamId: 'clv506pr50008d63sx8zkh2dh',
					user: {
						id: 'clh46q82z003oo14m3zkde079',
						discordId: '933704608277209170',
						discordAvatar: 'https://cdn.discordapp.com/avatars/933704608277209170/a4de288bcd85f8144fef70708a3b4f89.png',
						discordUsername: 'Charlotte-0205',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199275533668',
								type: 'STEAM',
								name: 'Charlotte-0205',
								avatar: 'https://avatars.akamai.steamstatic.com/239a08ff8d6e645510989fe44e718267836fa001_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'JP',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9609778845814393,
				averageNormalizedAccuracy: 0.5576484655461431,
				averageWeightedScore: 12.19151551032608,
				scores: [
					{
						id: 'clxiys1aq008u1320acyu855b',
						score: 1008783,
						accuracy: 0.9592155371193573,
						normalizedAccuracy: 0.9785003705327524,
						weightedScore: 2.06937810585227,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 95, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-17T12:41:09.267Z',
						updatedAt: '2024-06-17T12:43:29.412Z',
					},
					{
						id: 'clxiy17i1008q1320izf99w16',
						score: 981087,
						accuracy: 0.9649577315176819,
						normalizedAccuracy: 0.979902298121571,
						weightedScore: 2.2976912163033663,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 103, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-17T12:20:17.593Z',
						updatedAt: '2024-06-17T12:22:53.824Z',
					},
					{
						id: 'clxss6toy00f28478xgl4am48',
						score: 497103,
						accuracy: 0.9804116086660685,
						normalizedAccuracy: 0.9871929810625317,
						weightedScore: 2.851564743037611,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 125, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-24T09:34:23.698Z',
						updatedAt: '2024-06-24T09:37:18.520Z',
					},
					{
						id: 'clxvvrhgv00bh90r9cnvptowd',
						score: 2024883,
						accuracy: 0.9393266610226495,
						normalizedAccuracy: 0.9579436091061467,
						weightedScore: 3.1219745070893206,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 122, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-26T13:37:45.007Z',
						updatedAt: '2024-06-26T13:42:46.523Z',
					},
					{
						id: 'j36z8',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 185, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '3g771k',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 188, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '7sz51y',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 182, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
		],
		captain: {
			id: 'clv506pr10006d63sjf9vhlko',
			user: {
				id: 'clhsgg2gt0060pf4ml8jd2m6d',
				discordId: '435010295899881473',
				discordAvatar: 'https://cdn.discordapp.com/avatars/435010295899881473/bd2ff5ff1fc7ab364ff29c6a8a54d9f9.png',
				discordUsername: 'hibit',
				discordDiscriminator: '0',
				twitchUser: 'hibit0921',
				playableAccounts: [
					{
						id: '76561198405857645',
						type: 'STEAM',
						name: 'hibit_at',
						avatar: 'https://avatars.akamai.steamstatic.com/ca9e6930fcfad4f768a0c0ba0dc35f5c875f1039_full.jpg',
					},
				],
				bio: null,
				controllers: null,
				countryCode: 'JP',
				motto: null,
				pronouns: null,
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9639134670873045,
		averageNormalizedAccuracy: 0.9824572654684004,
		averageWeightedScore: 1.9086977685623148,
	},
	{
		id: 'clv3s27s3001kykbvlk7fxfhb',
		name: 'China',
		image: 'https://cdn.cube.community/1713372399403-china.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwo9cyca0031903x9d311xy5',
					teamId: 'clv3s27s3001kykbvlk7fxfhb',
					user: {
						id: 'clgipp2vi0004pf4m62csjvre',
						discordId: '706292981195472918',
						discordAvatar: 'https://cdn.discordapp.com/avatars/706292981195472918/1dfad793358f7e617b3cc3437fa1e6bc.png',
						discordUsername: 'ViSi',
						discordDiscriminator: '0',
						twitchUser: 'visikoa',
						playableAccounts: [
							{
								id: '76561199001767132',
								type: 'STEAM',
								name: 'ViSi',
								avatar: 'https://avatars.steamstatic.com/7d644368f20b6a7c49427675ff94ad6e64c376c3_full.jpg',
							},
						],
						bio: 'i suck',
						controllers: 'Valve Index Knuckles',
						countryCode: 'HK',
						motto: null,
						pronouns: 'She/her',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.964201770629198,
				averageNormalizedAccuracy: 0.9827339301208193,
				averageWeightedScore: 1.8033444408223214,
				scores: [
					{
						id: 'clxuy73ai00ehqiy3obidv5mk',
						score: 502496,
						accuracy: 0.9910479552693601,
						normalizedAccuracy: 0.9979028978139298,
						weightedScore: 1.3031869078380707,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 12, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T21:58:06.186Z',
						updatedAt: '2024-06-25T22:01:00.990Z',
					},
					{
						id: 'clxrkuvy8000p84783x3kwsy4',
						score: 994239,
						accuracy: 0.977893509980673,
						normalizedAccuracy: 0.9930384165543857,
						weightedScore: 1.4495034180317297,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 22, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T13:21:23.264Z',
						updatedAt: '2024-06-23T13:23:55.026Z',
					},
					{
						id: 'clxuykn8w00f3qiy364ugqma6',
						score: 1018547,
						accuracy: 0.9684997741697768,
						normalizedAccuracy: 0.987971265282051,
						weightedScore: 1.5983017320403339,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 42, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T22:08:38.576Z',
						updatedAt: '2024-06-25T22:10:56.028Z',
					},
					{
						id: 'clxrlbsex000z8478kmde7jdf',
						score: 2088279,
						accuracy: 0.9687355468704698,
						normalizedAccuracy: 0.9879353632188008,
						weightedScore: 1.6087267866520265,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 36, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T13:34:31.833Z',
						updatedAt: '2024-06-23T13:39:23.101Z',
					},
					{
						id: 'clxrly79n001784782z1p7nm6',
						score: 1773152,
						accuracy: 0.94425335559301,
						normalizedAccuracy: 0.9715810866419327,
						weightedScore: 1.9819569086296338,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 51, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T13:51:57.515Z',
						updatedAt: '2024-06-23T13:55:27.898Z',
					},
					{
						id: 'clxuybhi600epqiy3q6esq5ow',
						score: 653371,
						accuracy: 0.9556748455040772,
						normalizedAccuracy: 0.9748357670495661,
						weightedScore: 2.255097484744754,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 53, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T22:01:31.230Z',
						updatedAt: '2024-06-25T22:03:03.462Z',
					},
					{
						id: 'clxrmklxd001h8478xw3wnlvx',
						score: 1924116,
						accuracy: 0.9433074070170192,
						normalizedAccuracy: 0.9658727142850689,
						weightedScore: 2.4266378478197,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 76, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T14:09:22.945Z',
						updatedAt: '2024-06-23T14:12:45.674Z',
					},
				],
			},
			{
				player: {
					id: 'clv3s27s0001iykbvmpsillcv',
					teamId: 'clv3s27s3001kykbvlk7fxfhb',
					user: {
						id: 'clgihp1ga000gt74no12b3rm0',
						discordId: '373933951842254850',
						discordAvatar: 'https://cdn.discordapp.com/avatars/373933951842254850/adafbb5adb2963f55b0c22c5420022c7.png',
						discordUsername: 'SL4SH',
						discordDiscriminator: '0',
						twitchUser: 'SL4SH5',
						playableAccounts: [
							{
								id: '76561198127896190',
								type: 'STEAM',
								name: 'SL4SH',
								avatar: 'https://avatars.steamstatic.com/0f7cf5dc23da7cccb15bbeb41dc6bd7fd5eb9694_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest/Rift S Touch',
						countryCode: 'CN',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9601659136920214,
				averageNormalizedAccuracy: 0.9786104604395789,
				averageWeightedScore: 2.0468503112787064,
				scores: [
					{
						id: 'clxqh53gx010913201upibd4h',
						score: 1017348,
						accuracy: 0.9673596881165759,
						normalizedAccuracy: 0.9868082580304729,
						weightedScore: 1.6561489844164616,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 46, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-22T18:49:34.930Z',
						updatedAt: '2024-06-22T18:51:53.311Z',
					},
					{
						id: 'clxi5z88u00861320s4ywo73d',
						score: 990936,
						accuracy: 0.9746448119679556,
						normalizedAccuracy: 0.9897394050592834,
						weightedScore: 1.6625177350702953,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 44, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-16T23:14:55.998Z',
						updatedAt: '2024-06-16T23:17:30.408Z',
					},
					{
						id: 'clxuzx26w00gbqiy3t0wr09u1',
						score: 2077098,
						accuracy: 0.9635487724262702,
						normalizedAccuracy: 0.9826457897010145,
						weightedScore: 1.8756146464887573,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 59, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T22:46:17.433Z',
						updatedAt: '2024-06-25T22:51:07.296Z',
					},
					{
						id: 'clxuzj97300fzqiy3wg5g3h5w',
						score: 500304,
						accuracy: 0.9867247823128581,
						normalizedAccuracy: 0.993549822064057,
						weightedScore: 1.9325294286534596,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 66, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T22:35:33.327Z',
						updatedAt: '2024-06-25T22:38:29.171Z',
					},
					{
						id: 'clxuzncf900g5qiy3zliwdvv0',
						score: 1943588,
						accuracy: 0.9528536515414842,
						normalizedAccuracy: 0.9756473190867331,
						weightedScore: 2.018025769085491,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 56, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T22:38:44.134Z',
						updatedAt: '2024-06-25T22:42:06.827Z',
					},
					{
						id: 'clxuyymrs00fjqiy390r758tj',
						score: 649070,
						accuracy: 0.9493838446630344,
						normalizedAccuracy: 0.9684186340055831,
						weightedScore: 2.575159994046733,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 73, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T22:19:31.144Z',
						updatedAt: '2024-06-25T22:21:03.826Z',
					},
					{
						id: 'clxuz240b00frqiy3rppnkch6',
						score: 1740088,
						accuracy: 0.9266458448159716,
						normalizedAccuracy: 0.9534639951299084,
						weightedScore: 2.607955621189746,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 78, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T22:22:13.452Z',
						updatedAt: '2024-06-25T22:25:40.920Z',
					},
				],
			},
			{
				player: {
					id: 'clwoaya490039903xhxl1x5hn',
					teamId: 'clv3s27s3001kykbvlk7fxfhb',
					user: {
						id: 'clgty6s72002io14m9av8plpt',
						discordId: '390005201668407306',
						discordAvatar: 'https://cdn.discordapp.com/avatars/390005201668407306/68626f060de4a306123403278ae227f6.png',
						discordUsername: 'switronic',
						discordDiscriminator: '0',
						twitchUser: 'switronic',
						playableAccounts: [
							{
								id: '3204119979600541',
								type: 'OCULUS',
								name: 'rso somtionge \\',
								avatar: 'https://cdn.assets.beatleader.xyz/3204119979600541R16.png',
							},
						],
						bio: 'challenged player',
						controllers: 'Quest 2 Touch',
						countryCode: 'CN',
						motto: 'professionally selling since 2020',
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9602425733678704,
				averageNormalizedAccuracy: 0.9787190461759231,
				averageWeightedScore: 2.1636018356758764,
				scores: [
					{
						id: 'clxjw7dee00bi1320xeem54jv',
						score: 1950069,
						accuracy: 0.9560309939183873,
						normalizedAccuracy: 0.9789006681890126,
						weightedScore: 1.8820245939480422,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 47, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-18T04:16:52.118Z',
						updatedAt: '2024-06-18T04:20:14.730Z',
					},
					{
						id: 'clxjwmuvg00bp1320dxeuvdyc',
						score: 1011865,
						accuracy: 0.9621461002686191,
						normalizedAccuracy: 0.9814898520584937,
						weightedScore: 1.9206831668837747,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 73, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-18T04:28:54.605Z',
						updatedAt: '2024-06-18T04:31:14.678Z',
					},
					{
						id: 'clxjwqei900br13208luhgxd7',
						score: 985817,
						accuracy: 0.9696099693621123,
						normalizedAccuracy: 0.9846265864569735,
						weightedScore: 1.9926480072230104,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 83, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-18T04:31:40.017Z',
						updatedAt: '2024-06-18T04:34:14.416Z',
					},
					{
						id: 'clxjwenyg00bm1320xonbsm9i',
						score: 2070226,
						accuracy: 0.9603609078362926,
						normalizedAccuracy: 0.9793947433532613,
						weightedScore: 2.0396476822456675,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 74, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-18T04:22:32.393Z',
						updatedAt: '2024-06-18T04:27:22.478Z',
					},
					{
						id: 'clxjwu0uu00bt1320h4d926yn',
						score: 1760584,
						accuracy: 0.9375605417941406,
						normalizedAccuracy: 0.9646945754477904,
						weightedScore: 2.21990609261994,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 65, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-18T04:34:28.950Z',
						updatedAt: '2024-06-18T04:37:56.105Z',
					},
					{
						id: 'clxjwcdah00bk1320nnwkde7u',
						score: 653151,
						accuracy: 0.9553530551797272,
						normalizedAccuracy: 0.9745075249501296,
						weightedScore: 2.2714689685965173,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 55, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-18T04:20:45.258Z',
						updatedAt: '2024-06-18T04:22:17.916Z',
					},
					{
						id: 'clxjw2tnf00bg132077uzr1tc',
						score: 497217,
						accuracy: 0.9806364452158135,
						normalizedAccuracy: 0.9874193727758007,
						weightedScore: 2.818834338214183,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 123, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-18T04:13:19.899Z',
						updatedAt: '2024-06-18T04:16:14.711Z',
					},
				],
			},
			{
				player: {
					id: 'clwo9dli60033903xvr0hkfxd',
					teamId: 'clv3s27s3001kykbvlk7fxfhb',
					user: {
						id: 'cli8jtsad0010ob30w2r8fjx4',
						discordId: '692755186136907846',
						discordAvatar: 'https://cdn.discordapp.com/avatars/692755186136907846/61f253988109fd68c59d9129607ea73a.png',
						discordUsername: 'Black Mage',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198286832326',
								type: 'STEAM',
								name: '可爱母肥',
								avatar: 'https://avatars.steamstatic.com/438cacc017edc2395ba0685cd0d23c9d93b4e3ac_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'CN',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9575128703111775,
				averageNormalizedAccuracy: 0.9759395867702579,
				averageWeightedScore: 2.295656966478019,
				scores: [
					{
						id: 'clxsfpavq00dc84785labkxzp',
						score: 1011087,
						accuracy: 0.9614063280005705,
						normalizedAccuracy: 0.9807352068193547,
						weightedScore: 1.9582187484923048,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 78, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T03:44:50.774Z',
						updatedAt: '2024-06-24T03:47:09.607Z',
					},
					{
						id: 'clxsi0iiv00ds847882nov2xk',
						score: 1772281,
						accuracy: 0.94378952357369,
						normalizedAccuracy: 0.9711038308136308,
						weightedScore: 1.9984474989586882,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 52, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T04:49:33.128Z',
						updatedAt: '2024-06-24T04:52:59.015Z',
					},
					{
						id: 'clxvq6qt5007590r9em47zplz',
						score: 1936382,
						accuracy: 0.9493208743206905,
						normalizedAccuracy: 0.9720300326138083,
						weightedScore: 2.169240777269484,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 65, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T11:01:39.258Z',
						updatedAt: '2024-06-26T11:05:03.152Z',
					},
					{
						id: 'clxsg2tp300de8478wwhg6tqn',
						score: 2061660,
						accuracy: 0.9563872105025109,
						normalizedAccuracy: 0.9753422894803199,
						weightedScore: 2.2441161025445173,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 88, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T03:55:21.687Z',
						updatedAt: '2024-06-24T04:00:10.359Z',
					},
					{
						id: 'clxok3ggu00s51320liupimw2',
						score: 978896,
						accuracy: 0.9628027520003147,
						normalizedAccuracy: 0.9777139438418951,
						weightedScore: 2.438991358183929,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 114, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-21T10:36:44.958Z',
						updatedAt: '2024-06-21T10:39:19.882Z',
					},
					{
						id: 'clxojr92500s113204u7g442v',
						score: 498241,
						accuracy: 0.9826560296626465,
						normalizedAccuracy: 0.9894529264107779,
						weightedScore: 2.5248349124318117,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 111, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-21T10:27:15.485Z',
						updatedAt: '2024-06-21T10:30:10.930Z',
					},
					{
						id: 'clxrlf72z00138478t30u6kgk',
						score: 646912,
						accuracy: 0.9462273741178191,
						normalizedAccuracy: 0.9651988774120199,
						weightedScore: 2.7357493674653965,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 80, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T13:37:10.811Z',
						updatedAt: '2024-06-23T13:38:42.582Z',
					},
				],
			},
			{
				player: {
					id: 'clwo9gtj60037903xnwgwkbbd',
					teamId: 'clv3s27s3001kykbvlk7fxfhb',
					user: {
						id: 'cljby4ezo0028md2zukfrwgo0',
						discordId: '275335326740774922',
						discordAvatar: 'https://cdn.discordapp.com/avatars/275335326740774922/531b0010e26f169c23ecb25621d66fbc.png',
						discordUsername: 'Pryd',
						discordDiscriminator: '0',
						twitchUser: 'pryd__',
						playableAccounts: [
							{
								id: '76561198317122281',
								type: 'STEAM',
								name: 'Pryd_',
								avatar: 'https://avatars.steamstatic.com/d10978c0117b12cfba98fe94bcb8c9b8107b2be3_full.jpg',
							},
						],
						bio: 'Hi',
						controllers: 'Quest Pro Touch',
						countryCode: 'CN',
						motto: 'Hi',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8203061808937717,
				averageNormalizedAccuracy: 0.8347640629122407,
				averageWeightedScore: 5.6205363605054,
				scores: [
					{
						id: 'clx7o06x2001s7pioh4pt4bo7',
						score: 500271,
						accuracy: 0.9866596980484582,
						normalizedAccuracy: 0.9934842876207423,
						weightedScore: 1.9420040195233994,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 68, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-09T14:54:06.087Z',
						updatedAt: '2024-06-09T14:57:01.162Z',
					},
					{
						id: 'clx7oel7j001w7pio9884jffl',
						score: 984204,
						accuracy: 0.9680234874079757,
						normalizedAccuracy: 0.9830155342191291,
						weightedScore: 2.0966722559009416,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 93, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-09T15:05:17.791Z',
						updatedAt: '2024-06-09T15:07:53.474Z',
					},
					{
						id: 'clxojr3xh00rz1320q1j8x18p',
						score: 1007920,
						accuracy: 0.958394941403,
						normalizedAccuracy: 0.9776632769063037,
						weightedScore: 2.1110146186134027,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 98, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-21T10:27:08.838Z',
						updatedAt: '2024-06-21T10:29:28.148Z',
					},
					{
						id: 'clxbky3kd00a57pios7zz3ri9',
						score: 2056450,
						accuracy: 0.9539703341180836,
						normalizedAccuracy: 0.9728775119087549,
						weightedScore: 2.368477586289206,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 96, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-12T08:39:34.285Z',
						updatedAt: '2024-06-12T08:44:25.603Z',
					},
					{
						id: 'clxeii49g001w1320nelczbnt',
						score: 1906062,
						accuracy: 0.9344563440217085,
						normalizedAccuracy: 0.956809920782129,
						weightedScore: 2.8054937675746,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 84, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-14T09:54:27.988Z',
						updatedAt: '2024-06-14T09:57:51.816Z',
					},
					{
						id: 'clxka3fod00c713203udou1id',
						score: 643091,
						accuracy: 0.9406384612571763,
						normalizedAccuracy: 0.9594979089486256,
						weightedScore: 3.0200922756362556,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 93, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-18T10:45:43.070Z',
						updatedAt: '2024-06-18T10:47:16.125Z',
					},
					{
						id: 'clxunw4t600aqqiy3wjalnwz2',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 161, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T17:09:38.779Z',
						updatedAt: '2024-06-25T17:09:38.779Z',
					},
				],
			},
			{
				player: {
					id: 'clwo9ejdi0035903xuxoy6ilv',
					teamId: 'clv3s27s3001kykbvlk7fxfhb',
					user: {
						id: 'clwm25ic90023903x481dotxh',
						discordId: '1243894579124633612',
						discordAvatar: 'https://cdn.discordapp.com/avatars/1243894579124633612/6dcb4764b5b6e9784fe7e4935fb7c0fb.png',
						discordUsername: 'Ashu',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198409886056',
								type: 'STEAM',
								name: '四季鼠',
								avatar: 'https://avatars.steamstatic.com/57abde251f06fc9aff65db3ac5e8157701826aaf_full.jpg',
							},
						],
						bio: 'robin bro',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'CN',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8215519473467358,
				averageNormalizedAccuracy: 0.836730089031546,
				averageWeightedScore: 5.620548769604603,
				scores: [
					{
						id: 'clxleket400gw132036rqcame',
						score: 1008958,
						accuracy: 0.9593819383364632,
						normalizedAccuracy: 0.9786701172125073,
						weightedScore: 2.0609350123027936,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 94, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-19T05:38:39.737Z',
						updatedAt: '2024-06-19T05:41:00.641Z',
					},
					{
						id: 'clxlfqcdn00hc1320wl9gbd7i',
						score: 1763299,
						accuracy: 0.9390063557234795,
						normalizedAccuracy: 0.9661822328230367,
						weightedScore: 2.1685031618009014,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 62, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-19T06:11:16.139Z',
						updatedAt: '2024-06-19T06:14:45.875Z',
					},
					{
						id: 'clxlg0vfg00he1320zytbonco',
						score: 2064124,
						accuracy: 0.9575302399480441,
						normalizedAccuracy: 0.9765079731533209,
						weightedScore: 2.185300997756242,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 84, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-19T06:19:27.389Z',
						updatedAt: '2024-06-19T06:24:21.378Z',
					},
					{
						id: 'clxldzjmc00go13203fm3wgzy',
						score: 653240,
						accuracy: 0.9554832339927597,
						normalizedAccuracy: 0.974640313799447,
						weightedScore: 2.264845959220122,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 54, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-19T05:22:26.196Z',
						updatedAt: '2024-06-19T05:24:00.079Z',
					},
					{
						id: 'clxlf1dyf00h413209schs9qx',
						score: 976703,
						accuracy: 0.9606458053633515,
						normalizedAccuracy: 0.9755235919772994,
						weightedScore: 2.580420482393912,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 120, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-19T05:51:51.783Z',
						updatedAt: '2024-06-19T05:54:28.761Z',
					},
					{
						id: 'clxlfdfgr00h813204qmywmmc',
						score: 496294,
						accuracy: 0.9788160580630528,
						normalizedAccuracy: 0.985586394255211,
						weightedScore: 3.0838357737582545,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 135, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-19T06:01:13.612Z',
						updatedAt: '2024-06-19T06:04:10.307Z',
					},
					{
						id: 'clxt4vinh00jx8478n7h44p6j',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 168, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T15:29:31.182Z',
						updatedAt: '2024-06-24T15:29:31.182Z',
					},
				],
			},
			{
				player: {
					id: 'clwodlwfi003b903xihkpeidy',
					teamId: 'clv3s27s3001kykbvlk7fxfhb',
					user: {
						id: 'clwm54ndb0024903xj96vq4hg',
						discordId: '730042799814344757',
						discordAvatar: 'https://cdn.discordapp.com/avatars/730042799814344757/2410f720d4aa9fa0113ceda646098482.png',
						discordUsername: 'Dimension Ei',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199186850200',
								type: 'STEAM',
								name: '🍳',
								avatar: 'https://avatars.steamstatic.com/a1b85d155daf081bd261206954ae5426ed71a2b3_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'CN',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9680862247668678,
				averageNormalizedAccuracy: 0.42074284476938156,
				averageWeightedScore: 15.256469697092813,
				scores: [
					{
						id: 'clxp0djh700tr1320shxzk64j',
						score: 499399,
						accuracy: 0.9849398956679519,
						normalizedAccuracy: 0.991752589603457,
						weightedScore: 2.1923629055412,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 89, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-21T18:12:29.275Z',
						updatedAt: '2024-06-21T18:15:29.445Z',
					},
					{
						id: 'clxoyf9vg00t513207ri889rh',
						score: 981804,
						accuracy: 0.9656629438928314,
						normalizedAccuracy: 0.9806184323153307,
						weightedScore: 2.2514510512059847,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 102, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-21T17:17:50.908Z',
						updatedAt: '2024-06-21T17:20:29.468Z',
					},
					{
						id: 'clxoz9xa600t913200uogb0lq',
						score: 1002936,
						accuracy: 0.9536558347398199,
						normalizedAccuracy: 0.9728288914668829,
						weightedScore: 2.3514739229024944,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 110, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-21T17:41:40.926Z',
						updatedAt: '2024-06-21T17:44:03.698Z',
					},
					{
						id: 'tbwnps',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 170, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'fd8bzv',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 174, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'qlmf7m',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 177, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'n6sfwo',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 163, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwql0t0e003q903xbgou3ol4',
					teamId: 'clv3s27s3001kykbvlk7fxfhb',
					user: {
						id: 'cliotofqy0010p92z4krjsapv',
						discordId: '1064248152955297812',
						discordAvatar: 'https://cdn.discordapp.com/avatars/1064248152955297812/bb1809b511f7cea55199a59964fdfe31.png',
						discordUsername: 'Baka',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199036202201',
								type: 'STEAM',
								name: '🐟',
								avatar: 'https://avatars.steamstatic.com/a7afc472c606eeac0a589d6383659f33a18004e4_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'CN',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9637426756029702,
				averageNormalizedAccuracy: 0.41886123681412496,
				averageWeightedScore: 15.472361856722712,
				scores: [
					{
						id: 'clxszfbhf00ht8478woudn0qu',
						score: 980548,
						accuracy: 0.9644275927865724,
						normalizedAccuracy: 0.9793639489856764,
						weightedScore: 2.332451954082291,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 108, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T12:56:57.315Z',
						updatedAt: '2024-06-24T12:59:35.220Z',
					},
					{
						id: 'clxt04g3x00i58478sdtrcn57',
						score: 999755,
						accuracy: 0.9506311360448808,
						normalizedAccuracy: 0.9697433818194516,
						weightedScore: 2.5049452405075505,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 117, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-24T13:16:29.708Z',
						updatedAt: '2024-06-24T13:18:49.814Z',
					},
					{
						id: 'clxt16ftl00ib84787b40qjo5',
						score: 494952,
						accuracy: 0.9761692979774572,
						normalizedAccuracy: 0.9829213268937468,
						weightedScore: 3.4691358024691357,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 141, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-24T13:46:02.266Z',
						updatedAt: '2024-06-24T13:48:57.797Z',
					},
					{
						id: 'ro9svo',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 174, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'cx9mer',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 176, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '3hx0o',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 180, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '61l764',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 166, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
		],
		captain: {
			id: 'clv3s27s0001iykbvmpsillcv',
			user: {
				id: 'clgihp1ga000gt74no12b3rm0',
				discordId: '373933951842254850',
				discordAvatar: 'https://cdn.discordapp.com/avatars/373933951842254850/adafbb5adb2963f55b0c22c5420022c7.png',
				discordUsername: 'SL4SH',
				discordDiscriminator: '0',
				twitchUser: 'SL4SH5',
				playableAccounts: [
					{
						id: '76561198127896190',
						type: 'STEAM',
						name: 'SL4SH',
						avatar: 'https://avatars.steamstatic.com/0f7cf5dc23da7cccb15bbeb41dc6bd7fd5eb9694_full.jpg',
					},
				],
				bio: null,
				controllers: 'Quest/Rift S Touch',
				countryCode: 'CN',
				motto: null,
				pronouns: 'He/him',
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9618267553741235,
		averageNormalizedAccuracy: 0.9803218818112311,
		averageWeightedScore: 1.987335088553489,
	},
	{
		id: 'clv45voyp0003d63ss8ochv6p',
		name: 'France',
		image: 'https://cdn.cube.community/1713379439442-france.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwrvt3rl0051903xzeqimjrb',
					teamId: 'clv45voyp0003d63ss8ochv6p',
					user: {
						id: 'clgjbzph0000wo14mu173qzva',
						discordId: '517320770196865024',
						discordAvatar: 'https://cdn.discordapp.com/avatars/517320770196865024/33c308a61b9f5dc2c35300780c42e332.png',
						discordUsername: 'risi',
						discordDiscriminator: '0',
						twitchUser: 'la_rizette',
						playableAccounts: [
							{
								id: '76561198964267559',
								type: 'STEAM',
								name: 'BSFR | risi',
								avatar: 'https://avatars.akamai.steamstatic.com/bfb7fd63b7994208ee2d01655ad78dc7dd08feec_full.jpg',
							},
						],
						bio: null,
						controllers: 'Rift Touch',
						countryCode: 'FR',
						motto: 'Pausing is cheating',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9702416485353049,
				averageNormalizedAccuracy: 0.988929862743395,
				averageWeightedScore: 1.5803504752200908,
				scores: [
					{
						id: 'clxrucimu005u8478dj6v4mpi',
						score: 1970623,
						accuracy: 0.9661076943064241,
						normalizedAccuracy: 0.9892184181424536,
						weightedScore: 1.450707180929198,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 18, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T17:47:02.358Z',
						updatedAt: '2024-06-23T17:50:28.378Z',
					},
					{
						id: 'clxrty1eq005k84781ox5y59j',
						score: 1799341,
						accuracy: 0.9581997353335091,
						normalizedAccuracy: 0.9859310899569703,
						weightedScore: 1.486122155325836,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 22, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T17:35:46.850Z',
						updatedAt: '2024-06-23T17:39:17.543Z',
					},
					{
						id: 'clxrstux800518478zb7yj3vu',
						score: 501645,
						accuracy: 0.9893695701480174,
						normalizedAccuracy: 0.9962129035332994,
						weightedScore: 1.5475165087568188,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 35, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T17:04:32.205Z',
						updatedAt: '2024-06-23T17:07:28.056Z',
					},
					{
						id: 'clxdjqap70013132028zc2p4r',
						score: 1019331,
						accuracy: 0.9692452516224118,
						normalizedAccuracy: 0.9887317304073532,
						weightedScore: 1.560476672938679,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 39, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-13T17:41:03.020Z',
						updatedAt: '2024-06-13T17:43:24.133Z',
					},
					{
						id: 'clxrteiyy005784787m22wd0j',
						score: 991951,
						accuracy: 0.9756431251629021,
						normalizedAccuracy: 0.990753179406098,
						weightedScore: 1.597059202889204,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 35, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T17:20:36.490Z',
						updatedAt: '2024-06-23T17:23:13.432Z',
					},
					{
						id: 'clxrtq6jw005e8478gdirymy1',
						score: 2087635,
						accuracy: 0.9684368005381145,
						normalizedAccuracy: 0.9876306958951755,
						weightedScore: 1.6240989163125985,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 37, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T17:29:40.269Z',
						updatedAt: '2024-06-23T17:34:34.095Z',
					},
					{
						id: 'clxrupsup005y8478jt5283d7',
						score: 659534,
						accuracy: 0.9646893626357553,
						normalizedAccuracy: 0.9840310218624159,
						weightedScore: 1.7964726893883018,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 22, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T17:57:22.129Z',
						updatedAt: '2024-06-23T17:58:56.318Z',
					},
				],
			},
			{
				player: {
					id: 'clwrvt9hf0053903x23t5bjt7',
					teamId: 'clv45voyp0003d63ss8ochv6p',
					user: {
						id: 'clgil9eqj000qs74ma08uddve',
						discordId: '618067456682098708',
						discordAvatar: 'https://cdn.discordapp.com/avatars/618067456682098708/f3de8814bb6ded23d77aba6512fc7ac8.png',
						discordUsername: 'Kinaro',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199100396335',
								type: 'STEAM',
								name: 'Kinaro_',
								avatar: 'https://avatars.akamai.steamstatic.com/3bb83bfdb33fdc94ede98409d9347895dd52f73f_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'FR',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9618483236141872,
				averageNormalizedAccuracy: 0.9803402843124446,
				averageWeightedScore: 1.9780820403849184,
				scores: [
					{
						id: 'clxeves4e002k13209tvmin2w',
						score: 1016506,
						accuracy: 0.9665590605462714,
						normalizedAccuracy: 0.9859915340055948,
						weightedScore: 1.6967723259516572,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 54, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-14T15:55:47.294Z',
						updatedAt: '2024-06-14T15:58:05.984Z',
					},
					{
						id: 'clxt6senq00kr8478mbxjg87e',
						score: 500733,
						accuracy: 0.9875708777500567,
						normalizedAccuracy: 0.9944017698271479,
						weightedScore: 1.8093597473442435,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 61, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T16:23:05.270Z',
						updatedAt: '2024-06-24T16:26:00.406Z',
					},
					{
						id: 'clxevluak002m1320kwvkucxp',
						score: 988548,
						accuracy: 0.972296071170387,
						normalizedAccuracy: 0.987354288665004,
						weightedScore: 1.8165226363988134,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 63, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-14T16:01:16.700Z',
						updatedAt: '2024-06-14T16:03:50.609Z',
					},
					{
						id: 'clxoo5fpj00sd1320bju8e3hs',
						score: 2073743,
						accuracy: 0.961992415368736,
						normalizedAccuracy: 0.9810585864855441,
						weightedScore: 1.9556977132763642,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 66, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-21T12:30:15.752Z',
						updatedAt: '2024-06-21T12:35:03.648Z',
					},
					{
						id: 'clxt7nxfx00lf8478k2f2ber7',
						score: 1939813,
						accuracy: 0.9510029390784678,
						normalizedAccuracy: 0.9737523348464762,
						weightedScore: 2.097242623914047,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 60, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T16:47:35.949Z',
						updatedAt: '2024-06-24T16:50:56.784Z',
					},
					{
						id: 'clxt6zfgq00kz8478ycui1cpd',
						score: 1761846,
						accuracy: 0.9382325923204116,
						normalizedAccuracy: 0.9653860758557318,
						weightedScore: 2.1960127229353628,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 63, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T16:28:32.906Z',
						updatedAt: '2024-06-24T16:31:58.627Z',
					},
					{
						id: 'clxt7e6d600l984782tsjlme9',
						score: 653104,
						accuracy: 0.9552843090649797,
						normalizedAccuracy: 0.9744374005016136,
						weightedScore: 2.2749665128739394,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 56, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T16:40:00.954Z',
						updatedAt: '2024-06-24T16:41:33.841Z',
					},
				],
			},
			{
				player: {
					id: 'clwrvr9ez004z903xfa279zzn',
					teamId: 'clv45voyp0003d63ss8ochv6p',
					user: {
						id: 'clsffmk01000evo934vpvgsnq',
						discordId: '384070698772987904',
						discordAvatar: 'https://cdn.discordapp.com/avatars/384070698772987904/e05adaf6e47c4db85170bf20857b13c0.png',
						discordUsername: 'MaëlMinhAnh',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198986498175',
								type: 'STEAM',
								name: 'MaëlMinhAnh',
								avatar: 'https://avatars.steamstatic.com/cd0aff49cc979c8dafa792fd701ddab55928d363_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'FR',
						motto: "J'aime pas la tech",
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.961550002008866,
				averageNormalizedAccuracy: 0.9800563607446537,
				averageWeightedScore: 2.043103045720701,
				scores: [
					{
						id: 'clxs4b3gj00ay8478o1fibyg5',
						score: 2082924,
						accuracy: 0.9662514061720807,
						normalizedAccuracy: 0.9854019881908296,
						weightedScore: 1.7365493865469996,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 49, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T22:25:52.195Z',
						updatedAt: '2024-06-23T22:30:40.349Z',
					},
					{
						id: 'clxnvvw2200r01320xs0mhrw7',
						score: 1956808,
						accuracy: 0.9593348220742197,
						normalizedAccuracy: 0.9822835287969837,
						weightedScore: 1.740609392705754,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 38, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-20T23:19:01.130Z',
						updatedAt: '2024-06-20T23:22:21.960Z',
					},
					{
						id: 'clxs4i5yh00b08478te2cnpb9',
						score: 1014531,
						accuracy: 0.9646811039532175,
						normalizedAccuracy: 0.984075821476932,
						weightedScore: 1.7920586674386068,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 62, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-23T22:31:22.025Z',
						updatedAt: '2024-06-23T22:33:40.457Z',
					},
					{
						id: 'clxs5aku900b68478o4jz26si',
						score: 1778923,
						accuracy: 0.9473265755511001,
						normalizedAccuracy: 0.9747432489669959,
						weightedScore: 1.8726949146124428,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 46, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T22:53:27.682Z',
						updatedAt: '2024-06-23T22:56:53.252Z',
					},
					{
						id: 'clxf6idkl003e1320gm8v36hq',
						score: 500284,
						accuracy: 0.9866853373041309,
						normalizedAccuracy: 0.9935101042196238,
						weightedScore: 1.9382716049382716,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 67, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-14T21:06:30.837Z',
						updatedAt: '2024-06-14T21:09:25.698Z',
					},
					{
						id: 'clxf6nlbp003g13206tlm1218',
						score: 984316,
						accuracy: 0.9681336461053491,
						normalizedAccuracy: 0.9831273989746396,
						weightedScore: 2.089449245453373,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 92, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-14T21:10:34.165Z',
						updatedAt: '2024-06-14T21:13:00.760Z',
					},
					{
						id: 'clxs3xucj00am8478d6hzqlef',
						score: 641586,
						accuracy: 0.9384371229019637,
						normalizedAccuracy: 0.9572524345865716,
						weightedScore: 3.132088108349457,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 98, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T22:15:33.859Z',
						updatedAt: '2024-06-23T22:17:06.405Z',
					},
				],
			},
			{
				player: {
					id: 'clv45voyl0001d63s1fgfwr1t',
					teamId: 'clv45voyp0003d63ss8ochv6p',
					user: {
						id: 'clhvv4lvj000mlq2zcam6kv5i',
						discordId: '481533074249875486',
						discordAvatar: 'https://cdn.discordapp.com/avatars/481533074249875486/573568e06644cee310229a7ff576bff2.png',
						discordUsername: 'Hyldra Zolxy',
						discordDiscriminator: '0',
						twitchUser: 'hyldrazolxy',
						playableAccounts: [
							{
								id: '76561198235823594',
								type: 'STEAM',
								name: 'Gay Furry | BSFR | HyldraZolxy',
								avatar: 'https://avatars.steamstatic.com/c3555226b3dd6dea3e036815b699cc82a539b34f_full.jpg',
							},
						],
						bio: 'Imagine a Furry who playing Beat Saber',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'FR',
						motto: 'Merp !',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9488848880409533,
				averageNormalizedAccuracy: 0.9670872280025363,
				averageWeightedScore: 2.612284047303657,
				scores: [
					{
						id: 'clxvrc629007x90r9uv68061t',
						score: 983413,
						accuracy: 0.9672454916077761,
						normalizedAccuracy: 0.9822254893833355,
						weightedScore: 2.1476847671868953,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 96, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T11:33:51.922Z',
						updatedAt: '2024-06-26T11:36:25.939Z',
					},
					{
						id: 'clxvrlzm1008190r9nea4q68s',
						score: 2065624,
						accuracy: 0.9582260776786853,
						normalizedAccuracy: 0.9772176020126967,
						weightedScore: 2.149496347925717,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 83, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T11:41:30.121Z',
						updatedAt: '2024-06-26T11:46:17.949Z',
					},
					{
						id: 'clxvr3ach007t90r95uvezup3',
						score: 499262,
						accuracy: 0.9846696973581706,
						normalizedAccuracy: 0.99148052236909,
						weightedScore: 2.231696813092162,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 96, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T11:26:57.570Z',
						updatedAt: '2024-06-26T11:29:52.458Z',
					},
					{
						id: 'clxvt7h5y009d90r97wrg6wu5',
						score: 1005038,
						accuracy: 0.9556545510732879,
						normalizedAccuracy: 0.9748677915859966,
						weightedScore: 2.2500603078110677,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 103, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T12:26:12.263Z',
						updatedAt: '2024-06-26T12:28:30.656Z',
					},
					{
						id: 'clxvtodpk009v90r9bdshd693',
						score: 1741581,
						accuracy: 0.9274409093450703,
						normalizedAccuracy: 0.9542820697012685,
						weightedScore: 2.5796887424741564,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 77, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T12:39:20.937Z',
						updatedAt: '2024-06-26T12:42:46.317Z',
					},
					{
						id: 'clxqc5sfd00zz1320lzziwapv',
						score: 644530,
						accuracy: 0.9427432625150839,
						normalizedAccuracy: 0.9616449106808487,
						weightedScore: 2.913007888078583,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 88, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-22T16:30:09.193Z',
						updatedAt: '2024-06-22T16:31:41.718Z',
					},
					{
						id: 'clxqcdtom01031320nfwsdiu7',
						score: 1848455,
						accuracy: 0.9062142267085999,
						normalizedAccuracy: 0.9278922102845187,
						weightedScore: 4.014353464557015,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 103, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-22T16:36:24.071Z',
						updatedAt: '2024-06-22T16:39:44.537Z',
					},
				],
			},
			{
				player: {
					id: 'clwrvx11a0055903xsfnhl8he',
					teamId: 'clv45voyp0003d63ss8ochv6p',
					user: {
						id: 'clrxznyw60004t1bfk94lyg1r',
						discordId: '574263281393336320',
						discordAvatar: 'https://cdn.discordapp.com/avatars/574263281393336320/12445bc2c27447ee09fc4244e34664bf.png',
						discordUsername: 'THMX',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199391504615',
								type: 'STEAM',
								name: 'UwU | THMX',
								avatar: 'https://cdn.assets.beatleader.xyz/76561199391504615R48.png',
							},
						],
						bio: "Honnestly how the heck did i drop here XD\n\ni'm good but left just fucked me so hard !",
						controllers: 'Quest 2 Touch',
						countryCode: 'FR',
						motto: 'SPEEDMONKEY',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.954943036674036,
				averageNormalizedAccuracy: 0.6938222723556319,
				averageWeightedScore: 9.158536556044838,
				scores: [
					{
						id: 'clxrqy03o00418478lr4e930k',
						score: 2053167,
						accuracy: 0.9524473772716202,
						normalizedAccuracy: 0.9713243708785347,
						weightedScore: 2.4468420298849476,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 98, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T16:11:46.309Z',
						updatedAt: '2024-06-23T16:16:34.418Z',
					},
					{
						id: 'clxqaezvp00zp1320xzfywc6r',
						score: 977849,
						accuracy: 0.9617729648918331,
						normalizedAccuracy: 0.9766682081363631,
						weightedScore: 2.5065136076357537,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 118, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-22T15:41:19.525Z',
						updatedAt: '2024-06-22T15:43:53.671Z',
					},
					{
						id: 'clxt7j34p00ld84786ovxygao',
						score: 1920292,
						accuracy: 0.9414326720611054,
						normalizedAccuracy: 0.9639531328983821,
						weightedScore: 2.5068829479162296,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 78, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T16:43:50.042Z',
						updatedAt: '2024-06-24T16:47:11.071Z',
					},
					{
						id: 'clxrrldt3004d8478g8mr1t49',
						score: 994753,
						accuracy: 0.9458749138279411,
						normalizedAccuracy: 0.9648915367215417,
						weightedScore: 2.746272977276017,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 124, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-23T16:29:57.159Z',
						updatedAt: '2024-06-23T16:32:15.605Z',
					},
					{
						id: 'clxp57nqz00vl1320b4ljwwf4',
						score: 493440,
						accuracy: 0.9731872553176802,
						normalizedAccuracy: 0.9799186578546009,
						weightedScore: 3.903244329600919,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 148, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-21T20:27:52.956Z',
						updatedAt: '2024-06-21T20:30:47.779Z',
					},
					{
						id: '6i8ovl',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 187, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'd10s58',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 180, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwrvqyh1004x903xkic53gjy',
					teamId: 'clv45voyp0003d63ss8ochv6p',
					user: {
						id: 'clgjip7xj0016o14mp43367h5',
						discordId: '176052745982181376',
						discordAvatar: 'https://cdn.discordapp.com/avatars/176052745982181376/40bdbceec5dddeb622fecf42d1848bb6.png',
						discordUsername: 'cadavren',
						discordDiscriminator: '0',
						twitchUser: 'cadavren',
						playableAccounts: [
							{
								id: '76561198079942161',
								type: 'STEAM',
								name: 'Cadavren',
								avatar: 'https://avatars.akamai.steamstatic.com/9da659cdea25555c6e4adf3819cdd42b20a12839_full.jpg',
							},
						],
						bio: 'GREMLIN.\nhttps://cada.boo/',
						controllers: 'Quest 2 Touch',
						countryCode: 'FR',
						motto: 'Trying my best !!',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8311105267604816,
				averageNormalizedAccuracy: 0.7245156973698085,
				averageWeightedScore: 9.655906086198948,
				scores: [
					{
						id: 'clxvszl0u008z90r9iq97xe47',
						score: 985564,
						accuracy: 0.9693611287332241,
						normalizedAccuracy: 0.9843738919646148,
						weightedScore: 2.0089642718947505,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 84, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T12:20:04.014Z',
						updatedAt: '2024-06-26T12:22:38.070Z',
					},
					{
						id: 'clxvtr3dj00a190r9hzga51bs',
						score: 1004478,
						accuracy: 0.9551220671785485,
						normalizedAccuracy: 0.9743246022107808,
						weightedScore: 2.2770782071693927,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 105, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T12:41:27.511Z',
						updatedAt: '2024-06-26T12:43:45.935Z',
					},
					{
						id: 'clxvsmpm3008j90r9uap9ysuh',
						score: 498263,
						accuracy: 0.9826994191722465,
						normalizedAccuracy: 0.9894966160396543,
						weightedScore: 2.5185185185185186,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 110, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T12:10:03.435Z',
						updatedAt: '2024-06-26T12:12:58.114Z',
					},
					{
						id: 'clxvu9q5100ab90r9tpfylw9r',
						score: 617351,
						accuracy: 0.9029889933082239,
						normalizedAccuracy: 0.9210935833145589,
						weightedScore: 4.935555886292603,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 118, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T12:55:56.822Z',
						updatedAt: '2024-06-26T12:57:29.311Z',
					},
					{
						id: 'clxvu35uk00a990r9c0ry4r4j',
						score: 1942435,
						accuracy: 0.901079708212045,
						normalizedAccuracy: 0.9189386223076089,
						weightedScore: 5.089989019907385,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 129, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T12:50:50.588Z',
						updatedAt: '2024-06-26T12:55:38.677Z',
					},
					{
						id: 'inbwbf',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 183, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'clxvtyczl00a790r9025yi5df',
						score: 517178,
						accuracy: 0.27541184395860124,
						normalizedAccuracy: 0.28338256575144233,
						weightedScore: 25.76123669960998,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 108, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T12:47:06.562Z',
						updatedAt: '2024-06-26T12:50:32.079Z',
					},
				],
			},
		],
		captain: {
			id: 'clv45voyl0001d63s1fgfwr1t',
			user: {
				id: 'clhvv4lvj000mlq2zcam6kv5i',
				discordId: '481533074249875486',
				discordAvatar: 'https://cdn.discordapp.com/avatars/481533074249875486/573568e06644cee310229a7ff576bff2.png',
				discordUsername: 'Hyldra Zolxy',
				discordDiscriminator: '0',
				twitchUser: 'hyldrazolxy',
				playableAccounts: [
					{
						id: '76561198235823594',
						type: 'STEAM',
						name: 'Gay Furry | BSFR | HyldraZolxy',
						avatar: 'https://avatars.steamstatic.com/c3555226b3dd6dea3e036815b699cc82a539b34f_full.jpg',
					},
				],
				bio: 'Imagine a Furry who playing Beat Saber',
				controllers: 'Quest/Rift S Touch',
				countryCode: 'FR',
				motto: 'Merp !',
				pronouns: 'He/him',
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.961964575638326,
		averageNormalizedAccuracy: 0.980468052707727,
		averageWeightedScore: 1.99466236601688,
	},
	{
		id: 'clv2vrky9001bykbv6p1v5ig4',
		name: 'Italy',
		image: 'https://cdn.cube.community/1713301985289-italy.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clv2vrky60019ykbvo24m4etf',
					teamId: 'clv2vrky9001bykbv6p1v5ig4',
					user: {
						id: 'clgjo2u380010pf4mcm93sgrm',
						discordId: '165219042460696576',
						discordAvatar: 'https://cdn.discordapp.com/avatars/165219042460696576/a4f6ebc22d9cb666c1fbe603f205fa2a.png',
						discordUsername: 'mrsuperqwerasd',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198054519035',
								type: 'STEAM',
								name: 'ACC | mrsuperqwerasd',
								avatar: 'https://avatars.akamai.steamstatic.com/189b854ef8fa8f62520fe3fc5c2bd0e02fd672fa_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'IT',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.961709893508789,
				averageNormalizedAccuracy: 0.9802037467539749,
				averageWeightedScore: 1.9772893082421596,
				scores: [
					{
						id: 'clxavnodz009a7piom1r6kf04',
						score: 501498,
						accuracy: 0.9890796493338724,
						normalizedAccuracy: 0.9959209773767158,
						weightedScore: 1.5897215044501867,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 40, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-11T20:51:37.655Z',
						updatedAt: '2024-06-11T20:54:32.036Z',
					},
					{
						id: 'clxm3frk400iv1320qp5owe3k',
						score: 988612,
						accuracy: 0.9723590189974575,
						normalizedAccuracy: 0.9874182113824387,
						weightedScore: 1.8123952018573455,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 62, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-19T17:14:53.381Z',
						updatedAt: '2024-06-19T17:17:26.988Z',
					},
					{
						id: 'clxawb1t5009g7pionlq40798',
						score: 1013522,
						accuracy: 0.9637216820785889,
						normalizedAccuracy: 0.9830971106205163,
						weightedScore: 1.8407391325324456,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 68, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-11T21:09:48.137Z',
						updatedAt: '2024-06-11T21:12:06.519Z',
					},
					{
						id: 'clx7gxntk001e7piof1q0mucd',
						score: 2073900,
						accuracy: 0.9620652463845432,
						normalizedAccuracy: 0.9811328609728255,
						weightedScore: 1.9519501599274358,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 64, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-09T11:36:10.713Z',
						updatedAt: '2024-06-09T11:40:58.674Z',
					},
					{
						id: 'clxm4p2zv00j31320nuffvcdg',
						score: 1774121,
						accuracy: 0.9447693753711055,
						normalizedAccuracy: 0.9721120406001698,
						weightedScore: 1.9636108902268166,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 50, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-19T17:50:07.723Z',
						updatedAt: '2024-06-19T17:53:32.886Z',
					},
					{
						id: 'clxrk0lwv000h8478euvxmnox',
						score: 1937089,
						accuracy: 0.9496674845753534,
						normalizedAccuracy: 0.97238493429801,
						weightedScore: 2.1544046669744406,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 63, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-23T12:57:50.575Z',
						updatedAt: '2024-06-23T13:01:11.434Z',
					},
					{
						id: 'clx6mmkn9000u7piovzkj85zj',
						score: 649701,
						accuracy: 0.9503067978206019,
						normalizedAccuracy: 0.9693600920271486,
						weightedScore: 2.5282036017264473,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 68, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-08T21:27:44.902Z',
						updatedAt: '2024-06-08T21:29:17.267Z',
					},
				],
			},
			{
				player: {
					id: 'clwdym70y001e13xtum0411by',
					teamId: 'clv2vrky9001bykbv6p1v5ig4',
					user: {
						id: 'clgikffla0008s74m5kabaxuq',
						discordId: '287581770545889281',
						discordAvatar: 'https://cdn.discordapp.com/avatars/287581770545889281/37ce27e8c16600153e970018fdd1da42.png',
						discordUsername: 'Arbo_5418',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198298655923',
								type: 'STEAM',
								name: 'Arbo_5418',
								avatar: 'https://avatars.akamai.steamstatic.com/c220b12cb6c42e3752ed704adb286eea4dd0cc98_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 3 Touch',
						countryCode: null,
						motto: 'Man I suck at Beat Saber',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9602039653734344,
				averageNormalizedAccuracy: 0.9786965381642675,
				averageWeightedScore: 2.1743789291663775,
				scores: [
					{
						id: 'clxto1jpd000hqiy38zk51s9s',
						score: 1958782,
						accuracy: 0.9603025853595162,
						normalizedAccuracy: 0.9832744424102995,
						weightedScore: 1.6991857976245437,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 35, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T00:26:05.186Z',
						updatedAt: '2024-06-25T00:29:27.392Z',
					},
					{
						id: 'clxvrnar2008390r98wgdke5n',
						score: 1785133,
						accuracy: 0.9506335753673778,
						normalizedAccuracy: 0.9781459569965649,
						weightedScore: 1.7551213601423756,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 40, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T11:42:31.215Z',
						updatedAt: '2024-06-26T11:45:59.830Z',
					},
					{
						id: 'clxs5efz200b884787uijkyc7',
						score: 1009387,
						accuracy: 0.9597898590343975,
						normalizedAccuracy: 0.9790862390731637,
						weightedScore: 2.0402373715443622,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 89, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T22:56:27.999Z',
						updatedAt: '2024-06-23T22:58:47.011Z',
					},
					{
						id: 'clxvrfwu5007z90r92d0qi29u',
						score: 2070045,
						accuracy: 0.9602769434167951,
						normalizedAccuracy: 0.97930911480423,
						weightedScore: 2.0439681099918845,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 75, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T11:36:46.590Z',
						updatedAt: '2024-06-26T11:41:37.224Z',
					},
					{
						id: 'clxs5oudb00ba8478cilnjdfp',
						score: 982023,
						accuracy: 0.9658783434885883,
						normalizedAccuracy: 0.9808371678640524,
						weightedScore: 2.2373274861343995,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 100, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T23:04:33.215Z',
						updatedAt: '2024-06-23T23:07:07.723Z',
					},
					{
						id: 'clxs7253u00bo8478u3gd10l8',
						score: 498436,
						accuracy: 0.9830406184977368,
						normalizedAccuracy: 0.989840175394001,
						weightedScore: 2.4688486936548952,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 109, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T23:42:53.274Z',
						updatedAt: '2024-06-23T23:45:54.631Z',
					},
					{
						id: 'clxs4kz7b00b28478alwxz2bh',
						score: 643684,
						accuracy: 0.9415058324496288,
						normalizedAccuracy: 0.9603826706075612,
						weightedScore: 2.9759636850721836,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 90, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T22:33:33.239Z',
						updatedAt: '2024-06-23T22:35:07.859Z',
					},
				],
			},
			{
				player: {
					id: 'clwoo5b5v003e903xomb68lc2',
					teamId: 'clv2vrky9001bykbv6p1v5ig4',
					user: {
						id: 'clwoo1z7c003c903x4sy4bn2b',
						discordId: '603200263150632980',
						discordAvatar: 'https://cdn.discordapp.com/avatars/603200263150632980/7b41708975bc590cab7f3f9372632b5e.png',
						discordUsername: 'Bev',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199162096058',
								type: 'STEAM',
								name: 'Bevilix',
								avatar: 'https://avatars.akamai.steamstatic.com/f85a68dfed2060632364b745f73966c2285134a1_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'IT',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.906231205826581,
				averageNormalizedAccuracy: 0.9234352166887518,
				averageWeightedScore: 4.650648295226831,
				scores: [
					{
						id: 'clxd8qjyg000h1320g2cwyiuc',
						score: 973031,
						accuracy: 0.9570341737851807,
						normalizedAccuracy: 0.9718560260644881,
						weightedScore: 2.8172320392106283,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 126, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-13T12:33:19.241Z',
						updatedAt: '2024-06-13T12:35:53.007Z',
					},
					{
						id: 'clxkqyzdj00e9132044z8p58w',
						score: 986199,
						accuracy: 0.9377412223357977,
						normalizedAccuracy: 0.95659431901512,
						weightedScore: 3.1589713899744294,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 132, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-18T18:38:08.792Z',
						updatedAt: '2024-06-18T18:40:27.092Z',
					},
					{
						id: 'clxd8garx000e13205kc6wkmo',
						score: 493951,
						accuracy: 0.9741950752906604,
						normalizedAccuracy: 0.9809334487798678,
						weightedScore: 3.7565317255239736,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 144, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-13T12:25:20.781Z',
						updatedAt: '2024-06-13T12:28:16.514Z',
					},
					{
						id: 'clxgobaqm005a1320sg5jcpo9',
						score: 1997204,
						accuracy: 0.9264865993250374,
						normalizedAccuracy: 0.9448490643070403,
						weightedScore: 3.782665775528715,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 125, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-15T22:12:39.839Z',
						updatedAt: '2024-06-15T22:17:27.831Z',
					},
					{
						id: 'clxkgtnqi00cf13202vmajeqf',
						score: 1812406,
						accuracy: 0.8885410257604467,
						normalizedAccuracy: 0.9097962402508708,
						weightedScore: 4.770827212825786,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 105, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-18T13:54:04.267Z',
						updatedAt: '2024-06-18T13:57:24.783Z',
					},
					{
						id: 'clxkqsip500e51320dn9u1wp1',
						score: 1504196,
						accuracy: 0.8010267142746833,
						normalizedAccuracy: 0.8242093087352063,
						weightedScore: 7.074084592373812,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 99, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-18T18:33:07.241Z',
						updatedAt: '2024-06-18T18:36:32.605Z',
					},
					{
						id: 'clxi02cqb007a132033tk4rzj',
						score: 586999,
						accuracy: 0.8585936300142611,
						normalizedAccuracy: 0.8758081096686694,
						weightedScore: 7.194225331150469,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 124, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-16T20:29:24.083Z',
						updatedAt: '2024-06-16T20:30:56.491Z',
					},
				],
			},
			{
				player: {
					id: 'clwdydl58001c13xt9nnchp4v',
					teamId: 'clv2vrky9001bykbv6p1v5ig4',
					user: {
						id: 'clgilwglg0000qy4m1a5j0qn8',
						discordId: '624782493262020608',
						discordAvatar: 'https://cdn.discordapp.com/avatars/624782493262020608/bae2c0dc10402aa0e89271c057643620.png',
						discordUsername: 'Praunt',
						discordDiscriminator: '0',
						twitchUser: 'praunt',
						playableAccounts: [
							{
								id: '76561198826094646',
								type: 'STEAM',
								name: 'Praunt',
								avatar: 'https://avatars.steamstatic.com/28328c42765ee779545cb5d287a35a2bca6fda9e_full.jpg',
							},
						],
						bio: 'We playin beat saber',
						controllers: 'Quest 2 Touch',
						countryCode: 'IT',
						motto: 'What’s a motto',
						pronouns: 'He/they',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8284387662027317,
				averageNormalizedAccuracy: 0.8430707015170557,
				averageWeightedScore: 5.234955667859578,
				scores: [
					{
						id: 'clxtchuqn00ox8478e0pb8tqu',
						score: 2083125,
						accuracy: 0.9663446484279866,
						normalizedAccuracy: 0.985497078457986,
						weightedScore: 1.7317515634697094,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 48, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T19:02:50.591Z',
						updatedAt: '2024-06-24T19:07:41.042Z',
					},
					{
						id: 'clxtbp2u800nl8478i3blpn96',
						score: 1948798,
						accuracy: 0.9554078798679253,
						normalizedAccuracy: 0.9782626483295777,
						weightedScore: 1.9086960171234315,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 50, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T18:40:28.064Z',
						updatedAt: '2024-06-24T18:43:50.790Z',
					},
					{
						id: 'clxtbturd00np8478lxn2aace',
						score: 986721,
						accuracy: 0.9704991074194833,
						normalizedAccuracy: 0.9855294948407376,
						weightedScore: 1.9343479943247774,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 75, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T18:44:10.874Z',
						updatedAt: '2024-06-24T18:46:45.474Z',
					},
					{
						id: 'clxkooio100dr1320a0ckakai',
						score: 1010081,
						accuracy: 0.9604497587182352,
						normalizedAccuracy: 0.9797594059060205,
						weightedScore: 2.0067544748395814,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 85, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-18T17:34:01.345Z',
						updatedAt: '2024-06-18T17:36:20.328Z',
					},
					{
						id: 'clxtc11uw00o38478vt6y8zbe',
						score: 656645,
						accuracy: 0.9604636706037225,
						normalizedAccuracy: 0.9797206062929978,
						weightedScore: 2.0114600386962347,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 37, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T18:49:46.665Z',
						updatedAt: '2024-06-24T18:51:19.417Z',
					},
					{
						id: 'clx7xukuu002k7pio7fjcpjpm',
						score: 499889,
						accuracy: 0.9859062983817685,
						normalizedAccuracy: 0.9927256767920691,
						weightedScore: 2.0516795865633073,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 74, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-09T19:29:40.374Z',
						updatedAt: '2024-06-09T19:32:35.899Z',
					},
					{
						id: 'clxkns03z00dd1320jds2op1l',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 154, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-18T17:08:44.303Z',
						updatedAt: '2024-06-18T17:08:44.303Z',
					},
				],
			},
			{
				player: {
					id: 'clwdyt3nd001k13xtjd31czg0',
					teamId: 'clv2vrky9001bykbv6p1v5ig4',
					user: {
						id: 'clhv1isno0064o14m7yixkmjw',
						discordId: '596742943092637697',
						discordAvatar: 'https://cdn.discordapp.com/avatars/596742943092637697/69fe7e7ba6dacfec1189817d890c8904.png',
						discordUsername: 'Ryleeeee',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198271943341',
								type: 'STEAM',
								name: 'Ryleeeee 93 fc',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198271943341R2.png',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'IT',
						motto: 'I cut blocks (not in the center)',
						pronouns: 'She/her',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.922910782969292,
				averageNormalizedAccuracy: 0.806169449281307,
				averageWeightedScore: 7.213761477834289,
				scores: [
					{
						id: 'clxhxzrga007613207oeus87t',
						score: 1713089,
						accuracy: 0.9122681172733493,
						normalizedAccuracy: 0.9386701603327531,
						weightedScore: 3.1191260555113787,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 85, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-16T19:31:23.962Z',
						updatedAt: '2024-06-16T19:35:17.775Z',
					},
					{
						id: 'clxi0s42j007k13205oy5mxln',
						score: 975703,
						accuracy: 0.9277609527658259,
						normalizedAccuracy: 0.9464133981539321,
						weightedScore: 3.6653640179476046,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 134, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-16T20:49:25.915Z',
						updatedAt: '2024-06-16T20:51:44.203Z',
					},
					{
						id: 'clxhxl66g00741320b4h1raht',
						score: 959211,
						accuracy: 0.9434413773771411,
						normalizedAccuracy: 0.9580527142684494,
						weightedScore: 3.7084999355088355,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 135, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-16T19:20:03.209Z',
						updatedAt: '2024-06-16T19:22:37.770Z',
					},
					{
						id: 'clxi06oa7007c1320hr34vb4u',
						score: 1858194,
						accuracy: 0.9109888197357036,
						normalizedAccuracy: 0.9327810186330914,
						weightedScore: 3.809984471397994,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 101, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-16T20:32:45.680Z',
						updatedAt: '2024-06-16T20:36:07.807Z',
					},
					{
						id: 'clxf292jj002u1320ucic7vgu',
						score: 489869,
						accuracy: 0.9661443490094372,
						normalizedAccuracy: 0.9728270367310625,
						weightedScore: 4.928509905254091,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 152, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-14T19:07:18.175Z',
						updatedAt: '2024-06-14T19:10:13.171Z',
					},
					{
						id: 'clxhxhg850072132075bxyp6d',
						score: 599488,
						accuracy: 0.8768610816542948,
						normalizedAccuracy: 0.8944418168498606,
						weightedScore: 6.264845959220122,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 122, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-16T19:17:09.605Z',
						updatedAt: '2024-06-16T19:18:42.169Z',
					},
					{
						id: 'a6q4ja',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 172, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
				],
			},
			{
				player: {
					id: 'clwdyof3g001i13xthbjhxvae',
					teamId: 'clv2vrky9001bykbv6p1v5ig4',
					user: {
						id: 'clh6d0vdb0042pf4mpag6e3r1',
						discordId: '953442284442120282',
						discordAvatar: 'https://cdn.discordapp.com/avatars/953442284442120282/5ec6f76167b2bdddf16a0831c1e408f9.png',
						discordUsername: '𝕏𝕠𝕠𝕞𝕚𝕖𝕤',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199253219625',
								type: 'STEAM',
								name: 'Xoomies',
								avatar: 'https://avatars.akamai.steamstatic.com/8d63a4771c082107a7f5669cca5b065dc60c7364_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'IT',
						motto: 'I should be better',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9670666354414855,
				averageNormalizedAccuracy: 0.7021063866989544,
				averageWeightedScore: 8.604316845486627,
				scores: [
					{
						id: 'clxs1yt9o009g8478cfl6w754',
						score: 986995,
						accuracy: 0.970768602804129,
						normalizedAccuracy: 0.9858031639747545,
						weightedScore: 1.9166774151941184,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 74, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T21:20:19.884Z',
						updatedAt: '2024-06-23T21:22:53.907Z',
					},
					{
						id: 'clxtkr2jc001h96ln31yg7mwi',
						score: 1011199,
						accuracy: 0.9615128247795184,
						normalizedAccuracy: 0.9808438446943978,
						weightedScore: 1.9528151686206396,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 77, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T22:53:57.529Z',
						updatedAt: '2024-06-24T22:56:15.969Z',
					},
					{
						id: 'clxs0jaou008y8478zbp8bk9j',
						score: 2073803,
						accuracy: 0.9620202488779617,
						normalizedAccuracy: 0.9810869716399192,
						weightedScore: 1.954265527283143,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 65, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-23T20:40:16.350Z',
						updatedAt: '2024-06-23T20:45:06.195Z',
					},
					{
						id: 'clxhtyl22006w1320gn92a4om',
						score: 499505,
						accuracy: 0.9851489542142061,
						normalizedAccuracy: 0.9919630941789527,
						weightedScore: 2.1619293712316967,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 85, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-16T17:38:30.554Z',
						updatedAt: '2024-06-16T17:41:25.583Z',
					},
					{
						id: 'clxs2qv9r009w8478u4wu65qb',
						score: 653513,
						accuracy: 0.9558825465316122,
						normalizedAccuracy: 0.9750476324046569,
						weightedScore: 2.244530436076797,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 52, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T21:42:08.847Z',
						updatedAt: '2024-06-23T21:43:41.762Z',
					},
					{
						id: '6hld0g',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 163, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'gqcp1',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 156, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwdydgrk001a13xts8u1ojgq',
					teamId: 'clv2vrky9001bykbv6p1v5ig4',
					user: {
						id: 'clhgglcsk005gpf4mm03iiqza',
						discordId: '703982260059111494',
						discordAvatar: 'https://cdn.discordapp.com/avatars/703982260059111494/e1dbb5ea6d60aa91286fb47216843e29.png',
						discordUsername: 'Gio',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198171051938',
								type: 'STEAM',
								name: 'Gio',
								avatar: 'https://avatars.akamai.steamstatic.com/36d0f1210263159a738018729a107cc493ca9243_full.jpg',
							},
						],
						bio: 'Panda caster!',
						controllers: 'Quest 3 Touch',
						countryCode: 'IT',
						motto: 'Little black submarines - Operator, please, put me back on the line.',
						pronouns: 'He/him',
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Content', name: 'Content Cubes', color: '#206694'},
							{id: 'Tournament', name: 'Tournament Cubes', color: '#0fa57f'},
						],
					},
				},
				averageAccuracy: 0.9426949054864231,
				averageNormalizedAccuracy: 0.5492653305419629,
				averageWeightedScore: 12.291380830896852,
				scores: [
					{
						id: 'clxtebh2800qb84789su1vzzp',
						score: 499558,
						accuracy: 0.9852534834873332,
						normalizedAccuracy: 0.9920683464667006,
						weightedScore: 2.1467126040769453,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 83, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T19:53:52.161Z',
						updatedAt: '2024-06-24T19:56:47.274Z',
					},
					{
						id: 'clxssnnc000f48478tlko5scd',
						score: 2053137,
						accuracy: 0.9524334605170074,
						normalizedAccuracy: 0.9713101783013472,
						weightedScore: 2.447558122881558,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 99, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T09:47:28.608Z',
						updatedAt: '2024-06-24T09:52:18.719Z',
					},
					{
						id: 'clxst5xxh00fd84787dtb1bis',
						score: 1895899,
						accuracy: 0.9294738828927984,
						normalizedAccuracy: 0.9517082718195513,
						weightedScore: 3.0187602299911864,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 92, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T10:01:42.149Z',
						updatedAt: '2024-06-24T10:05:04.017Z',
					},
					{
						id: 'clxkpqcaj00e11320lee8xaei',
						score: 1696847,
						accuracy: 0.9036187950485532,
						normalizedAccuracy: 0.9297705172061411,
						weightedScore: 3.426634859328259,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 87, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-18T18:03:26.011Z',
						updatedAt: '2024-06-18T18:08:39.463Z',
					},
					{
						id: 'jtcfok',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 167, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'oahs8n',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 173, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'wexojm',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 174, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clwdymf69001g13xtunpp8dbg',
					teamId: 'clv2vrky9001bykbv6p1v5ig4',
					user: {
						id: 'clglaevih001ko14mg0d7opi6',
						discordId: '221603711875547137',
						discordAvatar: 'https://cdn.discordapp.com/avatars/221603711875547137/779ad8f90c4bf05d5e0c307d8061dd39.png',
						discordUsername: 'bucciax',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198084852852',
								type: 'STEAM',
								name: 'bucciax',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198084852852R18.png',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'IT',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9719644600471368,
				averageNormalizedAccuracy: 0.13981248638245333,
				averageWeightedScore: 22.0116073992043,
				scores: [
					{
						id: 'clxde4v6l000t1320ll8xdvl4',
						score: 492820,
						accuracy: 0.9719644600471368,
						normalizedAccuracy: 0.9786874046771734,
						weightedScore: 4.081251794430089,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 149, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-13T15:04:25.054Z',
						updatedAt: '2024-06-13T15:07:19.796Z',
					},
					{
						id: '1qc6ts',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 162, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'mv58eq',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 168, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '550w9h',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 171, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'dq499o',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 155, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '1hu9dr',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 174, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: '8p467',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 175, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clv2vrky60019ykbvo24m4etf',
			user: {
				id: 'clgjo2u380010pf4mcm93sgrm',
				discordId: '165219042460696576',
				discordAvatar: 'https://cdn.discordapp.com/avatars/165219042460696576/a4f6ebc22d9cb666c1fbe603f205fa2a.png',
				discordUsername: 'mrsuperqwerasd',
				discordDiscriminator: '0',
				twitchUser: null,
				playableAccounts: [
					{
						id: '76561198054519035',
						type: 'STEAM',
						name: 'ACC | mrsuperqwerasd',
						avatar: 'https://avatars.akamai.steamstatic.com/189b854ef8fa8f62520fe3fc5c2bd0e02fd672fa_full.jpg',
					},
				],
				bio: null,
				controllers: null,
				countryCode: 'IT',
				motto: null,
				pronouns: null,
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9584062576073719,
		averageNormalizedAccuracy: 0.976818526156104,
		averageWeightedScore: 2.1492489396620025,
	},
	{
		id: 'clvgaau6e001kd63sohhqmud5',
		name: 'Ireland',
		image: 'https://cdn.cube.community/1714112458462-ireland.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clvh1x7p3001pd63s6a0b6t0k',
					teamId: 'clvgaau6e001kd63sohhqmud5',
					user: {
						id: 'climch2nc0004p930offn420u',
						discordId: '163699102385831936',
						discordAvatar: 'https://cdn.discordapp.com/avatars/163699102385831936/0549bc000da4f2ed6bd64b6f7376504c.png',
						discordUsername: 'B3RRY',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198166811087',
								type: 'STEAM',
								name: 'B3RRY',
								avatar: 'https://avatars.akamai.steamstatic.com/3e16b823b217a00078f4b34e8de66c9e82c9d6cd_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'IE',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9587333808701659,
				averageNormalizedAccuracy: 0.977162947288,
				averageWeightedScore: 2.1667268760168734,
				scores: [
					{
						id: 'clxrzc92s00888478cp0jey1i',
						score: 1011671,
						accuracy: 0.9619616326336559,
						normalizedAccuracy: 0.9813016757392226,
						weightedScore: 1.9300429391614802,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 75, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T20:06:48.053Z',
						updatedAt: '2024-06-23T20:09:09.743Z',
					},
					{
						id: 'clxurj9vz00beqiy3bbqfy2k4',
						score: 2072704,
						accuracy: 0.9615104317673119,
						normalizedAccuracy: 0.98056705022895,
						weightedScore: 1.980498400725641,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 69, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T18:51:37.295Z',
						updatedAt: '2024-06-25T18:56:29.314Z',
					},
					{
						id: 'clxrz1e08008284780qbuv7pb',
						score: 984543,
						accuracy: 0.9683569141794899,
						normalizedAccuracy: 0.9833541248630406,
						weightedScore: 2.0748097510641044,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 91, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T19:58:21.224Z',
						updatedAt: '2024-06-23T20:00:56.105Z',
					},
					{
						id: 'clxuupgi400cwqiy37jns8cye',
						score: 1935715,
						accuracy: 0.9489938742643111,
						normalizedAccuracy: 0.9716952102328145,
						weightedScore: 2.1832375036723044,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 66, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T20:20:24.652Z',
						updatedAt: '2024-06-25T20:23:48.223Z',
					},
					{
						id: 'clxur49s000baqiy3llraqky5',
						score: 499421,
						accuracy: 0.9849832851775518,
						normalizedAccuracy: 0.9917962792323335,
						weightedScore: 2.186046511627907,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 87, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T18:39:57.313Z',
						updatedAt: '2024-06-25T18:42:53.266Z',
					},
					{
						id: 'clxutlqvr00ciqiy3jkm2z51o',
						score: 1752634,
						accuracy: 0.9333269429955241,
						normalizedAccuracy: 0.9603384516418203,
						weightedScore: 2.37042296186906,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 68, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T19:49:31.863Z',
						updatedAt: '2024-06-25T19:53:00.828Z',
					},
					{
						id: 'clxv7eu0g003190r9m9gq60ec',
						score: 650859,
						accuracy: 0.952000585073317,
						normalizedAccuracy: 0.9710878390778187,
						weightedScore: 2.4420300639976187,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 64, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T02:16:03.953Z',
						updatedAt: '2024-06-26T02:17:37.386Z',
					},
				],
			},
			{
				player: {
					id: 'clvgaau69001id63s6ja85039',
					teamId: 'clvgaau6e001kd63sohhqmud5',
					user: {
						id: 'clgikyyjn000os74m7tdyo7bc',
						discordId: '303761787818606593',
						discordAvatar: 'https://cdn.discordapp.com/avatars/303761787818606593/55ad66b845e18c90f0ab18caf5a748c3.png',
						discordUsername: 'Wazowski',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198199836170',
								type: 'STEAM',
								name: 'Wazowski',
								avatar: 'https://avatars.akamai.steamstatic.com/4e8c82186367e72064e4279c60e12de79d2ea01a_full.jpg',
							},
						],
						bio: "bacon pancakes, makin' bacon pancakes",
						controllers: 'Quest/Rift S Touch',
						countryCode: 'IE',
						motto: 'Tiocfaidh ár lá',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9568771551900317,
				averageNormalizedAccuracy: 0.9752832737284545,
				averageWeightedScore: 2.289017650966032,
				scores: [
					{
						id: 'clxdsdnbd001f1320r6fhgh44',
						score: 1771556,
						accuracy: 0.9434034406643821,
						normalizedAccuracy: 0.9707065742401304,
						weightedScore: 2.012173880116627,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 53, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-13T21:43:09.385Z',
						updatedAt: '2024-06-13T21:46:35.754Z',
					},
					{
						id: 'clxi0xr2t007m1320fa2kbj8l',
						score: 982966,
						accuracy: 0.9668058403780804,
						normalizedAccuracy: 0.9817790291537531,
						weightedScore: 2.1765123178124597,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 99, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-16T20:53:49.013Z',
						updatedAt: '2024-06-16T20:56:23.052Z',
					},
					{
						id: 'clxohzpj700rj13206b2efm3x',
						score: 499287,
						accuracy: 0.9847190036190796,
						normalizedAccuracy: 0.9915301696746314,
						weightedScore: 2.224519092736147,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 95, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-21T09:37:50.852Z',
						updatedAt: '2024-06-21T09:40:45.713Z',
					},
					{
						id: 'clxoiojw900rt13209221rdz4',
						score: 1933035,
						accuracy: 0.9476799909793088,
						normalizedAccuracy: 0.9703498969178772,
						weightedScore: 2.239476224451253,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 70, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-21T09:57:09.946Z',
						updatedAt: '2024-06-21T10:00:30.555Z',
					},
					{
						id: 'clxkwywua00f91320r8uonnyw',
						score: 2055498,
						accuracy: 0.9535287091050367,
						normalizedAccuracy: 0.9724271341260046,
						weightedScore: 2.3912016040483124,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 97, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-18T21:26:03.202Z',
						updatedAt: '2024-06-18T21:30:51.351Z',
					},
					{
						id: 'clxnq9dn900ps1320dn79x32u',
						score: 1001932,
						accuracy: 0.95270116718568,
						normalizedAccuracy: 0.9718550305156031,
						weightedScore: 2.3999131567520626,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 114, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-20T20:41:32.758Z',
						updatedAt: '2024-06-20T20:43:51.081Z',
					},
					{
						id: 'clxoi8yvw00rp132070aplosk',
						score: 649014,
						accuracy: 0.9493019343986543,
						normalizedAccuracy: 0.9683350814711811,
						weightedScore: 2.579327280845364,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 74, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-21T09:45:02.877Z',
						updatedAt: '2024-06-21T09:46:35.330Z',
					},
				],
			},
			{
				player: {
					id: 'clvh1x3jz001nd63sfxq12kfn',
					teamId: 'clvgaau6e001kd63sohhqmud5',
					user: {
						id: 'clhxtfxh70006p42zteashioz',
						discordId: '802895057388306472',
						discordAvatar: 'https://cdn.discordapp.com/avatars/802895057388306472/299f68480f4919095fdc5d041230034d.png',
						discordUsername: 'Luna',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198143262090',
								type: 'STEAM',
								name: 'WDG_Luna',
								avatar: 'https://avatars.akamai.steamstatic.com/62ac0ed3abbc252c4058cef12cb6100fd7b89b1d_full.jpg',
							},
						],
						bio: 'tiocfaidh ár lá',
						controllers: 'Valve Index Knuckles',
						countryCode: 'IE',
						motto: ':3',
						pronouns: 'She/her',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9522278317433129,
				averageNormalizedAccuracy: 0.9705469711750064,
				averageWeightedScore: 2.583608937012838,
				scores: [
					{
						id: 'clxtljbxn0003ljn25gktqrn3',
						score: 1765439,
						accuracy: 0.9401459659661259,
						normalizedAccuracy: 0.9673548246399897,
						weightedScore: 2.127986671210572,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 59, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T23:15:56.076Z',
						updatedAt: '2024-06-24T23:19:22.336Z',
					},
					{
						id: 'clxtfy4vs00rl847837qnnzj7',
						score: 1931710,
						accuracy: 0.9470304031611639,
						normalizedAccuracy: 0.9696847699991115,
						weightedScore: 2.267280815881143,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 71, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T20:39:29.080Z',
						updatedAt: '2024-06-24T20:42:54.656Z',
					},
					{
						id: 'clxcg5uzp000c1320oaahut91',
						score: 1002810,
						accuracy: 0.9535360258635035,
						normalizedAccuracy: 0.9727066738574593,
						weightedScore: 2.3575529502581176,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 111, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-12T23:13:24.517Z',
						updatedAt: '2024-06-12T23:15:47.241Z',
					},
					{
						id: 'clxcexegh00041320t98uadnd',
						score: 979137,
						accuracy: 0.9630397899116272,
						normalizedAccuracy: 0.9779546528247349,
						weightedScore: 2.423448987488714,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 113, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-12T22:38:50.225Z',
						updatedAt: '2024-06-12T22:41:25.975Z',
					},
					{
						id: 'clxceobw300021320a613okt4',
						score: 497146,
						accuracy: 0.9804964154348319,
						normalizedAccuracy: 0.987278374428063,
						weightedScore: 2.8392190640252655,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 124, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-12T22:31:46.995Z',
						updatedAt: '2024-06-12T22:34:43.198Z',
					},
					{
						id: 'clxtfohnd00rh8478jy1y1imd',
						score: 2032429,
						accuracy: 0.9428271886995953,
						normalizedAccuracy: 0.9615135153547127,
						weightedScore: 2.941853248675228,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 116, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T20:31:59.066Z',
						updatedAt: '2024-06-24T20:36:47.989Z',
					},
					{
						id: 'clxi5bd0200801320ltil9d33',
						score: 641642,
						accuracy: 0.9385190331663437,
						normalizedAccuracy: 0.9573359871209737,
						weightedScore: 3.127920821550826,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 97, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-16T22:56:22.418Z',
						updatedAt: '2024-06-16T22:57:55.403Z',
					},
				],
			},
			{
				player: {
					id: 'clw2at9it0005pj8v90rji1l5',
					teamId: 'clvgaau6e001kd63sohhqmud5',
					user: {
						id: 'clipwbgxl0020p92zp9iyc6mi',
						discordId: '661573004899385355',
						discordAvatar: 'https://cdn.discordapp.com/avatars/661573004899385355/1e83658ce193aff527a9c49b55233e90.png',
						discordUsername: 'drew',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198857378317',
								type: 'STEAM',
								name: 'Andrew Da Person',
								avatar: 'https://avatars.steamstatic.com/ce2a1d38a4a689b4d3d900d12cb815536038101a_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'IE',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9471380434320172,
				averageNormalizedAccuracy: 0.9653568145471988,
				averageWeightedScore: 2.880011150664263,
				scores: [
					{
						id: 'clxt5f9jt00k38478m3rzyhvj',
						score: 1765635,
						accuracy: 0.9402503414836767,
						normalizedAccuracy: 0.9674622208998601,
						weightedScore: 2.1242758150630467,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 58, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T15:44:52.505Z',
						updatedAt: '2024-06-24T15:48:18.779Z',
					},
					{
						id: 'clxvt5gwd009790r9um951zgt',
						score: 1003290,
						accuracy: 0.9539924406304229,
						normalizedAccuracy: 0.9731722647505014,
						weightedScore: 2.3343947508081246,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 107, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T12:24:38.606Z',
						updatedAt: '2024-06-26T12:26:57.910Z',
					},
					{
						id: 'clxvta1nv009h90r9fxqfkcph',
						score: 975747,
						accuracy: 0.9597055221964857,
						normalizedAccuracy: 0.9745687463856197,
						weightedScore: 2.6420740358570876,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 123, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T12:28:12.140Z',
						updatedAt: '2024-06-26T12:30:46.705Z',
					},
					{
						id: 'clxrqxbui003z8478o4m4dge4',
						score: 2040017,
						accuracy: 0.946347199832999,
						normalizedAccuracy: 0.9651032912113412,
						weightedScore: 2.7607294600658805,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 111, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T16:11:14.875Z',
						updatedAt: '2024-06-23T16:16:03.407Z',
					},
					{
						id: 'clxt4ff0f00jj8478fhlr2xje',
						score: 1907097,
						accuracy: 0.9349637579022971,
						normalizedAccuracy: 0.9573294727526366,
						weightedScore: 2.7837747093633274,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 83, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T15:16:59.967Z',
						updatedAt: '2024-06-24T15:20:20.984Z',
					},
					{
						id: 'clxrrpbgu004h8478psw3ydcx',
						score: 495481,
						accuracy: 0.9772126184582919,
						normalizedAccuracy: 0.9839718638790036,
						weightedScore: 3.31725523973586,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 138, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-23T16:33:00.750Z',
						updatedAt: '2024-06-23T16:35:56.017Z',
					},
					{
						id: 'clxrsjzb0004r8478a4cszuce',
						score: 627268,
						accuracy: 0.9174944235199474,
						normalizedAccuracy: 0.9358898419514291,
						weightedScore: 4.197574043756512,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 112, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-23T16:56:51.324Z',
						updatedAt: '2024-06-23T16:58:24.203Z',
					},
				],
			},
			{
				player: {
					id: 'clvh1xcno001rd63s208ez4ae',
					teamId: 'clvgaau6e001kd63sohhqmud5',
					user: {
						id: 'clh3ox0gv003go14mzaclobhn',
						discordId: '727459707387641877',
						discordAvatar: 'https://cdn.discordapp.com/avatars/727459707387641877/64f12da576211981ce6f4a939b4a535d.png',
						discordUsername: 'BE | Tboo',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '4025605504201200',
								type: 'OCULUS',
								name: 'BE | Tboo',
								avatar: 'https://cdn.assets.beatleader.xyz/4025605504201200R19.png',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'IE',
						motto: 'sigma gyatt',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.970188332445973,
				averageNormalizedAccuracy: 0.7043737350741077,
				averageWeightedScore: 8.445955698746719,
				scores: [
					{
						id: 'clxjey0e000a613208wnidbrz',
						score: 1015906,
						accuracy: 0.9659885420876221,
						normalizedAccuracy: 0.9854095453892922,
						weightedScore: 1.7257200752641482,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 57, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T20:13:41.881Z',
						updatedAt: '2024-06-17T20:16:00.379Z',
					},
					{
						id: 'clxc9j4fo00ar7pioypimbopy',
						score: 500992,
						accuracy: 0.988081690613074,
						normalizedAccuracy: 0.9949161159125572,
						weightedScore: 1.7349985644559287,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 55, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-12T20:07:45.972Z',
						updatedAt: '2024-06-12T20:10:40.967Z',
					},
					{
						id: 'clx9f35ut007d7pio366aags1',
						score: 989469,
						accuracy: 0.9732019297443236,
						normalizedAccuracy: 0.9882741765205866,
						weightedScore: 1.7571262737005031,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 56, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-10T20:20:00.486Z',
						updatedAt: '2024-06-10T20:22:34.667Z',
					},
					{
						id: 'clxvyrmxb00g590r96nu1ptp9',
						score: 2079619,
						accuracy: 0.9647182437055679,
						normalizedAccuracy: 0.9838384392706718,
						weightedScore: 1.8154389650069223,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 58, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T15:01:50.928Z',
						updatedAt: '2024-06-26T15:06:39.249Z',
					},
					{
						id: 'clxjelyzu00a21320xsslnix2',
						score: 655611,
						accuracy: 0.9589512560792774,
						normalizedAccuracy: 0.9781778684256465,
						weightedScore: 2.0884060127995236,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 44, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T20:04:20.202Z',
						updatedAt: '2024-06-17T20:05:53.118Z',
					},
					{
						id: 'nl986e',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 135, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'rsst5',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 125, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clw2dsyr5000dpj8vvog7g2nm',
					teamId: 'clvgaau6e001kd63sohhqmud5',
					user: {
						id: 'clw2b71az0009pj8vp8y0nk2o',
						discordId: '248990710680911872',
						discordAvatar: 'https://cdn.discordapp.com/avatars/248990710680911872/a88685b65f041d38499b9c9843a010d0.png',
						discordUsername: 'Astra',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199353355406',
								type: 'STEAM',
								name: 'Shrimp Alfredo',
								avatar: 'https://avatars.akamai.steamstatic.com/78c9c88d01907b639ab0f8d37470a0e5daa0b69e_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: null,
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.6377851985393004,
				averageNormalizedAccuracy: 0.2781880044474668,
				averageWeightedScore: 18.560820786086104,
				scores: [
					{
						id: 'clxtrowxu001pqiy33qc92g6f',
						score: 980898,
						accuracy: 0.9647718387158644,
						normalizedAccuracy: 0.9797135263466469,
						weightedScore: 2.3098800464336384,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 104, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T02:08:14.275Z',
						updatedAt: '2024-06-25T02:10:48.750Z',
					},
					{
						id: 'clxtrc4lt001jqiy38s6usb7a',
						score: 648523,
						accuracy: 0.9485837569020368,
						normalizedAccuracy: 0.9676025047856206,
						weightedScore: 2.6158654561690726,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 76, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T01:58:17.681Z',
						updatedAt: '2024-06-25T01:59:50.677Z',
					},
					{
						id: 'ep3nek',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 177, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'mt0p41',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 151, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'vmug4d',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 158, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'clxts14az001zqiy3ugzm7bdg',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 140, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-25T02:17:43.691Z',
						updatedAt: '2024-06-25T02:17:43.691Z',
					},
					{
						id: '79dr7c',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 162, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
				],
			},
			{
				player: {
					id: 'clw2bfwug000bpj8v13pbxys8',
					teamId: 'clvgaau6e001kd63sohhqmud5',
					user: {
						id: 'clij1nbwz0008p92z1hgy3ubd',
						discordId: '397032464628842497',
						discordAvatar: 'https://cdn.discordapp.com/avatars/397032464628842497/2a19ff2781409e6b08f9bbd91e42fde8.png',
						discordUsername: 'Oaky',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198450449030',
								type: 'STEAM',
								name: 'Oaky Tree',
								avatar: 'https://avatars.steamstatic.com/dd42feccf0129e0ecc989d2add906b803e3a2298_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest/Rift S Touch',
						countryCode: 'IE',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.49074521482737876,
				averageNormalizedAccuracy: 0.1411827520153969,
				averageWeightedScore: 21.813502317378287,
				scores: [
					{
						id: 'clxeykza2002s1320nylt8boo',
						score: 497650,
						accuracy: 0.9814904296547575,
						normalizedAccuracy: 0.9882792641077783,
						weightedScore: 2.6945162216480045,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 114, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-14T17:24:35.354Z',
						updatedAt: '2024-06-14T17:27:30.241Z',
					},
					{
						id: '15xy4o',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 150, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '2qwbsj',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 156, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'clxtar5f000mv8478muxd56wb',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 157, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T18:14:05.100Z',
						updatedAt: '2024-06-24T18:14:05.100Z',
					},
					{
						id: '7qs46a',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 139, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '4brjbg',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 161, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'jfpaid',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 162, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clwr094870047903xmonhom4x',
					teamId: 'clvgaau6e001kd63sohhqmud5',
					user: {
						id: 'clipvt0xj001wp92zcp43r5fc',
						discordId: '1044478179806564382',
						discordAvatar: 'https://cdn.discordapp.com/avatars/1044478179806564382/e84b30f6044b42ee77e9633b19f2a5f3.png',
						discordUsername: 'Cat',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198204804992',
								type: 'STEAM',
								name: 'Cat',
								avatar: 'https://avatars.steamstatic.com/46c5fdde9e08c3b838e88c6db5865c663f77c343_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'IE',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'e0637l',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 188, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.616Z',
						updatedAt: '2024-07-08T16:23:01.616Z',
					},
					{
						id: '7i3jrhf',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 180, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'tg1l',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 183, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'ql43ip',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 184, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'o04ey',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 174, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'nvbk2k',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 184, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'xu98zw',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 186, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clvgaau69001id63s6ja85039',
			user: {
				id: 'clgikyyjn000os74m7tdyo7bc',
				discordId: '303761787818606593',
				discordAvatar: 'https://cdn.discordapp.com/avatars/303761787818606593/55ad66b845e18c90f0ab18caf5a748c3.png',
				discordUsername: 'Wazowski',
				discordDiscriminator: '0',
				twitchUser: null,
				playableAccounts: [
					{
						id: '76561198199836170',
						type: 'STEAM',
						name: 'Wazowski',
						avatar: 'https://avatars.akamai.steamstatic.com/4e8c82186367e72064e4279c60e12de79d2ea01a_full.jpg',
					},
				],
				bio: "bacon pancakes, makin' bacon pancakes",
				controllers: 'Quest/Rift S Touch',
				countryCode: 'IE',
				motto: 'Tiocfaidh ár lá',
				pronouns: 'He/him',
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9578080859849436,
		averageNormalizedAccuracy: 0.9762247669810459,
		averageWeightedScore: 2.2245801900091515,
	},
	{
		id: 'clvjl8m70001vd63smw8l0g5y',
		name: 'Sweden',
		image: 'https://cdn.cube.community/1714312228850-sweden.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwazfb3g000813xtd2yr5bs4',
					teamId: 'clvjl8m70001vd63smw8l0g5y',
					user: {
						id: 'cljg07bfj00remd2zuejl4ebt',
						discordId: '830099600219176970',
						discordAvatar: 'https://cdn.discordapp.com/avatars/830099600219176970/125370cc4a9f194bd35cbfa4a4e27a30.png',
						discordUsername: 'molie198',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199089624837',
								type: 'STEAM',
								name: 'molie198',
								avatar: 'https://avatars.steamstatic.com/8dfe278c7493b6984540e57ecd57b791df13841e_full.jpg',
							},
						],
						bio: null,
						controllers: 'Valve Index Knuckles',
						countryCode: 'SE',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9477639119627302,
				averageNormalizedAccuracy: 0.9659215711725722,
				averageWeightedScore: 2.608379694229524,
				scores: [
					{
						id: 'clxudfml2006aqiy38cex644k',
						score: 500451,
						accuracy: 0.987014703127003,
						normalizedAccuracy: 0.9938417482206405,
						weightedScore: 1.890324432960092,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 65, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T12:16:52.503Z',
						updatedAt: '2024-06-25T12:19:47.455Z',
					},
					{
						id: 'clxn1q81400mf13209kaalwq4',
						score: 983583,
						accuracy: 0.9674126967734321,
						normalizedAccuracy: 0.9823952841015212,
						weightedScore: 2.1367212691861215,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 95, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-20T09:14:48.233Z',
						updatedAt: '2024-06-20T09:17:22.107Z',
					},
					{
						id: 'clxud6xer0066qiy3ppv1abd2',
						score: 1006574,
						accuracy: 0.95711507832743,
						normalizedAccuracy: 0.9763576824437314,
						weightedScore: 2.175954069571091,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 100, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T12:10:06.627Z',
						updatedAt: '2024-06-25T12:12:25.186Z',
					},
					{
						id: 'clxucgz5r005uqiy32xhirbit',
						score: 2057960,
						accuracy: 0.9546708107669292,
						normalizedAccuracy: 0.9735918716271932,
						weightedScore: 2.3324342387931445,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 93, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T11:49:55.840Z',
						updatedAt: '2024-06-25T11:54:43.851Z',
					},
					{
						id: 'clxucoi51005yqiy3zlh0e0i8',
						score: 645509,
						accuracy: 0.9441752294584415,
						normalizedAccuracy: 0.963105588023341,
						weightedScore: 2.840154784938235,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 85, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T11:55:47.030Z',
						updatedAt: '2024-06-25T11:57:19.492Z',
					},
					{
						id: 'clxuf3bu7006uqiy3j01h1css',
						score: 1708946,
						accuracy: 0.9100618531447119,
						normalizedAccuracy: 0.9364000444927363,
						weightedScore: 3.1975652239766745,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 86, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T13:03:17.935Z',
						updatedAt: '2024-06-25T13:06:43.427Z',
					},
					{
						id: 'clxufkek2006yqiy32iw4xqq9',
						score: 1864126,
						accuracy: 0.913897012141164,
						normalizedAccuracy: 0.9357587792988408,
						weightedScore: 3.685503840181307,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 99, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T13:16:34.611Z',
						updatedAt: '2024-06-25T13:19:55.107Z',
					},
				],
			},
			{
				player: {
					id: 'clvuxb7y6003dd63suf9pq57n',
					teamId: 'clvjl8m70001vd63smw8l0g5y',
					user: {
						id: 'cli2tjz4c000kui2zms4dx4ll',
						discordId: '285736449620574209',
						discordAvatar: 'https://cdn.discordapp.com/avatars/285736449620574209/de24d1369c8e83bb7ebcd1b55c099d46.png',
						discordUsername: 'Miljon',
						discordDiscriminator: '0',
						twitchUser: 'miljon',
						playableAccounts: [
							{
								id: '76561198837548711',
								type: 'STEAM',
								name: 'Miljon',
								avatar: 'https://avatars.steamstatic.com/ec621fef2be56ef294bde4c0e7513d518fb082ff_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'SE',
						motto: 'How am I here',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.942256151895083,
				averageNormalizedAccuracy: 0.960319477801816,
				averageWeightedScore: 3.016798716199151,
				scores: [
					{
						id: 'clxs98xns00c48478vpuvz2dm',
						score: 980485,
						accuracy: 0.9643656285192999,
						normalizedAccuracy: 0.9793010250607016,
						weightedScore: 2.336514897459048,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 109, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-24T00:44:09.449Z',
						updatedAt: '2024-06-24T00:46:43.333Z',
					},
					{
						id: 'clxtqdn5l0013qiy3sk9jpqqu',
						score: 1002157,
						accuracy: 0.9529151116076735,
						normalizedAccuracy: 0.9720732762467166,
						weightedScore: 2.3890577507598785,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 113, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T01:31:28.762Z',
						updatedAt: '2024-06-25T01:33:46.984Z',
					},
					{
						id: 'clxtqjavu0017qiy3ut6v3w5q',
						score: 1925104,
						accuracy: 0.9437917789146245,
						normalizedAccuracy: 0.966368673074307,
						weightedScore: 2.4059050656817895,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 75, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T01:35:52.794Z',
						updatedAt: '2024-06-25T01:39:14.057Z',
					},
					{
						id: 'clxj8gpl900961320fc1cei6w',
						score: 2043977,
						accuracy: 0.9481842114418918,
						normalizedAccuracy: 0.966976711400093,
						weightedScore: 2.6662051845132955,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 107, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-17T17:12:17.037Z',
						updatedAt: '2024-06-17T17:17:05.304Z',
					},
					{
						id: 'clxj8yp51009c1320757x58oh',
						score: 495951,
						accuracy: 0.9781395761633812,
						normalizedAccuracy: 0.9849052332231825,
						weightedScore: 3.182314097042779,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 137, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-17T17:26:16.261Z',
						updatedAt: '2024-06-17T17:29:10.921Z',
					},
					{
						id: 'clxpf5tr200wf1320biepqe5e',
						score: 1680477,
						accuracy: 0.8949013092204586,
						normalizedAccuracy: 0.9208007377465525,
						weightedScore: 3.7365670794047485,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 89, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-22T01:06:23.582Z',
						updatedAt: '2024-06-22T01:09:49.800Z',
					},
					{
						id: 'clxpf3dhc00wd1320ra7e7lt8',
						score: 624534,
						accuracy: 0.9134954473982521,
						normalizedAccuracy: 0.9318106878611596,
						weightedScore: 4.40102693853252,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 115, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-22T01:04:29.184Z',
						updatedAt: '2024-06-22T01:06:01.660Z',
					},
				],
			},
			{
				player: {
					id: 'clvzsw7dw001114nabjthc6lx',
					teamId: 'clvjl8m70001vd63smw8l0g5y',
					user: {
						id: 'clkh3y2es0001pd30lhagrmaa',
						discordId: '994679547318501488',
						discordAvatar: 'https://cdn.discordapp.com/avatars/994679547318501488/3b3620d8b0b1d21792102d5a6e0689df.png',
						discordUsername: 'Millz ♱',
						discordDiscriminator: '0',
						twitchUser: 'nikomillz',
						playableAccounts: [
							{
								id: '76561198164256931',
								type: 'STEAM',
								name: '𝙼𝚒𝚕𝚕𝚣',
								avatar: 'https://avatars.steamstatic.com/814d25fdb1a111920a622ec5d42a84430dd2bb9c_full.jpg',
							},
							{
								id: '2647707105316159',
								type: 'OCULUS',
								name: 'PhonkZyz | TC',
								avatar: 'https://cdn.assets.beatleader.xyz/2647707105316159R13.png',
							},
						],
						bio: 'blip blop',
						controllers: 'Quest 2 Touch',
						countryCode: 'SE',
						motto: 'mily',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9192838246697008,
				averageNormalizedAccuracy: 0.9368662133818726,
				averageWeightedScore: 4.2004167711583,
				scores: [
					{
						id: 'clxtm51m30003sh1cuokdoh5l',
						score: 969191,
						accuracy: 0.9532573041609497,
						normalizedAccuracy: 0.9680206630184107,
						weightedScore: 3.064878111698697,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 131, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T23:32:49.131Z',
						updatedAt: '2024-06-24T23:35:23.155Z',
					},
					{
						id: 'clxtm0mbv0001sh1cc9io3lk2',
						score: 986486,
						accuracy: 0.9380141203318516,
						normalizedAccuracy: 0.9568727035699182,
						weightedScore: 3.145124716553288,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 131, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T23:29:22.700Z',
						updatedAt: '2024-06-24T23:31:40.973Z',
					},
					{
						id: 'clxs20oj7009i8478bvzf2sak',
						score: 493711,
						accuracy: 0.9737217351859339,
						normalizedAccuracy: 0.9804568346466701,
						weightedScore: 3.825437840941717,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 147, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-23T21:21:47.059Z',
						updatedAt: '2024-06-23T21:24:42.092Z',
					},
					{
						id: 'clxs3uux200ai84782341fwx6',
						score: 1854522,
						accuracy: 0.9091886035332675,
						normalizedAccuracy: 0.9309377385985952,
						weightedScore: 3.887039912704075,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 102, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-23T22:13:14.630Z',
						updatedAt: '2024-06-23T22:16:35.536Z',
					},
					{
						id: 'clxtma97f0001qiy3cmz8lf3e',
						score: 1991607,
						accuracy: 0.9238901968061048,
						normalizedAccuracy: 0.9422012024897565,
						weightedScore: 3.91626485892968,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 126, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-24T23:36:52.251Z',
						updatedAt: '2024-06-24T23:41:35.553Z',
					},
					{
						id: 'clxs2ckch009q84789rtfldd9',
						score: 1654723,
						accuracy: 0.8811865792255443,
						normalizedAccuracy: 0.9066890883756151,
						weightedScore: 4.224166004013783,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 91, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T21:31:01.506Z',
						updatedAt: '2024-06-23T21:34:27.255Z',
					},
					{
						id: 'clxtmh0090003qiy3nstf02mh',
						score: 585040,
						accuracy: 0.8557282334442535,
						normalizedAccuracy: 0.8728852629741419,
						weightedScore: 7.340005953266855,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 125, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-24T23:42:06.922Z',
						updatedAt: '2024-06-24T23:43:41.221Z',
					},
				],
			},
			{
				player: {
					id: 'clw4v643p0017pj8vf5hhtaru',
					teamId: 'clvjl8m70001vd63smw8l0g5y',
					user: {
						id: 'cli28cdyc0000ui2zst6xf63i',
						discordId: '536645459788300294',
						discordAvatar: 'https://cdn.discordapp.com/avatars/536645459788300294/abc9333e735e1c8ed1eafe9a01bdf29d.png',
						discordUsername: '_Robertas',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198914844976',
								type: 'STEAM',
								name: '_Robertas',
								avatar: 'https://avatars.akamai.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'SE',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9669894924320873,
				averageNormalizedAccuracy: 0.8447029083597135,
				averageWeightedScore: 5.136121000871414,
				scores: [
					{
						id: 'clx83wgt8004b7pioml2fygo2',
						score: 1803895,
						accuracy: 0.9606248685321128,
						normalizedAccuracy: 0.9884264091786542,
						weightedScore: 1.3999015487144535,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 17, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T22:19:06.140Z',
						updatedAt: '2024-06-09T22:22:32.305Z',
					},
					{
						id: 'clx8353lc00427pioixvd4zr4',
						score: 2094384,
						accuracy: 0.9715676064341795,
						normalizedAccuracy: 0.9908235526764598,
						weightedScore: 1.4630018618417913,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 20, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T21:57:49.297Z',
						updatedAt: '2024-06-09T22:02:37.868Z',
					},
					{
						id: 'clx83ksif00487piokoq9x5li',
						score: 992803,
						accuracy: 0.9764811181107783,
						normalizedAccuracy: 0.9916041505819464,
						weightedScore: 1.542112730555914,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 29, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T22:10:01.431Z',
						updatedAt: '2024-06-09T22:12:35.377Z',
					},
					{
						id: 'clx84raxx004p7piowb2jzb7p',
						score: 1965628,
						accuracy: 0.9636588707957573,
						normalizedAccuracy: 0.9867110151543521,
						weightedScore: 1.5555252444705585,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 26, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T22:43:04.870Z',
						updatedAt: '2024-06-09T22:46:25.623Z',
					},
					{
						id: 'clx844rlc004d7piovfaqsi09',
						score: 499481,
						accuracy: 0.9851016202037335,
						normalizedAccuracy: 0.991915432765633,
						weightedScore: 2.168819982773471,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 86, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-09T22:25:33.361Z',
						updatedAt: '2024-06-09T22:28:28.270Z',
					},
					{
						id: 'clx84miuz004j7piorv6vmayj',
						score: 645733,
						accuracy: 0.9445028705159615,
						normalizedAccuracy: 0.963439798160949,
						weightedScore: 2.823485637743712,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 83, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-09T22:39:21.851Z',
						updatedAt: '2024-06-09T22:40:53.428Z',
					},
					{
						id: '0prvzp',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 166, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
				],
			},
			{
				player: {
					id: 'clvjlpho3001xd63sev2ggxay',
					teamId: 'clvjl8m70001vd63smw8l0g5y',
					user: {
						id: 'clhw1du500002r62zrtbb977v',
						discordId: '275618116179066890',
						discordAvatar: 'https://cdn.discordapp.com/avatars/275618116179066890/b3702e98b128bb18332c08f8785c9aed.png',
						discordUsername: 'Chromia',
						discordDiscriminator: '0',
						twitchUser: 'Chromia__',
						playableAccounts: [
							{
								id: '76561198259042010',
								type: 'STEAM',
								name: 'Chromia',
								avatar: 'https://avatars.akamai.steamstatic.com/0f9755199cb170d23033df5a2017c29a83dfec72_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'SE',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9608104561087557,
				averageNormalizedAccuracy: 0.8380689719337385,
				averageWeightedScore: 5.4084055351868034,
				scores: [
					{
						id: 'clx7jrea8001q7pio88ls8cnq',
						score: 502018,
						accuracy: 0.9901052195607798,
						normalizedAccuracy: 0.9969536413319776,
						weightedScore: 1.4404249210450761,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 26, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-09T12:55:17.264Z',
						updatedAt: '2024-06-09T12:58:14.348Z',
					},
					{
						id: 'clxn2lf7l00mj1320d0w3y7ac',
						score: 986549,
						accuracy: 0.9703299351342313,
						normalizedAccuracy: 0.985357702537632,
						weightedScore: 1.9454404746549723,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 78, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-20T09:39:03.873Z',
						updatedAt: '2024-06-20T09:41:42.477Z',
					},
					{
						id: 'clxn2t0k200ml13201bf6vx2b',
						score: 1009286,
						accuracy: 0.9596938217605249,
						normalizedAccuracy: 0.9789882709894194,
						weightedScore: 2.0451102426786316,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 90, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-20T09:44:58.130Z',
						updatedAt: '2024-06-20T09:47:16.962Z',
					},
					{
						id: 'clx8xswre00597piorgwbs5t1',
						score: 2066736,
						accuracy: 0.9587419253830007,
						normalizedAccuracy: 0.9777436735404472,
						weightedScore: 2.1229531675180215,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 81, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-10T12:16:08.667Z',
						updatedAt: '2024-06-10T12:21:00.845Z',
					},
					{
						id: 'clxixn5in008o132031nnepb7',
						score: 1914583,
						accuracy: 0.9386338065110761,
						normalizedAccuracy: 0.9610873143480175,
						weightedScore: 2.626684013933773,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 80, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-17T12:09:21.839Z',
						updatedAt: '2024-06-17T12:12:43.142Z',
					},
					{
						id: 'clxtjfbx2000t96lnzs89uwnf',
						score: 647685,
						accuracy: 0.9473580283029217,
						normalizedAccuracy: 0.9663522007886762,
						weightedScore: 2.6782259264771544,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 79, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T22:16:50.198Z',
						updatedAt: '2024-06-24T22:18:23.394Z',
					},
					{
						id: '2l4j6',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 127, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clw4z2ku7001apj8vev6unlqc',
					teamId: 'clvjl8m70001vd63smw8l0g5y',
					user: {
						id: 'clw4z18k00018pj8vug6r13y5',
						discordId: '372025707628593155',
						discordAvatar: 'https://cdn.discordapp.com/embed/avatars/1.png',
						discordUsername: 'protein',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198375777848',
								type: 'STEAM',
								name: 'protein',
								avatar: 'https://avatars.steamstatic.com/e3ad7c43f202f2ed404f13c0ecddc34d556e7c8c_full.jpg',
							},
						],
						bio: ' ',
						controllers: 'Valve Index Knuckles',
						countryCode: 'SE',
						motto: null,
						pronouns: ' ',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9469718814317756,
				averageNormalizedAccuracy: 0.8278060602716082,
				averageWeightedScore: 5.979370063849187,
				scores: [
					{
						id: 'clxkvuq5400ex1320vqrf4yo4',
						score: 1751013,
						accuracy: 0.932463714863127,
						normalizedAccuracy: 0.9594502407374835,
						weightedScore: 2.4011132568442575,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 69, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-18T20:54:48.280Z',
						updatedAt: '2024-06-18T20:58:13.946Z',
					},
					{
						id: 'clxulk3ym009qqiy3y1azqquo',
						score: 999909,
						accuracy: 0.9507775691159341,
						normalizedAccuracy: 0.9698927588976359,
						weightedScore: 2.497515318184011,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 115, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T16:04:18.575Z',
						updatedAt: '2024-06-25T16:06:36.910Z',
					},
					{
						id: 'clxun1e6c00agqiy3z8a37lmw',
						score: 2049986,
						accuracy: 0.9509717373908405,
						normalizedAccuracy: 0.969819484610752,
						weightedScore: 2.5227717572922135,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 101, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T16:45:44.580Z',
						updatedAt: '2024-06-25T16:50:32.809Z',
					},
					{
						id: 'clxujnw17008oqiy3p2nx2txb',
						score: 646710,
						accuracy: 0.9459319120927341,
						normalizedAccuracy: 0.9648974914843556,
						weightedScore: 2.7507813662747433,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 82, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T15:11:15.691Z',
						updatedAt: '2024-06-25T15:12:48.350Z',
					},
					{
						id: 'clxukwat1009eqiy3q8d6e05y',
						score: 1888347,
						accuracy: 0.9257714774568514,
						normalizedAccuracy: 0.9479172993738771,
						weightedScore: 3.1772359088429094,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 93, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T15:45:47.701Z',
						updatedAt: '2024-06-25T15:49:08.417Z',
					},
					{
						id: 'clxkvnk5u00er1320z29y9fl3',
						score: 494823,
						accuracy: 0.9759148776711667,
						normalizedAccuracy: 0.982665146797153,
						weightedScore: 3.506172839506173,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 142, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-18T20:49:13.938Z',
						updatedAt: '2024-06-18T20:52:08.801Z',
					},
					{
						id: '2exq77',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 167, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clvz1k7c2000l14naswj2r36v',
					teamId: 'clvjl8m70001vd63smw8l0g5y',
					user: {
						id: 'clvyutdfq000j14naqd27vn02',
						discordId: '249718085244813312',
						discordAvatar: 'https://cdn.discordapp.com/avatars/249718085244813312/d0a2e1d35362a1738a143ae9f96aaef2.png',
						discordUsername: 'DawnSnowmeow',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198091763897',
								type: 'STEAM',
								name: 'Dawn',
								avatar: 'https://avatars.steamstatic.com/71a7c8d523b80238802862d932b4e24ab4bab91b_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'SE',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8003857735354645,
				averageNormalizedAccuracy: 0.6973021112122503,
				averageWeightedScore: 8.877241299177394,
				scores: [
					{
						id: 'clxs1188a009484788xlu36p2',
						score: 2059753,
						accuracy: 0.9555025688009556,
						normalizedAccuracy: 0.9744401146571002,
						weightedScore: 2.2896357473623907,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 91, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-23T20:54:12.971Z',
						updatedAt: '2024-06-23T20:59:02.015Z',
					},
					{
						id: 'clxvww97b00ct90r9j7cpvcab',
						score: 498844,
						accuracy: 0.9838452966757719,
						normalizedAccuracy: 0.9906504194204372,
						weightedScore: 2.3517082974447314,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 101, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T14:09:27.192Z',
						updatedAt: '2024-06-26T14:12:22.701Z',
					},
					{
						id: 'clxqj0o63010d13200b7csnlx',
						score: 1002414,
						accuracy: 0.953159483680795,
						normalizedAccuracy: 0.9723225613706996,
						weightedScore: 2.3766584648043616,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 112, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-22T19:42:07.707Z',
						updatedAt: '2024-06-22T19:44:26.259Z',
					},
					{
						id: 'clxp3rwr400ur1320saunn7iu',
						score: 979762,
						accuracy: 0.9636545147853627,
						normalizedAccuracy: 0.9785788981121823,
						weightedScore: 2.3831420095446925,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 111, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-21T19:47:38.512Z',
						updatedAt: '2024-06-21T19:50:12.818Z',
					},
					{
						id: 'clxp4d3vu00v51320qbg990db',
						score: 646861,
						accuracy: 0.9461527772699017,
						normalizedAccuracy: 0.9651227849253324,
						weightedScore: 2.7395445750855782,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 81, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-21T20:04:07.530Z',
						updatedAt: '2024-06-21T20:05:40.617Z',
					},
					{
						id: 'dunex8',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 144, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'clxs1gvpk009a8478csbqeaa5',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 133, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-23T21:06:23.240Z',
						updatedAt: '2024-06-23T21:06:23.240Z',
					},
				],
			},
			{
				player: {
					id: 'clvjl8m6w001td63s00x6kz18',
					teamId: 'clvjl8m70001vd63smw8l0g5y',
					user: {
						id: 'clvgk41kd001ld63s7uenyqvi',
						discordId: '787299138193719299',
						discordAvatar: 'https://cdn.discordapp.com/avatars/787299138193719299/535bd590b060b135732f4ce40c0dbecb.png',
						discordUsername: 'mdwl',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199116866553',
								type: 'STEAM',
								name: 'mdwl',
								avatar: 'https://avatars.steamstatic.com/5703a93849ba2de31d387e725ecf0f874559f4ae_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'SE',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: '1is54r',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 173, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: '8kgbis',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 145, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'e8ep3',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 152, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'w1k3kk',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 153, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '2n52j',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 134, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'wrhhy5',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 157, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: '435vr',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 158, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clvjl8m6w001td63s00x6kz18',
			user: {
				id: 'clvgk41kd001ld63s7uenyqvi',
				discordId: '787299138193719299',
				discordAvatar: 'https://cdn.discordapp.com/avatars/787299138193719299/535bd590b060b135732f4ce40c0dbecb.png',
				discordUsername: 'mdwl',
				discordDiscriminator: '0',
				twitchUser: null,
				playableAccounts: [
					{
						id: '76561199116866553',
						type: 'STEAM',
						name: 'mdwl',
						avatar: 'https://avatars.steamstatic.com/5703a93849ba2de31d387e725ecf0f874559f4ae_full.jpg',
					},
				],
				bio: null,
				controllers: null,
				countryCode: 'SE',
				motto: null,
				pronouns: null,
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9550669961602413,
		averageNormalizedAccuracy: 0.9734048006084838,
		averageWeightedScore: 2.303550264173539,
	},
	{
		id: 'clv0unpc3000rykbvkw4w11u6',
		name: 'Switzerland',
		image: 'https://cdn.cube.community/1713179192423-switzerland.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwqsx5ip003s903xehkbwhoq',
					teamId: 'clv0unpc3000rykbvkw4w11u6',
					user: {
						id: 'clh4y4pux003wpf4mxbry1950',
						discordId: '219039405090930688',
						discordAvatar: 'https://cdn.discordapp.com/avatars/219039405090930688/992e39a3d908af47a220640efe900db3.png',
						discordUsername: 'KultainenAnkka',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198178820185',
								type: 'STEAM',
								name: 'KultainenAnkka',
								avatar: 'https://avatars.akamai.steamstatic.com/70ebd7d7850747f5403342bfe6b3e0f517a2f1d9_full.jpg',
							},
						],
						bio: 'the duck',
						controllers: 'Quest 2 Touch',
						countryCode: 'CH',
						motto: "when you aren't good enough to be #1 in finland so you move to switzerland",
						pronouns: '',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9529589113417819,
				averageNormalizedAccuracy: 0.9712537345702962,
				averageWeightedScore: 2.440535786954475,
				scores: [
					{
						id: 'clxn6t8ht00mv1320pqz4sjn0',
						score: 1009485,
						accuracy: 0.9598830437159769,
						normalizedAccuracy: 0.9791812972138265,
						weightedScore: 2.0355092391566556,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 88, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-20T11:37:06.881Z',
						updatedAt: '2024-06-20T11:39:25.562Z',
					},
					{
						id: 'clxrns4i4002f8478loxp9l10',
						score: 2069225,
						accuracy: 0.9598965521240447,
						normalizedAccuracy: 0.9789211843611045,
						weightedScore: 2.0635413185659046,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 78, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T14:43:13.229Z',
						updatedAt: '2024-06-23T14:48:01.857Z',
					},
					{
						id: 'clxn5tojw00mr1320upy8vly3',
						score: 981895,
						accuracy: 0.9657524478344472,
						normalizedAccuracy: 0.9807093224291831,
						weightedScore: 2.2455823552173353,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 101, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-20T11:09:28.077Z',
						updatedAt: '2024-06-20T11:12:02.356Z',
					},
					{
						id: 'clxn5czhs00mp1320c4u8li4p',
						score: 498745,
						accuracy: 0.9836500438825722,
						normalizedAccuracy: 0.9904538160904931,
						weightedScore: 2.3801320700545507,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 103, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-20T10:56:29.104Z',
						updatedAt: '2024-06-20T10:59:23.824Z',
					},
					{
						id: 'clxn6dk4m00mt1320r7bk8yz7',
						score: 647844,
						accuracy: 0.9475905949464293,
						normalizedAccuracy: 0.9665894303059962,
						weightedScore: 2.6663938086024705,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 78, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-20T11:24:55.462Z',
						updatedAt: '2024-06-20T11:26:28.053Z',
					},
					{
						id: 'clxroivm2002l8478s9ez1k9v',
						score: 1732982,
						accuracy: 0.9228616997766045,
						normalizedAccuracy: 0.949570332769503,
						weightedScore: 2.742493089477072,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 79, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T15:04:01.418Z',
						updatedAt: '2024-06-23T15:07:27.699Z',
					},
					{
						id: 'clxrpllvo00338478udpstp42',
						score: 1899171,
						accuracy: 0.9310779971123982,
						normalizedAccuracy: 0.9533507588219674,
						weightedScore: 2.9500986276073364,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 90, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T15:34:08.388Z',
						updatedAt: '2024-06-23T15:37:29.570Z',
					},
				],
			},
			{
				player: {
					id: 'clwqsxmet0040903x9hqpsopy',
					teamId: 'clv0unpc3000rykbvkw4w11u6',
					user: {
						id: 'clhi13shn005kpf4mg7yula7h',
						discordId: '423147536862150676',
						discordAvatar: 'https://cdn.discordapp.com/avatars/423147536862150676/5cff798ecd66a3d8746465597550ff13.png',
						discordUsername: 'jojobanana',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198294659898',
								type: 'STEAM',
								name: 'Jojobanana',
								avatar: 'https://avatars.akamai.steamstatic.com/66b82be1c041733bbb4fdbfd79be8e3c3a02b989_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'CH',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9540875106015031,
				averageNormalizedAccuracy: 0.972435609187097,
				averageWeightedScore: 2.4543590264649873,
				scores: [
					{
						id: 'clxsxmrs400gx8478hi2wufr1',
						score: 1761375,
						accuracy: 0.9379817715613992,
						normalizedAccuracy: 0.9651279960679818,
						weightedScore: 2.204930137453141,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 64, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T12:06:45.796Z',
						updatedAt: '2024-06-24T12:10:11.049Z',
					},
					{
						id: 'clxrq6x95003j8478hky2tyhv',
						score: 1004440,
						accuracy: 0.9550859343428341,
						normalizedAccuracy: 0.9742877429317482,
						weightedScore: 2.2789115646258504,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 106, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T15:50:42.905Z',
						updatedAt: '2024-06-23T15:53:00.912Z',
					},
					{
						id: 'clxmcpteb00kp13203ieaoyim',
						score: 980260,
						accuracy: 0.9641443275647551,
						normalizedAccuracy: 0.9790762967572205,
						weightedScore: 2.351025409518896,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 110, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-19T21:34:38.867Z',
						updatedAt: '2024-06-19T21:37:12.560Z',
					},
					{
						id: 'clxt3y4jb00j98478lquvkps1',
						score: 1927261,
						accuracy: 0.9448492588570686,
						normalizedAccuracy: 0.9674514494997994,
						weightedScore: 2.3606412892936586,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 73, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T15:03:33.240Z',
						updatedAt: '2024-06-24T15:06:53.488Z',
					},
					{
						id: 'clxswj5sn00gh8478jo5hndpz',
						score: 2050895,
						accuracy: 0.951393415055609,
						normalizedAccuracy: 0.9702495196995337,
						weightedScore: 2.5010741394949156,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 100, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T11:35:57.720Z',
						updatedAt: '2024-06-24T11:40:45.466Z',
					},
					{
						id: 'clxrqokrm003t84781s7ae62k',
						score: 498060,
						accuracy: 0.9822990523336653,
						normalizedAccuracy: 0.9890934799186578,
						weightedScore: 2.57680160780936,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 113, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T16:04:26.531Z',
						updatedAt: '2024-06-23T16:07:21.170Z',
					},
					{
						id: 'clxmc0jfn00kf1320tloub3xz',
						score: 644609,
						accuracy: 0.9428588144951914,
						normalizedAccuracy: 0.9617627794347373,
						weightedScore: 2.9071290370590863,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 87, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-19T21:14:59.556Z',
						updatedAt: '2024-06-19T21:16:31.929Z',
					},
				],
			},
			{
				player: {
					id: 'clwqsxpkw0042903xeqzcjvo2',
					teamId: 'clv0unpc3000rykbvkw4w11u6',
					user: {
						id: 'clhzbl4970008o42z4sratysc',
						discordId: '519868526803156994',
						discordAvatar: 'https://cdn.discordapp.com/avatars/519868526803156994/c81e11a8b4518db95bda1daf2ccebdc3.png',
						discordUsername: 'Nickname69',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '2374105149332029',
								type: 'OCULUS',
								name: 'Nickname69',
								avatar: 'https://avatars.akamai.steamstatic.com/8c9a0830959cf4958325ff7ea25547da60878608.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: null,
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9457405083755213,
				averageNormalizedAccuracy: 0.963899425495648,
				averageWeightedScore: 2.8741251051552985,
				scores: [
					{
						id: 'clxaq4329008m7pioiah3cwkv',
						score: 1913343,
						accuracy: 0.938025890364284,
						normalizedAccuracy: 0.9604648559485689,
						weightedScore: 2.652704914592689,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 82, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-11T18:16:25.474Z',
						updatedAt: '2024-06-11T18:19:46.007Z',
					},
					{
						id: 'clxtazja600n384780twpb7yp',
						score: 996509,
						accuracy: 0.9475446311835881,
						normalizedAccuracy: 0.9665948234052542,
						weightedScore: 2.661552564288127,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 123, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T18:20:36.318Z',
						updatedAt: '2024-06-24T18:22:54.592Z',
					},
					{
						id: 'clxm7ybtb00jh1320a68tjvwx',
						score: 972559,
						accuracy: 0.9565699335605357,
						normalizedAccuracy: 0.9713845960234077,
						weightedScore: 2.8476718689539533,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 128, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-19T19:21:17.904Z',
						updatedAt: '2024-06-19T19:23:52.155Z',
					},
					{
						id: 'clxm84yem00jl13204hkcabuy',
						score: 496756,
						accuracy: 0.9797272377646513,
						normalizedAccuracy: 0.9865038764616166,
						weightedScore: 2.9511915015790984,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 126, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-19T19:26:27.118Z',
						updatedAt: '2024-06-19T19:29:22.158Z',
					},
					{
						id: 'clxm8ekl000jp1320gt1cphwj',
						score: 1719857,
						accuracy: 0.9158722677977564,
						normalizedAccuracy: 0.9423786189388921,
						weightedScore: 2.990987920784581,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 82, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-19T19:33:55.764Z',
						updatedAt: '2024-06-19T19:37:21.220Z',
					},
					{
						id: 'clxapxapb008k7pioqjwrsrnr',
						score: 643364,
						accuracy: 0.9410377737960288,
						normalizedAccuracy: 0.9599052275538354,
						weightedScore: 2.9997767524929304,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 92, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-11T18:11:08.783Z',
						updatedAt: '2024-06-11T18:12:41.689Z',
					},
					{
						id: 'clxhmy32w005u1320xwonn88h',
						score: 2029365,
						accuracy: 0.9414058241618055,
						normalizedAccuracy: 0.9600639801379613,
						weightedScore: 3.014990213395713,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 119, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-16T14:22:09.944Z',
						updatedAt: '2024-06-16T14:26:57.913Z',
					},
				],
			},
			{
				player: {
					id: 'clwqsxi3c003y903x4jvwgwhc',
					teamId: 'clv0unpc3000rykbvkw4w11u6',
					user: {
						id: 'clgytbjlr002qo14mn0zq3zq3',
						discordId: '769619307977900052',
						discordAvatar: 'https://cdn.discordapp.com/avatars/769619307977900052/8b01d09235ca7aa0a641e52065515d99.png',
						discordUsername: 'DeadPacity',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198166144902',
								type: 'STEAM',
								name: 'DeadPacity',
								avatar: 'https://avatars.steamstatic.com/a462f0f0cc9394658f90032e64cf56abce8da8eb_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: null,
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9240635153609029,
				averageNormalizedAccuracy: 0.9416076163681879,
				averageWeightedScore: 3.5710894282661685,
				scores: [
					{
						id: 'clxt9azmj00lv847843ewgfx2',
						score: 984885,
						accuracy: 0.9686932916303979,
						normalizedAccuracy: 0.9836957118843318,
						weightedScore: 2.0527537727331358,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 88, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T17:33:31.483Z',
						updatedAt: '2024-06-24T17:36:05.597Z',
					},
					{
						id: 'clxt9lf7i00m184786470e4rm',
						score: 1009103,
						accuracy: 0.9595198136306369,
						normalizedAccuracy: 0.9788107644614471,
						weightedScore: 2.0539393062189415,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 92, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T17:41:38.239Z',
						updatedAt: '2024-06-24T17:43:56.751Z',
					},
					{
						id: 'clxrr9cn7004784785pbhz62t',
						score: 499750,
						accuracy: 0.9856321555711144,
						normalizedAccuracy: 0.9924496377732588,
						weightedScore: 2.0915877117427506,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 78, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T16:20:35.780Z',
						updatedAt: '2024-06-23T16:23:31.289Z',
					},
					{
						id: 'clxnr8iig00q41320dhenh4yw',
						score: 650020,
						accuracy: 0.9507733937909094,
						normalizedAccuracy: 0.9698360430713315,
						weightedScore: 2.50446495014139,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 67, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-20T21:08:52.025Z',
						updatedAt: '2024-06-20T21:10:25.552Z',
					},
					{
						id: 'clxp6ug7s00vz1320mmvvl0gi',
						score: 2028990,
						accuracy: 0.9412318647291452,
						normalizedAccuracy: 0.9598865729231174,
						weightedScore: 3.023941375853344,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 120, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-21T21:13:35.896Z',
						updatedAt: '2024-06-21T21:18:31.607Z',
					},
					{
						id: 'clxrss9tt004z8478j5o52byt',
						score: 1574503,
						accuracy: 0.8384671709708255,
						normalizedAccuracy: 0.8627333334429214,
						weightedScore: 5.742966412965277,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 96, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T17:03:18.209Z',
						updatedAt: '2024-06-23T17:06:48.457Z',
					},
					{
						id: 'clxrt88y9005384780geffsmb',
						score: 1681017,
						accuracy: 0.8241269172032916,
						normalizedAccuracy: 0.8438412510209071,
						weightedScore: 7.527972468208335,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 107, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T17:15:43.570Z',
						updatedAt: '2024-06-23T17:19:05.566Z',
					},
				],
			},
			{
				player: {
					id: 'clwqsxez4003w903xm1tf9n7a',
					teamId: 'clv0unpc3000rykbvkw4w11u6',
					user: {
						id: 'clhzbka9x0006o42z9xm2fp0o',
						discordId: '114441799166197767',
						discordAvatar: 'https://cdn.discordapp.com/avatars/114441799166197767/ec4e6fd08e79de252fe76795c810b2d2.png',
						discordUsername: 'Lukeforce',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198063012680',
								type: 'STEAM',
								name: '[Meow] Lukeforce',
								avatar: 'https://avatars.akamai.steamstatic.com/89c6df6bc74d132acac55384f11ee1c0a4281052_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'CH',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.7798653099625961,
				averageNormalizedAccuracy: 0.7937185125302072,
				averageWeightedScore: 9.499273302846621,
				scores: [
					{
						id: 'clxuwgbkv00d9qiy3us52vrlo',
						score: 977036,
						accuracy: 0.9609733307760778,
						normalizedAccuracy: 0.9758561898664515,
						weightedScore: 2.5589449245453375,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 119, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T21:09:17.599Z',
						updatedAt: '2024-06-25T21:11:52.095Z',
					},
					{
						id: 'clxqpa8yr011x132044x3p3mc',
						score: 497484,
						accuracy: 0.9811630360823217,
						normalizedAccuracy: 0.9879496059989832,
						weightedScore: 2.7421762848119435,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 120, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-22T22:37:32.260Z',
						updatedAt: '2024-06-22T22:40:27.608Z',
					},
					{
						id: 'clxuvz7hv00d5qiy3yrjjnl05',
						score: 2037816,
						accuracy: 0.9453261739362381,
						normalizedAccuracy: 0.9640620291316839,
						weightedScore: 2.813266816250537,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 112, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T20:55:59.156Z',
						updatedAt: '2024-06-25T21:00:47.742Z',
					},
					{
						id: 'clxuwjzu400ddqiy37w9cpuum',
						score: 988871,
						accuracy: 0.9402819312049825,
						normalizedAccuracy: 0.9591861083197213,
						weightedScore: 3.0300574130361366,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 129, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T21:12:09.004Z',
						updatedAt: '2024-06-25T21:14:27.525Z',
					},
					{
						id: 'clxuwstl200dnqiy38nx3pnc6',
						score: 576472,
						accuracy: 0.8431959629941127,
						normalizedAccuracy: 0.8601017252106344,
						weightedScore: 7.97760083345736,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 127, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T21:19:00.807Z',
						updatedAt: '2024-06-25T21:20:33.597Z',
					},
					{
						id: 'clxuwne0l00dhqiy3qsnosgur',
						score: 712388,
						accuracy: 0.37936666427029,
						normalizedAccuracy: 0.39034595294180824,
						weightedScore: 22.065337574311787,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 104, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T21:14:47.350Z',
						updatedAt: '2024-06-25T21:18:13.186Z',
					},
					{
						id: 'clxuwv4ol00dpqiy3k97qy2r5',
						score: 833750,
						accuracy: 0.4087500704741501,
						normalizedAccuracy: 0.4185279762421684,
						weightedScore: 25.307529273513243,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 109, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T21:20:48.501Z',
						updatedAt: '2024-06-25T21:24:09.511Z',
					},
				],
			},
			{
				player: {
					id: 'clwqsxt520044903xhcgtqkqp',
					teamId: 'clv0unpc3000rykbvkw4w11u6',
					user: {
						id: 'clheqyb3t004qo14ml4ohn6tp',
						discordId: '445637302320889857',
						discordAvatar: 'https://cdn.discordapp.com/avatars/445637302320889857/fe78806172eed16671d314ecdd3e522b.png',
						discordUsername: 'HeroYT',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198178294718',
								type: 'STEAM',
								name: 'HeroYT',
								avatar: 'https://avatars.akamai.steamstatic.com/5893ff7c75d3182b746db38b088055e76be30faa_full.jpg',
							},
						],
						bio: "cunny love is cunny life one day i hope for a cunny wife as i arise in the morning and gaze upon my cunny wife yawning with those eyes like dazzling gems and those gams ten out of ten i'll know that all is well in life when i have myself a cunny wife",
						controllers: 'Valve Index Knuckles',
						countryCode: 'CH',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9695135939532589,
				averageNormalizedAccuracy: 0.5626104961044048,
				averageWeightedScore: 11.859993025608258,
				scores: [
					{
						id: 'clxn8kwx500n11320vqg1vf39',
						score: 1013322,
						accuracy: 0.9635315092590392,
						normalizedAccuracy: 0.982903114415082,
						weightedScore: 1.8503883823032758,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 70, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-20T12:26:37.865Z',
						updatedAt: '2024-06-20T12:28:56.326Z',
					},
					{
						id: 'clxdcf48q000n1320am1jwvd2',
						score: 499877,
						accuracy: 0.9858826313765322,
						normalizedAccuracy: 0.9927018460854092,
						weightedScore: 2.0551248923341947,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 75, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-13T14:16:24.123Z',
						updatedAt: '2024-06-13T14:19:18.773Z',
					},
					{
						id: 'clxag2iib00847pionrltmi2n',
						score: 984832,
						accuracy: 0.9686411629611051,
						normalizedAccuracy: 0.9836427758839563,
						weightedScore: 2.056171804462789,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 89, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-11T13:35:16.019Z',
						updatedAt: '2024-06-11T13:37:50.241Z',
					},
					{
						id: 'clxdck886000p1320c37os8bh',
						score: 2069446,
						accuracy: 0.9599990722163592,
						normalizedAccuracy: 0.9790257363463859,
						weightedScore: 2.0582661001575406,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 77, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-13T14:20:22.566Z',
						updatedAt: '2024-06-13T14:25:13.786Z',
					},
					{
						id: 'kx5bs',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 179, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 's0yenzg',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 182, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '3r13t',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 173, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwqsxbjv003u903xuq8i5ts9',
					teamId: 'clv0unpc3000rykbvkw4w11u6',
					user: {
						id: 'clhzghbyx001mo42z2302aw99',
						discordId: '293037370100416513',
						discordAvatar: 'https://cdn.discordapp.com/avatars/293037370100416513/b32550f34e8955f2baf79aadc1f96838.png',
						discordUsername: 'selenit4',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198304343617',
								type: 'STEAM',
								name: 'Cum Jar Gaming | selenit4',
								avatar: 'https://avatars.akamai.steamstatic.com/d8ce3a91c215cbeb1f40f7a526ed67e2d2779aa7_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'CH',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.7224121338122295,
				averageNormalizedAccuracy: 0.41858750223807306,
				averageWeightedScore: 15.447385117318102,
				scores: [
					{
						id: 'clxrnzm8r002j8478xk7cykrx',
						score: 978063,
						accuracy: 0.9619834466886,
						normalizedAccuracy: 0.9768819497227852,
						weightedScore: 2.492712498387721,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 116, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T14:49:02.811Z',
						updatedAt: '2024-06-23T14:51:36.593Z',
					},
					{
						id: 'clxrnkypf00218478yv6zgny0',
						score: 2043650,
						accuracy: 0.948032518816612,
						normalizedAccuracy: 0.9668220123087491,
						weightedScore: 2.67401059817635,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 109, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T14:37:39.124Z',
						updatedAt: '2024-06-23T14:42:26.890Z',
					},
					{
						id: 'clxrmwryn001r8478sti2gp9r',
						score: 496708,
						accuracy: 0.979632569743706,
						normalizedAccuracy: 0.9864085536349771,
						weightedScore: 2.964972724662647,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 128, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-23T14:18:50.639Z',
						updatedAt: '2024-06-23T14:21:45.229Z',
					},
					{
						id: 'qv2tyi',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 175, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '7gnfm',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 177, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'clxrnx0fc002h84785i02kqwb',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 168, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-23T14:47:01.225Z',
						updatedAt: '2024-06-23T14:47:01.225Z',
					},
					{
						id: '2jpcv7',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 180, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
				],
			},
			{
				player: {
					id: 'clwrpqpdk004t903xuf25955y',
					teamId: 'clv0unpc3000rykbvkw4w11u6',
					user: {
						id: 'clwqwck6o0045903xlknlmucm',
						discordId: '369226484981825564',
						discordAvatar: 'https://cdn.discordapp.com/avatars/369226484981825564/d364ae6f53a81a18bbfa0264a5d786ac.png',
						discordUsername: 'reru*',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{id: '76561198163520974', type: 'STEAM', name: 'reru*', avatar: 'https://cdn.assets.beatleader.xyz/76561198163520974R20.png'},
						],
						bio: null,
						controllers: null,
						countryCode: 'CH',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.6223777967001713,
				averageNormalizedAccuracy: 0.2725974228946093,
				averageWeightedScore: 18.745794361309983,
				scores: [
					{
						id: 'clxtn4i6e0009qiy37myhc0fh',
						score: 1903517,
						accuracy: 0.9332086451559133,
						normalizedAccuracy: 0.9555323751155187,
						weightedScore: 2.8588995677172955,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 86, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T00:00:23.558Z',
						updatedAt: '2024-06-25T00:03:44.127Z',
					},
					{
						id: 'clxrwolch006m8478sokdt8zv',
						score: 638501,
						accuracy: 0.9339247449446009,
						normalizedAccuracy: 0.9526495851467466,
						weightedScore: 3.361660961452597,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 101, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T18:52:24.977Z',
						updatedAt: '2024-06-23T18:53:57.446Z',
					},
					{
						id: '2sdpfn',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 191, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.616Z',
						updatedAt: '2024-07-08T16:23:01.616Z',
					},
					{
						id: 'ew6mvo',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 188, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'clxry5del007s8478umtyoqwi',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 179, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-23T19:33:27.454Z',
						updatedAt: '2024-06-23T19:33:27.454Z',
					},
					{
						id: '245me',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 186, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'ltx1qtn',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 189, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clv0unpbz000pykbvd4vtdz9r',
			user: {
				id: 'clhz9uu8k0002qn2z8v884hp9',
				discordId: '223853045132296192',
				discordAvatar: 'https://cdn.discordapp.com/avatars/223853045132296192/f6648c28065fd3bee38af523d3d81408.png',
				discordUsername: 'Depito',
				discordDiscriminator: '0',
				twitchUser: null,
				playableAccounts: [
					{
						id: '76561198144232325',
						type: 'STEAM',
						name: 'CJG | Depito',
						avatar: 'https://avatars.akamai.steamstatic.com/d35f67f7356ad02bef1d44d0fd1f85675178664d_full.jpg',
					},
				],
				bio: null,
				controllers: null,
				countryCode: 'CH',
				motto: null,
				pronouns: null,
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9502372232478754,
		averageNormalizedAccuracy: 0.9684545521261227,
		averageWeightedScore: 2.5323680993128357,
	},
	{
		id: 'clv5b6pdn000dd63sokn5xrgv',
		name: 'Argentina',
		image: 'https://cdn.cube.community/1713448816986-argentina.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clv5b6pdj000bd63sj2k21nn0',
					teamId: 'clv5b6pdn000dd63sokn5xrgv',
					user: {
						id: 'clghmq1pk0000pe4mz0tbyboi',
						discordId: '200038416061169664',
						discordAvatar: 'https://cdn.discordapp.com/avatars/200038416061169664/a_d38bd013ddd4d8776328bda51b8666ce.png',
						discordUsername: 'Dereknalox123',
						discordDiscriminator: '0',
						twitchUser: 'dereknalux',
						playableAccounts: [
							{
								id: '76561198128883308',
								type: 'STEAM',
								name: 'Dereknalox123',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198128883308R17.png',
							},
						],
						bio: 'Go watch the Roster Reveal from Argentina again!! https://youtu.be/eWVlb3g6X-g',
						controllers: null,
						countryCode: 'AR',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9595662587931064,
				averageNormalizedAccuracy: 0.9780096209270731,
				averageWeightedScore: 2.1116450134321663,
				scores: [
					{
						id: 'clxgpr7z4005g1320cfz7lm71',
						score: 1011581,
						accuracy: 0.9618760548648584,
						normalizedAccuracy: 0.9812143774467771,
						weightedScore: 1.9343851015583537,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 76, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-15T22:53:02.369Z',
						updatedAt: '2024-06-15T22:55:22.003Z',
					},
					{
						id: 'clxtnl9v1000fqiy3jscn2c8h',
						score: 2072079,
						accuracy: 0.9612204993795447,
						normalizedAccuracy: 0.9802713715375434,
						weightedScore: 1.9954170048216928,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 71, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T00:13:25.934Z',
						updatedAt: '2024-06-25T00:18:16.176Z',
					},
					{
						id: 'clxgpaqey005e13207ej13j23',
						score: 985486,
						accuracy: 0.969284411068982,
						normalizedAccuracy: 0.9842959861527414,
						weightedScore: 2.013994582742164,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 85, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-15T22:40:13.114Z',
						updatedAt: '2024-06-15T22:42:47.438Z',
					},
					{
						id: 'clxgnzx4h00561320i0llj9b5',
						score: 499770,
						accuracy: 0.9856716005798416,
						normalizedAccuracy: 0.9924893556176919,
						weightedScore: 2.0858455354579384,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 77, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-15T22:03:48.978Z',
						updatedAt: '2024-06-15T22:06:44.522Z',
					},
					{
						id: 'clxtoca9j000lqiy30dabo7vo',
						score: 1938563,
						accuracy: 0.9503901203821047,
						normalizedAccuracy: 0.9731248566212255,
						weightedScore: 2.1234733705460194,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 61, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T00:34:26.167Z',
						updatedAt: '2024-06-25T00:37:48.788Z',
					},
					{
						id: 'clxvh1dv0004p90r9wkyi5yrd',
						score: 654005,
						accuracy: 0.9566021867115223,
						normalizedAccuracy: 0.9757817010997603,
						weightedScore: 2.2079178449173984,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 51, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T06:45:32.652Z',
						updatedAt: '2024-06-26T06:47:05.462Z',
					},
					{
						id: 'clxvh8sb6004v90r92r28i2ts',
						score: 1749990,
						accuracy: 0.9319189385648898,
						normalizedAccuracy: 0.9588896980137719,
						weightedScore: 2.420481653981597,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 71, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T06:51:17.971Z',
						updatedAt: '2024-06-26T06:54:45.446Z',
					},
				],
			},
			{
				player: {
					id: 'clwlf8ad2001q903xjg256usp',
					teamId: 'clv5b6pdn000dd63sokn5xrgv',
					user: {
						id: 'clw2vqdgi000hpj8v8pyer8l1',
						discordId: '513854069392015370',
						discordAvatar: 'https://cdn.discordapp.com/avatars/513854069392015370/676a4f54e6b3644223f0c58c89c433ff.png',
						discordUsername: 'Uadyet',
						discordDiscriminator: '0',
						twitchUser: 'theuadyet',
						playableAccounts: [
							{
								id: '76561199070938854',
								type: 'STEAM',
								name: 'Uadyet',
								avatar: 'https://avatars.steamstatic.com/7718c72ce2f0e661be72e079ed2ae72c307df027_full.jpg',
							},
						],
						bio: 'An onimai enthusiast dude with a high skill level in this game.\n(fuck acc)',
						controllers: 'Quest 2 Touch',
						countryCode: 'AR',
						motto: 'He is like Mahiro, but different.',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.947620941435016,
				averageNormalizedAccuracy: 0.9658601313404133,
				averageWeightedScore: 2.9979203764627838,
				scores: [
					{
						id: 'clxl82x0q00gd1320bw6br2v2',
						score: 1004617,
						accuracy: 0.9552542372881356,
						normalizedAccuracy: 0.9744594295735576,
						weightedScore: 2.2703719785786656,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 104, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-19T02:37:05.834Z',
						updatedAt: '2024-06-19T02:39:24.863Z',
					},
					{
						id: 'clxv6wjhd002l90r91mjusznl',
						score: 1745904,
						accuracy: 0.9297430285408462,
						normalizedAccuracy: 0.9566508147595337,
						weightedScore: 2.497841644893786,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 75, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T02:01:50.497Z',
						updatedAt: '2024-06-26T02:05:31.794Z',
					},
					{
						id: 'clxtnhm2l000bqiy386adlcbl',
						score: 2049964,
						accuracy: 0.950961531770791,
						normalizedAccuracy: 0.9698090767208145,
						weightedScore: 2.5232968921563947,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 103, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T00:10:35.133Z',
						updatedAt: '2024-06-25T00:15:25.489Z',
					},
					{
						id: 'clxtp2zrm000tqiy31138g4b2',
						score: 976019,
						accuracy: 0.9599730504615355,
						normalizedAccuracy: 0.9748404179347169,
						weightedScore: 2.6245324390558493,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 121, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T00:55:12.275Z',
						updatedAt: '2024-06-25T00:57:47.592Z',
					},
					{
						id: 'clxpf27jr00wb1320zf032gy7',
						score: 1913963,
						accuracy: 0.93832984843768,
						normalizedAccuracy: 0.9607760851482932,
						weightedScore: 2.639694464263231,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 81, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-22T01:03:34.839Z',
						updatedAt: '2024-06-22T01:06:58.376Z',
					},
					{
						id: 'clxv5lskc002390r931nsq2ut',
						score: 638474,
						accuracy: 0.9338852524957034,
						normalizedAccuracy: 0.9526093008890885,
						weightedScore: 3.3636701890162226,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 102, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T01:25:29.436Z',
						updatedAt: '2024-06-26T01:27:02.315Z',
					},
					{
						id: 'clxgw89vd005q13200zu4e1se',
						score: 489390,
						accuracy: 0.9651996410504206,
						normalizedAccuracy: 0.9718757943568886,
						weightedScore: 5.066035027275337,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 153, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-16T01:54:15.674Z',
						updatedAt: '2024-06-16T01:57:11.542Z',
					},
				],
			},
			{
				player: {
					id: 'clwlf7zi9001o903xefcv81k2',
					teamId: 'clv5b6pdn000dd63sokn5xrgv',
					user: {
						id: 'clwlf7koe001m903xml1u3s27',
						discordId: '377120502885384202',
						discordAvatar: 'https://cdn.discordapp.com/avatars/377120502885384202/ae40253723c4ae516d40bd6c3b961899.png',
						discordUsername: 'ElBurrito',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198417204770',
								type: 'STEAM',
								name: 'ElBurrito',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198417204770R17.png',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'AR',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9318095267950068,
				averageNormalizedAccuracy: 0.9496482712402327,
				averageWeightedScore: 3.4730908430335505,
				scores: [
					{
						id: 'clxqubscp012z1320vp8vxxbj',
						score: 2058968,
						accuracy: 0.9551384137219201,
						normalizedAccuracy: 0.9740687422206936,
						weightedScore: 2.308373514107032,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 92, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T00:58:42.121Z',
						updatedAt: '2024-06-23T01:03:30.043Z',
					},
					{
						id: 'clxthusc100rv84780njg6ap3',
						score: 1003094,
						accuracy: 0.9538060712672641,
						normalizedAccuracy: 0.972982148469176,
						weightedScore: 2.3438510155835384,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 108, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T21:32:52.081Z',
						updatedAt: '2024-06-24T21:35:10.047Z',
					},
					{
						id: 'clxqtwtcz012t1320yun6gj6m',
						score: 498450,
						accuracy: 0.9830682300038459,
						normalizedAccuracy: 0.9898679778851042,
						weightedScore: 2.464829170255527,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 108, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-23T00:47:03.587Z',
						updatedAt: '2024-06-23T00:49:58.201Z',
					},
					{
						id: 'clxqv29ve01351320ldchnvnl',
						score: 974725,
						accuracy: 0.9587003240829535,
						normalizedAccuracy: 0.9735479804915856,
						weightedScore: 2.7079840061911518,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 125, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T01:19:17.883Z',
						updatedAt: '2024-06-23T01:21:51.793Z',
					},
					{
						id: 'clxkninod00d71320wdxn1kbg',
						score: 1887082,
						accuracy: 0.9251513049361321,
						normalizedAccuracy: 0.9472822914099235,
						weightedScore: 3.203781424434465,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 94, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-18T17:01:28.286Z',
						updatedAt: '2024-06-18T17:04:48.792Z',
					},
					{
						id: 'clxko7tx100dh13203t0ir422',
						score: 1681890,
						accuracy: 0.8956537714974958,
						normalizedAccuracy: 0.9215749771098023,
						weightedScore: 3.7098148358514145,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 88, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-18T17:21:02.774Z',
						updatedAt: '2024-06-18T17:24:28.119Z',
					},
					{
						id: 'clxkpj47800dx1320xykvagy0',
						score: 581909,
						accuracy: 0.8511485720554357,
						normalizedAccuracy: 0.8682137810953439,
						weightedScore: 7.573001934811728,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 126, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-18T17:57:48.932Z',
						updatedAt: '2024-06-18T17:59:21.359Z',
					},
				],
			},
			{
				player: {
					id: 'clwlfaapv001y903x0s6bog1y',
					teamId: 'clv5b6pdn000dd63sokn5xrgv',
					user: {
						id: 'clgkxa2z7001qpf4my71o464l',
						discordId: '239899183618850817',
						discordAvatar: 'https://cdn.discordapp.com/avatars/239899183618850817/dd48314f1e15bfcddcd9cf6b8f09be7b.png',
						discordUsername: 'Elecast',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198291000367',
								type: 'STEAM',
								name: 'Elecast',
								avatar: 'https://avatars.akamai.steamstatic.com/4cbef966a1f11e9106e5bb26da1405c5be6a917d_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'AR',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8616518722666745,
				averageNormalizedAccuracy: 0.8777887000066279,
				averageWeightedScore: 6.499207183355813,
				scores: [
					{
						id: 'clxjuaym700be1320lj536s54',
						score: 998124,
						accuracy: 0.9490802767014525,
						normalizedAccuracy: 0.9681613427641356,
						weightedScore: 2.583634872388672,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 120, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-18T03:23:40.351Z',
						updatedAt: '2024-06-18T03:26:00.275Z',
					},
					{
						id: 'clxv3p16w001x90r9nnugxn02',
						score: 2043231,
						accuracy: 0.9478381481438528,
						normalizedAccuracy: 0.9666237893140301,
						weightedScore: 2.684012030362343,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 110, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T00:32:01.353Z',
						updatedAt: '2024-06-26T00:36:52.477Z',
					},
					{
						id: 'clxv33lq7001l90r9a6g736k4',
						score: 974928,
						accuracy: 0.9588999867219428,
						normalizedAccuracy: 0.9737507353609486,
						weightedScore: 2.6948922997549336,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 124, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T00:15:21.535Z',
						updatedAt: '2024-06-26T00:17:56.494Z',
					},
					{
						id: 'clxv2q2ov001d90r9h4ykxh7z',
						score: 495023,
						accuracy: 0.9763093277584388,
						normalizedAccuracy: 0.9830623252414845,
						weightedScore: 3.4487510766580534,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 140, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T00:04:50.336Z',
						updatedAt: '2024-06-26T00:07:45.046Z',
					},
					{
						id: 'clxv6z78t002n90r91kcx5r4a',
						score: 1651302,
						accuracy: 0.8793648004217623,
						normalizedAccuracy: 0.9048145852887945,
						weightedScore: 4.288935590139725,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 92, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T02:03:54.605Z',
						updatedAt: '2024-06-26T02:07:24.158Z',
					},
					{
						id: 'clxrzjxr1008i8478kshosw5m',
						score: 625163,
						accuracy: 0.9144154751892346,
						normalizedAccuracy: 0.9327491618636393,
						weightedScore: 4.354219377883614,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 114, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T20:12:46.622Z',
						updatedAt: '2024-06-23T20:14:19.542Z',
					},
					{
						id: 'clxva12od003f90r9vkbqyuql',
						score: 827437,
						accuracy: 0.40565509093003815,
						normalizedAccuracy: 0.41535896021336266,
						weightedScore: 25.440005036303354,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 110, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T03:29:20.846Z',
						updatedAt: '2024-06-26T03:32:46.223Z',
					},
				],
			},
			{
				player: {
					id: 'clwlfa0ak001w903xm2o6w20h',
					teamId: 'clv5b6pdn000dd63sokn5xrgv',
					user: {
						id: 'cli2nbtwj000gui2zczha0m00',
						discordId: '651603861861040149',
						discordAvatar: 'https://cdn.discordapp.com/avatars/651603861861040149/a_0e79180d204539737799b62b4146d8a9.png',
						discordUsername: 'Darturr',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198101047482',
								type: 'STEAM',
								name: 'Darturr',
								avatar: 'https://avatars.akamai.steamstatic.com/0248263b929062fbe3356c281913fbe869c126a2_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'AR',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8436958803261287,
				averageNormalizedAccuracy: 0.8593826194852798,
				averageWeightedScore: 7.207759055971747,
				scores: [
					{
						id: 'clxrxory9007g8478sih47xll',
						score: 998282,
						accuracy: 0.9492305132288967,
						normalizedAccuracy: 0.9683145997664285,
						weightedScore: 2.576011965069716,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 119, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-23T19:20:33.153Z',
						updatedAt: '2024-06-23T19:22:53.500Z',
					},
					{
						id: 'clxvc83ez003r90r92z5lkaaw',
						score: 2043857,
						accuracy: 0.9481285444234404,
						normalizedAccuracy: 0.9669199410913429,
						weightedScore: 2.6690695564997373,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 108, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T04:30:47.627Z',
						updatedAt: '2024-06-26T04:35:38.302Z',
					},
					{
						id: 'clxvbng7a003l90r9qqwwebok',
						score: 972867,
						accuracy: 0.9568728699783126,
						normalizedAccuracy: 0.9716922241010618,
						weightedScore: 2.8278085902231394,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 127, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T04:14:44.423Z',
						updatedAt: '2024-06-26T04:17:19.169Z',
					},
					{
						id: 'clxvbreco003n90r9recyo0pc',
						score: 496296,
						accuracy: 0.9788200025639255,
						normalizedAccuracy: 0.9855903660396543,
						weightedScore: 3.083261556129773,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 134, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T04:17:48.649Z',
						updatedAt: '2024-06-26T04:20:43.478Z',
					},
					{
						id: 'clxrxvrkf007m8478xteenfm9',
						score: 635168,
						accuracy: 0.929049621530698,
						normalizedAccuracy: 0.9476767173402841,
						weightedScore: 3.6096889418068163,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 105, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-23T19:25:59.248Z',
						updatedAt: '2024-06-23T19:27:32.129Z',
					},
					{
						id: 'clxvd2a6u003x90r9g7hyrsi8',
						score: 1628908,
						accuracy: 0.867439365013433,
						normalizedAccuracy: 0.8925440146584936,
						weightedScore: 4.712919837934038,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 93, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T04:54:16.087Z',
						updatedAt: '2024-06-26T04:57:43.981Z',
					},
					{
						id: 'clxvdtvzw004190r9aw7utdtn',
						score: 563646,
						accuracy: 0.2763302455441953,
						normalizedAccuracy: 0.2829404733996921,
						weightedScore: 30.975552944139,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 121, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T05:15:44.060Z',
						updatedAt: '2024-06-26T05:19:07.495Z',
					},
				],
			},
			{
				player: {
					id: 'clwlf9an2001s903x8e6uhdcj',
					teamId: 'clv5b6pdn000dd63sokn5xrgv',
					user: {
						id: 'clgk11f6q001cpf4muvk072i2',
						discordId: '395027032972591106',
						discordAvatar: 'https://cdn.discordapp.com/avatars/395027032972591106/bb8e276c879bba0c517f026f642ab87d.png',
						discordUsername: 'Megu',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199129371752',
								type: 'STEAM',
								name: 'Megu',
								avatar: 'https://avatars.akamai.steamstatic.com/295da29db4fe89718283862e9207b81f34f19a83_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'AR',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9499144829657429,
				averageNormalizedAccuracy: 0.551824692935757,
				averageWeightedScore: 12.41157326360325,
				scores: [
					{
						id: 'clxvfkc1n004790r9kou6u41b',
						score: 498137,
						accuracy: 0.982450915617265,
						normalizedAccuracy: 0.9892463936197254,
						weightedScore: 2.5546942291128336,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 112, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T06:04:17.531Z',
						updatedAt: '2024-06-26T06:07:15.365Z',
					},
					{
						id: 'clxscog5y00d48478bngluufn',
						score: 997825,
						accuracy: 0.9487959683362256,
						normalizedAccuracy: 0.9678713184370114,
						weightedScore: 2.598060500796063,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 122, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-24T02:20:12.119Z',
						updatedAt: '2024-06-24T02:22:32.041Z',
					},
					{
						id: 'clxvgdjuo004h90r9mqq78kp6',
						score: 2030974,
						accuracy: 0.9421522261008732,
						normalizedAccuracy: 0.9608251753611183,
						weightedScore: 2.976583759010837,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 117, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-26T06:27:00.673Z',
						updatedAt: '2024-06-26T06:31:51.050Z',
					},
					{
						id: 'clxvf7wdh004590r9zhgrkvhs',
						score: 633260,
						accuracy: 0.9262588218086079,
						normalizedAccuracy: 0.9448299631324442,
						weightedScore: 3.7516743563030213,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 109, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T05:54:37.349Z',
						updatedAt: '2024-06-26T05:56:12.695Z',
					},
					{
						id: 'tlvji',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 176, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'zp445d',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 169, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '6lb4ts',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 182, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clwlf9ggl001u903xikp21o9e',
					teamId: 'clv5b6pdn000dd63sokn5xrgv',
					user: {
						id: 'clgifdcs70000t74nk6otw23j',
						discordId: '203224684211339274',
						discordAvatar: 'https://cdn.discordapp.com/avatars/203224684211339274/fdd2c45f8cbe8f453c81f7991f4ebebc.png',
						discordUsername: 'Salvadorcinny',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198135263645',
								type: 'STEAM',
								name: 'Salva',
								avatar: 'https://avatars.steamstatic.com/3b93b6457b6e9d7b1fd558d06bb1495f9d6c06dc_full.jpg',
							},
						],
						bio: 'i like the acc thing',
						controllers: 'Quest 2 Touch',
						countryCode: 'AR',
						motto: 'the better morphites',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9634813972790314,
				averageNormalizedAccuracy: 0.41873277356634897,
				averageWeightedScore: 15.375737080812655,
				scores: [
					{
						id: 'clxqrdzct01271320jmq4hcxi',
						score: 980572,
						accuracy: 0.9644511982217239,
						normalizedAccuracy: 0.9793879200047143,
						weightedScore: 2.3309041661292405,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 107, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-22T23:36:25.661Z',
						updatedAt: '2024-06-22T23:39:01.226Z',
					},
					{
						id: 'clxlgkw0p00hm13201sxr50wu',
						score: 498779,
						accuracy: 0.9837171003974085,
						normalizedAccuracy: 0.9905213364260295,
						weightedScore: 2.3703703703703702,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 102, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-19T06:35:01.273Z',
						updatedAt: '2024-06-19T06:37:57.020Z',
					},
					{
						id: 'clxo8n1wy00r91320347wxqhz',
						score: 990968,
						accuracy: 0.9422758932179618,
						normalizedAccuracy: 0.9612201585336991,
						weightedScore: 2.9288850291889807,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 127, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-21T05:16:03.826Z',
						updatedAt: '2024-06-21T05:18:22.885Z',
					},
					{
						id: 'b002ct',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 177, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'lqbw29',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 179, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'gstdpi',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 181, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '98p0dp',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 170, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwlf6j16001l903x22xtpt9e',
					teamId: 'clv5b6pdn000dd63sokn5xrgv',
					user: {
						id: 'clgieckh30000rr4ms9oj4q27',
						discordId: '270764783551119361',
						discordAvatar: 'https://cdn.discordapp.com/avatars/270764783551119361/d4bf94bc428bf550defcfaa4bedbc0c8.png',
						discordUsername: 'Feco',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{id: '76561198086481046', type: 'STEAM', name: 'Feco', avatar: 'https://cdn.assets.beatleader.xyz/76561198086481046.png'},
						],
						bio: 'You are it seems one with many skills.\nWhat a rare combination.\nBut alas, your abilities are no match for me!\nTHE MISSER!',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'AR',
						motto: 'Professional Choker',
						pronouns: 'Ñ',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9222868532591043,
				averageNormalizedAccuracy: 0.40482556819496934,
				averageWeightedScore: 15.695747816046495,
				scores: [
					{
						id: 'clxp9qoeo00w51320a746dj9o',
						score: 2028743,
						accuracy: 0.9411172834494996,
						normalizedAccuracy: 0.9597697207042736,
						weightedScore: 3.0298372081921037,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 121, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-21T22:34:38.736Z',
						updatedAt: '2024-06-21T22:39:29.098Z',
					},
					{
						id: 'clxkwpbi700f51320jx2plss6',
						score: 1717676,
						accuracy: 0.9147108239009285,
						normalizedAccuracy: 0.9411835615777826,
						weightedScore: 3.032280661895566,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 84, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-18T21:18:35.647Z',
						updatedAt: '2024-06-18T21:22:03.262Z',
					},
					{
						id: 'clxv6gay3002990r9arpwe3ke',
						score: 1858283,
						accuracy: 0.9110324524268846,
						normalizedAccuracy: 0.9328256950827293,
						weightedScore: 3.8081168422377973,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 100, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T01:49:12.939Z',
						updatedAt: '2024-06-26T01:52:35.604Z',
					},
					{
						id: 'zns8z7',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 187, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'cov5r4',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 178, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'mtkxge',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 181, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'fcj919',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 181, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clv5b6pdj000bd63sj2k21nn0',
			user: {
				id: 'clghmq1pk0000pe4mz0tbyboi',
				discordId: '200038416061169664',
				discordAvatar: 'https://cdn.discordapp.com/avatars/200038416061169664/a_d38bd013ddd4d8776328bda51b8666ce.png',
				discordUsername: 'Dereknalox123',
				discordDiscriminator: '0',
				twitchUser: 'dereknalux',
				playableAccounts: [
					{
						id: '76561198128883308',
						type: 'STEAM',
						name: 'Dereknalox123',
						avatar: 'https://cdn.assets.beatleader.xyz/76561198128883308R17.png',
					},
				],
				bio: 'Go watch the Roster Reveal from Argentina again!! https://youtu.be/eWVlb3g6X-g',
				controllers: null,
				countryCode: 'AR',
				motto: null,
				pronouns: null,
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.9487806617946745,
		averageNormalizedAccuracy: 0.9669873382861659,
		averageWeightedScore: 2.647474144692904,
	},
	{
		id: 'clxufsbk40072qiy3qj1uzkg2',
		name: 'Staff',
		image: 'https://cdn.cube.community/1719321763426-Boomer_360.png',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clxufx5e30078qiy30hqlidop',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clhz6l7k30000mu2zffpxd7h0',
						discordId: '300260910981971972',
						discordAvatar: 'https://cdn.discordapp.com/avatars/300260910981971972/4ef5f6b283022f0b5c1ffc29de60eb46.png',
						discordUsername: 'Rusty',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198096273599',
								type: 'STEAM',
								name: 'l',
								avatar: 'https://avatars.steamstatic.com/997beefeab02fcb15254822381cc7c3a16e3153e_full.jpg',
							},
						],
						bio: null,
						controllers: 'Valve Index Knuckles',
						countryCode: 'NL',
						motto: 'The absolute sillyness my brain has is impossible to contain',
						pronouns: 'He/him',
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Content', name: 'Content Cubes', color: '#206694'},
						],
					},
				},
				averageAccuracy: 0.93669134267211,
				averageNormalizedAccuracy: 0.9545577304584263,
				averageWeightedScore: 3.0854753219527273,
				scores: [
					{
						id: 'clxugvbui007iqiy398bmmpkp',
						score: 985292,
						accuracy: 0.9690936004681745,
						normalizedAccuracy: 0.9841022204155176,
						weightedScore: 2.0265058686959887,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 86, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T13:53:03.931Z',
						updatedAt: '2024-06-25T13:55:37.751Z',
					},
					{
						id: 'clxuhcnak007qqiy3zo9vmrl9',
						score: 653041,
						accuracy: 0.9551921600175522,
						normalizedAccuracy: 0.9743434039004113,
						weightedScore: 2.279654710522399,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 57, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T14:06:31.916Z',
						updatedAt: '2024-06-25T14:08:04.289Z',
					},
					{
						id: 'clxuhwc6l0080qiy3qkte133j',
						score: 1003042,
						accuracy: 0.9537566263341812,
						normalizedAccuracy: 0.972931709455763,
						weightedScore: 2.346359820523954,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 109, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T14:21:50.638Z',
						updatedAt: '2024-06-25T14:24:08.705Z',
					},
					{
						id: 'clxuh06uq007kqiy36aie44uq',
						score: 498532,
						accuracy: 0.9832299545396275,
						normalizedAccuracy: 0.9900308210472801,
						weightedScore: 2.4412862474877977,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 106, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-25T13:56:50.738Z',
						updatedAt: '2024-06-25T13:59:45.441Z',
					},
					{
						id: 'clxuhow2d007yqiy3urxoj8ap',
						score: 2034277,
						accuracy: 0.9436844607837452,
						normalizedAccuracy: 0.9623877781094635,
						weightedScore: 2.8977419200840218,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 113, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T14:16:03.158Z',
						updatedAt: '2024-06-25T14:21:01.302Z',
					},
					{
						id: 'clxui8y340086qiy3id6jvej5',
						score: 1818780,
						accuracy: 0.8916659108569411,
						normalizedAccuracy: 0.9129958772170688,
						weightedScore: 4.637071389600034,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 104, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T14:31:38.896Z',
						updatedAt: '2024-06-25T14:34:59.215Z',
					},
					{
						id: 'clxuiemxp008aqiy3fjfkf1av',
						score: 1615345,
						accuracy: 0.860216685704548,
						normalizedAccuracy: 0.8851123030634783,
						weightedScore: 4.969707296754894,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 94, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T14:36:04.381Z',
						updatedAt: '2024-06-25T14:39:29.636Z',
					},
				],
			},
			{
				player: {
					id: 'clxujodgc008uqiy3xjofjjox',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clhvh5yog0002lq2zulkmaeii',
						discordId: '767569853774561311',
						discordAvatar: 'https://cdn.discordapp.com/avatars/767569853774561311/5a386a36850a8cdb577796e8b7a9be66.png',
						discordUsername: 'Ger',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198329540255',
								type: 'STEAM',
								name: 'Ger0sty',
								avatar: 'https://avatars.akamai.steamstatic.com/5fc7efce3a58c6255e2d16483bc6a5eecc832ab0_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'US',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8410471523787612,
				averageNormalizedAccuracy: 0.8566726804068209,
				averageWeightedScore: 7.345398443852184,
				scores: [
					{
						id: 'clxuyfr0300exqiy327r6swbz',
						score: 496723,
						accuracy: 0.9796621535002514,
						normalizedAccuracy: 0.9864383420183019,
						weightedScore: 2.960666092449038,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 127, withinTeam: 9, counted: false, ghost: false},
						createdAt: '2024-06-25T22:04:50.163Z',
						updatedAt: '2024-06-25T22:07:45.712Z',
					},
					{
						id: 'clxuwrsc000dlqiy3rolnh1vm',
						score: 968685,
						accuracy: 0.9527596229031735,
						normalizedAccuracy: 0.9675152740336933,
						weightedScore: 3.0975106410421773,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 133, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T21:18:12.529Z',
						updatedAt: '2024-06-25T21:20:47.878Z',
					},
					{
						id: 'clxuxmt7100e3qiy3p4u2jhwo',
						score: 981694,
						accuracy: 0.9334575795754392,
						normalizedAccuracy: 0.9522245544877143,
						weightedScore: 3.3763207410623823,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 133, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T21:42:19.981Z',
						updatedAt: '2024-06-25T21:44:39.446Z',
					},
					{
						id: 'clxuww43b00drqiy3ngl9td4d',
						score: 1985023,
						accuracy: 0.9208359330604103,
						normalizedAccuracy: 0.9390864048830035,
						weightedScore: 4.073423401919129,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 127, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T21:21:34.392Z',
						updatedAt: '2024-06-25T21:26:24.479Z',
					},
					{
						id: 'clxuwipw400dbqiy3xgxwcylz',
						score: 619235,
						accuracy: 0.9057446886312941,
						normalizedAccuracy: 0.9239045292933694,
						weightedScore: 4.795356451852954,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 117, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T21:11:09.460Z',
						updatedAt: '2024-06-25T21:12:42.367Z',
					},
					{
						id: 'clxuxq7t300e7qiy3v980h2x3',
						score: 1528759,
						accuracy: 0.8141072032420313,
						normalizedAccuracy: 0.8376683614454002,
						weightedScore: 6.609034798742853,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 98, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T21:44:58.888Z',
						updatedAt: '2024-06-25T21:48:26.634Z',
					},
					{
						id: 'clxuyn3rg00fbqiy3pm11p14r',
						score: 776663,
						accuracy: 0.3807628857387284,
						normalizedAccuracy: 0.3898712966862624,
						weightedScore: 26.505476979896756,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 112, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T22:10:33.292Z',
						updatedAt: '2024-06-25T22:13:57.482Z',
					},
				],
			},
			{
				player: {
					id: 'clxuzxv2s00gdqiy34bq3lrao',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clhw0ih4b0000mq2zb5f07kmj',
						discordId: '212317530860158979',
						discordAvatar: 'https://cdn.discordapp.com/avatars/212317530860158979/12436e75b3714b5398f7cbb256c0a8f6.png',
						discordUsername: 'Tranch',
						discordDiscriminator: '0',
						twitchUser: 'rAveAve',
						playableAccounts: [
							{
								id: '76561198313983208',
								type: 'STEAM',
								name: 'Tranch',
								avatar: 'https://avatars.akamai.steamstatic.com/14a53a67e17fcfa405a20649b51f752c0dffa5cd_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'GB',
						motto: 'Origami Angel - Somewhere City (Full Album 2019)',
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9725899875226359,
				averageNormalizedAccuracy: 0.7066412480796708,
				averageWeightedScore: 8.286218010683884,
				scores: [
					{
						id: 'clxv1nknp000n90r91yc60jqg',
						score: 2095611,
						accuracy: 0.9721368016978441,
						normalizedAccuracy: 0.9914040290834292,
						weightedScore: 1.433713658280422,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 18, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T23:34:54.037Z',
						updatedAt: '2024-06-25T23:39:41.712Z',
					},
					{
						id: 'clxv0zi17000990r9wt74rxc6',
						score: 501717,
						accuracy: 0.9895115721794353,
						normalizedAccuracy: 0.9963558877732588,
						weightedScore: 1.5268446741314958,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 33, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T23:16:10.892Z',
						updatedAt: '2024-06-25T23:19:07.374Z',
					},
					{
						id: 'clxv0p0ip000790r9c48y3twp',
						score: 662945,
						accuracy: 0.9696785753464731,
						normalizedAccuracy: 0.9891202664132239,
						weightedScore: 1.5426402738502754,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 7, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T23:08:01.633Z',
						updatedAt: '2024-06-25T23:09:34.094Z',
					},
					{
						id: 'clxv19309000f90r9f7u1vrpg',
						score: 991100,
						accuracy: 0.9748061157748238,
						normalizedAccuracy: 0.9899032070227095,
						weightedScore: 1.651941184057784,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 42, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T23:23:37.977Z',
						updatedAt: '2024-06-25T23:26:11.504Z',
					},
					{
						id: 'clxv13n6k000b90r9zzexhlud',
						score: 1951672,
						accuracy: 0.9568168726146032,
						normalizedAccuracy: 0.9797053462650739,
						weightedScore: 1.848386284467201,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 46, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T23:19:24.189Z',
						updatedAt: '2024-06-25T23:22:44.656Z',
					},
					{
						id: 'tudbzl',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 194, withinTeam: 15, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'e5tzzo',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 194, withinTeam: 15, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
				],
			},
			{
				player: {
					id: 'clxufytj6007aqiy32jyjg37s',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clhv4w8y90002pf2ztez53xft',
						discordId: '249494456439406594',
						discordAvatar: 'https://cdn.discordapp.com/avatars/249494456439406594/a4ba92e1f835aa1a22d2847a23285009.png',
						discordUsername: 'rosie',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198160636106',
								type: 'STEAM',
								name: 'rosie',
								avatar: 'https://avatars.akamai.steamstatic.com/aee7bf4d1bdef56ee281fa3f19766a4880f38fc0_full.jpg',
							},
						],
						bio: 'the best map pooler around ',
						controllers: 'Valve Index Knuckles',
						countryCode: 'GB',
						motto: 'Meow ',
						pronouns: 'She/Her',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.7886657527985085,
				averageNormalizedAccuracy: 0.6881882569183306,
				averageWeightedScore: 9.193494180067626,
				scores: [
					{
						id: 'clxv7gxix003390r9l1wnydti',
						score: 499832,
						accuracy: 0.985793880106896,
						normalizedAccuracy: 0.9926124809354346,
						weightedScore: 2.0680447889750218,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 76, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T02:17:41.817Z',
						updatedAt: '2024-06-26T02:20:36.413Z',
					},
					{
						id: 'clxv76bf5002x90r9jmkz6naa',
						score: 998110,
						accuracy: 0.9490669646040839,
						normalizedAccuracy: 0.9681477630297551,
						weightedScore: 2.58431031987263,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 121, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T02:09:26.609Z',
						updatedAt: '2024-06-26T02:11:45.725Z',
					},
					{
						id: 'clxv7l1w8003590r9vfuloaob',
						score: 2046290,
						accuracy: 0.9492571932225405,
						normalizedAccuracy: 0.9680709591012503,
						weightedScore: 2.6109944144746264,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 106, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T02:20:54.105Z',
						updatedAt: '2024-06-26T02:25:43.067Z',
					},
					{
						id: 'clxv6olib002j90r9sragfg6o',
						score: 1875933,
						accuracy: 0.9196854524195308,
						normalizedAccuracy: 0.9416856876232681,
						weightedScore: 3.437738699794351,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 96, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-26T01:55:39.875Z',
						updatedAt: '2024-06-26T01:59:01.918Z',
					},
					{
						id: 'clxv7029q002p90r9d0rdylxz',
						score: 634581,
						accuracy: 0.9281910264380006,
						normalizedAccuracy: 0.9468009077386059,
						weightedScore: 3.6533710373567496,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 106, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T02:04:34.815Z',
						updatedAt: '2024-06-26T02:06:07.163Z',
					},
					{
						id: 'clxv92nv1003b90r9ysycfhsc',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 189, withinTeam: 10, counted: false, ghost: false},
						createdAt: '2024-06-26T03:02:35.341Z',
						updatedAt: '2024-06-26T03:02:35.341Z',
					},
					{
						id: 'lyoj78',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 193, withinTeam: 14, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clxujo97h008qqiy38sfo4l13',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clhto2fmg005yo14mgod7pbcf',
						discordId: '169894107517419520',
						discordAvatar: 'https://cdn.discordapp.com/avatars/169894107517419520/223e7bbec41ea46d9cdea9d25f260a27.png',
						discordUsername: 'Joshabi',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198138161802',
								type: 'STEAM',
								name: 'Joshabi',
								avatar: 'https://avatars.akamai.steamstatic.com/a7a9fdbe3d8096ab8356f8c34544f08962ee7f6b_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'GB',
						motto: null,
						pronouns: null,
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Tournament', name: 'Tournament Cubes', color: '#0fa57f'},
						],
					},
				},
				averageAccuracy: 0.7637344970975211,
				averageNormalizedAccuracy: 0.7770293635558859,
				averageWeightedScore: 9.849212567007106,
				scores: [
					{
						id: 'clxuloir0009uqiy3zr03c6nl',
						score: 1010305,
						accuracy: 0.960662752276131,
						normalizedAccuracy: 0.9799766816561069,
						weightedScore: 1.9959473150962512,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 82, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T16:07:44.364Z',
						updatedAt: '2024-06-25T16:10:02.604Z',
					},
					{
						id: 'clxulyayl00a6qiy3mpfz7bw6',
						score: 984021,
						accuracy: 0.9678434959649459,
						normalizedAccuracy: 0.9828327551989644,
						weightedScore: 2.108474139042951,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 94, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T16:15:20.829Z',
						updatedAt: '2024-06-25T16:17:54.793Z',
					},
					{
						id: 'clxulk3th009oqiy3bg2mhcgt',
						score: 499511,
						accuracy: 0.9851607877168242,
						normalizedAccuracy: 0.9919750095322827,
						weightedScore: 2.160206718346253,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 84, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-25T16:04:18.390Z',
						updatedAt: '2024-06-25T16:07:13.186Z',
					},
					{
						id: 'clxuysodb00ffqiy34dcc3e8q',
						score: 2033845,
						accuracy: 0.9434840595173205,
						normalizedAccuracy: 0.9621834049979634,
						weightedScore: 2.908053659235213,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 114, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T22:14:53.280Z',
						updatedAt: '2024-06-25T22:19:41.909Z',
					},
					{
						id: 'clxuydp6r00etqiy3wd1sxmj0',
						score: 634387,
						accuracy: 0.9279072658792555,
						normalizedAccuracy: 0.9465114578872846,
						weightedScore: 3.6678077094805777,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 107, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T22:03:14.500Z',
						updatedAt: '2024-06-25T22:04:46.949Z',
					},
					{
						id: 'clxuygzb200ezqiy3ddf5ejuu',
						score: 456842,
						accuracy: 0.24328122545378056,
						normalizedAccuracy: 0.250322051794586,
						weightedScore: 26.903574538982923,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 110, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T22:05:47.583Z',
						updatedAt: '2024-06-25T22:09:13.058Z',
					},
					{
						id: 'clxuyzcn000flqiy3in36ee38',
						score: 648238,
						accuracy: 0.3178018928743893,
						normalizedAccuracy: 0.3254041838240129,
						weightedScore: 29.200423888865572,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 117, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T22:20:04.669Z',
						updatedAt: '2024-06-25T22:23:25.677Z',
					},
				],
			},
			{
				player: {
					id: 'clxug2t14007cqiy3aflwg4c9',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clgiko11z000ks74mlwsya34o',
						discordId: '172410617066422273',
						discordAvatar: 'https://cdn.discordapp.com/avatars/172410617066422273/9b347b0453f5df591cc567f08664b69b.png',
						discordUsername: 'Bitz',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561197992369547',
								type: 'STEAM',
								name: 'Bitz',
								avatar: 'https://avatars.akamai.steamstatic.com/5b4b223bbfbf3feb17704a49855bdc31c67a42aa_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'DK',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.7272207404897629,
				averageNormalizedAccuracy: 0.421371496506323,
				averageWeightedScore: 15.198454225995096,
				scores: [
					{
						id: 'clxuu12b900cqqiy3yezfz7o4',
						score: 988446,
						accuracy: 0.9721957480709933,
						normalizedAccuracy: 0.9872524118340926,
						weightedScore: 1.8231007351992776,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 64, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T20:01:26.517Z',
						updatedAt: '2024-06-25T20:04:00.486Z',
					},
					{
						id: 'clxutni6900ckqiy3am5nsrz2',
						score: 499987,
						accuracy: 0.9860995789245318,
						normalizedAccuracy: 0.9929202942297916,
						weightedScore: 2.023542922767729,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 72, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T19:50:53.890Z',
						updatedAt: '2024-06-25T19:53:48.774Z',
					},
					{
						id: 'clxutrvff00cmqiy33i07np2l',
						score: 2049158,
						accuracy: 0.9505876349635265,
						normalizedAccuracy: 0.9694277694803767,
						weightedScore: 2.5425359239986633,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 104, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T19:54:17.691Z',
						updatedAt: '2024-06-25T19:59:05.974Z',
					},
					{
						id: 'urwti',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 191, withinTeam: 12, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '01qss',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 192, withinTeam: 13, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '4248sr',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 190, withinTeam: 11, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'clxuu6dg400csqiy31607y0st',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 190, withinTeam: 11, counted: false, ghost: false},
						createdAt: '2024-06-25T20:05:34.229Z',
						updatedAt: '2024-06-25T20:05:34.229Z',
					},
				],
			},
			{
				player: {
					id: 'clvmuncrw002sd63swd1v21vf',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clgkjffxe001kpf4mbhy2e2xp',
						discordId: '469171963236057120',
						discordAvatar: 'https://cdn.discordapp.com/avatars/469171963236057120/e45fed957cdc1cbd5ace4285d4d0b074.png',
						discordUsername: 'ServerBP',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199016011545',
								type: 'STEAM',
								name: '고양이두마리',
								avatar: 'https://avatars.steamstatic.com/5902f894475242c34db9ace8e1113e285d8fdf56_full.jpg',
							},
						],
						bio: 'Hi!\nI am ServerBP a 17 year old who enjoys music and all sorts of games.\n- Beat Saber player(quitting ranked)\n- Discord Developer(I make bots)\nHave fun!',
						controllers: 'Valve Index Knuckles',
						countryCode: 'HU',
						motto: null,
						pronouns: 'She/Her',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9626449434441896,
				averageNormalizedAccuracy: 0.41951438738361757,
				averageWeightedScore: 15.268821972797648,
				scores: [
					{
						id: 'clxc5zzwj00aj7piol6z5one4',
						score: 1936813,
						accuracy: 0.9495321742071965,
						normalizedAccuracy: 0.9722463871058746,
						weightedScore: 2.16019641583078,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 64, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-12T18:28:54.787Z',
						updatedAt: '2024-06-12T18:32:15.537Z',
					},
					{
						id: 'clxc6902h00an7pioynmydsvo',
						score: 653025,
						accuracy: 0.9551687570848721,
						normalizedAccuracy: 0.974319531747725,
						weightedScore: 2.2808453638934365,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 58, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-12T18:35:54.905Z',
						updatedAt: '2024-06-12T18:37:27.311Z',
					},
					{
						id: 'clxc5ocfz00ah7piow214kfir',
						score: 498534,
						accuracy: 0.9832338990405002,
						normalizedAccuracy: 0.9900347928317235,
						weightedScore: 2.440712029859317,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 105, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-12T18:19:51.168Z',
						updatedAt: '2024-06-12T18:22:46.048Z',
					},
					{
						id: 'vljn7i',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 182, withinTeam: 12, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'aihakp',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 171, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'jquac8',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 182, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'uay0ra',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 183, withinTeam: 11, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clxv0iewn000390r9u47dmtst',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clv0bx450000mykbvxxn840y7',
						discordId: '458403789926301748',
						discordAvatar: 'https://cdn.discordapp.com/avatars/458403789926301748/fbf492e76779dcc3e629277222668c05.png',
						discordUsername: 'Jessy',
						discordDiscriminator: '0',
						twitchUser: 'JessyJams',
						playableAccounts: [
							{
								id: '76561198071905106',
								type: 'STEAM',
								name: 'JessyJams',
								avatar: 'https://avatars.akamai.steamstatic.com/5f51273d61deb6871c4670d1ba12b4e2b2c8fb60_full.jpg',
							},
						],
						bio: 'Playing since 2018. Im old.',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'US',
						motto: null,
						pronouns: 'She/her',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.6998471289557644,
				averageNormalizedAccuracy: 0.40546714480835794,
				averageWeightedScore: 16.273354879496335,
				scores: [
					{
						id: 'clxv1o3up000p90r95p4qhyg4',
						score: 956648,
						accuracy: 0.9409205136149265,
						normalizedAccuracy: 0.9554928091936848,
						weightedScore: 3.8737907906616793,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 136, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T23:35:18.914Z',
						updatedAt: '2024-06-25T23:37:53.247Z',
					},
					{
						id: 'clxv1g2td000l90r9eof5w4xj',
						score: 492182,
						accuracy: 0.9707061642687388,
						normalizedAccuracy: 0.977420405439756,
						weightedScore: 4.26442721791559,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 150, withinTeam: 11, counted: false, ghost: false},
						createdAt: '2024-06-25T23:29:04.321Z',
						updatedAt: '2024-06-25T23:31:59.571Z',
					},
					{
						id: 'clxv1wzhh000v90r9vtc7mfuv',
						score: 1913726,
						accuracy: 0.8877618379393926,
						normalizedAccuracy: 0.9053567990250646,
						weightedScore: 5.7752661478970735,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 130, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-25T23:42:13.158Z',
						updatedAt: '2024-06-25T23:47:01.646Z',
					},
					{
						id: 'zeu8l',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 195, withinTeam: 16, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'clxv3rqde002190r9xijsnfqs',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 195, withinTeam: 16, counted: false, ghost: false},
						createdAt: '2024-06-26T00:34:07.298Z',
						updatedAt: '2024-06-26T00:34:07.298Z',
					},
					{
						id: 'e3z0n9',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 195, withinTeam: 16, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'gpc3f',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 195, withinTeam: 16, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
				],
			},
			{
				player: {
					id: 'clxuo7pcb00auqiy3slrtlabk',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clhvbapg80002lr2zbva6uvd2',
						discordId: '195661231787474944',
						discordAvatar: 'https://cdn.discordapp.com/avatars/195661231787474944/a_7bd22599cb51b88804d370465e5bf1de.png',
						discordUsername: 'Justin',
						discordDiscriminator: '0',
						twitchUser: 'egg_time_',
						playableAccounts: [
							{
								id: '76561198117409561',
								type: 'STEAM',
								name: 'Egg Time 🥚',
								avatar: 'https://avatars.akamai.steamstatic.com/23fd56f147e46f254d8f71728d47c47a4b3a7780_full.jpg',
							},
						],
						bio: 'we ball',
						controllers: 'Quest 2 Touch',
						countryCode: 'US',
						motto: 'its egg time',
						pronouns: 'He/him',
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Editor', name: 'Editor Cubes', color: '#e91e63'},
							{id: 'Tournament', name: 'Tournament Cubes', color: '#0fa57f'},
						],
					},
				},
				averageAccuracy: 0.6678930149142281,
				averageNormalizedAccuracy: 0.38685938911697415,
				averageWeightedScore: 20.919343684425534,
				scores: [
					{
						id: 'clxvh26p0004r90r9jg96yxq1',
						score: 493944,
						accuracy: 0.9741812695376059,
						normalizedAccuracy: 0.9809195475343162,
						weightedScore: 3.7585414872236576,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 145, withinTeam: 10, counted: false, ghost: false},
						createdAt: '2024-06-26T06:46:10.021Z',
						updatedAt: '2024-06-26T06:49:10.052Z',
					},
					{
						id: 'clxvgxni1004n90r9cka4o23l',
						score: 943057,
						accuracy: 0.9275529524006236,
						normalizedAccuracy: 0.941918220870967,
						weightedScore: 4.750290210241197,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 139, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-26T06:42:38.521Z',
						updatedAt: '2024-06-26T06:45:20.198Z',
					},
					{
						id: 'j01nky',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 194, withinTeam: 15, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'kf2cas',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 193, withinTeam: 14, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'lg66yi',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 193, withinTeam: 14, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'clxvhavkp004x90r9zo0dwy8i',
						score: 888273,
						accuracy: 0.41206257900657567,
						normalizedAccuracy: 0.42022943720281336,
						weightedScore: 30.252589869671073,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 134, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-26T06:52:55.513Z',
						updatedAt: '2024-06-26T06:57:50.127Z',
					},
					{
						id: 'clxvh73w3004t90r9qbmy5imf',
						score: 244602,
						accuracy: 0.35777525871210736,
						normalizedAccuracy: 0.3649485182107225,
						weightedScore: 32.67398422384284,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 134, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-26T06:49:59.667Z',
						updatedAt: '2024-06-26T06:51:37.066Z',
					},
				],
			},
			{
				player: {
					id: 'clv3ptvk1001eykbv9gbw27w5',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clhw27ymi0004r62zxmtxw5qt',
						discordId: '124467312458858496',
						discordAvatar: 'https://cdn.discordapp.com/avatars/124467312458858496/975b8e22fe5504225b59edc268070340.png',
						discordUsername: 'Ixsen',
						discordDiscriminator: '0',
						twitchUser: 'ixsen',
						playableAccounts: [
							{
								id: '76561197999550197',
								type: 'STEAM',
								name: 'ACC | Ixsen',
								avatar: 'https://avatars.akamai.steamstatic.com/47036e45f048a54fb03f93b312991bbbb7d0391e_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'DE',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9847643653791158,
				averageNormalizedAccuracy: 0.1416536921708185,
				averageWeightedScore: 21.745416512858373,
				scores: [
					{
						id: 'clxuxzjd700ebqiy328vgz006',
						score: 499310,
						accuracy: 0.9847643653791158,
						normalizedAccuracy: 0.9915758451957295,
						weightedScore: 2.217915590008613,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 93, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-25T21:52:13.771Z',
						updatedAt: '2024-06-25T21:55:08.464Z',
					},
					{
						id: '6rhblc',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 128, withinTeam: 9, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'f3qzn',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 141, withinTeam: 10, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'pd2u6',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 142, withinTeam: 11, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '3ajw2',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 117, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '0sd69',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 148, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'oob452',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 150, withinTeam: 10, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clxufsydu0076qiy3ea9yzhcy',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clh4wxep8003upf4ms7n68bkq',
						discordId: '319419755926650882',
						discordAvatar: 'https://cdn.discordapp.com/avatars/319419755926650882/1cb425ca78362d872cdf8723b5ba8c57.png',
						discordUsername: 'Batthew',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198121538359',
								type: 'STEAM',
								name: 'ACC | ZOOMR | Batthew',
								avatar: 'https://avatars.akamai.steamstatic.com/da3408094d4b06b98573db32e8ed97904cb58d9c_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'GB',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.4920478862405948,
				averageNormalizedAccuracy: 0.14155751824751253,
				averageWeightedScore: 21.759320782576594,
				scores: [
					{
						id: 'clxupqij000b0qiy3tue2xc2b',
						score: 498971,
						accuracy: 0.9840957724811896,
						normalizedAccuracy: 0.9909026277325877,
						weightedScore: 2.3152454780361755,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 99, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T18:01:15.852Z',
						updatedAt: '2024-06-25T18:04:10.422Z',
					},
					{
						id: 'otuqhb',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 190, withinTeam: 11, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'pxzbm8',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 191, withinTeam: 12, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'ncey9o',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 193, withinTeam: 14, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'ptb92n',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 188, withinTeam: 9, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '1kdk7',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 189, withinTeam: 10, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'clxupup0k00b2qiy35watucj9',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 192, withinTeam: 13, counted: false, ghost: false},
						createdAt: '2024-06-25T18:04:30.884Z',
						updatedAt: '2024-06-25T18:04:30.884Z',
					},
				],
			},
			{
				player: {
					id: 'clxujv6bz0090qiy3c0wimeo1',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clh17nasq0034o14m7hbg9ana',
						discordId: '299887053448019980',
						discordAvatar: 'https://cdn.discordapp.com/avatars/299887053448019980/31336e0a5f535e7e7298a8862cb2c4e0.png',
						discordUsername: 'baoziii',
						discordDiscriminator: '0',
						twitchUser: 'baoziiii',
						playableAccounts: [
							{
								id: '76561198203926359',
								type: 'STEAM',
								name: 'baoziii',
								avatar: 'https://avatars.akamai.steamstatic.com/4ce0e23afb33d8cf7a2c3e436d7309c646aa1d86_full.jpg',
							},
						],
						bio: null,
						controllers: 'Valve Index Knuckles',
						countryCode: 'US',
						motto: null,
						pronouns: null,
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Developer', name: 'Developer Cubes', color: '#007990'},
						],
					},
				},
				averageAccuracy: 0.5171670330211553,
				averageNormalizedAccuracy: 0.5254761006891763,
				averageWeightedScore: 22.700247485617133,
				scores: [
					{
						id: 'clxvnq0e8005n90r997430mkp',
						score: 919254,
						accuracy: 0.9041412785293814,
						normalizedAccuracy: 0.9181439639475873,
						weightedScore: 6.285373403843673,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 144, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-26T09:52:39.296Z',
						updatedAt: '2024-06-26T09:55:20.399Z',
					},
					{
						id: 'clxvx229w00d990r9xpvs6l68',
						score: 483200,
						accuracy: 0.9529914108493497,
						normalizedAccuracy: 0.9595831215048297,
						weightedScore: 6.843238587424634,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 159, withinTeam: 12, counted: false, ghost: false},
						createdAt: '2024-06-26T14:13:58.149Z',
						updatedAt: '2024-06-26T14:17:00.620Z',
					},
					{
						id: 'clxvo97lz006790r9g3cp4uvm',
						score: 812149,
						accuracy: 0.7722433261226139,
						normalizedAccuracy: 0.7877691212359886,
						weightedScore: 11.556231003039514,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 142, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-26T10:07:35.112Z',
						updatedAt: '2024-06-26T10:09:58.378Z',
					},
					{
						id: 'clxvy19k000fd90r9cc4xg2jc',
						score: 334869,
						accuracy: 0.17832716931998818,
						normalizedAccuracy: 0.1834881538089782,
						weightedScore: 29.21288197205498,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 111, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-26T14:41:20.545Z',
						updatedAt: '2024-06-26T14:44:55.738Z',
					},
					{
						id: 'clxvxl9g200et90r9sbw20dx1',
						score: 220326,
						accuracy: 0.32226715910337517,
						normalizedAccuracy: 0.3287284945474511,
						weightedScore: 34.48050305104926,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 135, withinTeam: 8, counted: false, ghost: false},
						createdAt: '2024-06-26T14:28:53.906Z',
						updatedAt: '2024-06-26T14:30:30.580Z',
					},
					{
						id: 'clxvxny0q00f390r910oje57z',
						score: 685966,
						accuracy: 0.3182140164913542,
						normalizedAccuracy: 0.3245208467670019,
						weightedScore: 35.08161073184704,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 136, withinTeam: 9, counted: false, ghost: false},
						createdAt: '2024-06-26T14:30:59.066Z',
						updatedAt: '2024-06-26T14:35:53.526Z',
					},
					{
						id: 'clxvy7p3p00fj90r96varsh9q',
						score: 350807,
						accuracy: 0.17198487073202418,
						normalizedAccuracy: 0.17609900301239748,
						weightedScore: 35.441893650060855,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 122, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-26T14:46:20.630Z',
						updatedAt: '2024-06-26T14:49:52.656Z',
					},
				],
			},
			{
				player: {
					id: 'cluxbkg9l0005ivs99jml2op3',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clgijz64m000mt74nkasu3q2q',
						discordId: '226507204495278080',
						discordAvatar: 'https://cdn.discordapp.com/avatars/226507204495278080/a_f28565c94e255a4edf3f8a66e2c2a174.png',
						discordUsername: 'Gregi',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198137504223',
								type: 'STEAM',
								name: 'Gregi',
								avatar: 'https://avatars.akamai.steamstatic.com/4fced62c6aab0ca14a7c8c2f7dcef78211ce9c8a_full.jpg',
							},
						],
						bio: 'Your local german weeb and Beat Saber Tournament Organiser',
						controllers: 'Rift Touch',
						countryCode: 'DE',
						motto: 'Life is Strange',
						pronouns: 'He/him',
						groups: [
							{id: 'Staff', name: 'Staff Cubes', color: '#f8db01'},
							{id: 'Content', name: 'Content Cubes', color: '#206694'},
							{id: 'Tournament', name: 'Tournament Cubes', color: '#0fa57f'},
						],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'z3jloo',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 165, withinTeam: 13, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'aba80j',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 127, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'x3tkoa',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 140, withinTeam: 9, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '84ldl8',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 141, withinTeam: 10, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'aox2t8',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 116, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '6poql',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 147, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'k288l',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 149, withinTeam: 9, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clxufsh460074qiy3g1kcr7up',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clgj71tpb000kpf4mt1ivf75k',
						discordId: '168313783091986432',
						discordAvatar: 'https://cdn.discordapp.com/avatars/168313783091986432/a6584d960405eb2c3aac7db8ee2047ff.png',
						discordUsername: 'Magician',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198217008717',
								type: 'STEAM',
								name: 'Magician',
								avatar: 'https://avatars.akamai.steamstatic.com/98c5da96c5d1b363b2e8d9f56098237386e466c4_full.jpg',
							},
						],
						bio: 'Caster\n',
						controllers: 'Windows Mixed Reality',
						countryCode: 'DE',
						motto: 'follow my twitter @Ugly_magician',
						pronouns: 'He/him',
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Tournament', name: 'Tournament Cubes', color: '#0fa57f'},
						],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'tpx3z',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 193, withinTeam: 14, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.616Z',
						updatedAt: '2024-07-08T16:23:01.616Z',
					},
					{
						id: 'ys5q749',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 189, withinTeam: 10, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'bu8zi',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 190, withinTeam: 11, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'giwk92',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 192, withinTeam: 13, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'sufvz',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 187, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 't1dlqr',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 188, withinTeam: 9, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: '765xfr',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 191, withinTeam: 12, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clxuhg37x007sqiy39effhniy',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'clgikqixt000ms74m2vrs2c05',
						discordId: '416026760480817174',
						discordAvatar: 'https://cdn.discordapp.com/avatars/416026760480817174/0ef5e087db48f9409e16d89d180038d4.png',
						discordUsername: 'Hammy',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198803740722',
								type: 'STEAM',
								name: 'GF | Hammy',
								avatar: 'https://avatars.akamai.steamstatic.com/a2d19d826e2803e8f0358fc4ff23147b06ffe20a_full.jpg',
							},
						],
						bio: null,
						controllers: 'Valve Index Knuckles',
						countryCode: 'US',
						motto: null,
						pronouns: null,
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Editor', name: 'Editor Cubes', color: '#e91e63'},
							{id: 'Content', name: 'Content Cubes', color: '#206694'},
						],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'az92lu',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 194, withinTeam: 15, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.616Z',
						updatedAt: '2024-07-08T16:23:01.616Z',
					},
					{
						id: 'u4u4bk',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 192, withinTeam: 13, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'mx15g',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 193, withinTeam: 14, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'aaj64',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 194, withinTeam: 15, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'ibxa7a',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 191, withinTeam: 12, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '7lscrl',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 191, withinTeam: 12, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'z82m0f',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 194, withinTeam: 15, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clxulpiqf009wqiy3g71rekv1',
					teamId: 'clxufsbk40072qiy3qj1uzkg2',
					user: {
						id: 'cltkfejhu001cvo934bktikxv',
						discordId: '501043967815712792',
						discordAvatar: 'https://cdn.discordapp.com/avatars/501043967815712792/ddca40060caba91fd7441df66d7c456c.png',
						discordUsername: 'NoneTaken',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198827283834',
								type: 'STEAM',
								name: 'ACC | NoneTaken',
								avatar: 'https://avatars.steamstatic.com/29b90f0270eb714aed581598dd8be4cccb3123f7_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'GB',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: '96076',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 195, withinTeam: 16, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.616Z',
						updatedAt: '2024-07-08T16:23:01.616Z',
					},
					{
						id: 'hq88g',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 193, withinTeam: 14, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '98wqt',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 194, withinTeam: 15, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'qt5sb',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 195, withinTeam: 16, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'gn351',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 192, withinTeam: 13, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'jwfwr',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 192, withinTeam: 13, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: '37o88o',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 195, withinTeam: 16, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clxufsbk00070qiy340lik6pr',
			user: {
				id: 'clgfoxlm50000s04mvxqq8ymg',
				discordId: '186156892379283456',
				discordAvatar: 'https://cdn.discordapp.com/avatars/186156892379283456/e0326016dddf669e5052b309fb5c3d1d.png',
				discordUsername: 'JiveOff',
				discordDiscriminator: '0',
				twitchUser: 'jiveoff',
				playableAccounts: [
					{
						id: '76561198278902434',
						type: 'STEAM',
						name: 'JiveOff',
						avatar: 'https://avatars.akamai.steamstatic.com/001cc0d637b53da03bd9f0284b750adf2c4d259d_full.jpg',
					},
				],
				bio: 'Full Stack Developer & Infrastructure Admin for Cube Community, organizing and programming for tournaments since the dawn of time.',
				controllers: 'Quest 2 Touch',
				countryCode: 'FR',
				motto: 'The art of learning, thinking, and doing',
				pronouns: 'He/him',
				groups: [
					{id: 'Staff', name: 'Staff Cubes', color: '#f8db01'},
					{id: 'Developer', name: 'Developer Cubes', color: '#007990'},
				],
			},
		},
		averageAccuracy: 0.8951777859060582,
		averageNormalizedAccuracy: 0.9117976353382001,
		averageWeightedScore: 4.4539888545164406,
	},
	{
		id: 'clwt4i2ul005v903xb0z776bh',
		name: 'Czechia',
		image: 'https://cdn.cube.community/1717330673091-czechia.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwt4i2uh005t903xf41ul9ie',
					teamId: 'clwt4i2ul005v903xb0z776bh',
					user: {
						id: 'clwt4akl7005r903xxsik72ne',
						discordId: '475650830091943937',
						discordAvatar: 'https://cdn.discordapp.com/avatars/475650830091943937/72f131818421371a2cf667a522e4e4a7.png',
						discordUsername: 'UwU',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{id: '76561198176650281', type: 'STEAM', name: 'uwu', avatar: 'https://cdn.assets.beatleader.xyz/76561198176650281R25.gif'},
						],
						bio: 'hi',
						controllers: 'Valve Index Knuckles',
						countryCode: 'CZ',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9597754295035312,
				averageNormalizedAccuracy: 0.9782665457163988,
				averageWeightedScore: 2.2464794758378797,
				scores: [
					{
						id: 'clxrpuvmy003b84789af6dd42',
						score: 1790187,
						accuracy: 0.9533249726413663,
						normalizedAccuracy: 0.9809152462689389,
						weightedScore: 1.6594342837668976,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 35, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T15:41:20.938Z',
						updatedAt: '2024-06-23T15:44:46.442Z',
					},
					{
						id: 'clxriaegq000d8478qyohbrlb',
						score: 1943687,
						accuracy: 0.9529021867822361,
						normalizedAccuracy: 0.9756970153621729,
						weightedScore: 2.015948293952239,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 55, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T12:09:28.251Z',
						updatedAt: '2024-06-23T12:12:48.813Z',
					},
					{
						id: 'clxrqd3nl003p8478x0g78m0p',
						score: 2069590,
						accuracy: 0.9600658726385007,
						normalizedAccuracy: 0.979093860716886,
						weightedScore: 2.05482885377381,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 76, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T15:55:31.137Z',
						updatedAt: '2024-06-23T16:00:19.253Z',
					},
					{
						id: 'clxrqw3dp003x8478t4ymi9o8',
						score: 1008232,
						accuracy: 0.9586916110014976,
						normalizedAccuracy: 0.9779659109867811,
						weightedScore: 2.0959617889709077,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 97, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T16:10:17.246Z',
						updatedAt: '2024-06-23T16:12:35.616Z',
					},
					{
						id: 'clxrrc5s600498478n9qi2hpk',
						score: 980700,
						accuracy: 0.9645770938758649,
						normalizedAccuracy: 0.9795157654395835,
						weightedScore: 2.3226492970463046,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 106, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T16:22:46.854Z',
						updatedAt: '2024-06-23T16:25:20.938Z',
					},
					{
						id: 'clxrpq3pf00378478n2pn58de',
						score: 649175,
						accuracy: 0.9495374264087468,
						normalizedAccuracy: 0.9685752950075869,
						weightedScore: 2.5673463312993006,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 72, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T15:37:38.115Z',
						updatedAt: '2024-06-23T15:39:10.699Z',
					},
					{
						id: 'clxrr12qt00438478sejwqyif',
						score: 496554,
						accuracy: 0.9793288431765066,
						normalizedAccuracy: 0.9861027262328419,
						weightedScore: 3.009187482055699,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 131, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-23T16:14:09.702Z',
						updatedAt: '2024-06-23T16:17:04.571Z',
					},
				],
			},
			{
				player: {
					id: 'clwti7m980064903xsirnz8sm',
					teamId: 'clwt4i2ul005v903xb0z776bh',
					user: {
						id: 'clwtgvnih0062903x1tnibhcn',
						discordId: '202070081486716929',
						discordAvatar: 'https://cdn.discordapp.com/avatars/202070081486716929/c55c59eb867eb29a13192c43e3e180bf.png',
						discordUsername: 'VláááčeK',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198264187228',
								type: 'STEAM',
								name: 'Gay furry | V l a a a c e K 🐺',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198264187228R49.png',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'CZ',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8594975523760668,
				averageNormalizedAccuracy: 0.7492007708707406,
				averageWeightedScore: 9.284001805463976,
				scores: [
					{
						id: 'clxum0i2n00a8qiy3gl1r0ijn',
						score: 499336,
						accuracy: 0.9848156438904612,
						normalizedAccuracy: 0.9916274783934926,
						weightedScore: 2.210450760838358,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 91, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T16:17:03.360Z',
						updatedAt: '2024-06-25T16:19:57.897Z',
					},
					{
						id: 'clxuk76y20096qiy3hwjmflm6',
						score: 978692,
						accuracy: 0.9626021058015275,
						normalizedAccuracy: 0.9775101901800723,
						weightedScore: 2.4521475557848573,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 115, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T15:26:16.298Z',
						updatedAt: '2024-06-25T15:28:50.126Z',
					},
					{
						id: 'clxul6s33009kqiy3hngmktkw',
						score: 644628,
						accuracy: 0.9428866054777489,
						normalizedAccuracy: 0.9617911276160522,
						weightedScore: 2.905715136180979,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 86, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T15:53:56.656Z',
						updatedAt: '2024-06-25T15:55:29.013Z',
					},
					{
						id: 'clxujs1z2008yqiy34lyotuqq',
						score: 991224,
						accuracy: 0.9425193144269856,
						normalizedAccuracy: 0.9614684736766549,
						weightedScore: 2.9165339894823177,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 126, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T15:14:30.015Z',
						updatedAt: '2024-06-25T15:16:48.015Z',
					},
					{
						id: 'clxukkah2009aqiy38jwdw6xs',
						score: 2012422,
						accuracy: 0.9335461050483027,
						normalizedAccuracy: 0.9520484856283598,
						weightedScore: 3.419415668114766,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 123, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T15:36:27.398Z',
						updatedAt: '2024-06-25T15:41:15.227Z',
					},
					{
						id: 'pyky76',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 185, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'clxukvhac009cqiy3wzee181u',
						score: 796760,
						accuracy: 0.3906155396113749,
						normalizedAccuracy: 0.3999596406005519,
						weightedScore: 26.08374952784656,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 111, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T15:45:09.444Z',
						updatedAt: '2024-06-25T15:48:29.931Z',
					},
				],
			},
			{
				player: {
					id: 'clwt9mmaj0061903xdsjidui7',
					teamId: 'clwt4i2ul005v903xb0z776bh',
					user: {
						id: 'clwt9ldyq005z903xwz02yw8b',
						discordId: '536261946563887143',
						discordAvatar: 'https://cdn.discordapp.com/avatars/536261946563887143/fa8599d6a402f217ea10c9a7351189e5.png',
						discordUsername: 'Blu_VR',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198241145306',
								type: 'STEAM',
								name: 'Blu_VR',
								avatar: 'https://avatars.steamstatic.com/ad80d46b29db0c711b8849da3cd03627b019a9d5_full.jpg',
							},
						],
						bio: null,
						controllers: 'Valve Index Knuckles',
						countryCode: 'CZ',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9243134606223362,
				averageNormalizedAccuracy: 0.5363281042699113,
				averageWeightedScore: 13.365299704909985,
				scores: [
					{
						id: 'clxu3mno0003sqiy3fidgtzbl',
						score: 968777,
						accuracy: 0.9528501104045873,
						normalizedAccuracy: 0.9676071629400055,
						weightedScore: 3.091577453888817,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 132, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T07:42:24.336Z',
						updatedAt: '2024-06-25T07:45:02.876Z',
					},
					{
						id: 'clxu4ds560040qiy3tdpbfj3m',
						score: 986723,
						accuracy: 0.9382394751230181,
						normalizedAccuracy: 0.9571025890733578,
						weightedScore: 3.133690355574854,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 130, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T08:03:29.850Z',
						updatedAt: '2024-06-25T08:05:49.180Z',
					},
					{
						id: 'clxu3co9x003qqiy3i6y5xinj',
						score: 493809,
						accuracy: 0.9739150157286972,
						normalizedAccuracy: 0.9806514520843925,
						weightedScore: 3.7973011771461382,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 146, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T07:34:38.566Z',
						updatedAt: '2024-06-25T07:37:37.251Z',
					},
					{
						id: 'clxu3uk92003uqiy3mtc15gz3',
						score: 568988,
						accuracy: 0.832249241233042,
						normalizedAccuracy: 0.848935525791623,
						weightedScore: 8.534528947760084,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 128, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-25T07:48:33.158Z',
						updatedAt: '2024-06-25T07:50:10.292Z',
					},
					{
						id: '4gut2',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 187, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'hcj7hg',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 190, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'lzgpkq',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 184, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwtirr3c0066903xyk0awqes',
					teamId: 'clwt4i2ul005v903xb0z776bh',
					user: {
						id: 'clws5mzw2005l903xlk7chw7c',
						discordId: '307798877443063809',
						discordAvatar: 'https://cdn.discordapp.com/avatars/307798877443063809/a_b688f3375ba0227e2ce2bf635b42ebe5.png',
						discordUsername: '𝕄𝕒𝕥𝕥𝕪 💛 ℕ𝕖𝕙𝕖𝕥𝕖',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198304253579',
								type: 'STEAM',
								name: 'Nehete_vole',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198304253579R16.gif',
							},
						],
						bio: '2+2=8',
						controllers: 'Quest 3 Touch',
						countryCode: 'CZ',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8900734134239449,
				averageNormalizedAccuracy: 0.516399529652983,
				averageWeightedScore: 14.503374349936097,
				scores: [
					{
						id: 'clxougyry00sv132026mxcs77',
						score: 490596,
						accuracy: 0.9675781750766712,
						normalizedAccuracy: 0.9742707803762074,
						weightedScore: 4.719781797301177,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 151, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-21T15:27:11.374Z',
						updatedAt: '2024-06-21T15:30:06.237Z',
					},
					{
						id: 'clxounc3l00sz13205ew5rufe',
						score: 940634,
						accuracy: 0.9251697870101258,
						normalizedAccuracy: 0.9394981467405906,
						weightedScore: 4.90655230233458,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 140, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-21T15:32:08.577Z',
						updatedAt: '2024-06-21T15:34:42.505Z',
					},
					{
						id: 'clxouqvxw00t11320jm53e64k',
						score: 923044,
						accuracy: 0.8776894002424703,
						normalizedAccuracy: 0.8953351672441288,
						weightedScore: 6.205963236358373,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 138, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-21T15:34:54.261Z',
						updatedAt: '2024-06-21T15:37:12.782Z',
					},
					{
						id: 'clxoul2r600sx1320qk44yr6b',
						score: 540005,
						accuracy: 0.7898562913665119,
						normalizedAccuracy: 0.8056926132099541,
						weightedScore: 10.691323113558566,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 130, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-21T15:30:23.154Z',
						updatedAt: '2024-06-21T15:31:55.711Z',
					},
					{
						id: 'buk6d',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 188, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'wyz7n',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 191, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'g8kh0n',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 186, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clwt81yno005y903xh1d461x9',
					teamId: 'clwt4i2ul005v903xb0z776bh',
					user: {
						id: 'clwt5nzky005w903x5wm9zkwq',
						discordId: '516961691687452672',
						discordAvatar: 'https://cdn.discordapp.com/avatars/516961691687452672/e20d34efac50c564617a3e514a23a876.png',
						discordUsername: 'AZUR24',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198328124070',
								type: 'STEAM',
								name: 'AZUR24',
								avatar: 'https://avatars.steamstatic.com/131b940b4455e57cc51ba054ebf266b2ede22f3a_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'CZ',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: '7bocy',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 192, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.616Z',
						updatedAt: '2024-07-08T16:23:01.616Z',
					},
					{
						id: 'n3lykq',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 186, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '02ubqa',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 189, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'b2el2',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 189, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'j7b5rk',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 183, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '98gf1',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 187, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'tad4vo',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 190, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clwt4i2uh005t903xf41ul9ie',
			user: {
				id: 'clwt4akl7005r903xxsik72ne',
				discordId: '475650830091943937',
				discordAvatar: 'https://cdn.discordapp.com/avatars/475650830091943937/72f131818421371a2cf667a522e4e4a7.png',
				discordUsername: 'UwU',
				discordDiscriminator: '0',
				twitchUser: null,
				playableAccounts: [
					{id: '76561198176650281', type: 'STEAM', name: 'uwu', avatar: 'https://cdn.assets.beatleader.xyz/76561198176650281R25.gif'},
				],
				bio: 'hi',
				controllers: 'Valve Index Knuckles',
				countryCode: 'CZ',
				motto: null,
				pronouns: null,
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.6833200291773658,
		averageNormalizedAccuracy: 0.6950487376275083,
		averageWeightedScore: 9.849788834036985,
	},
	{
		id: 'clv8384bm0010d63s0l88j28z',
		name: 'Slovakia',
		image: 'https://cdn.cube.community/1713616844577-slovakia.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clwpevlmv003n903x7n8fwsf1',
					teamId: 'clv8384bm0010d63s0l88j28z',
					user: {
						id: 'clhvr8ty90004lq2zg52tysa9',
						discordId: '194749473174323200',
						discordAvatar: 'https://cdn.discordapp.com/avatars/194749473174323200/2d304e219b286810a9eee4ff7a0c4530.png',
						discordUsername: '3bygone3',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198102081935',
								type: 'STEAM',
								name: '3bygone3',
								avatar: 'https://avatars.akamai.steamstatic.com/95210ebde9f6b85d74fae65beac492c3a33ca5ef_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'SK',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.7200717811483935,
				averageNormalizedAccuracy: 0.7325279934135086,
				averageWeightedScore: 12.26535028077342,
				scores: [
					{
						id: 'clxeve9ku002i1320oiyh76nu',
						score: 494648,
						accuracy: 0.9755697338448036,
						normalizedAccuracy: 0.982317615658363,
						weightedScore: 3.5564168819982775,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 143, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-14T15:55:23.262Z',
						updatedAt: '2024-06-14T15:58:19.420Z',
					},
					{
						id: 'clxkpk73m00dz1320kvrkhtqg',
						score: 961654,
						accuracy: 0.9144022630565526,
						normalizedAccuracy: 0.9327861347032053,
						weightedScore: 4.34317556809958,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 136, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-18T17:58:39.347Z',
						updatedAt: '2024-06-18T18:00:58.634Z',
					},
					{
						id: 'clxevrcgv002o1320rdt8e8w1',
						score: 948720,
						accuracy: 0.9331228515365663,
						normalizedAccuracy: 0.9475743825714711,
						weightedScore: 4.385076744486005,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 137, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-14T16:05:33.535Z',
						updatedAt: '2024-06-14T16:08:10.211Z',
					},
					{
						id: 'clxnggvun00o31320tzv3sfpu',
						score: 1882756,
						accuracy: 0.8733951082607536,
						normalizedAccuracy: 0.8907053285084878,
						weightedScore: 6.514512818064639,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 131, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-20T16:07:26.783Z',
						updatedAt: '2024-06-20T16:12:16.273Z',
					},
					{
						id: 'clxq8mjch00zl1320tgy6nye8',
						score: 543775,
						accuracy: 0.7953706073792373,
						normalizedAccuracy: 0.8113174891866608,
						weightedScore: 10.410775413007888,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 129, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-22T14:51:12.114Z',
						updatedAt: '2024-06-22T14:52:45.357Z',
					},
					{
						id: 'clxt9jk4t00lz84782rztuz79',
						score: 459683,
						accuracy: 0.24479413793011634,
						normalizedAccuracy: 0.25187874962260626,
						weightedScore: 26.84978605778333,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 109, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T17:40:11.309Z',
						updatedAt: '2024-06-24T17:43:38.251Z',
					},
					{
						id: 'clxta968700ml8478ql0iq23n',
						score: 619775,
						accuracy: 0.3038477660307243,
						normalizedAccuracy: 0.31111625364376605,
						weightedScore: 29.79770848197423,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 119, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T18:00:06.343Z',
						updatedAt: '2024-06-24T18:03:28.295Z',
					},
				],
			},
			{
				player: {
					id: 'clwb1q6wq000k13xt93u76z23',
					teamId: 'clv8384bm0010d63s0l88j28z',
					user: {
						id: 'clw57kojc001bpj8v7c99f3ax',
						discordId: '469429934218280960',
						discordAvatar: 'https://cdn.discordapp.com/avatars/469429934218280960/c8460ddfd27a1fd173f5286dc17d5be5.png',
						discordUsername: 'capple',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{id: '76561198768001659', type: 'STEAM', name: 'C4PPL3', avatar: 'https://cdn.assets.beatleader.xyz/76561198768001659R7.png'},
						],
						bio: 'profesionalny kokot (furrik)',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'SK',
						motto: 'mrowww :3',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.6237389050622569,
				averageNormalizedAccuracy: 0.5431218392484035,
				averageWeightedScore: 16.142621841406914,
				scores: [
					{
						id: 'clxsvkb6800fp84788rqhf0lo',
						score: 937794,
						accuracy: 0.9223764771838716,
						normalizedAccuracy: 0.9366615761544292,
						weightedScore: 5.0897072101122145,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 141, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T11:08:51.729Z',
						updatedAt: '2024-06-24T11:11:25.646Z',
					},
					{
						id: 'clxsvcprz00fl84788d69epet',
						score: 488428,
						accuracy: 0.9633023361306419,
						normalizedAccuracy: 0.9699653660396543,
						weightedScore: 5.342233706574792,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 155, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T11:02:57.408Z',
						updatedAt: '2024-06-24T11:05:52.137Z',
					},
					{
						id: 'clxsvh14g00fn8478l3hg3o45',
						score: 873993,
						accuracy: 0.8310485653837926,
						normalizedAccuracy: 0.8477566278803587,
						weightedScore: 8.572489988903362,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 140, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T11:06:18.736Z',
						updatedAt: '2024-06-24T11:08:36.818Z',
					},
					{
						id: 'clxsw968d00g78478l3uk0csg',
						score: 489671,
						accuracy: 0.7162335905218122,
						normalizedAccuracy: 0.730593804877976,
						weightedScore: 14.43696978717071,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 132, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-24T11:28:11.726Z',
						updatedAt: '2024-06-24T11:29:44.173Z',
					},
					{
						id: 'clxsvorlz00ft8478ckdwz6w1',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 169, withinTeam: 7, counted: false, ghost: false},
						createdAt: '2024-06-24T11:12:19.655Z',
						updatedAt: '2024-06-24T11:12:19.655Z',
					},
					{
						id: '73buvi',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 151, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'clxswc93h00gd847876xz34op',
						score: 631248,
						accuracy: 0.30947246115342286,
						normalizedAccuracy: 0.3168754997864064,
						weightedScore: 29.556952197087337,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 118, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T11:30:35.405Z',
						updatedAt: '2024-06-24T11:33:55.962Z',
					},
				],
			},
			{
				player: {
					id: 'clv8384bj000yd63snazams8y',
					teamId: 'clv8384bm0010d63s0l88j28z',
					user: {
						id: 'clioeyyto000kp92zlb6wlzbk',
						discordId: '223149698452684800',
						discordAvatar: 'https://cdn.discordapp.com/avatars/223149698452684800/4c14005716ab2e2cba9270f501d399b8.png',
						discordUsername: 'mlv1',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198159942254',
								type: 'STEAM',
								name: 'TMBS | Maly Vitalosnik',
								avatar: 'https://avatars.steamstatic.com/976f67fa4733aed1f1bf8384c7c39a2b1191c2cb_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'SK',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9389380866027792,
				averageNormalizedAccuracy: 0.27124754585120553,
				averageWeightedScore: 19.459662087140625,
				scores: [
					{
						id: 'clxtgnq8e00rp8478zv6cjxzm',
						score: 933344,
						accuracy: 0.9179996360828747,
						normalizedAccuracy: 0.9322169497078032,
						weightedScore: 5.376692893073649,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 142, withinTeam: 3, counted: true, ghost: false},
						createdAt: '2024-06-24T20:59:23.150Z',
						updatedAt: '2024-06-24T21:01:57.084Z',
					},
					{
						id: 'clxtge8g400rn84783egoki6c',
						score: 486691,
						accuracy: 0.9598765371226838,
						normalizedAccuracy: 0.9665158712506355,
						weightedScore: 5.840941716910709,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 158, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-24T20:52:00.196Z',
						updatedAt: '2024-06-24T20:54:54.926Z',
					},
					{
						id: 'ev65wa',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 125, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '6ol6h',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 138, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'o287da',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 139, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'oimeyt',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 114, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'ytbypi',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 145, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
				],
			},
			{
				player: {
					id: 'clwb1l0so000e13xth3hs9221',
					teamId: 'clv8384bm0010d63s0l88j28z',
					user: {
						id: 'clhvr6t6m0004lr2zd22vb936',
						discordId: '220191839217647618',
						discordAvatar: 'https://cdn.discordapp.com/avatars/220191839217647618/ff44839dccb9db67bda91868b776f9f1.png',
						discordUsername: 'adqm',
						discordDiscriminator: '0',
						twitchUser: 'onlyadqm',
						playableAccounts: [
							{
								id: '76561198334254404',
								type: 'STEAM',
								name: 'adqm',
								avatar: 'https://avatars.steamstatic.com/95ca08b3513db0ac32b35cf5156589dc5d8700b9_full.jpg',
							},
						],
						bio: 'hi',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'SK',
						motto: 'stay hydrated.',
						pronouns: 'He/him',
						groups: [
							{id: 'Player', name: 'Player', color: '#bebebe'},
							{id: 'Tournament', name: 'Tournament Cubes', color: '#0fa57f'},
						],
					},
				},
				averageAccuracy: 0.4897620479848531,
				averageNormalizedAccuracy: 0.14089990422325513,
				averageWeightedScore: 21.854394815635125,
				scores: [
					{
						id: 'clxrwllmc006k8478t8wh5fc8',
						score: 496653,
						accuracy: 0.9795240959697062,
						normalizedAccuracy: 0.986299329562786,
						weightedScore: 2.98076370944588,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 129, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-23T18:50:05.364Z',
						updatedAt: '2024-06-23T18:53:00.345Z',
					},
					{
						id: 'fm07u9',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 157, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'iim5n3',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 163, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 's67so5',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 166, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'uq04qo',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 148, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'nhbqsw',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 170, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'clxrwqmpu006o8478s846ivnp',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 171, withinTeam: 5, counted: false, ghost: false},
						createdAt: '2024-06-23T18:54:00.066Z',
						updatedAt: '2024-06-23T18:54:00.066Z',
					},
				],
			},
			{
				player: {
					id: 'clwb1pmvb000i13xtx7jm74nr',
					teamId: 'clv8384bm0010d63s0l88j28z',
					user: {
						id: 'cluuviwal000513x2p9b9l2s0',
						discordId: '1213531951370080327',
						discordAvatar: 'https://cdn.discordapp.com/avatars/1213531951370080327/21001d7d1ed5a56b491b9b5e87fb0adf.png',
						discordUsername: 'teusinko',
						discordDiscriminator: '0',
						twitchUser: 'Dedkosmm',
						playableAccounts: [
							{
								id: '76561199501201464',
								type: 'STEAM',
								name: 'Teušinko :3 - EasySkins.com',
								avatar: 'https://avatars.steamstatic.com/e8cba66061d80aa24760feae6e31a7cbf596e57e_full.jpg',
							},
						],
						bio: 'I love Slovenia 🇪🇸🇪🇸🇪🇸',
						controllers: 'Quest 3 Touch',
						countryCode: 'SK',
						motto: 'Click the circles or something idk I don’t play seat baber ',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9613971422091178,
				averageNormalizedAccuracy: 0.13829242773621905,
				averageWeightedScore: 22.231368688733028,
				scores: [
					{
						id: 'clxc2yc6g00af7piojczjv5w1',
						score: 487462,
						accuracy: 0.9613971422091178,
						normalizedAccuracy: 0.9680469941535333,
						weightedScore: 5.619580821131208,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 156, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-12T17:03:38.536Z',
						updatedAt: '2024-06-12T17:06:33.169Z',
					},
					{
						id: 'lsqow',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 159, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'z0asb',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 165, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'j95nsh',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 168, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'v784fj',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 150, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'n7eksf',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 172, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'rpjw0m',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 173, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clwb1kt86000c13xt7eosxrho',
					teamId: 'clv8384bm0010d63s0l88j28z',
					user: {
						id: 'clhvu0t7h0008lr2zh9k87d06',
						discordId: '336900858539278336',
						discordAvatar: 'https://cdn.discordapp.com/avatars/336900858539278336/368d38bc998c30b5b02fe699dca6f680.png',
						discordUsername: "Hi, I'm Milan",
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198277540194',
								type: 'STEAM',
								name: 'imMilanxdddd ツ',
								avatar: 'https://avatars.akamai.steamstatic.com/015df232f3ee7faba76b79b36cb50d39165d2d3d_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'SK',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9336594120721449,
				averageNormalizedAccuracy: 0.13430248656402063,
				averageWeightedScore: 22.80821131208728,
				scores: [
					{
						id: 'clxutcrxf00c8qiy3170m1bbs',
						score: 473398,
						accuracy: 0.9336594120721449,
						normalizedAccuracy: 0.9401174059481444,
						weightedScore: 9.657479184610967,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 161, withinTeam: 6, counted: false, ghost: false},
						createdAt: '2024-06-25T19:42:33.315Z',
						updatedAt: '2024-06-25T19:45:27.957Z',
					},
					{
						id: 'el364r',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 156, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'x1sx1n',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 162, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'fsf2mh',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 165, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'jj0bda',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 147, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'krm4l',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 169, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: '977fv',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 170, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clwb1m50n000g13xt7hoeaw6u',
					teamId: 'clv8384bm0010d63s0l88j28z',
					user: {
						id: 'clhvtb8ut0006lr2zfti2c6w4',
						discordId: '516650838131736577',
						discordAvatar: 'https://cdn.discordapp.com/avatars/516650838131736577/78fc6b0cb0252fb6630a7507ea9ee4c6.png',
						discordUsername: 'ENGeN',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198336425019',
								type: 'STEAM',
								name: 'ENGeN2003',
								avatar: 'https://avatars.akamai.steamstatic.com/83d2ab083eb76aebed718fd01921fbe2af2b7e35_full.jpg',
							},
						],
						bio: 'Hellou there friendo, i am an Beat Saber Veteran playing since 2019 \n19 years old but my hands still swingin like old times xD\nSee ya on the BSWC hehe-',
						controllers: 'Quest 2 Touch',
						countryCode: 'SK',
						motto: 'Even trying is doing something :)',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'j6jkhz',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 182, withinTeam: 7, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'ifqvwc',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 158, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'astx7g',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 164, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '04xh58',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 167, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'sclywk',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 149, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'p5e5f',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 171, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'i5zm38',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 172, withinTeam: 6, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clwpeuo33003l903x4j5ypm8m',
					teamId: 'clv8384bm0010d63s0l88j28z',
					user: {
						id: 'clwb22d4p000l13xth98bc06p',
						discordId: '395879767439835139',
						discordAvatar: 'https://cdn.discordapp.com/avatars/395879767439835139/259672ffe47bc261ac6cbdf981741c1c.png',
						discordUsername: 'Squashi',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198027079794',
								type: 'STEAM',
								name: 'Squashi',
								avatar: 'https://avatars.steamstatic.com/f4e7432889c3773c1f585f0f5ccf488fec760a5b_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'SK',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'l1idvo',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 184, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'xwls1g',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 171, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'twe0o',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 175, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '5fwn86',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 178, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '6lazw',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 164, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '0rjoge',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 178, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: '18u5c',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 178, withinTeam: 8, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clv8384bj000yd63snazams8y',
			user: {
				id: 'clioeyyto000kp92zlb6wlzbk',
				discordId: '223149698452684800',
				discordAvatar: 'https://cdn.discordapp.com/avatars/223149698452684800/4c14005716ab2e2cba9270f501d399b8.png',
				discordUsername: 'mlv1',
				discordDiscriminator: '0',
				twitchUser: null,
				playableAccounts: [
					{
						id: '76561198159942254',
						type: 'STEAM',
						name: 'TMBS | Maly Vitalosnik',
						avatar: 'https://avatars.steamstatic.com/976f67fa4733aed1f1bf8384c7c39a2b1191c2cb_full.jpg',
					},
				],
				bio: null,
				controllers: null,
				countryCode: 'SK',
				motto: null,
				pronouns: null,
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.41578059902407116,
		averageNormalizedAccuracy: 0.4220040036449109,
		averageWeightedScore: 17.422601509961183,
	},
	{
		id: 'clvy8owmh000814nad40e81uo',
		name: 'Latvia',
		image: 'https://cdn.cube.community/1715198146306-latvia.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clvy8owmc000614na1sqrfg26',
					teamId: 'clvy8owmh000814nad40e81uo',
					user: {
						id: 'clvxdwr16000014nahcdgepb6',
						discordId: '269873966632534027',
						discordAvatar: 'https://cdn.discordapp.com/avatars/269873966632534027/03aa6d501952242958c18f305135bf19.png',
						discordUsername: 'manderz11',
						discordDiscriminator: '0',
						twitchUser: 'manderz11',
						playableAccounts: [
							{
								id: '76561198394642894',
								type: 'STEAM',
								name: 'manderz',
								avatar: 'https://avatars.steamstatic.com/455d49fab216fd670922475789af3476fa7a5b32_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest/Rift S Touch',
						countryCode: 'LV',
						motto: null,
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9492338692767488,
				averageNormalizedAccuracy: 0.6896780533772798,
				averageWeightedScore: 9.272894421056053,
				scores: [
					{
						id: 'clxjduxds009w13207zit4j4t',
						score: 999768,
						accuracy: 0.9506434972781516,
						normalizedAccuracy: 0.9697559915728048,
						weightedScore: 2.5043180392724467,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 116, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T19:43:18.401Z',
						updatedAt: '2024-06-17T19:45:36.609Z',
					},
					{
						id: 'clxjcvwpq009k1320xwphxwr8',
						score: 975977,
						accuracy: 0.9599317409500204,
						normalizedAccuracy: 0.9747984686514004,
						weightedScore: 2.6272410679736877,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 122, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T19:16:04.575Z',
						updatedAt: '2024-06-17T19:18:38.763Z',
					},
					{
						id: 'clxjdi0ch009q1320eyao6w0t',
						score: 1898926,
						accuracy: 0.9309578846479112,
						normalizedAccuracy: 0.9532277730898182,
						weightedScore: 2.955239853947203,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 91, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T19:33:15.714Z',
						updatedAt: '2024-06-17T19:36:36.720Z',
					},
					{
						id: 'clxjc33sq009g1320djgvzftn',
						score: 496478,
						accuracy: 0.9791789521433432,
						normalizedAccuracy: 0.9859517984239959,
						weightedScore: 3.0310077519379846,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 132, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T18:53:40.731Z',
						updatedAt: '2024-06-17T18:56:35.868Z',
					},
					{
						id: 'clxjd2guk009o1320795961qp',
						score: 632712,
						accuracy: 0.9254572713643179,
						normalizedAccuracy: 0.9440123419029388,
						weightedScore: 3.7924542342610508,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 110, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T19:21:10.605Z',
						updatedAt: '2024-06-17T19:22:43.092Z',
					},
					{
						id: 'fi5rt',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 161, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'bqm73q',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 143, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'clvyao0af000d14nalphgk78j',
					teamId: 'clvy8owmh000814nad40e81uo',
					user: {
						id: 'clvy9ihls000a14nabmo2qam1',
						discordId: '274947794735726593',
						discordAvatar: 'https://cdn.discordapp.com/avatars/274947794735726593/57982591b1282da11d97adb919bc5758.png',
						discordUsername: 'Janis',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198193906669',
								type: 'STEAM',
								name: 'Janis',
								avatar: 'https://avatars.steamstatic.com/3e08b31ffdf449a1729214710e5a4b2afa6f433e_full.jpg',
							},
						],
						bio: 'bio in progress',
						controllers: 'Quest 2 Touch',
						countryCode: 'LV',
						motto: 'ae',
						pronouns: 'She/her',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.6116200320125295,
				averageNormalizedAccuracy: 0.5323522087193003,
				averageWeightedScore: 13.777251125723046,
				scores: [
					{
						id: 'clxvwl8lv00ch90r9qnt6vk4g',
						score: 948594,
						accuracy: 0.9329989230020213,
						normalizedAccuracy: 0.9474485347215217,
						weightedScore: 4.39320263123952,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 138, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T14:00:53.203Z',
						updatedAt: '2024-06-26T14:03:27.196Z',
					},
					{
						id: 'clxvxdrhm00ed90r9og1ig58x',
						score: 947768,
						accuracy: 0.9011985641952124,
						normalizedAccuracy: 0.9193169781599072,
						weightedScore: 5.01312297968833,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 137, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T14:23:04.043Z',
						updatedAt: '2024-06-26T14:25:22.306Z',
					},
					{
						id: 'clxvwdblv00c790r9w4jx5ne7',
						score: 487404,
						accuracy: 0.9612827516838088,
						normalizedAccuracy: 0.9679318124046772,
						weightedScore: 5.636233132357163,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 157, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T13:54:43.844Z',
						updatedAt: '2024-06-26T13:57:38.618Z',
					},
					{
						id: 'clxvx9xdd00dz90r93ac50iow',
						score: 597696,
						accuracy: 0.8742399531941346,
						normalizedAccuracy: 0.8917681357489963,
						weightedScore: 6.398199136776306,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 123, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T14:20:05.041Z',
						updatedAt: '2024-06-26T14:21:37.422Z',
					},
					{
						id: '2p3rm',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 141, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'clxvwrj0j00cp90r92zys7j3v',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 150, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T14:05:46.627Z',
						updatedAt: '2024-06-26T14:05:46.627Z',
					},
					{
						id: 'clxvxd2xp00eb90r9d7d1qjet',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 130, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T14:22:32.221Z',
						updatedAt: '2024-06-26T14:22:32.221Z',
					},
				],
			},
			{
				player: {
					id: 'clvyao3ou000f14nafysydni4',
					teamId: 'clvy8owmh000814nad40e81uo',
					user: {
						id: 'clvy9tphu000b14nahihzhgob',
						discordId: '217927788823314432',
						discordAvatar: 'https://cdn.discordapp.com/avatars/217927788823314432/b18bd7c2f3fcd6eef6311a95c71f932c.png',
						discordUsername: '0nion_man_LV',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198126382801',
								type: 'STEAM',
								name: '0nion_man_LV',
								avatar: 'https://avatars.akamai.steamstatic.com/199f6baccb5688bc9277742e1508faa0faea4df5_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'LV',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'dhjva5',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 171, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: '981mkt',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 142, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'jjhat4s',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 150, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'q68pyz',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 151, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '04kfjp',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 131, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'dmioh',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 155, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'z07ie',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 156, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clvyao9l3000h14nanibg37hw',
					teamId: 'clvy8owmh000814nad40e81uo',
					user: {
						id: 'clvy9hcxn000914na9owd0l33',
						discordId: '558975029162016798',
						discordAvatar: 'https://cdn.discordapp.com/avatars/558975029162016798/5832ff039d1ea6057b484738f4f2d297.png',
						discordUsername: 'rrurushi',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198414290621',
								type: 'STEAM',
								name: 'rrurushi',
								avatar: 'https://cdn.assets.beatleader.xyz/76561198414290621R4.png',
							},
						],
						bio: null,
						controllers: null,
						countryCode: null,
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'fe5f5r',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 172, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'g7mmb6',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 143, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '4eftsb',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 151, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '1vxv9',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 152, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '6bmml',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 132, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'np1nro',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 156, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: '5ixrxk',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 157, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clwro6gd3004r903x5d7ekf7a',
					teamId: 'clvy8owmh000814nad40e81uo',
					user: {
						id: 'clwnyj5qu002x903xxz1utory',
						discordId: '164496788261175298',
						discordAvatar: 'https://cdn.discordapp.com/avatars/164496788261175298/2d6d4135546fcb844dc23af50712e4c0.png',
						discordUsername: 'GlitchCloud',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{id: '293023', type: 'OCULUS', name: 'GlitchCloud', avatar: 'https://cdn.assets.beatleader.xyz/oculusavatar.png'},
						],
						bio: null,
						controllers: null,
						countryCode: null,
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'y132x',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 190, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.616Z',
						updatedAt: '2024-07-08T16:23:01.616Z',
					},
					{
						id: 'y1tdb',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 182, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'weok0j',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 186, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '5sz8t',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 187, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '0qdi4',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 178, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'vy3pn',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 185, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'ehz15',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 188, withinTeam: 5, counted: false, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clvy8owmc000614na1sqrfg26',
			user: {
				id: 'clvxdwr16000014nahcdgepb6',
				discordId: '269873966632534027',
				discordAvatar: 'https://cdn.discordapp.com/avatars/269873966632534027/03aa6d501952242958c18f305135bf19.png',
				discordUsername: 'manderz11',
				discordDiscriminator: '0',
				twitchUser: 'manderz11',
				playableAccounts: [
					{
						id: '76561198394642894',
						type: 'STEAM',
						name: 'manderz',
						avatar: 'https://avatars.steamstatic.com/455d49fab216fd670922475789af3476fa7a5b32_full.jpg',
					},
				],
				bio: null,
				controllers: 'Quest/Rift S Touch',
				countryCode: 'LV',
				motto: null,
				pronouns: 'He/him',
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.30056748351639007,
		averageNormalizedAccuracy: 0.305507565524145,
		averageWeightedScore: 18.262536386694773,
	},
	{
		id: 'cluzjpgno000rivs9lfczk7p5',
		name: 'South Africa',
		image: 'https://cdn.cube.community/1713100332498-south-africa.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'cluzjpgml000pivs9ehrz6lua',
					teamId: 'cluzjpgno000rivs9lfczk7p5',
					user: {
						id: 'clu5pdrve001qvo93bcasjp98',
						discordId: '243094517509652480',
						discordAvatar: 'https://cdn.discordapp.com/avatars/243094517509652480/ca0ca9e3cd5c48d469dfd598a542e7b3.png',
						discordUsername: '[ N0va ]',
						discordDiscriminator: '0',
						twitchUser: 'Super_N0v4',
						playableAccounts: [
							{
								id: '76561198101982243',
								type: 'STEAM',
								name: '[ N0va ]',
								avatar: 'https://avatars.steamstatic.com/bf1e5255c3d7c2d8216095a4f35c211ae4ccec34_full.jpg',
							},
						],
						bio: null,
						controllers: 'Valve Index Knuckles',
						countryCode: 'ZA',
						motto: null,
						pronouns: 'She/her',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.8509324245756772,
				averageNormalizedAccuracy: 0.7416832399316368,
				averageWeightedScore: 9.651590285848513,
				scores: [
					{
						id: 'clx951qjk005x7pio2qx8yfo1',
						score: 979700,
						accuracy: 0.9635935340778881,
						normalizedAccuracy: 0.9785169729796676,
						weightedScore: 2.387140461756739,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 112, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-10T15:38:57.824Z',
						updatedAt: '2024-06-10T15:41:33.642Z',
					},
					{
						id: 'clx95850j005z7piopge8bno7',
						score: 999453,
						accuracy: 0.9503439750873607,
						normalizedAccuracy: 0.9694504475492459,
						weightedScore: 2.5195156076615044,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 118, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-10T15:43:56.515Z',
						updatedAt: '2024-06-10T15:46:15.499Z',
					},
					{
						id: 'clxkjck7m00cv1320kiz37lgc',
						score: 2049965,
						accuracy: 0.9509619956626115,
						normalizedAccuracy: 0.9698095498067207,
						weightedScore: 2.523273022389841,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 102, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-18T15:04:45.395Z',
						updatedAt: '2024-06-18T15:09:35.614Z',
					},
					{
						id: 'clxkja1lm00cr1320623aubgl',
						score: 642921,
						accuracy: 0.9403898050974513,
						normalizedAccuracy: 0.9592442673263338,
						weightedScore: 3.0327429677035274,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 94, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-18T15:02:47.963Z',
						updatedAt: '2024-06-18T15:04:21.628Z',
					},
					{
						id: 'clx94wnax005v7pioobp6d02v',
						score: 496432,
						accuracy: 0.9790882286232706,
						normalizedAccuracy: 0.9858604473817997,
						weightedScore: 3.044214757393052,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 133, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-10T15:35:00.346Z',
						updatedAt: '2024-06-10T15:37:57.297Z',
					},
					{
						id: 'en8ml',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 126, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'clxkjkvm800cz13206lwehwfv',
						score: 655204,
						accuracy: 0.32121700890548127,
						normalizedAccuracy: 0.3289009944776896,
						weightedScore: 29.05424518403492,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 116, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-18T15:11:13.424Z',
						updatedAt: '2024-06-18T15:14:36.558Z',
					},
				],
			},
			{
				player: {
					id: 'cluzsb0ya0015ivs9jiq2reup',
					teamId: 'cluzjpgno000rivs9lfczk7p5',
					user: {
						id: 'cll8f5ilb0047qi2znkg6avdv',
						discordId: '434683213462503435',
						discordAvatar: 'https://cdn.discordapp.com/avatars/434683213462503435/5668ae64f447cf764a190f382ad9549c.png',
						discordUsername: 'Error404',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198843288904',
								type: 'STEAM',
								name: 'A Random Hacker On the Street',
								avatar: 'https://avatars.akamai.steamstatic.com/c293c28982636383df5d1a086ad95c20011158ac_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 3 Touch',
						countryCode: 'ZA',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.6232804611467265,
				averageNormalizedAccuracy: 0.6339838465778413,
				averageWeightedScore: 17.958766525107936,
				scores: [
					{
						id: 'clxvx19xy00d590r96ta8x34i',
						score: 882418,
						accuracy: 0.8679108698111073,
						normalizedAccuracy: 0.881352444894123,
						weightedScore: 8.660969947117245,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 145, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T14:13:21.430Z',
						updatedAt: '2024-06-26T14:15:55.812Z',
					},
					{
						id: 'clxvryqlr008590r94s2bgorw',
						score: 476179,
						accuracy: 0.9391442405356633,
						normalizedAccuracy: 0.9456401722165735,
						weightedScore: 8.859029572207866,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 160, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T11:51:24.976Z',
						updatedAt: '2024-06-26T11:54:20.275Z',
					},
					{
						id: 'clxvxmjuo00ex90r9hmye80gk',
						score: 858195,
						accuracy: 0.8160268143675565,
						normalizedAccuracy: 0.8324328676131095,
						weightedScore: 9.33468422830125,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 141, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T14:29:54.049Z',
						updatedAt: '2024-06-26T14:32:12.714Z',
					},
					{
						id: 'clxvxc54y00e590r9b7epgk9g',
						score: 518687,
						accuracy: 0.7586748089369949,
						normalizedAccuracy: 0.7738859537745604,
						weightedScore: 12.277719898794464,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 131, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T14:21:48.419Z',
						updatedAt: '2024-06-26T14:23:21.149Z',
					},
					{
						id: 'clxvxhf8g00ej90r9bkm9yu74',
						score: 572188,
						accuracy: 0.30470621753242433,
						normalizedAccuracy: 0.3135247507283494,
						weightedScore: 24.719735696164186,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 107, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T14:25:54.784Z',
						updatedAt: '2024-06-26T14:29:21.686Z',
					},
					{
						id: 'clxvyi1vr00ft90r9hdciqwl5',
						score: 568160,
						accuracy: 0.2785432564205015,
						normalizedAccuracy: 0.28520642276671715,
						weightedScore: 30.880828471901623,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 120, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T14:54:23.752Z',
						updatedAt: '2024-06-26T14:57:46.049Z',
					},
					{
						id: 'clxvyb2j700fp90r967tl39z9',
						score: 857866,
						accuracy: 0.39795702042283737,
						normalizedAccuracy: 0.40584431405145566,
						weightedScore: 30.978397861268917,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 135, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-26T14:48:58.003Z',
						updatedAt: '2024-06-26T14:53:47.605Z',
					},
				],
			},
			{
				player: {
					id: 'cluzsachu0013ivs9kngzp9ef',
					teamId: 'cluzjpgno000rivs9lfczk7p5',
					user: {
						id: 'cluy49gws0006ivs9clxtgebe',
						discordId: '247432366245675018',
						discordAvatar: 'https://cdn.discordapp.com/avatars/247432366245675018/516df5e57bc244144e606deb6a72cc27.png',
						discordUsername: 'Azimuth',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198058231982',
								type: 'STEAM',
								name: 'AzimuthL_',
								avatar: 'https://avatars.steamstatic.com/ee72d35db0c19b47ad1485e596b187359a298bfc_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'ZA',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: '7h5ju',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 163, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'v7dumv',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 124, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'q9mewe',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 137, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'gnlvx',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 138, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'e3fqvk',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 113, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'bi2r39',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 144, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: '5mltu',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 147, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'cluzsb8f30017ivs9arr89yb4',
					teamId: 'cluzjpgno000rivs9lfczk7p5',
					user: {
						id: 'cluy8z7tu0007ivs9alpt9wmg',
						discordId: '325649244193161216',
						discordAvatar: 'https://cdn.discordapp.com/avatars/325649244193161216/ac5cb8b56e5dfed14a739eb019868900.png',
						discordUsername: 'Ezkills',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198089492236',
								type: 'STEAM',
								name: 'Ezkills',
								avatar: 'https://avatars.steamstatic.com/7bfcda45d3a7032e63b879f3fd16f9297b7e1bc6_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'ZA',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'a6xrpr',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 164, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'q5k8e5d',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 126, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'nldlh31',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 139, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 's0xcq',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 140, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '8by32o',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 115, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '8tie2m',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 146, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'zooz7h',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 148, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'cluzjpgml000pivs9ehrz6lua',
			user: {
				id: 'clu5pdrve001qvo93bcasjp98',
				discordId: '243094517509652480',
				discordAvatar: 'https://cdn.discordapp.com/avatars/243094517509652480/ca0ca9e3cd5c48d469dfd598a542e7b3.png',
				discordUsername: '[ N0va ]',
				discordDiscriminator: '0',
				twitchUser: 'Super_N0v4',
				playableAccounts: [
					{
						id: '76561198101982243',
						type: 'STEAM',
						name: '[ N0va ]',
						avatar: 'https://avatars.steamstatic.com/bf1e5255c3d7c2d8216095a4f35c211ae4ccec34_full.jpg',
					},
				],
				bio: null,
				controllers: 'Valve Index Knuckles',
				countryCode: 'ZA',
				motto: null,
				pronouns: 'She/her',
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.3381627776957553,
		averageNormalizedAccuracy: 0.3439167716273694,
		averageWeightedScore: 19.40258920273911,
	},
	{
		id: 'cluzrr92s000zivs9jul0x349',
		name: 'Saudi Arabia',
		image: 'https://cdn.cube.community/1713113852971-saudi-arabia.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clvy7hf4q000414nac7btldwn',
					teamId: 'cluzrr92s000zivs9jul0x349',
					user: {
						id: 'clvy7fwec000214nafeilvxhk',
						discordId: '355636551226753029',
						discordAvatar: 'https://cdn.discordapp.com/avatars/355636551226753029/423ddfd0a9dcd71088321e2b03597844.png',
						discordUsername: 'abdllah351',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198418164608',
								type: 'STEAM',
								name: 'abdllah351',
								avatar: 'https://avatars.steamstatic.com/6a1bb05822eb5654a5faaa607abdcc2e6ec4fe73_full.jpg',
							},
						],
						bio: 'fear the saudis (we are so cooked)',
						controllers: 'Quest/Rift S Touch',
						countryCode: 'SA',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9357068533423568,
				averageNormalizedAccuracy: 0.6792901710363506,
				averageWeightedScore: 9.914168383809903,
				scores: [
					{
						id: 'clxtjw62s001596lnxbzjvo4a',
						score: 495079,
						accuracy: 0.9764197737828749,
						normalizedAccuracy: 0.9831735352058973,
						weightedScore: 3.4326729830605798,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 139, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-24T22:29:55.780Z',
						updatedAt: '2024-06-24T22:32:51.047Z',
					},
					{
						id: 'clxuzm1wb00g3qiy3k2zm4ks8',
						score: 962708,
						accuracy: 0.946880885990666,
						normalizedAccuracy: 0.9615454915007756,
						weightedScore: 3.482974332516445,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 134, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T22:37:43.836Z',
						updatedAt: '2024-06-25T22:40:19.452Z',
					},
					{
						id: 'clxv2kxzy001990r9kc41jkml',
						score: 2003689,
						accuracy: 0.9294949377805096,
						normalizedAccuracy: 0.9479170264090745,
						weightedScore: 3.6278703394280805,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 124, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-26T00:00:50.974Z',
						updatedAt: '2024-06-26T00:05:40.191Z',
					},
					{
						id: 'clxv1oygf000r90r9fxdwqjva',
						score: 974060,
						accuracy: 0.9261986830532246,
						normalizedAccuracy: 0.9448197193262899,
						weightedScore: 3.7446326048149756,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 135, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T23:35:58.575Z',
						updatedAt: '2024-06-25T23:38:17.926Z',
					},
					{
						id: 'clxv2awpc001590r9u4uhfeox',
						score: 614993,
						accuracy: 0.8995399861045087,
						normalizedAccuracy: 0.917575424812417,
						weightedScore: 5.111028426849233,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 119, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-25T23:53:02.736Z',
						updatedAt: '2024-06-25T23:54:35.938Z',
					},
					{
						id: '8jz4wm',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 140, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'heuw2h',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 129, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
				],
			},
			{
				player: {
					id: 'cluzrv3cm0011ivs9uwwjg0ve',
					teamId: 'cluzrr92s000zivs9jul0x349',
					user: {
						id: 'clud93pxx001uvo93k3fzjizm',
						discordId: '685128853009465416',
						discordAvatar: 'https://cdn.discordapp.com/avatars/685128853009465416/a_ae848bcef1be0f0bd1a17076b6f33b55.png',
						discordUsername: 'Vaso',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561199270590853',
								type: 'STEAM',
								name: 'vaso',
								avatar: 'https://avatars.steamstatic.com/c156fc9aa895eada0b98db64e44be0821ad91557_full.jpg',
							},
						],
						bio: "Heyo!, i'm Vaso\ni play on a laptop which handles pretty well, trying my best to be better in BS, yep thats it\n\nif u ever need me my discord is connected! :P\ncya!",
						controllers: 'Quest 2 Touch',
						countryCode: 'SA',
						motto: 'Vaso',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.9316141883696392,
				averageNormalizedAccuracy: 0.13400829081632654,
				averageWeightedScore: 22.85074443213978,
				scores: [
					{
						id: 'clxuvn6gu00d3qiy3460yaub8',
						score: 472361,
						accuracy: 0.9316141883696392,
						normalizedAccuracy: 0.9380580357142857,
						weightedScore: 9.955211024978468,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 162, withinTeam: 2, counted: true, ghost: false},
						createdAt: '2024-06-25T20:46:37.950Z',
						updatedAt: '2024-06-25T20:49:34.143Z',
					},
					{
						id: 'ty2na',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 123, withinTeam: 1, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'or2sek',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 136, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: '321r9l',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 137, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'dc8bt8a',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 112, withinTeam: 1, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '41s5h',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 143, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'sgavw7',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 146, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'cluzrr92n000xivs9esmcrrom',
					teamId: 'cluzrr92s000zivs9jul0x349',
					user: {
						id: 'clj1p849a001cpn2zz5887ryj',
						discordId: '795884452844666891',
						discordAvatar: 'https://cdn.discordapp.com/avatars/795884452844666891/60e51fc488c43b5c23640197bbbad4e8.png',
						discordUsername: 'abbad',
						discordDiscriminator: '0',
						twitchUser: 'Mog_abbad',
						playableAccounts: [
							{
								id: '76561199542806425',
								type: 'STEAM',
								name: 'abbad',
								avatar: 'https://avatars.steamstatic.com/d113fa8990be71b107e61f5b1832ad6d232031cb_full.jpg',
							},
							{id: '9680', type: 'OCULUS', name: 'abbad', avatar: 'https://cdn.assets.beatleader.xyz/9680R21.png'},
						],
						bio: 'worst top 1000 player',
						controllers: 'Quest 2 Touch',
						countryCode: null,
						motto: "I can't acc",
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'nvy73g',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 166, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: '06led9',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 129, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'jnf5ec',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 143, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'kxewz',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 143, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: '6u2som',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 118, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'n5tddk',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 149, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: '7q8nl',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 151, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clv8im1kj0012d63ssd53ln7s',
					teamId: 'cluzrr92s000zivs9jul0x349',
					user: {
						id: 'clv1emex2000wykbvnax0obec',
						discordId: '338299742603706368',
						discordAvatar: 'https://cdn.discordapp.com/avatars/338299742603706368/a_46dcd6042dc58e2902a355a1402defd0.png',
						discordUsername: 'Potato',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198832472169',
								type: 'STEAM',
								name: 'Potato',
								avatar: 'https://avatars.steamstatic.com/190f6ea182b85f4aa6c8d0aa0d2dcb7ab08e8746_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'SA',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: '0tgbpv',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 168, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'i0j5s8',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 133, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: 'a8bz0x',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 145, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'kq4a3r',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 145, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'k06qc',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 123, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '9h5w3h',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 151, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'rh3y0z',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 154, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'cluzrr92n000xivs9esmcrrom',
			user: {
				id: 'clj1p849a001cpn2zz5887ryj',
				discordId: '795884452844666891',
				discordAvatar: 'https://cdn.discordapp.com/avatars/795884452844666891/60e51fc488c43b5c23640197bbbad4e8.png',
				discordUsername: 'abbad',
				discordDiscriminator: '0',
				twitchUser: 'Mog_abbad',
				playableAccounts: [
					{
						id: '76561199542806425',
						type: 'STEAM',
						name: 'abbad',
						avatar: 'https://avatars.steamstatic.com/d113fa8990be71b107e61f5b1832ad6d232031cb_full.jpg',
					},
					{id: '9680', type: 'OCULUS', name: 'abbad', avatar: 'https://cdn.assets.beatleader.xyz/9680R21.png'},
				],
				bio: 'worst top 1000 player',
				controllers: 'Quest 2 Touch',
				countryCode: null,
				motto: "I can't acc",
				pronouns: 'He/him',
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.20036244482433657,
		averageNormalizedAccuracy: 0.2033246154631693,
		averageWeightedScore: 20.691228203987425,
	},
	{
		id: 'clvmuncs0002ud63s3vprionn',
		name: 'Hungary',
		image: 'https://cdn.cube.community/1714509431242-hungary.svg',
		tournamentId: 'bswc-2024',
		players: [
			{
				player: {
					id: 'clvzdk9h5000z14nav3ulx6fi',
					teamId: 'clvmuncs0002ud63s3vprionn',
					user: {
						id: 'cli8278fx000uob30944y2tsv',
						discordId: '332093547530682371',
						discordAvatar: 'https://cdn.discordapp.com/avatars/332093547530682371/3fe18f9371fbcff92de2fe724d493cde.png',
						discordUsername: 'Hiro',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198295961047',
								type: 'STEAM',
								name: 'Hiro',
								avatar: 'https://avatars.steamstatic.com/ffa24242f85fb3de4d41f0a1c52e3b6a2cc95711_full.jpg',
							},
						],
						bio: "19 years old person who's trying to master all the genres",
						controllers: 'Quest/Rift S Touch',
						countryCode: 'HU',
						motto: 'AMABATAKUUUUUUUUUUM',
						pronouns: 'He/him',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0.6369388806132958,
				averageNormalizedAccuracy: 0.6472810093039284,
				averageWeightedScore: 11.740738852984324,
				scores: [
					{
						id: 'clxenm5xi00261320ff3xyyqy',
						score: 489162,
						accuracy: 0.9647499679509304,
						normalizedAccuracy: 0.9714230109303508,
						weightedScore: 5.131495836922194,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 154, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-14T12:17:34.855Z',
						updatedAt: '2024-06-14T12:20:30.895Z',
					},
					{
						id: 'clxj4v8vn00921320h3e3vlb6',
						score: 931006,
						accuracy: 0.915700073275205,
						normalizedAccuracy: 0.9298817729365197,
						weightedScore: 5.527473236166645,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 143, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T15:31:36.756Z',
						updatedAt: '2024-06-17T15:34:11.426Z',
					},
					{
						id: 'clxj64z3k009413203v1v724q',
						score: 601193,
						accuracy: 0.8793549566680074,
						normalizedAccuracy: 0.8969856931204931,
						weightedScore: 6.137966959368954,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 121, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T16:07:10.256Z',
						updatedAt: '2024-06-17T16:08:43.214Z',
					},
					{
						id: 'clxenv7lw002c1320bo7w3ihp',
						score: 1842987,
						accuracy: 0.8549465944541733,
						normalizedAccuracy: 0.8718911751028134,
						weightedScore: 7.463789564138063,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 132, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-14T12:24:36.933Z',
						updatedAt: '2024-06-14T12:29:25.696Z',
					},
					{
						id: 'clxj4rrvj00901320mphfyjru',
						score: 887425,
						accuracy: 0.8438205719447548,
						normalizedAccuracy: 0.860785413037321,
						weightedScore: 7.924446374294399,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 139, withinTeam: 1, counted: true, ghost: false},
						createdAt: '2024-06-17T15:28:54.752Z',
						updatedAt: '2024-06-17T15:31:13.761Z',
					},
					{
						id: 'clxenrk0k00281320mqxzqzp0',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 149, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-14T12:21:46.388Z',
						updatedAt: '2024-06-14T12:21:46.388Z',
					},
					{
						id: 'clxj4qye6008y1320mzf5q657',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 138, withinTeam: 4, counted: true, ghost: false},
						createdAt: '2024-06-17T15:28:16.542Z',
						updatedAt: '2024-06-17T15:28:16.542Z',
					},
				],
			},
			{
				player: {
					id: 'clvzdjqhg000t14naj504les7',
					teamId: 'clvmuncs0002ud63s3vprionn',
					user: {
						id: 'clj1dwizp0000nv30de8uakkf',
						discordId: '322772059283259393',
						discordAvatar: 'https://cdn.discordapp.com/avatars/322772059283259393/7503599a964b4e34651548a3eb78722d.png',
						discordUsername: 'Blue Way',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198363166529',
								type: 'STEAM',
								name: 'Blue Way',
								avatar: 'https://avatars.steamstatic.com/8e020c5bd644d153ae1bffbdf5f2b6e62ff3d545_full.jpg',
							},
						],
						bio: null,
						controllers: 'Quest 2 Touch',
						countryCode: 'HU',
						motto: 'Hi Blue Way',
						pronouns: '',
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: '4h9xft',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 174, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: '3svpli',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 146, withinTeam: 1, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '0p8z3',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 153, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'psto7',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 154, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'zr94fs',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 135, withinTeam: 1, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '39er4k',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 158, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'witcon',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 159, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clvzdjuyz000v14na0qd0io7p',
					teamId: 'clvmuncs0002ud63s3vprionn',
					user: {
						id: 'clgj7c5fe000uo14mdtel3wmk',
						discordId: '357596529642569728',
						discordAvatar: 'https://cdn.discordapp.com/avatars/357596529642569728/a7bd4216e0b92a5da8f115defafb85ac.png',
						discordUsername: 'Mr.TBN.-',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198275958920',
								type: 'STEAM',
								name: 'ACC | TBN',
								avatar: 'https://avatars.akamai.steamstatic.com/62af895746a4b2d84195bbb2a2c498000f0fa8ea_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'HU',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: '1u1hd8',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 175, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: 'nasxx',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 147, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '366miq',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 154, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'u6jqcq',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 155, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 'urqtno',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 136, withinTeam: 2, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: 'gaoiss',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 159, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'tv1sig',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 160, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
			{
				player: {
					id: 'clvzdjxxg000x14nayxv8onyx',
					teamId: 'clvmuncs0002ud63s3vprionn',
					user: {
						id: 'cli805tuz000oob30l9ss83uk',
						discordId: '221136608810893312',
						discordAvatar: 'https://cdn.discordapp.com/avatars/221136608810893312/2a6389b5007270bc29bb13c2d36a11ca.png',
						discordUsername: 'keppy',
						discordDiscriminator: '0',
						twitchUser: null,
						playableAccounts: [
							{
								id: '76561198069899344',
								type: 'STEAM',
								name: '-VGN- Kaoplo',
								avatar: 'https://avatars.steamstatic.com/a28b8a23f82ce5ddb02f9fc816531b2f27c02eed_full.jpg',
							},
						],
						bio: null,
						controllers: null,
						countryCode: 'HU',
						motto: null,
						pronouns: null,
						groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
					},
				},
				averageAccuracy: 0,
				averageNormalizedAccuracy: 0,
				averageWeightedScore: 25,
				scores: [
					{
						id: 'so01ob',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Osmosis',
							songAuthor: 'Good Kid',
							levelAuthor: 'fqrb',
							characteristic: 'Standard',
							difficulty: 'Normal',
							hash: 'afcf534d1f7d45edaf5abd0df3f17e5b4b883b60',
							key: '36a2a',
							maxScore: 507035,
							nps: 3.287,
						},
						standings: {overall: 176, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.615Z',
						updatedAt: '2024-07-08T16:23:01.615Z',
					},
					{
						id: '7uqym',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'KOKUSHIMUSOU',
							songAuthor: 'Laur',
							levelAuthor: 'Aquaflee',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '0a53b842f59843728361a1b75704fa14ac3f28de',
							key: '328aa',
							maxScore: 2039755,
							nps: 11.9,
						},
						standings: {overall: 148, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.618Z',
						updatedAt: '2024-07-08T16:23:01.618Z',
					},
					{
						id: '2hxce',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Los! Los! Los!',
							songAuthor: 'Tanya Degurechaff (CV: Aoi Yuuki)',
							levelAuthor: 'CoolingCloset',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'f3bd378dfe91f4371b976493c95936878d65448e',
							key: '34df1',
							maxScore: 683675,
							nps: 8.558,
						},
						standings: {overall: 155, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.619Z',
						updatedAt: '2024-07-08T16:23:01.619Z',
					},
					{
						id: 'zb8tqg',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Field of Hopes and Dreams',
							songAuthor: 'Toby Fox',
							levelAuthor: 'Nolanimations',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: 'bee0a7386f598c2fdbf09faef2e728c6d90a700c',
							key: '3676a',
							maxScore: 2155675,
							nps: 8.474,
						},
						standings: {overall: 156, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.620Z',
						updatedAt: '2024-07-08T16:23:01.620Z',
					},
					{
						id: 't417yq',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Impulse',
							songAuthor: 'surk',
							levelAuthor: 'Voidless',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '2613a51986afdbd2fdf866917b0e43d85d113c4d',
							key: '3b5cf',
							maxScore: 1877835,
							nps: 10.385,
						},
						standings: {overall: 137, withinTeam: 3, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.621Z',
						updatedAt: '2024-07-08T16:23:01.621Z',
					},
					{
						id: '8rji8a',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'Crimson Throne',
							songAuthor: 'Dimier√Lisb',
							levelAuthor: 'yabje & Joshabi',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '1003d64c909953e174ca2ff1d01866a4d54bd1bf',
							key: '31b10',
							maxScore: 1051675,
							nps: 8.992,
						},
						standings: {overall: 160, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.622Z',
						updatedAt: '2024-07-08T16:23:01.622Z',
					},
					{
						id: 'q7iw7s',
						score: 0,
						accuracy: 0,
						normalizedAccuracy: 0,
						weightedScore: 25,
						map: {
							songName: 'The Title Track',
							songAuthor: 'Origami Angel',
							levelAuthor: 'Tranch',
							characteristic: 'Standard',
							difficulty: 'ExpertPlus',
							hash: '6f692b1c4f79eae43ccedab51dadfa2a70516e0c',
							key: '1e95f',
							maxScore: 1016715,
							nps: 7.867,
						},
						standings: {overall: 161, withinTeam: 4, counted: true, ghost: true},
						createdAt: '2024-07-08T16:23:01.623Z',
						updatedAt: '2024-07-08T16:23:01.623Z',
					},
				],
			},
		],
		captain: {
			id: 'clvmuncrw002sd63swd1v21vf',
			user: {
				id: 'clgkjffxe001kpf4mbhy2e2xp',
				discordId: '469171963236057120',
				discordAvatar: 'https://cdn.discordapp.com/avatars/469171963236057120/e45fed957cdc1cbd5ace4285d4d0b074.png',
				discordUsername: 'ServerBP',
				discordDiscriminator: '0',
				twitchUser: null,
				playableAccounts: [
					{
						id: '76561199016011545',
						type: 'STEAM',
						name: '고양이두마리',
						avatar: 'https://avatars.steamstatic.com/5902f894475242c34db9ace8e1113e285d8fdf56_full.jpg',
					},
				],
				bio: 'Hi!\nI am ServerBP a 17 year old who enjoys music and all sorts of games.\n- Beat Saber player(quitting ranked)\n- Discord Developer(I make bots)\nHave fun!',
				controllers: 'Valve Index Knuckles',
				countryCode: 'HU',
				motto: null,
				pronouns: 'She/Her',
				groups: [{id: 'Player', name: 'Player', color: '#bebebe'}],
			},
		},
		averageAccuracy: 0.15923472015332396,
		averageNormalizedAccuracy: 0.1618202523259821,
		averageWeightedScore: 21.68518471324608,
	},
];
